import AspApi from "../../../api/ApiConfig";

export const CreateAccount = async (payload) => {
  const res = await AspApi.post("/api/v1/accounts", payload);
  return res;
};

export const CreateBrand = async (payload) => {
  const res = await AspApi.post("/api/v1/brands", payload);
  return res;
};

export const WhatsappOTPSent = async (payload) => {
  const res = await AspApi.post("/api/v1/user/sent_whatsapp_otp", payload);
  return res;
};

export const InviteTeamMembers = async (id, payload) => {
  const res = await AspApi.post(`/api/v1/accounts/${id}/invite_users`, payload);
  return res;
};

export const InviteTokenValidateWithLogin = async (payload) => {
  const res = await AspApi.post(
    "/api/v1/brand/validate_intitation_token",
    payload
  );
  return res;
};

export const InviteTokenValidateWithoutLogin = async (payload) => {
  const res = await AspApi.post(
    "/api/v1/svc/aaa/authentication/validate_invitation_token",
    payload
  );
  return res;
};

export const AssignBrandUsers = async (id, payload) => {
  const res = await AspApi.post(`/api/v1/brands/${id}/assign_user`, payload);
  return res;
};
