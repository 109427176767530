import React from 'react';
import { addLineBreake, replaceFormating } from '../../helper';
import { cn } from '../../../../helper/cn';

export default function DocumentMessagePreview({
  url,
  caption,
  fileName,
  urls,
  content,
  dateTime,
  rootClassName,
  className,
  documentClassName,
}) {
  return (
    <div
      className={cn(
        'flex flex-col relative w-auto max-w-[26vw] min-h-[3.5vh] bg-[#F4F6F8] rounded-[0.6vw] rounded-tr-none p-[0.8vw]',
        rootClassName
      )}
      style={{ boxShadow: '0px 4px 16px 0px #0000000A' }}>
      {content && (
        <div className='w-full flex-row align-center bg-[var(--channelButton)] inbox12px-400 text-[var(--font-600)] b-radius-6 mb-10'>
          <div className=' h-[7vh] bg-[#6564CA] w-1' />
          <div className='flex flex-1 items-center justify-between'>
            <div className='truncate max-w-[25ch] ml-10'>{content}</div>
            {urls?.[0] && (
              <iframe
                className='w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10'
                src={urls?.[0]}></iframe>
            )}
          </div>
        </div>
      )}
      <div className={cn('w-[18vw] h-full', className)}>
        {/* <iframe
          title='cover'
          className='imagePreview w-full h-full object-cover rounded-[0.4vw]'
          src={url}
          sandbox='allow-scripts allow-same-origin'
          // type='application/pdf'
          width='100%'
          height='150px'
        ></iframe> */}
        <embed
          src={url}
          type='application/pdf'
          className={cn('imagePreview w-full', documentClassName)}
        />
      </div>
      {fileName && (
        <p className='pt-10 new-body-text w-full  break-words'>{fileName}</p>
      )}
      {caption && (
        <p
          className='new-body-text px-[0.5vw] pt-[0.5vw]'
          dangerouslySetInnerHTML={{
            __html: addLineBreake(replaceFormating(caption)),
          }}></p>
      )}
    </div>
  );
}
