import AspApi from '../ApiConfig';

export const createCampaignApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/campaigns?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const campaignList = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);
  if (query?.sort_by) qpm.push(`sort_by=${query?.sort_by}`);
  if (query?.order_by) qpm.push(`order_by=${query?.order_by}`);
  if (query?.campaign_type) qpm.push(`campaign_type=${query?.campaign_type}`);
  if (query?.goal_type) qpm.push(`goal_type=${query?.goal_type}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '&' + qpm.join('&');

  const res = await AspApi.get(
    `/api/v1/campaigns?brand_id=${brandId}${qpmString}`
  );
  return res;
};

export const getSingleCampaign = async (brandId, campaignId) => {
  const res = await AspApi.get(
    `/api/v1/campaigns/${campaignId}?brand_id=${brandId}`
  );
  return res;
};

export const broadcastList = async (brandId, campaignId, query) => {
  let qpm = [];
  if (brandId) qpm.push(`brand_id=${brandId}`);
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.status) qpm.push(`status=${query?.status}`);
  if (query?.type) qpm.push(`type=${query?.type}`);
  if (query?.search) qpm.push(`search=${query?.search}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  // new api for broacast list
  const res = await AspApi.get(
    `/api/v1/campaign_utils/${campaignId}/campaign_execution_list${qpmString}`
  );
  return res;
};

export const contactList = async (
  brandId,
  broadcastId,
  broadcastPartId,
  query
) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '' + qpm.join('&');

  const res = await AspApi.get(
    `api/v1/broadcast/contacts?brand_id=${brandId}&broadcast_id=${broadcastId}&broadcast_part=${broadcastPartId}&${qpmString}`
  );
  return res;
};
