import { useEffect, useState } from "react";
import "./appLayout.css";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import SideBar from "./SideBar";

import updateToken from "../../api/updateToken";

function Index({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const TOKEN = localStorage.getItem("authToken");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNavigate, setPageNavigate] = useState("");

  useEffect(() => {
    if (TOKEN) {
      // navigate("/Accounts");
      updateToken(TOKEN);
      localStorage.removeItem("verify-email");
    } else {
      navigate("/login");
    }
  }, []);

  const getActiveNav = () => {
    const splits = pathname.split("/");
    return splits.includes("account-settings")
      ? splits.includes("settings")
        ? "Settings"
        : "Account"
      : null;
  };

  return (
    <>
      {isModalVisible && <div className="modal-overlay" />}
      <div className="appWrapper">
        {/* <Header /> */}
        {pathname.includes("/user") ? (
          <section>
            {/* <SideBar
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              activeNav={pageNavigate}
            /> */}
            <SideBar />

            <div className="mainLayout">
              <Outlet />
              {children}
              {/* <div className='campaignCreateWrapper'>
                <div className='CreateButtonWrapper flex-column space-between align-right'>
                  <Create />
                </div>
              </div> */}
            </div>
          </section>
        ) : (
          <section>
            {/* <AccountSettingsSideBar /> */}
            <SideBar />

            <div className="mainLayout">
              <Outlet />
              {children}
              <div className="campaignCreateWrapper">
                <div className="CreateButtonWrapper flex-column space-between align-right">
                  {/* <Create /> */}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default Index;
