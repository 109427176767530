import React from 'react';

const SendTextPreview = ({ data, text }) => {
  return (
    <div className='w-fit max-w-[60%] px-3 py-2 bg-[#D8FBD2] rounded-t-xl rounded-bl-xl text-base break-words weight-medium text-[var(--contentText)]'>
      {text}
    </div>
  );
};

export default SendTextPreview;
