import AspApi from 'api/ApiConfig';

export const getSequenceOverviewContactlists = async (seqId, payload) => {
  let qpm = [];
  if (payload?.brandId) qpm.push(`brand_id=${payload?.brandId}`);
  if (payload?.campaignId) qpm.push(`campaign_id=${payload?.campaignId}`);
  if (payload?.status) qpm.push(`status=${payload?.status}`);
  if (payload?.order) qpm.push(`order=${payload?.order}`);
  if (payload?.sort) qpm.push(`sort=${payload?.sort}`);
  if (payload?.search) qpm.push(`search=${payload?.search}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/sequence_contact_overview${qpmString}`
  );

  return res;
};

export const getSequenceActivitesCard = async (seqId, payload) => {
  let qpm = [];
  if (payload?.brandId) qpm.push(`brand_id=${payload?.brandId}`);
  if (payload?.campaignId) qpm.push(`campaign_id=${payload?.campaignId}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/sequence_activities${qpmString}`
  );

  return res;
};

export const updateSequenceStatus = async (seqId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/change_state`,
    payload
  );

  return res;
};

export const getSequenceOverviewPerformenceMetrics = async (seqId, payload) => {
  let qpm = [];
  if (payload?.brandId) qpm.push(`brand_id=${payload?.brandId}`);
  if (payload?.campaignId) qpm.push(`campaign_id=${payload?.campaignId}`);
  if (payload?.startData) qpm.push(`from_date=${payload?.startData}`);
  if (payload?.endDate) qpm.push(`to_date=${payload?.endDate}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/performance_metrics${qpmString}`
  );

  return res;
};

export const getSequenceOverviewPreview = async (seqId, payload) => {
  let qpm = [];
  if (payload?.brandId) qpm.push(`brand_id=${payload?.brandId}`);
  if (payload?.campaignId) qpm.push(`campaign_id=${payload?.campaignId}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/previews/show${qpmString}`
  );

  return res;
};

// PREVIEW
export const previewSendButtonClick = async (seqId, query, payload) => {
  let qpm = [];
  if (query?.brandId) qpm.push(`brand_id=${query?.brandId}`);
  if (query?.campaignId) qpm.push(`campaign_id=${query?.campaignId}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/previews/${query?.previewId}${qpmString}`,
    payload
  );
  return res;
};

export const previewSendCustomTextMessage = async (seqId, query, payload) => {
  let qpm = [];
  if (query?.brandId) qpm.push(`brand_id=${query?.brandId}`);
  if (query?.campaignId) qpm.push(`campaign_id=${query?.campaignId}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/previews/${query?.previewId}/compose${qpmString}`,
    payload
  );
  return res;
};

export const previewResetMessage = async (seqId, query) => {
  let qpm = [];
  if (query?.brandId) qpm.push(`brand_id=${query?.brandId}`);
  if (query?.campaignId) qpm.push(`campaign_id=${query?.campaignId}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/previews/${query?.previewId}/reset${qpmString}`
  );
  return res;
};

export const exportAllContacts = async (seqId, query) => {
  let qpm = [];
  if (query?.brandId) qpm.push(`brand_id=${query?.brandId}`);
  if (query?.campaignId) qpm.push(`campaign_id=${query?.campaignId}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/export_contacts_metrics${qpmString}`
  );
  return res;
};
