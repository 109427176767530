import React from "react";

const Subscription = ({ color, fillColors, stroke }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.3335 15.5381V6.71184C3.3335 4.3335 3.3335 3.14433 4.06573 2.40548C4.79796 1.66663 5.97647 1.66663 8.3335 1.66663H11.6668C14.0238 1.66663 15.2023 1.66663 15.9346 2.40548C16.6668 3.14433 16.6668 4.3335 16.6668 6.71184V15.5381C16.6668 16.7979 16.6668 17.4277 16.2818 17.6756C15.6527 18.0809 14.6802 17.2311 14.1911 16.9227C13.7869 16.6678 13.5849 16.5404 13.3606 16.533C13.1182 16.525 12.9126 16.6473 12.4759 16.9227L10.8835 17.927C10.4539 18.1978 10.2392 18.3333 10.0002 18.3333C9.76116 18.3333 9.54641 18.1978 9.11683 17.927L7.52444 16.9227C7.12029 16.6678 6.91821 16.5404 6.69394 16.533C6.4516 16.525 6.24594 16.6473 5.80922 16.9227C5.32012 17.2311 4.34755 18.0809 3.71845 17.6756C3.3335 17.4277 3.3335 16.7979 3.3335 15.5381Z"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill={fillColors}
        />
        <path
          d="M10.4167 6.66668C9.49622 6.66668 8.75 7.31957 8.75 8.12501C8.75 8.93046 9.49622 9.58335 10.4167 9.58335C11.3371 9.58335 12.0833 10.2362 12.0833 11.0417C12.0833 11.8471 11.3371 12.5 10.4167 12.5M10.4167 6.66668C11.1423 6.66668 11.7597 7.07246 11.9884 7.6389M10.4167 6.66668V5.69446M10.4167 12.5C9.691 12.5 9.07367 12.0942 8.84489 11.5278M10.4167 12.5V13.4722"
          stroke={stroke}
          stroke-width="1.67"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default Subscription;
