import React, { useState } from "react";
import { ICONS } from "../../../assets/icons";
import "../components/styles/cardcontent.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Tooltip } from "antd";

const CardContent = ({ handleClose, broadcastList, col, segmentId }) => {
  const navigate = useNavigate();

  //   const scheduleOn = moment(broadcastList?.schedule_on).utc();

  //   const endDate = broadcastList?.end_date
  //     ? moment(broadcastList.end_date).utc()
  //     : null;

  //   const formattedDates = ${moment(scheduleOn)
  //     .utc()
  //     .format("MMM DD YYYY")
  // } @${ moment(scheduleOn).utc().format("hh:mm A") };

  const scheduleOn = moment(broadcastList?.schedule_on).utcOffset("+05:30");

  const endDate = broadcastList?.end_date
    ? moment(broadcastList?.end_date).utcOffset("+05:30")
    : null;

  // const formattedDates = `${moment(scheduleOn)
  //   .utc()
  //   .format("MMM DD YYYY")} @ ${moment(scheduleOn).utc().format("hh:mm A")}`;
  const formattedDates = `${scheduleOn.format(
    "MMM DD YYYY"
  )} @ ${scheduleOn.format("hh:mm A")}`;

  let formattedEndDates;
  if (endDate) {
    formattedEndDates = `${moment(endDate)
      .utcOffset("+05:30") // Convert to IST
      .format("MMM DD YYYY")} @ ${moment(endDate)
      .utcOffset("+05:30")
      .format("hh:mm A")}`;
  } else {
    formattedEndDates = "No end date";
  }
  // if (endDate) {
  //   formattedEndDates = `${moment(endDate)
  //     .utc()
  //     .format("MMM DD YYYY")} @ ${moment(endDate).utc().format("hh:mm A")}`;
  // } else {
  //   formattedEndDates = "No end date";
  // }

  const navigateFunction = (item) => {
    const campaignOverviewData = item;
    if (item?.status === 10) {
      navigate(`/user/campaign/view/${item?.campaign_id}/execution`, {
        state: { item, data: campaignOverviewData },
      });
    } else {
      navigate(`/user/campaign/time_specified/segment_list/${item?.id}`, {
        state: { item, data: campaignOverviewData },
      });
    }
  };

  return (
    <div className="calender-card-popup">
      <div className="calender-card-header">
        <div className="flex-row space-between">
          <div className="flex-row pt-10">
            <div
              className={`calender-border ${
                broadcastList?.display_color ? "mt-[0.5vw]" : "mt-0.5"
              }`}
              style={{
                background: broadcastList?.display_color
                  ? broadcastList?.display_color
                  : undefined,
              }}
            >
              {broadcastList?.display_emoji && (
                <p className="pr-1 text-[1vw]">
                  {broadcastList?.display_emoji && broadcastList?.display_emoji}
                </p>
              )}
            </div>
            {/* <h2 className="calender-header pb-5 ellipsis-text">
              {broadcastList?.name
                ? broadcastList.name?.charAt(0)?.toUpperCase() +
                  broadcastList?.name?.slice(1)
                : ""}
            </h2> */}
            <Tooltip
              title={broadcastList?.name.length > 25 ? broadcastList?.name : ""}
            >
              <h2 className="calender-header pb-5">
                {broadcastList?.name
                  ? broadcastList.name.length > 25
                    ? broadcastList.name.charAt(0).toUpperCase() +
                      broadcastList.name.slice(1, 25) +
                      "..."
                    : broadcastList.name.charAt(0).toUpperCase() +
                      broadcastList.name.slice(1)
                  : ""}
              </h2>
            </Tooltip>
          </div>

          <img
            className="calender-popup-exit pointer"
            src={ICONS.popupX}
            alt="popup"
            onClick={handleClose}
          />
        </div>
        <div className="flex-row align-center pl-[25px]">
          {broadcastList?.status === 10 && (
            <div className="flex-row align-center">
              <img
                src={ICONS?.calenderScheduled}
                className="boardCardRunning"
              />
              <p className="calender-scheduled pl-5">
                Scheduled on {formattedDates}
              </p>
            </div>
          )}

          {broadcastList?.status === 11 && (
            <div className="flex-row align-center">
              <img
                src={ICONS?.CampaignRunningIcon}
                className="boardCardRunning "
              />
              <p className="text-[0.65vw] text-[var(--primary)] pl-5 ">
                Running
              </p>
            </div>
          )}

          {/* {broadcastList?.status === 0 && (
            <p className="calender-scheduled">Draft</p>
          )} */}

          {broadcastList?.status === 12 && (
            <div className="flex-row align-center">
              <img
                src={ICONS?.CampaignCompleteIcon}
                className="boardCardRunning"
              />
              <p className="calender-scheduled pl-5">
                Completed on {formattedDates}
              </p>
            </div>
          )}

          {/* <img src={ICONS?.edit} className="calender-edit" />
          <div
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
              background: hover ? "#FFEEED" : "transparent",
              borderRadius: hover ? "6px" : "6px",
            }}
          >
            <CustomDelete
              color={hover ? "#F96056" : "#898E99"}
              className="calender-delete"
            />
          </div> */}
        </div>
      </div>

      <div className="">
        <div className="calender-data flex-row align-center">
          <img src={ICONS?.startEndDate} className="boardCardRunning" />
          <p className="calender-title pl-10 ">
            {formattedDates} - {formattedEndDates}
          </p>
        </div>
        <div className="flex-row calender-data">
          <img src={ICONS?.whatsApp} className="first-img" />
          <div className="flex-column">
            <p className="calender-title1">{broadcastList?.phone_name}</p>
            <p className="calender-phone1">{broadcastList?.phone_number}</p>
          </div>
        </div>

        <div className="flex-row calender-data align-center">
          <img src={ICONS?.uploadFile} className="boardCardSegment" />
          <p className="calender-title">
            {broadcastList?.uploaded_files_count} - Audience lists{" "}
            <span className="ml-10">/</span>
          </p>
          <img
            src={ICONS?.BroadcastSegmentIcons}
            className="boardCardSegment ml-10"
          />
          <p className="calendar-segments">
            {broadcastList?.segments_count} - Segments
          </p>
        </div>
        <div className="flex-row calender-data align-center ml-[4px]">
          <img src={ICONS?.calenderContact} className="calender-popover-img" />
          <p className="calender-title">
            {broadcastList?.contacts ? broadcastList?.contacts : 0} Contacts
          </p>
        </div>
        <div className="flex-row calender-data align-center ml-[4px]">
          <img src={ICONS?.calenderDinner} className="calender-popover-img" />
          <p className="calender-title">{broadcastList?.template_name}</p>
          <span
            className="status-color flex align-center justify-center"
            style={{
              color:
                broadcastList?.template_category === "MARKETING"
                  ? "#ED4F9D"
                  : "#f6a723",
              background:
                broadcastList?.template_category === "MARKETING"
                  ? "#fdf2f8"
                  : "#FFFBEB",
              padding:
                broadcastList?.template_category === "MARKETING"
                  ? "5px !important"
                  : "5px !important",
            }}
          >
            {broadcastList?.template_category}
          </span>
          {/* <p className="calendar-title lowercase first-letter:uppercase">
            {broadcastList?.template_category}
          </p> */}
        </div>
      </div>

      <div className="calender-button">
        <button
          onClick={() => navigateFunction(broadcastList)}
        >
          {broadcastList?.status !== 10 ? "Go to Broadcast" : "View Broadcast"}
        </button>
      </div>
    </div>
  );
};

export default CardContent;
