import React from "react";

const Others = ({ color }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 10C18 5.58172 14.4182 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4182 5.58172 18 10 18C14.4182 18 18 14.4182 18 10Z"
          stroke={color}
          stroke-width="1.67"
        />
        <path
          d="M10.0076 10H10.0151M14.0151 10H14.0226M6 10H6.00748"
          stroke={color}
          stroke-width="2.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Others;
