import AspApi from 'api/ApiConfig';

// sequence
export const createSequence = async (payload) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_sequence/sequences`,
    payload
  );
  return res;
};

export const getSequence = async (seqId, payload) => {
  let qpm = [];
  if (payload?.brandId) qpm.push(`brand_id=${payload?.brandId}`);
  if (payload?.campaignId) qpm.push(`campaign_id=${payload?.campaignId}`);
  if (payload?.flow) qpm.push(`flow=${payload?.flow}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.get(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}${qpmString}`
  );

  return res;
};

export const updateSequence = async (seqId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}`,
    payload
  );
  return res;
};

export const changeSequenceStatus = async (seqId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/change_state`,
    payload
  );
  return res;
};

export const deleteSequence = async (seqId, brandId, campaignId) => {
  const res = await AspApi.delete(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}?brand_id=${brandId}&campaign_id=${campaignId}`
  );
  return res;
};

// triggers
export const createInitialTrigger = async (seqId, payload) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/triggers`,
    payload
  );
  return res;
};

export const updateInitialTrigger = async (seqId, triggerId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/triggers/${triggerId}`,
    payload
  );
  return res;
};

export const createNewBoroadcast = async (payload) => {
  const res = await AspApi.post(
    `api/v1/svc/campaign_broadcast/broadcasts/quick_create`,
    payload
  );
  return res;
};

export const sendWhatsappPreview = async (payload) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/conversations/send_template`,
    payload
  );
  return res;
};

// nodes
export const createNode = async (seqId, payload) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/nodes`,
    payload
  );
  return res;
};

export const updateNode = async (seqId, nodeId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/nodes/${nodeId}`,
    payload
  );
  return res;
};

export const deleteNode = async (seqId, nodeId, payload) => {
  let qpm = [];
  if (payload?.brandId) qpm.push(`brand_id=${payload?.brandId}`);
  if (payload?.campaignId) qpm.push(`campaign_id=${payload?.campaignId}`);
  if (payload?.nodeType) qpm.push(`node_type=${payload?.nodeType}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.delete(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/nodes/${nodeId}${qpmString}`
  );
  return res;
};

export const updateNodeCoordinates = async (seqId, nodeId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/nodes/${nodeId}/node_position`,
    payload
  );
  return res;
};

export const updateNodeDefaults = async (seqId, nodeId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/nodes/${nodeId}/node_defaults`,
    payload
  );
  return res;
};

// NODE
export const createTextNode = async (seqId, nodeId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/nodes/${nodeId}`,
    payload
  );
  return res;
};

export const markAsEndNode = async (seqId, nodeId, payload) => {
  const res = await AspApi.put(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/nodes/${nodeId}/mark_end`,
    payload
  );
  return res;
};

// EDGES
export const createNewEdge = async (seqId, payload) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/edges`,
    payload
  );
  return res;
};

export const deleteEdge = async (seqId, edgeId, payload) => {
  let qpm = [];
  if (payload?.brand_id) qpm.push(`brand_id=${payload?.brand_id}`);
  if (payload?.campaign_id) qpm.push(`campaign_id=${payload?.campaign_id}`);

  let qpmString = '';
  if (qpm?.length > 0) qpmString = '?' + qpm.join('&');

  const res = await AspApi.delete(
    `/api/v1/svc/campaign_sequence/sequences/${seqId}/edges/${edgeId}${qpmString}`
  );
  return res;
};
