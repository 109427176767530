import React, { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { billingHistory } from "../../../constant/app/account/account";
import { cn } from "../../../helper/cn";
import { ICONSS } from "../../../assets/icons/index1";
import { Credit_usage } from "../constants/home";
import { ICONS } from "../../../assets/icons";
import { formatCurrency } from "../../../helper/formatCurrency";
import NoData from "../../../components/commonComponents/Empty/NoData";

const BillingHistory = ({
  creditUsage,
  paymentHistory,
  creditReportData,
  paymentHistoryData,
}) => {
  const listContainerRef = useRef();

  return (
    <>
      {creditUsage && creditReportData?.bcur?.length > 0 && (
        <div className="flex-row align-center space-between mt-20 mb-20 gap-8">
          {Credit_usage?.map((item) => (
            <div
              key={item?.id}
              className="w-100 gap-1 border border-[var(--border-input)] b-radius p-10 flex flex-col"
            >
              <span className="font14px-400 text-[#4C515B]">{item?.title}</span>
              <span
                className={`font18px-500  ${
                  item?.id === 4
                    ? "text-[var(--new-primary)]"
                    : "text-[var(--textBlack)]"
                }`}
              >
                {creditReportData?.credit_used_and_added?.[0] && (
                  <>
                    {item?.id === 2
                      ? parseFloat(
                          creditReportData.credit_used_and_added[0].amount_added
                        ).toFixed(2) || 0
                      : parseFloat(
                          creditReportData.credit_used_and_added[0].amount_used
                        ).toFixed(2) || 0}
                  </>
                )}
              </span>
              <span className="italic font14px-400 text-[#4C515B]">
                Dec 20 , 2024
              </span>
            </div>
          ))}
        </div>
      )}
      <div className="w-full relative overflow-hidden">
        {creditReportData?.bcur?.length > 0 && (
          <div className="w-full h-14 sticky top-0 flex items-center pr-1 b-radius-6">
            <div className={cn("new-header2", "w-[30%]")}>
              {creditUsage ? "Date" : "Invoice"}
            </div>
            <div className={cn("new-header2", "w-[24%]")}>
              {creditUsage ? "Marketing" : "Paid on"}
            </div>
            <div className={cn("new-header2", "w-[18%]")}>
              {creditUsage ? "Utility" : "Payment Method"}
            </div>
            <div className={cn("new-header2", "w-[18%]")}>
              {creditUsage ? "Authentication" : "Amount"}
            </div>
            <div className={cn("new-header2")}>
              {creditUsage ? "Total Amount" : "Actions"}
            </div>
          </div>
        )}
      </div>
      <div
        className={`min-w-full bg-white h-full max-h-[40vh] overflow-hidden overflow-y-scroll`}
        id="scrollableDiv"
        ref={listContainerRef}
      >
        {/* <InfiniteScroll
          dataLength={count}
          next={() => {
            loadNextPage();
          }}
          hasMore={list?.length < count}
          scrollableTarget="scrollableDiv"
          height={isLoading ? "53vh" : "58vh"}
          className="!pr-1 max-[1600px]:pb-24 min-[1600px]:pb-24 listScroll"
        > */}
        {creditReportData?.bcur?.length > 0 ? (
          <div className="h-full max-h-[40vh] overflow-scroll">
            {creditReportData?.bcur?.map((l) => {
              return (
                <div
                  key={l?.id}
                  className="w-full odd:bg-gray-50 my-1 py-4 flex items-center  pointer"
                >
                  <div className={"w-[41%] flex-row align-center gap-2 pl-10"}>
                    {l?.credit_status === "CREDIT" ? (
                      <img
                        src={ICONSS?.creditUsage}
                        alt="billingHistory"
                        className="w-5"
                      />
                    ) : (
                      <img
                        src={ICONSS?.debitUsage}
                        alt="billingHistory"
                        className="w-5"
                      />
                    )}

                    <div className="font15px-500 text-[var(--textBlack)]">
                      {l?.created_date}
                    </div>
                  </div>

                  <div className="w-[31%] font12px-500 flex-row text-[var(--font-600)]">
                    ₹
                    {l?.debit_amount
                      ? l?.marketing_credits / 100
                      : l?.marketing_credits || "--"}
                  </div>
                  <div className="w-[25%] font12px-500 flex-row text-[var(--font-600)] capitalize">
                    ₹
                    {l?.debit_amount
                      ? l?.utility_credits / 100
                      : l?.utility_credits || "--"}
                  </div>
                  <div className="w-[25%] font12px-500 flex-row text-[var(--font-600)]">
                    ₹
                    {l?.debit_amount
                      ? l?.authentication_credits / 100
                      : l?.authentication_credits || "--"}
                  </div>
                  <div
                    className={`w-[12%] font12px-500 flex-row  ${
                      l?.credit_status === "CREDIT"
                        ? "text-[var(--new-primary)]"
                        : "text-[red]"
                    }`}
                  >
                    {l?.credit_status === "CREDIT" ? "+" : "-"} ₹
                    {l?.debit_amount
                      ? parseFloat(l.debit_amount).toFixed(2) / 100
                      : parseFloat(l.credit_amount).toFixed(2) || "--"}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="h-full flex flex-col items-center justify-center">
            <img src={ICONS?.noChartData} alt="noChartData" />
            <p className="text-[#616874]">There’s no data yet</p>
          </div>
        )}
        {/* </InfiniteScroll> */}
      </div>
    </>
  );
};

export default BillingHistory;
