import React, { useEffect, useReducer, useRef, useState } from "react";
import "../../styles/popover.css";
import { ICONS } from "../../../../assets/icons";
import { getRandomColorsBassedOnLetters } from "../../../../helper/colors";
import Header from "../../../../components/commonComponents/modals/Header";
import { DatePicker, Popover } from "antd";
import moment from "moment";
import Select from "../../../../components/commonComponents/select";
import { gender } from "../../../../constant/app/crm/crm";
import { validateEmail } from "../../../../helper/validation";
import {
  cityListApi,
  countryListApi,
  crmSingleContactEdit,
  crmSingleContactView,
  stateListApi,
} from "../../../crm/api/Api";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import Delivered from "../../../../assets/customSVG/inbox/Delivered";
import {
  INITIAL_STATE,
  singleContactReducer,
} from "../../../crm/reducers/singleContactReducer";
import { formatPhoneNumber } from "../../helper";

const genders = {
  1: "Male",
  2: "Female",
  0: "Other",
};

const SideNavComponent = ({
  selectedPerson,
  setIsSideNavOpen,
  contactPopover,
  setContactPopover,
  data,
  type,
  journeyLists,
  setIsSideNavOpenIcon2,
  setIsSideNavOpenIcon1,
  profession,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [countryListss, setCountryListss] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const datePickerRef = useRef(null);

  const { currentBrand, toggleToast } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();
  const [viewDispatch] = useReducer(singleContactReducer, INITIAL_STATE);

  // CRM STATES

  const [emails, setEmails] = useState([]);
  const [dynamicInputs, setDynamicInputs] = useState([
    { id: 1, value: data?.information?.first_name || "", label: "First Name" },
    { id: 2, value: data?.information?.last_name || "", label: "Last Name" },
    { id: 3, value: "", label: "Phone Number" },
    { id: 4, value: emails?.[0]?.email || "", label: "Email" },
  ]);
  const [infoInputs, setInfoInputs] = useState([
    { id: 1, value: "", label: "Date of Birth" },
    { id: 2, value: genders[data?.information?.gender] || "", label: "Gender" },
    { id: 3, value: data?.profession?.[0]?.name || "", label: "Profession" },
  ]);

  const initialAddressData = [
    {
      id: 1,
      value: data?.address?.primary_address_line_1 || "",
      label: "Street",
    },
    { id: 2, value: data?.address?.primary_country || "", label: "Country" },
    { id: 3, value: data?.address?.primary_state || "", label: "State" },
    { id: 4, value: data?.address?.primary_city || "", label: "City" },
    {
      id: 5,
      value: data?.address?.primary_zip_code || "",
      label: "Zip / Postal code",
    },
  ];
  const [addressInfo, setAddressInfo] = useState(initialAddressData);

  const countryId = countryListss?.find(
    (item) => item?.name === addressInfo?.[1]?.value
  )?.id;

  const stateId = stateList?.find(
    (item) => item?.name === addressInfo?.[2]?.value
  )?.id;

  const cityId = cityList?.find(
    (item) => item?.name === addressInfo?.[3]?.value
  )?.id;

  const countryLists = () => {
    countryListApi().then((res) => {
      if (res && res.data?.data?.length > 0) {
        const updatedData = res?.data?.data.map((p) => {
          return { ...p, label: p.name };
        });
        setCountryListss(updatedData);
      }
    });
  };

  const stateLists = async () => {
    stateListApi(countryId || addressInfo?.[1]?.value?.id).then((res) => {
      if (res && res?.data?.data?.length > 0) {
        const updateState = res?.data?.data?.map((item) => {
          return { ...item, label: item?.name };
        });
        setStateList(updateState);
      }
    });
  };

  const cityLists = async () => {
    cityListApi(stateId || addressInfo?.[2]?.value?.id).then((res) => {
      if (res && res?.data?.data?.length > 0) {
        const updateCity = res?.data?.data?.map((item) => {
          return { ...item, label: item?.name };
        });
        setCityList(updateCity);
      }
    });
  };

  useEffect(() => {
    countryLists();

    if (countryId || addressInfo?.[1]?.value?.id) stateLists();

    if (stateId || addressInfo?.[2]?.value?.id) {
      cityLists();
    }
  }, [addressInfo?.[1]?.value?.id, addressInfo?.[2]?.value?.id]);

  const firstLetter =
    data?.information?.first_name !== "UNKNOWN"
      ? data?.information?.first_name?.slice(0, 1).toUpperCase()
      : selectedPerson?.profile_name?.slice(0, 1).toUpperCase();

  const colors = firstLetter ? getRandomColorsBassedOnLetters(firstLetter) : "";

  // Popover open

  const handleContactClick = () => {
    setContactPopover(!contactPopover);
  };
  const handleInfoClick = () => {
    setInfoOpen(!infoOpen);
  };
  const handleAddressClick = () => {
    setAddressOpen(!addressOpen);
    setAddressInfo(initialAddressData);
  };

  // Onchange functions

  const handleChange = (id, value) => {
    setDynamicInputs((prevInputs) =>
      prevInputs.map((input) => (input.id === id ? { ...input, value } : input))
    );
    if (id === 4) {
      if (!validateEmail(value) && value !== "") {
        setErrorMessage("Enter a valid email address");
      } else {
        setErrorMessage("");
      }
      if (value.length > 40) {
        return;
      }
    }
  };

  const handleInfoChange = (id, value) => {
    setInfoInputs((prevInputs) =>
      prevInputs.map((input) => (input.id === id ? { ...input, value } : input))
    );
  };
  const handleAddressChange = (id, value) => {
    setAddressInfo((prev) => {
      return prev.map((item) => {
        // Clear State and City if Country changes
        if (id === 2 && (item.id === 3 || item.id === 4)) {
          return { ...item, value: "" };
        }
        // Update the item if the ID matches, with length validation for Zip/Postal code
        if (item.id === id) {
          if (id === 5 && value?.length > 6) {
            return item; // No update for Zip if length > 6
          }
          return { ...item, value };
        }
        return item; // Return the item unchanged
      });
    });
  };

  const values = addressInfo.map(({ value }) => value);
  const addressValues = addressInfo.every((item) => {
    return typeof item.value === "string"
      ? item.value.trim() !== ""
      : item.value != null;
  });

  // CRM DATAS

  useEffect(() => {
    const filteredEmails =
      data?.person_contacts?.filter(
        (contact) => contact?.contact_type === "EMAIL"
      ) || [];
    setEmails(
      filteredEmails?.length > 0
        ? filteredEmails
        : [
            {
              contact_id: "",
              contact_category: "",
              contact_category_name: "",
              contact_source: "",
              contact_source_name: "",
              contact_type: "EMAIL",
              contact_type_name: "Email address",
              email: "",
              phone_country_code: "",
              phone_number: null,
              priority: 2,
            },
          ]
    );
  }, [data]);

  const handleSave = (id) => {
    setDynamicInputs((prevInputs) =>
      prevInputs.map((input) => (input.id === id ? { ...input, value } : input))
    );
    const brandId = currentBrand?.brand_id;

    let payLoad = {};

    if (contactPopover) {
      payLoad = {
        brand_id: brandId,
        first_name: dynamicInputs?.[0]?.value,
        last_name: dynamicInputs?.[1]?.value,
        email: dynamicInputs?.[2]?.value,
      };
    } else if (addressOpen) {
      payLoad = {
        brand_id: brandId,
        address: {
          address_line_1: addressInfo?.[0]?.value
            ? addressInfo?.[0]?.value
            : data?.address?.primary_address_line_1,
          country_id: countryId || addressInfo?.[1]?.value?.id,
          province_id: stateId || addressInfo?.[2]?.value?.id,
          city_id: cityId || addressInfo?.[3]?.value?.id,
          postal_code: addressInfo
            ? addressInfo?.[4]?.value
            : data?.address?.primary_zip_code,
        },
      };
    } else {
      payLoad = {
        brand_id: brandId,
        dob: infoInputs?.[0]?.value
          ? moment(Number(infoInputs?.[0]?.value)).format("DD/MM/YYYY")
          : "",
        gender: data?.information?.gender || infoInputs?.[1]?.value?.id,
        profession: {
          id: infoInputs?.[2]?.value?.id,
        },
      };
    }

    if (brandId) {
      crmSingleContactEdit(payLoad, selectedPerson?.contact_person_id).then(
        (res) => {
          if (res && res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: res?.data?.message,
                  status: "Success",
                  duration: 3000,
                },
              ])
            );
            crmSingleContactView(
              currentBrand?.brand_id,
              selectedPerson?.contact_person_id
            ).then((res) => {
              if (res && res?.data) {
                const contactData = res?.data?.overview?.[0];
                viewDispatch({ type: "update_data", data: contactData });
              }
            });
            setContactPopover(false);
            setAddressOpen(false);
            setInfoOpen(false);
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: res?.response?.data?.error,
                  status: "Error",
                  duration: 3000,
                },
              ])
            );
          }
        }
      );
    }
  };

  const name =
    data?.information?.first_name && data?.information?.last_name
      ? `${data?.information?.first_name} ${data?.information?.last_name}`
      : data?.information?.first_name !== "UNKNOWN"
      ? data?.information?.first_name
      : data?.information?.last_name
      ? data?.information?.last_name
      : data?.information?.last_name === "UNKNOWN";

  const formattedPhoneNumber = formatPhoneNumber(
    selectedPerson?.phone_country_code,
    selectedPerson?.to_number
  );

  return (
    <div className="bg-[#F9F9FC] border-r-2">
      {type === "contact" && (
        <div className="w-[20vw]">
          <div className="w-100 flex-row align-center space-between bg-white">
            <Header
              title={"Contact Details"}
              handleCancel={() => {
                setIsSideNavOpen(false);
                setIsSideNavOpenIcon2(false);
                setIsSideNavOpenIcon1(false);
              }}
              className="new-header"
            />
          </div>
          <div className="h-[90vh] overflow-y-scroll ">
            <div
              className="w-[92] b-radius p-[15px] m-10 gap-2 flex-column  bg-white"
              style={{
                boxShadow: "0px 4px 4px 0px #0000000A",
              }}
            >
              <Popover
                trigger="click"
                open={contactPopover}
                onOpenChange={(open) => {
                  if (!open) {
                    setContactPopover(false);
                  }
                }}
                overlayClassName="contact-details-popover"
                placement="left"
                closable={false}
                arrow={false}
                content={
                  <>
                    <Header
                      title="Contact Details"
                      handleCancel={() => setContactPopover(false)}
                      className="new-header"
                    />
                    <div className="w-100 flex-column prl-20 ptb-10">
                      {dynamicInputs.map((item) => (
                        <div className="flex-column mt-5">
                          <span className=" input-label-text">
                            {item?.label}
                            <span className="text-[red]"> * </span>
                          </span>

                          <div className="flex-row align-center mt-10 mb-5">
                            <div
                              key={item.id}
                              className="w-100 flex-row align-center border border-[var(--border-input)] rounded-lg"
                            >
                              <input
                                type={item.id === 3 ? "number" : "text"}
                                className="w-100 h-[2.5rem] rounded-lg pl-10"
                                value={item?.value || ""}
                                placeholder={item.label}
                                onChange={(e) => {
                                  if (item.id === 3) {
                                    if (e.target.value.length <= 10) {
                                      handleChange(item.id, e.target.value);
                                    }
                                  } else if (item?.id === 4) {
                                    handleChange(item.id, e.target.value);
                                  } else {
                                    handleChange(item.id, e.target.value);
                                  }
                                }}
                                autoComplete="off"
                                maxLength={62}
                              />
                            </div>
                          </div>
                          <span
                            style={{ color: "red" }}
                            className="text-[.8vw] font-[500] pt-2"
                          >
                            {item?.id === 4 && errorMessage}
                          </span>
                        </div>
                      ))}

                      <div className="w-100 flex-row align-end justify-end mt-20">
                        {/* {values.every((v) => v?.trim().length > 0) &&
                        !errorMessage ? (
                          <button
                            className="button-title prl-10 ptb-5 b-radius-6"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        ) : (
                          <span className="disable-button">Save</span>
                        )} */}
                      </div>
                    </div>
                  </>
                }
              >
                <div className=" w-100 flex-row space-between">
                  <div className="flex-row align-center gap-2">
                    {(name || selectedPerson?.profile_name) && (
                      <span
                        style={{
                          color: colors?.color,
                          backgroundColor: colors?.backgroundColor,
                        }}
                        className="px-2.5 py-1 rounded-full "
                      >
                        {firstLetter}
                      </span>
                    )}
                    <span className="capitalize">
                      {name ? name : selectedPerson?.profile_name}
                    </span>
                  </div>
                  {/* <span
                    className="inbox-edit gap-2 flex-row align-center pointer"
                    onClick={handleContactClick}
                  >
                    <img src={ICONS?.inboxEdit} alt="inboxEdit" />
                    Edit
                  </span> */}
                </div>
              </Popover>
              <span className="border-b-[1px]" />
              <div className="w-100">
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Phone</span>
                  <span className="new-body-text">
                    +{selectedPerson?.phone_country_code}{" "}
                    {selectedPerson?.to_number}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Email</span>
                  <span className="new-body-text">
                    {emails?.[0]?.email ? emails?.[0]?.email : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="w-[95%] b-radius p-[15px] m-10 gap-2 flex-column  bg-white"
              style={{
                boxShadow: "0px 4px 4px 0px #0000000A",
              }}
            >
              <Popover
                trigger="click"
                open={infoOpen}
                onOpenChange={(open) => {
                  if (!open) {
                    setInfoOpen(false);
                  }
                }}
                closable="false"
                overlayClassName="contact-details-popover"
                placement="left"
                arrow={false}
                wrapClassName={"bg-[#00000065] !important"}
                content={
                  <>
                    <Header
                      title="Info"
                      handleCancel={() => setInfoOpen(false)}
                      className="input-label-text"
                    />
                    <div className="w-100 flex-column prl-20 ptb-10">
                      {infoInputs.map((item) => (
                        <div className="flex-column mt-5">
                          <span className="input-label-text">
                            {item?.label}
                          </span>

                          <div className="flex-row align-center mt-5">
                            <div
                              key={item.id}
                              className="w-100 flex-row align-center  "
                              onClick={() => {
                                if (item.id === 1) {
                                  setIsOpen(!isOpen);
                                }
                              }}
                            >
                              {item?.id !== 1 && (
                                <Select
                                  value={item?.value || ""}
                                  options={
                                    item?.id === 3 && item?.id !== 1
                                      ? profession
                                      : gender
                                  }
                                  onChange={(value) =>
                                    handleInfoChange(item?.id, value)
                                  }
                                  rootClassName={` w-100 rounded-[0.4vw]`}
                                  placeholder={"Select"}
                                  position="bottom"
                                  optionClassName="p-20 border-b last:border-none"
                                  labelClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
                                  optionClick="option-click"
                                />
                              )}
                              {item?.id === 1 && (
                                <>
                                  <input
                                    type="text"
                                    value={
                                      item?.value
                                        ? moment(Number(item?.value)).format(
                                            "DD / MM / YYYY"
                                          )
                                        : data?.information?.dob
                                    }
                                    readOnly
                                    placeholder="MM / DD / YYYY"
                                    style={{
                                      borderRadius: "6px",
                                      padding: "6px",
                                      border: "1px solid var(--border-input)",
                                      width: "100%",
                                      position: "relative",
                                      fontSize: "0.875rem",
                                      fontWeight: "400",
                                      color: "var(--font-600)",
                                    }}
                                  />
                                  <div ref={datePickerRef}>
                                    <DatePicker
                                      format="MM / DD / YYYY"
                                      onChange={
                                        (dateString) =>
                                          handleInfoChange(item?.id, dateString) // Update the input field's value
                                      }
                                      open={isOpen}
                                      allowClear={false}
                                      placeholder={false}
                                      suffixIcon={null}
                                      picker="date"
                                      showTime={false}
                                      inputReadOnly
                                      className="date-picker-inbox"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <span
                            style={{ color: "red" }}
                            className="text-[.8vw] font-[500] pt-2"
                          >
                            {item?.id === 4 && errorMessage}
                          </span>
                        </div>
                      ))}

                      <div className="w-100 flex-row align-end justify-end mt-20">
                        {values?.length > 0 ? (
                          <button
                            className="button-title prl-10 ptb-5 b-radius-6"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        ) : (
                          <span className="disable-button">Save</span>
                        )}
                      </div>
                    </div>
                  </>
                }
              >
                <div className=" w-100 flex-row space-between">
                  <span> Info</span>
                  {/* <span
                    className="inbox-edit gap-2 flex-row align-center pointer"
                    onClick={handleInfoClick}
                  >
                    <img src={ICONS?.inboxEdit} alt="inboxEdit" />
                    Edit
                  </span> */}
                </div>
              </Popover>
              <span className="border-b-[1px]" />
              <div className="w-100">
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Age</span>
                  <span className="new-body-text">
                    {data?.information?.age_range_code
                      ? data?.information?.age_range_code
                      : "-"}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Date of Birth</span>
                  <span className="new-body-text">
                    {data?.information?.dob ? data?.information?.dob : "-"}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Gender</span>
                  <span className="new-body-text">
                    {genders[data?.information?.gender]}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Profession</span>
                  <span className="new-body-text">
                    {data?.profession?.[0]?.name
                      ? data?.profession?.[0]?.name
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="w-[94%] b-radius p-[15px] m-10 gap-2 flex-column  bg-white"
              style={{
                boxShadow: "0px 4px 4px 0px #0000000A",
              }}
            >
              <Popover
                trigger="click"
                open={addressOpen}
                onOpenChange={(open) => {
                  if (!open) {
                    setAddressOpen(false);
                  }
                }}
                closable="false"
                overlayClassName="contact-details-popover"
                placement="left"
                arrow={false}
                wrapClassName={"bg-[#00000065] !important"}
                content={
                  <>
                    <Header
                      title="Address Info"
                      handleCancel={() => setAddressOpen(false)}
                      className="input-label-text"
                    />
                    <div className="w-100 flex-column prl-20 ptb-10">
                      {addressInfo?.map((item) => (
                        <div className="flex-column mt-5" key={item?.id}>
                          <span className="input-label-text">
                            {item?.label}
                            <span className="text-[red]"> * </span>
                          </span>

                          <div className="flex-row align-center mt-5">
                            <div
                              className="w-100 flex-row align-center border border-[var(--border-input)] rounded-lg"
                              onClick={() => {
                                // if (item.id === 1) {
                                setAddressOpen(!isOpen);
                                // }
                              }}
                            >
                              {(item?.id === 1 || item?.id === 5) && (
                                <input
                                  type={item?.id === 5 ? "number" : "text"}
                                  className="w-100 h-[2.5rem] rounded-lg pl-10"
                                  value={item?.value || ""}
                                  placeholder={item.label}
                                  onChange={(e) => {
                                    handleAddressChange(
                                      item.id,
                                      e.target.value
                                    );
                                  }}
                                  autoComplete="off"
                                  maxLength={62}
                                />
                              )}
                              {item?.id !== 1 && item?.id !== 5 && (
                                <Select
                                  value={item?.value || ""}
                                  options={
                                    item?.id === 2
                                      ? countryListss
                                      : item?.id === 4
                                      ? cityList
                                      : stateList
                                  }
                                  onChange={(value) => {
                                    handleAddressChange(item.id, value);
                                  }}
                                  rootClassName={` border-none w-100`}
                                  placeholder={"Select"}
                                  position="bottom"
                                  optionClassName="p-20 border-b last:border-none"
                                  labelClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
                                  // dropDownIcon={isHovered("gender")}
                                  optionClick="option-click"
                                />
                              )}
                            </div>
                          </div>
                          <span
                            style={{ color: "red" }}
                            className="text-[.8vw] font-[500] pt-2"
                          >
                            {item?.id === 4 && errorMessage}
                          </span>
                        </div>
                      ))}

                      <div className="w-100 flex-row align-end justify-end mt-20">
                        {addressValues ? (
                          <button
                            className="button-title prl-10 ptb-5 b-radius-6"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        ) : (
                          <span className="disable-button">Save</span>
                        )}
                      </div>
                    </div>
                  </>
                }
              >
                <div className=" w-100 flex-row space-between">
                  <span>Address Info</span>
                  {/* <span
                    className="inbox-edit gap-2 flex-row align-center pointer"
                    onClick={handleAddressClick}
                  >
                    <img src={ICONS?.inboxEdit} alt="inboxEdit" />
                    Edit
                  </span> */}
                </div>
              </Popover>

              <span className="border-b-[1px]" />
              <div className="w-100">
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Street</span>
                  <span className="new-body-text">
                    {data?.address?.primary_address_line_1
                      ? data?.address?.primary_address_line_1
                      : "-"}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">City</span>
                  <span className="new-body-text">
                    {data?.address?.primary_city
                      ? data?.address?.primary_city
                      : "-"}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">State</span>
                  <span className="new-body-text">
                    {data?.address?.primary_state
                      ? data?.address?.primary_state
                      : "-"}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Country</span>
                  <span className="new-body-text">
                    {data?.address?.primary_country
                      ? data?.address?.primary_country
                      : "-"}
                  </span>
                </div>
                <div className="w-100 flex-row align-center space-between mt-20 mb-10">
                  <span className="sidebar-sub-header">Zip / postal code</span>
                  <span className="new-body-text">
                    {data?.address?.primary_zip_code
                      ? data?.address?.primary_zip_code
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === "journey" && (
        <div className="w-[20vw] mb-10">
          <div className="w-100 flex-row align-center space-between bg-white">
            <Header
              title={"Customer Journey"}
              handleCancel={() => {
                setIsSideNavOpen(false);
                setIsSideNavOpenIcon2(false);
                setIsSideNavOpenIcon1(false);
              }}
              className="new-header"
            />
          </div>
          {journeyLists?.length > 0 ? (
            <div className="h-[90vh] overflow-scroll bg-white mb-20">
              {Object.entries(
                journeyLists
                  ?.sort((a, b) => new Date(b?.send_at) - new Date(a?.send_at)) // Sort by send_at in ascending order
                  .reduce((acc, item) => {
                    const date = new Date(item?.send_at);
                    const monthYear = date.toLocaleString("en-US", {
                      month: "long",
                      year: "numeric",
                    });
                    if (!acc[monthYear]) acc[monthYear] = [];
                    acc[monthYear].push(item);
                    return acc;
                  }, {})
              ).map(([monthYear, items]) => (
                <div key={monthYear} className="mb-10">
                  <div className="ml-[18px] mt-20">
                    <span className=" text-[0.75rem] font-[600] text-[var(--font-600)] bg-[var(--BG-25)] ptb-10 px-[15px] b-radius-6">
                      {monthYear}
                    </span>
                  </div>
                  {items.map((item) => {
                    const date = new Date(item?.send_at);

                    const formattedDate = date.toLocaleString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    });

                    return (
                      <div
                        key={item?.id}
                        className="relative flex-row ml-[18px] mr-5 pl-20 mb-10"
                      >
                        <div
                          className="flex flex-col items-center"
                          style={{ transform: "translate(0, 26px)" }}
                        >
                          <div className="absolute w-2 h-2 bg-[#7B54FF] rounded-full"></div>
                          <div className="w-10 h-full border border-1 mt-4"></div>
                        </div>

                        <div className="flex flex-col space-y-1 ml-[15px] mt-20 ">
                          <span className="journey14px-500  flex-row capitalize">
                            {item?.status === "READ" && (
                              <span className="mr-10 mt-5">
                                <Delivered color={"#1778F2"} />
                              </span>
                            )}
                            {item?.message}
                          </span>
                          <span className="inbox12px-500 text-[var(--font-600)]">
                            {formattedDate}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          ) : (
            <div className="m-10 w-100 text-center  h-[83vh]">
              <img src={ICONS?.emptyLoader} alt="emptyLoader" />
              <span className="pt-10 text-[1rem] font-[600] text-[var(--textBlack)]">
                No data found
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SideNavComponent;
