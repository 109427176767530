import React, { useState } from "react";
import { Popover } from "antd";
import { ICONS } from "../../../assets/icons";
import FilterCalendar from "./FilterCalendar";

const FilterHome = ({
  fetchApiByFilter,
  filterList,
  setStartDate,
  setEndDate,
  customStartDate,
  customEndDate,
  startDate,
  endDate,
}) => {
  const [filterData, setFilterData] = useState(filterList[0]?.title);
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [isCustomDateVisible, setCustomDateVisible] = useState(false);
  const [customDateRange, setCustomDateRange] = useState(null);

  const onFilterChange = (data) => {
    setPopoverVisible(false);

    if (data === "Custom") {
      setCustomDateVisible(true);
    } else {
      setFilterData(data);
      setCustomDateRange(null);
      fetchApiByFilter(data);
      // setCustomDateVisible(false);
    }
  };

  const SetActionFilter = ({ filterList, onFilterChange }) => {
    return (
      <div className="align-center justify-center p-10">
        {filterList?.map((item) => (
          <div key={item.id} className="border-b-[1px] last:border-b-0 p-10">
            <span
              className="new-body-text pointer p-0"
              onClick={() => onFilterChange(item.title)}
            >
              {item.title}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Popover
        content={
          <SetActionFilter
            filterList={filterList}
            onFilterChange={onFilterChange}
          />
        }
        trigger="click"
        open={isPopoverVisible}
        onOpenChange={setPopoverVisible}
      >
        <div
          className="flex-row items-center border pointer px-3.5 py-[0.497vw] rounded-md"
        >
          <p className="new-body-text">{customDateRange || filterData}</p>
          <img
            src={ICONS?.chevronDownCarousel}
            className="ml-2"
            alt="dropdown-icon"
          />
        </div>
      </Popover>

      {isCustomDateVisible && (
        <Popover
          content={
            <FilterCalendar
              setCustomDateVisible={setCustomDateVisible}
              customEndDate={customEndDate}
              customStartDate={customStartDate}
              setCustomDateRange={setCustomDateRange}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              customDateRange={customDateRange}
            />
          }
          trigger="click"
          open={isCustomDateVisible}
          onOpenChange={setCustomDateVisible}
          arrow={false}
          overlayClassName="calendarMargin"
        >
          <div
            style={{
              display: isCustomDateVisible ? "inline-block" : "none",
            }}
          />
        </Popover>
      )}
    </div>
  );
};

export default FilterHome;
