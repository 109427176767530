import React, { useCallback, useEffect, useReducer, useState } from "react";
import { planListPurchaseFilter } from "../../constants/home";
import { Popover } from "antd";
import { cn } from "../../../../helper/cn";
import CheckBoxSqure from "../../../../components/commonComponents/checkbox/CheckBoxSqure";
import PurchaseList from "./PurchaseList";

const PurchaseTable = () => {
  //account role filter state
  const [planListPurchase, setPlanListPurchase] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState([]);

  useEffect(() => {
    setPlanListPurchase(planListPurchaseFilter);
  }, []);

  useEffect(() => {
    getSelectedPlanListId();
  }, [planListPurchase]);

  //-----------------------------role filter-------------------------
  const getSelectedPlanListId = useCallback(
    () => setSelectedPlanId(planListPurchase?.filter((t) => t?.isSelected)),
    [planListPurchase]
  );

  const onPlanChange = (data) => {
    const res = planListPurchase?.map((s) => {
      if (s?.id === data?.id) return { ...s, isSelected: !s?.isSelected };
      return s;
    });
    setPlanListPurchase(res);
  };

  const clearPurchaseList = () => {
    const res = planListPurchase?.map((t) => {
      return { ...t, isSelected: false };
    });

    setPlanListPurchase(res);
  };

  return (
    <>
      <div className="w-full h-[85vh] bg-white rounded-2xl px-5 mt-6">
        <div className="flex justify-between pl-3 items-center">
          <div className="flex py-5">
            <span className="font16px-600 pr-2">Brand Billing Details</span>
          </div>
          <div className="flex items-center gap-3">
            <Popover
              trigger={["click"]}
              arrow={false}
              placement="bottom"
              content={
                <div className="min-w-56 px-3.5 py-2.5">
                  <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                    <div className="text-[var(--font-400)]">Plans</div>
                    <div
                      className={cn(
                        "text-[#F96056]",
                        selectedPlanId?.length > 0
                          ? "cursor-pointer opacity-100"
                          : "cursor-not-allowed opacity-50"
                      )}
                      onClick={() => {
                        if (selectedPlanId?.length > 0) clearPurchaseList();
                      }}
                    >
                      Clear
                    </div>
                  </div>
                  {planListPurchase?.map((s) => {
                    return (
                      <div
                        className={cn(
                          "w-full h-12 flex items-center gap-2.5 py-1 cursor-pointer pl-10"
                        )}
                        onClick={() => {
                          onPlanChange(s);
                        }}
                        key={s?.id}
                      >
                        <CheckBoxSqure
                          value={s?.isSelected}
                          onChange={(e) => {}}
                          className="size-4"
                        />
                        <p>{s?.label}</p>
                      </div>
                    );
                  })}
                </div>
              }
            >
              <div className="group/goal bg-[var(--channelButton)] flex items-center gap-2.5 px-2 py-2 rounded-[0.4vw] cursor-pointer">
                <div className="campaign-filters flex items-center">
                  Plans
                  <div
                    className={`ml-1 
              ${
                selectedPlanId?.length === planListPurchaseFilter?.length ||
                selectedPlanId?.length === 0
                  ? ""
                  : "rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center"
              }`}
                  >
                    {selectedPlanId?.length ===
                      planListPurchaseFilter?.length ||
                    selectedPlanId?.length === 0
                      ? "(All)"
                      : selectedPlanId?.length}
                  </div>
                </div>
                <div
                  className={cn(
                    "transition-transform duration-200 ease-linear"
                    // false ? "-rotate-180" : "rotate-0",
                    // isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                >
                  <div
                    className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                  ></div>
                </div>
              </div>
            </Popover>
          </div>
        </div>

        <PurchaseList />
      </div>
    </>
  );
};

export default PurchaseTable;
