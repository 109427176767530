import React, { useCallback, useEffect, useState } from "react";

// assets
import Mail from "assets/customSVG/Mail";
import User from "assets/customSVG/User";

// API
import { SignUpApi } from "../../api/SignUp";

// utils
import { Link } from "react-router-dom";

// helpers
import { cn } from "../../../../helper/cn";
import { validateEmail } from "../../../../helper/validation";

// hooks
import useToggle from "../../../../hooks/useToggle";

// redux
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";

// components
import Loader from "../../../../components/commonComponents/Loader/Index";
import CheckBoxSqure from "../../../../components/commonComponents/checkbox/CheckBoxSqure";
import { ICONS } from "../../../../assets/icons";
import ReCAPTCHA from "react-google-recaptcha";

export default function Step1({
  data,
  token,
  onChange,
  errorMail,
  setToastError,
  toastError,
}) {
  const { toggleToast } = useAspSelector((state) => state.app);
  const [captchaValue, setCaptchaValue] = useState(null);

  const dispatch = useAspDispatch();

  const [isLoading, setIsLoading] = useToggle(false);

  useEffect(() => {
    const email = localStorage.getItem("verify-email");
    onChange("update", "mail", email);
  }, []);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validation = useCallback(() => {
    return (
      data?.firstname &&
      data?.lastname &&
      data?.mail &&
      captchaValue &&
      !data?.lastname?.endsWith(".") &&
      !data?.lastname?.endsWith("-") &&
      !data?.lastname?.endsWith("'") &&
      !data?.firstname?.endsWith(".") &&
      !data?.firstname?.endsWith("-") &&
      !data?.firstname?.endsWith("'") &&
      data?.terms &&
      !toastError &&
      validateEmail(data?.mail)
    );
  }, [data, captchaValue, toastError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      setIsLoading(true);
      const res = await SignUpApi({
        first_name: data?.firstname,
        last_name: data?.lastname,
        email: data?.mail,
        terms_accepted: data?.terms,
        promotional_consent: data?.services,
      });

      if (res.status === 200) {
        onChange("update", "step1", true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setToastError(res?.response?.data?.error);
        // dispatch(
        //   updateToggleToast([
        //     ...toggleToast,
        //     {
        //       id: toggleToast?.length + 1,
        //       content: res?.response?.data?.error,
        //       status: "Error",
        //       duration: "",
        //     },
        //   ])
        // );
      }
    }
  };

  // styles
  const card = "flex flex-col gap-2";
  const label = "font16px-500 leading-6 text-[var(--textBlack)]";
  const inputContainer =
    "w-100 flex border-[1px] border-[#D1D3D8] items-center gap-2 py-2.5 px-3 b-radius-6";
  const inputContainer2 = `w-100 flex items-center gap-2 py-2.5 px-3 b-radius-6 ${
    data?.lastname?.endsWith(".") ||
    data?.lastname?.endsWith("-") ||
    data?.lastname?.endsWith("'") ||
    data?.last_name
      ? "border-[1px] border-red-500"
      : "border-[1px] border-[#D1D3D8]"
  }`;
  const inputContainer1 = `w-100 flex items-center gap-2 py-2.5 px-3 b-radius-6 ${
    data?.firstname?.endsWith(".") ||
    data?.firstname?.endsWith("-") ||
    data?.firstname?.endsWith("'") ||
    data?.first_name
      ? "border-[1px] border-red-500"
      : "border-[1px] border-[#D1D3D8]"
  }`;
  const input = "w-100 font14px-400";

  return (
    <div className="w-full max-w-[470px] mx-auto flex-column align-center">
      <div className="w-85 font24px-600 text-center text-[var(--textBlack)] mt-8 mb-6">
        Let’s Ignite Growth with Conversational Relationship Platform
      </div>
      {/* <div className='mb-2'>
        <Google />
      </div>
      <MidTileDivider title='Or' width={'100%'} /> */}
      <form action="" onSubmit={handleSubmit}>
        <div className="flex-row items-center gap-3.5">
          <div className={cn(card)}>
            <label htmlFor="firstname" className={cn(label)}>
              First Name<sup className="text-red-500">*</sup>
              <span className="font12px-500 italic text-[var(--font-400)]">
                (Required)
              </span>
            </label>
            <div className={cn(inputContainer1, "w-[15vw]")}>
              <div className="">
                <User width="16px" height="16px" />
              </div>
              <input
                name="firstname"
                id="firstname"
                type="text"
                placeholder="Ex:- Ronald"
                className={cn(input)}
                value={data?.firstname}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange("update", name, value);
                }}
                maxLength={100}
                autoComplete="off"
              />
            </div>
          </div>
          <div className={cn(card)}>
            <label htmlFor="lastname" className={cn(label)}>
              Last Name<sup className="text-red-500">*</sup>
              <span className="font12px-500 italic text-[var(--font-400)]">
                (Required)
              </span>
            </label>
            <div className={cn(inputContainer2, "w-[15vw]")}>
              <div className="">
                <User width="16px" height="16px" />
              </div>
              <input
                name="lastname"
                id="lastname"
                type="text"
                placeholder="Ex:- Warren"
                className={cn(input)}
                value={data?.lastname}
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange("update", name, value);
                }}
                maxLength={100}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className={cn(card, "mt-6")}>
          <label htmlFor="email" className={cn(label)}>
            Email
            <sup className="text-red-500">*</sup>
            <span className="font12px-500 italic text-[var(--font-400)]">
              (Required)
            </span>
          </label>
          <div className={cn(inputContainer)}>
            <div>
              <Mail width="16px" height="16px" />
            </div>
            <input
              name="mail"
              id="mail"
              type="email"
              placeholder="Ex:- yourmail@email.com"
              className={cn(input)}
              value={data?.mail}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange("update", name, value);
              }}
              autoComplete="off"
            />
          </div>

          {errorMail && (
            <div className="flex-row align-center">
              <img src={ICONS?.alertIconError} alt="alertIconError" />
              <span className="error-message pl-5">
                Enter a valid email address
              </span>
            </div>
          )}
          {toastError && (
            <div className="flex-row align-center">
              <img src={ICONS?.alertIconError} alt="alertIconError" />
              <span className="error-message pl-5">{toastError}</span>
            </div>
          )}
        </div>
        <div className="mt-20 w-100 max-w-[470px] flex-row justify-center pointer">
          <ReCAPTCHA
            sitekey="6LfThrQqAAAAADPt2mC7Zc1QyQkIrLnEDeD6Aaiy"
            onChange={handleCaptchaChange}
            // className="recaptcha-checkbox-border"
          />
        </div>
        <div className="flex-row gap-2 mt-3">
          {/* <CheckBox
            // data={data?.terms}
            value={data?.terms}
            onChange={(e) => {
              onChange("update", "terms", e);
            }}
            className="size-[18px]"
          /> */}
          <CheckBoxSqure
            value={data?.terms}
            onChange={(e) => {
              onChange("update", "terms", e);
            }}
            className="mt-0.5"
          />
          <p className="font13px-400 text-[#4C515B]">
            By signing up, I accept the Gainwix.ai terms of service & privacy
            notice.
          </p>
        </div>
        <div className="flex-row mt-3 gap-2">
          <CheckBoxSqure
            value={data?.services}
            onChange={(e) => {
              onChange("update", "services", e);
            }}
            className="mt-0.5"
          />
          <p className="font13px-400 text-[#4C515B] w-100">
            I consent to receive promotional messages, updates, and
            announcements about Gainwix.ai products and services.
          </p>
        </div>
        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className={cn(
              "px-7 rounded-[8px] py-2 font16px-600 flex items-center justify-center",
              validation()
                ? isLoading
                  ? "cursor-not-allowed bg-[var(--new-primary)] text-[var(--white)]"
                  : "bg-[var(--new-primary)] text-[var(--white)] cursor-pointer"
                : "bg-[#EDEFF1] text-[#566F8F] cursor-not-allowed"
            )}
          >
            {isLoading ? (
              <div className=" flex items-center justify-center">
                <Loader Width={110} Height={20} loaderBg="white" />
              </div>
            ) : (
              "Register Now"
            )}
          </button>
        </div>
      </form>

      <div className="w-85 flex items-center justify-center gap-1 border-t border-[var(--border-100)] mt-6 pt-6">
        <p className="font16px-400 text-[var(--textBlack)]">
          Already have an account?
        </p>
        <Link to={"/login"} className="text-[var(--a)] font16px-600">
          Login
        </Link>
      </div>
    </div>
  );
}
