import React from 'react';
// assets
import Icons from '../Icons';
import FullArrow from 'assets/customSVG/FullArrow';

// helper
import { cn } from 'helper/cn';

export default function TriggerListCard({ data }) {
  return (
    <div
      className={cn(
        'w-full h-[60px] flex items-center gap-4 py-1 px-3.5 border border-[var(--border-50)] rounded-md',
        data?.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      )}>
      <div className='size-9 flex items-center justify-center '>
        <Icons
          keyProp={data?.key}
          className='rounded-[10px] p-1.5'
        />
      </div>
      <div className='flex flex-col flex-1 gap-0.5'>
        <h2
          className={cn(
            'text-sm weight-medium',
            data?.disabled
              ? 'text-[var(--font-400)]'
              : 'text-[var(--contentText)]'
          )}>
          {data?.label}
        </h2>
        <p className='text-xs weight-medium text-[var(--font-400)]'>
          {data?.text}
        </p>
      </div>
      {data?.warning || data?.error ? (
        <div className='size-6 flex items-center justify-center'>errror</div>
      ) : (
        <div className='size-6 flex items-center justify-center'>
          <FullArrow
            width={16}
            height={16}
            color={'#898E99'}
          />
        </div>
      )}
    </div>
  );
}
