import React from "react";

const Credits = ({ color, fillColors }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 11.875C14.4873 11.875 18.125 10.1961 18.125 8.125C18.125 6.05393 14.4873 4.375 10 4.375C5.51269 4.375 1.875 6.05393 1.875 8.125C1.875 10.1961 5.51269 11.875 10 11.875Z"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill={fillColors}
        />
        <path
          d="M10 11.875V15.625"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.875 8.125V11.875C1.875 13.75 5 15.625 10 15.625C15 15.625 18.125 13.75 18.125 11.875V8.125"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 11.1021V14.8521"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 11.1021V14.8521"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Credits;
