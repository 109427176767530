import React, { useState } from "react";

// assets
import User from "../../assets/customSVG/User";
import Business from "../../assets/customSVG/Business";
import Contact from "../../assets/customSVG/Contact";
import Mail from "../../assets/customSVG/Mail";
import Map from "../../assets/customSVG/Map";

// helper
import { cn } from "../../helper/cn";

const datas = {
  name: "Cameron Williamson",
  company_name: "The Walt Disney Company",
};

export default function BillingInformation({ edit, data = datas, onChange }) {
  const [billingInformation, setBillingInformation] = useState({
    first_name: "",
    last_name: "",
    brand_name: "",
    Mail: "",
    phone_number: "",
    address: "",
    country: "",
    city: "",
    postal_code: "",
    gst_number: "",
  });

  const information = [
    {
      id: 1,
      icons: (
        <User
          width={16}
          height={16}
          color={"var(--contentText)"}
          strokeWidth="0.2"
        />
      ),
      key: "name",
    },
    {
      id: 2,
      icons: <Business width={16} height={16} color={"var(--contentText)"} />,
      key: "company_name",
    },
    {
      id: 3,
      icons: (
        <Contact
          width={16}
          height={16}
          color="var(--contentText)"
          strokeWidth="1.2"
        />
      ),
      key: "name",
    },
    {
      id: 4,
      icons: (
        <Mail
          width={16}
          height={16}
          color={"var(--contentText)"}
          strokeWidth="1.4"
        />
      ),
      key: "company_name",
    },
    {
      id: 5,
      icons: <Map width={16} height={16} color={"var(--contentText)"} />,
      key: "company_name",
    },
  ];

  const formDetailes = [
    {
      id: 1,
      label: "First Name",
      name: "first_name",
      placeholder: "Enter first name",
      required: true,
    },
    {
      id: 2,
      label: "Last Name",
      name: "last_name",
      placeholder: "Enter last name",
      required: true,
    },
    {
      id: 3,
      label: "Brand Name",
      name: "brand_name",
      placeholder: "Enter brand name",
      required: true,
    },
    {
      id: 4,
      label: "Eail address",
      name: "Mail",
      placeholder: "Ex:- name@gmail.com",
      required: false,
    },
    {
      id: 5,
      label: "Phone number",
      name: "phone_number",
      placeholder: "Ex:- +00 00000 00000",
      required: true,
    },
    {
      id: 6,
      label: "Address",
      name: "address",
      placeholder: "Enter address",
      required: true,
    },
    {
      id: 7,
      label: "Country",
      name: "country",
      placeholder: "Enter country",
      required: true,
    },
    {
      id: 8,
      label: "City",
      name: "city",
      placeholder: "Enter city",
      required: true,
    },
    {
      id: 9,
      label: "Postal code",
      name: "postal_code",
      placeholder: "Enter zip / postal code",
      required: true,
    },
    {
      id: 10,
      label: "GST Number",
      name: "gst_number",
      placeholder: "Enter GST number",
      required: true,
    },
  ];

  const onInputChange = (key, value) => {
    const data = { ...billingInformation };
    data[key] = value;
    setBillingInformation(data);
    onChange(data);
  };

  // styles
  const iconsContainer =
    "size-3.5 bg-[var(--BG-50)] flex items-center justify-center rounded-md";
  const card = "w-full flex flex-col gap-2.5";
  const label = "text-sm weight-semibold leading-5 text-[var(--contentText)]";
  const inputContainer =
    "w-full h-11 flex items-center gap-2 py-2 px-3 border border-[#D1D3D8] rounded-lg";
  const input = "w-full text-sm weight-medium";

  return (
    <div className="w-full">
      {edit ? (
        <div className="grid grid-cols-2 gap-5 pl-4 mt-4">
          {formDetailes?.map((f) => {
            return (
              <div className={cn(card)}>
                <label htmlFor={f?.name} className={cn(label)}>
                  {f.label}
                  {f.required && <sup className="text-red-500">*</sup>}
                </label>
                <div className={cn(inputContainer)}>
                  <input
                    name={f?.name}
                    id={f?.name}
                    type="text"
                    placeholder={f?.placeholder}
                    className={cn(input)}
                    value={billingInformation[f?.name] ?? ""}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      onInputChange(name, value);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-1 pl-10">
          {information?.map((i) => {
            return (
              <div className="w-full h-9 flex items-center gap-2.5">
                <div className={cn(iconsContainer)}>{i.icons}</div>
                <div className="font14px-400 text-[var(--textBlack)]">
                  {data[i.key]}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
