import React from 'react';

// assets
import { ICONS } from 'assets/icons';

// utils
import { Modal } from 'antd';

// helper
import { cn } from 'helper/cn';

// components
import Loader from 'components/commonComponents/Loader/Index';

export default function Alert({
  open,
  setOpen,
  rootClassName = '',
  icon = null,
  title = '',
  content,
  contentClassName = '',
  contentStyle = {},
  yesContent,
  yesButtonClassName = '',
  yesButtonStyle = {},
  yesDisabled = false,
  yesLoading = false,
  noContent,
  noButtonStyle = {},
  noDisabled = false,
  noButtonClassName = '',
  handleYes,
  handleNo,
  carousel_delete,
  showCloseIcon = false,
}) {
  const handleCancel = () => setOpen(false);

  const btnStyle = 'w-auto h-9 px-4 py-1 text-base weight-semibold rounded-md';

  return (
    <Modal
      open={open}
      afterOpenChange={(open) => setOpen(open)}
      onCancel={handleCancel}
      footer={false}
      centered={true}
      closable={false}
      width={'auto'}
      maskClosable
      mask={true}
      wrapClassName={'bg-[#00000095]'}
      destroyOnClose
      style={{
        width: '100%',
        borderRadius: 20,
        boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
      }}>
      <div
        className={cn(
          'min-w-[30vw] max-w-[50vw] w-auto flex flex-col gap-9 p-6',
          rootClassName
        )}>
        <div className='w-[95%] flex flex-col gap-5'>
          <img
            src={icon ?? ICONS?.discardCarousel}
            alt='popup'
            className='size-9'
          />
          <h5 className='text-lg weight-bold text-[var(--textBlack)]'>
            {title}
          </h5>
          <p
            className={cn(
              'text-base weight-medium text-[var(--font-600)]',
              contentClassName
            )}
            style={contentStyle}>
            {content}
          </p>
        </div>
        <div className='flex items-center justify-end gap-5'>
          {noContent && (
            <button
              className={cn(
                'h-auto hover:bg-[var(--BG-25)] rounded-[0.37vw]',
                btnStyle,
                noDisabled
                  ? 'text-[var(--font-200)] cursor-not-allowed'
                  : 'text-[var(--font-600)] cursor-pointer',
                noButtonClassName
              )}
              style={noButtonStyle}
              onClick={() => {
                if (!noDisabled) handleNo();
              }}>
              {noContent}
            </button>
          )}
          {yesContent && (
            <button
              className={cn(
                yesDisabled
                  ? 'bg-[var(--lightGrey)] text-[var(--darkgrey)] cursor-not-allowed'
                  : 'bg-[#05805C] text-[var(--white)] cursor-pointer',
                btnStyle,
                yesButtonClassName
              )}
              style={yesButtonStyle}
              onClick={() => {
                if (!yesDisabled) handleYes();
              }}
              carousel_delete={carousel_delete}>
              {yesLoading ? (
                <div className='w-full h-full flex items-center justify-center'>
                  <Loader
                    Width={25}
                    Height={25}
                    loaderBg='white'
                  />
                </div>
              ) : (
                yesContent
              )}
            </button>
          )}
        </div>
        {showCloseIcon && (
          <div
            className='w-[5%] h-fit cursor-pointer rounded-full'
            onClick={() => {
              handleCancel();
            }}>
            <img
              src={ICONS?.popupX}
              alt='popup'
              className={`size-[1.2vw]`}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
