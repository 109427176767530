import { Tooltip } from "antd";
import React, { useState } from "react";
import Download from "../../../assets/customSVG/campaigns/Download";
import { ICONS } from "../../../assets/icons";
import { ICONSS } from "../../../assets/icons/index1";
import FilterHome from "../../../components/commonComponents/filterHome/FilterHome";
import { creditHistory } from "../../../constant/app/account/account";
import { HomeFilterList } from "../../../constant/app/Filter/Filter";
import { formatCurrency } from "../../../helper/formatCurrency";
import { useAspSelector } from "../../../test/jest-redux-hooks";
import BillingHistory from "./BillingHistory";
import PaymentHostory from "./PaymentHostory";

import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const Credits = ({
  setAddCredits,
  brandsData,
  handleFilterChange,
  setStartDate,
  setEndDate,
  customStartDate,
  customEndDate,
  startDate,
  endDate,
  creditReportData,
  paymentHistoryData,
  setYearFilterOpen,
  yearFilterOpen,
  setSelectedYear,
  selectedYear,
  years,
  filterValue,
  listLoading,
}) => {
  const { currentAccount } = useAspSelector((state) => state.app);
  const [openBillingHistory, setOpenBillingHistory] = useState({
    creditUsage: false,
    paymentHistory: false,
  });

  const handleBillingHistory = (key) => {
    setOpenBillingHistory((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const downloadCreditReport = () => {
    const formattedContacts = paymentHistoryData?.bcur.map((contact) => ({
      Date: contact.created_date,
      "Credit Status": contact.credit_status,
      "Credit Amount": contact?.credit_amount,
      "Marketing Credits": contact?.debit_amount
        ? contact?.marketing_credits / 100
        : contact?.marketing_credits,
      "Utility Credits": contact?.debit_amount
        ? contact?.utility_credits / 100
        : contact?.utility_credits,
      "Authntication Credits": contact?.debit_amount
        ? contact?.authentication_credits / 100
        : contact?.authentication_credits,
      "Debit Amount": contact?.debit_amount / 100,
    }));

    // Generate Excel file
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedContacts);
    XLSX.utils.book_append_sheet(wb, ws, "Broadcast Contacts");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `$Credit Usage Report.xlsx`
    );
    // }
  };

  return (
    <>
      <div className="w-100 bg-white b-radius p-20">
        <div className="flex-row align-center gap-2">
          <img src={ICONSS?.conversationCredits} alt="conversationCredits" />
          <span className="font16px-600 text-[var(--textBlack)]">
            WhatsApp Conversation Credits
          </span>
        </div>

        <div className="flex-row align-center space-between mt-20">
          <div className="w-100 flex-column mt-10">
            <span className="mb-5 font14px-400 text-[var(--font-600)]">
              Amount Remaining
            </span>
            <div className="w-100 flex-row align-center space-between">
              <span className="font22px-600 text-[var(--textBlack)]">
                {formatCurrency(brandsData?.remaining_credits_amount)}
              </span>
              {(currentAccount?.role === "Account Owner" ||
                currentAccount?.role === "Account Manager") && (
                <button
                  className="font15px-500 text-[var(--new-primary)] border border-[var(--new-primary)] px-4 py-1.5 b-radius-6 flex-row align-center gap-2"
                  onClick={() => setAddCredits(true)}
                >
                  <img
                    src={ICONSS?.conversationCredits}
                    alt="conversationCredits"
                  />
                  Buy More
                </button>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex-row align-center gap-2 mt-10">
            <span className="font15px-400 text-[var(--font-600)]">
              Message Limit
            </span>
            <Tooltip
              title={
                "Total number of messages can be sent using remaining amount"
              }
            >
              <img
                src={ICONS?.conversationAction}
                alt="performanceIcon"
                className="pointer"
              />
            </Tooltip>
          </div>
          <div className="w-90 flex-row align-center space-between mt-20">
            {creditHistory?.map((item) => {
              return (
                <div key={item?.id} className="flex-row gap-2">
                  <div className="border-l-[1px] border-l-[#898E99] h-6 first:border-l-0 " />

                  <img src={item?.icon} alt="creditHistory" className="pb-20" />
                  <div className="flex-column">
                    <span className="font15px-400 text-[var(--textBlack)]">
                      {item?.label}
                    </span>
                    {item?.id !== 4 && (
                      <span className="font18px-500 text-[var(--textBlack)]">
                        {brandsData?.current_plan === "Starter Plan"
                          ? item?.id === 1
                            ? Math.round(
                                (brandsData?.remaining_credits_amount * 100) /
                                  17
                              )
                            : Math.round(
                                (brandsData?.remaining_credits_amount * 100) / 6
                              )
                          : brandsData?.current_plan === "Growth Plan"
                          ? item?.id === 1
                            ? Math.round(
                                (brandsData?.remaining_credits_amount * 100) /
                                  12
                              )
                            : Math.round(
                                (brandsData?.remaining_credits_amount * 100) / 4
                              )
                          : brandsData?.current_plan === "Magnum Plan"
                          ? item?.id === 1
                            ? Math.round(
                                (brandsData?.remaining_credits_amount * 100) / 8
                              )
                            : Math.round(
                                (brandsData?.remaining_credits_amount * 100) / 2
                              )
                          : ""}
                      </span>
                    )}
                    <span className="font18px-500 text-[var(--textBlack)]">
                      {item?.id === 4 && item?.msg3}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-100 bg-white b-radius p-[15px] mt-10">
        <div className="w-100 flex-row align-center space-between pointer">
          <span
            className={`font15px-500 w-50  ${
              openBillingHistory["creditUsage"]
                ? "text-[var(--textBlack)]"
                : "text-[var(--font-600)]"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleBillingHistory("creditUsage");
            }}
          >
            Credit Usage Report
          </span>
          <div className="w-auto flex-row align-center space-between gap-3">
            {openBillingHistory["creditUsage"] &&
              creditReportData?.bcur?.length > 0 && (
                <FilterHome
                  filterList={HomeFilterList}
                  fetchApiByFilter={handleFilterChange}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  customStartDate={customStartDate}
                  customEndDate={customEndDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            {openBillingHistory["creditUsage"] &&
              creditReportData?.bcur?.length > 0 && (
                <Tooltip title="Download Reports">
                  <div
                    className="group flex-row align-center pointer hover:bg-[var(--new-primary-bg)] b-radius-6 px-2 border border-[var(--border-input)] py-1.5 text-[1rem] font-[500] text-[var(--font-600)] hover:text-[var(--new-primary)] hover:border-[var(--new-primary-bg)]"
                    onClick={downloadCreditReport}
                  >
                    <Download
                      className="stroke-[var(--font-600)] group-hover:stroke-[var(--new-primary)]"
                      color=""
                    />
                  </div>
                </Tooltip>
              )}
            {openBillingHistory["creditUsage"] ? (
              <img
                src={ICONS?.sidebarChevronDown}
                alt="chevronDown pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBillingHistory("creditUsage");
                }}
              />
            ) : (
              <img
                src={ICONS?.chevronDownCarousel}
                className=""
                alt="dropdown-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBillingHistory("creditUsage");
                }}
              />
            )}
          </div>
        </div>

        {openBillingHistory["creditUsage"] && creditReportData && (
          <>
            <BillingHistory
              creditUsage={openBillingHistory["creditUsage"]}
              creditReportData={creditReportData}
            />
          </>
        )}
      </div>
      <div className="w-100 bg-white b-radius p-[15px] mt-10">
        <div
          className="w-100 flex-row align-center space-between pointer"
          onClick={() => handleBillingHistory("paymentHistory")}
        >
          <span className="font15px-500 text-[var(--font-600)]">
            Your Payment History
          </span>
          {openBillingHistory["paymentHistory"] ? (
            <img src={ICONS?.sidebarChevronDown} alt="chevronDown pointer" />
          ) : (
            <img
              src={ICONS?.chevronDownCarousel}
              className=""
              alt="dropdown-icon"
            />
          )}
        </div>

        {openBillingHistory["paymentHistory"] && (
          <>
            <PaymentHostory
              paymentHistory={openBillingHistory["paymentHistory"]}
              paymentHistoryData={paymentHistoryData}
              yearFilterOpen={yearFilterOpen}
              setYearFilterOpen={setYearFilterOpen}
              years={years}
              setSelectedYear={setSelectedYear}
              selectedYear={selectedYear}
              listLoading={listLoading}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Credits;
