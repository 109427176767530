import React from 'react';

const UnLink = ({
  width = 16,
  height = 14,
  color = '#2D3036',
  strokeWidth = '1.66667',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 14'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.08333 7.0013H8M10.1875 4.08464H11.6458C13.2567 4.08464 14.5625 5.39047 14.5625 7.0013C14.5625 7.87243 14.1806 8.65436 13.5751 9.1888M5.8125 9.91797H4.35417C2.74334 9.91797 1.4375 8.61213 1.4375 7.0013C1.4375 5.39047 2.74334 4.08464 4.35417 4.08464H5.08333M2.16667 1.16797L13.8333 12.8346'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default UnLink;
