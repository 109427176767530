export default function InlineStyleControls({
  options,
  editorState,
  onToggle,
}) {
  const currentStyle = editorState?.getCurrentInlineStyle();

  return (
    <div className="flex justify-end gap-[0.5vw]">
      {options.map((type) => {
        return (
          <StyleButton
            key={type.style}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={onToggle}
            style={type.style}
          />
        );
      })}
    </div>
  );
}

const StyleButton = ({ active, label, onToggle, style }) => {
  const onToggleBtn = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <span
      className={`size-[1.5vw] rounded-[0.37vw] cursor-pointer flex items-center justify-center hover:bg-slate-100 ${
        active ? "!bg-slate-100" : ""
      }`}
      onMouseDown={onToggleBtn}
    >
      {label}
    </span>
  );
};
