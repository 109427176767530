import React, { useState } from "react";

import { Tooltip } from "antd";
import TimeCircle from "../../../assets/customSVG/accounts/TimeCircle";
import { getRandomColorsBassedOnLetters } from "../../../helper/colors";
import { useAspSelector } from "../../../test/jest-redux-hooks";

export default function OverviewCarousel({
  data,
  viewAccount,
  billing_plan_name,
  total_amount,
  brandUsers,
  differenceInDays,
  brandsData,
  name,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { currentUser } = useAspSelector((state) => state?.app);

  const limit = Math.round((data?.length - 3) / 3);

  const handleNext = () => {
    if (currentIndex < limit)
      setCurrentIndex((prevIndex) => (prevIndex + 2) % data?.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 2 + data?.length) % data?.length
    );
  };

  const colorsBrand = getRandomColorsBassedOnLetters(name);

  return (
    <div className="w-full h-full relative">
      <div
        className="w-full  relative px-5 py-5"
        onClick={() => {
          if (viewAccount) viewAccount();
        }}
      >
        {/* <div className="w-full flex items-center justify-between gap-2">
          {data?.slice(currentIndex, currentIndex + 2).map((c, index) => (
            <div
              key={c.id}
              className="w-1/3 h-[52px] flex flex-col items-center justify-between"
            >
               <div className="text-xs weight-semibold text-[var(--font-600)] text-center">
                {"bsjfe"}
              </div>
              <div className="text-lg weight-bold">{billing_plan_name}</div>
             
            </div>
          ))}
        </div> */}

        <div className="w-full flex items-center justify-between gap-2">
          <div>
            <p className="text-xs weight-semibold text-[var(--font-600)]">
              Plan name
            </p>
            <div className="text-lg weight-bold">{billing_plan_name}</div>
          </div>
          <div>
            <p className="text-xs weight-semibold text-[var(--font-600)] text-end">
              Credits left
            </p>
            <div
              className={`text-lg weight-bold ${
                total_amount < 1000 ? "text-[#FF543E]" : ""
              }`}
            >{`₹${
              total_amount !== null ? parseFloat(total_amount).toFixed(2) : 0
            }`}</div>
          </div>
        </div>
        <div className="flex-row align-center space-between pt-20">
          <div className="flex-row">
            <TimeCircle color={"#54577A"} />
            <span className="font14px-400 text-[#4C515B] pl-10">
              {billing_plan_name !== "Starter Plan"
                ? `${differenceInDays} Days Left`
                : "Free"}
            </span>
          </div>
          {brandUsers?.map((data) => (
            <img src={data?.profile_photo} />
          ))}
          {/* <div className="flex relative">
            {brandusersimage?.map((data, index) => (
              <div
              className={`w-[2.5vw] h-[2.5vw] border rounded-full -ml-4 relative bg-white z-${
                brandusersimage.length - index
              }`}
              >
                <img
                  key={data?.id}
                  src={data?.img}
                  alt=""
                  className="w-full h-full object-cover rounded-full p-[0.1vw]"
                />
              </div>
            ))}
          </div> */}

          <div className="w-[2.5vw] h-[2.5vw] invisible">
            <p className=" text-white bg-[#8796AF] w-full h-full rounded-full flex justify-center items-center">
              {"hii"}
            </p>
          </div>

          <div className="flex relative">
            {brandUsers?.length !== 0 ? (
              <>
                {brandUsers?.slice(0, 3).map((data, index) => {
                  const colors = getRandomColorsBassedOnLetters(
                    data?.first_name && data?.first_name
                  );
                  return (
                    <div
                      key={data?.id}
                      className={`w-[2vw] h-[2vw] ${
                        data?.img || (data?.first_name && "border")
                      } bg-white rounded-full -ml-3 relative z-${
                        brandUsers?.length - 10
                      }`}
                    >
                      {data?.img ? (
                        <img
                          src={data?.img}
                          alt=""
                          className="w-full h-full object-cover rounded-full"
                        />
                      ) : (
                        data?.first_name && (
                          <p
                            className="text-white bg-[#8796AF] w-full h-full rounded-full flex justify-center items-center"
                            style={{
                              color: colors.color,
                              backgroundColor: colors.backgroundColor,
                            }}
                          >
                            {data?.first_name.charAt(0).toUpperCase()}
                          </p>
                        )
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <p
                  className="px-2 py-0.5  w-full h-full rounded-full flex justify-center items-center"
                  style={{
                    color: colorsBrand.color,
                    backgroundColor: colorsBrand.backgroundColor,
                  }}
                >
                  {currentUser?.first_name.charAt(0).toUpperCase()}
                </p>
              </>
            )}

            {brandUsers?.length > 3 && (
              <Tooltip
                title={
                  <div className="h-[20vh] overflow-scroll p-5 w-[10vw]">
                    {brandUsers.map((data) => {
                      const colors = getRandomColorsBassedOnLetters(
                        data?.first_name && data?.first_name
                      );
                      return (
                        <div
                          key={data?.id}
                          className="flex items-center py-1 gap-2"
                        >
                          <p
                            className="!text-white bg-[#8796AF] w-[2vw] h-[2vw] rounded-full flex justify-center items-center"
                            // style={{
                            //   color: colors.color,
                            //   backgroundColor: colors.backgroundColor,
                            // }}
                          >
                            {data.first_name.charAt(0).toUpperCase()}
                          </p>
                          <p>{data?.first_name}</p>
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <div className="w-[2vw] h-[2vw] border bg-gray-400 text-white font-semibold flex items-center justify-center rounded-full -ml-3 relative cursor-pointer">
                  +{brandUsers.length - 3}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {/* {currentIndex > 0 && (
        <button
          className={cn(
            "w-7 h-8 rounded-md bg-[var(--BG-50)] items-center justify-center hidden absolute top-10 -left-0 !z-50 cursor-pointer",
            className
          )}
          onClick={handlePrev}
        >
          <Arrow />
        </button>
      )}
      {currentIndex < limit && (
        <button
          className={cn(
            "w-7 h-8 rounded-md bg-[var(--BG-50)] items-center justify-center hidden absolute top-10 -right-0 !z-50 rotate-180 cursor-pointer",
            className
          )}
          onClick={handleNext}
        >
          <Arrow />
        </button>
      )} */}
    </div>
  );
}
