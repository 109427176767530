import React, { useCallback, useState } from 'react';
import { Popover } from 'antd';

// helpers
import { cn } from '../../../helper/cn';

// hppks
import useToggle from '../../../hooks/useToggle';

// components
import CheckBoxSqure from '../checkbox/CheckBoxSqure';
import RadioButton from '../../form/radio/RadioButton';

const Filters = ({
  isLoading = false,
  type = 'multiple',
  lists,
  setLists,
  onSelect,
  label,
  rootClassName = '',
  labelClassName = '',
  listTitleStyle = '',
  arrow = true,
  disabled = false,
}) => {
  const [initialList, setInitialList] = useState(lists);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isOpen, setIsOpen] = useToggle(false);

  const getSingleFilterData = useCallback(() => {
    return lists?.find((l) => l?.isSelected);
  }, [lists]);

  const onFilterChange = useCallback(
    (data) => {
      if (type === 'multiple') {
        const checkIsSelectecd = selectedIds?.find((id) => id === data?.id);

        if (checkIsSelectecd) {
          const updatedIds = selectedIds?.filter((id) => id !== data?.id);
          setSelectedIds(updatedIds);

          const update = lists?.map((list) => {
            if (list?.id === data?.id) return { ...list, isSelected: false };
            return list;
          });
          setLists(update);
        } else {
          setSelectedIds([...selectedIds, data?.id]);

          const update = lists?.map((list) => {
            if (list?.id === data?.id) return { ...list, isSelected: true };
            return list;
          });
          setLists(update);
        }
      } else if (type === 'single') {
        if (selectedIds?.[0] !== data?.id) {
          setSelectedIds([data?.id]);

          const update = lists?.map((list) => {
            if (list?.id === data?.id) return { ...list, isSelected: true };
            return { ...list, isSelected: false };
          });
          setLists(update);
        } else {
          setSelectedIds([]);

          const update = lists?.map((list) => {
            return { ...list, isSelected: false };
          });
          setLists(update);
        }
      }
    },
    [selectedIds, lists, type]
  );

  const clear = () => {
    setLists(initialList);
    setSelectedIds([]);
    setIsOpen(false);
  };

  return (
    <Popover
      trigger={!disabled ? ['click'] : []}
      //   open={isOpen}
      arrow={false}
      placement='bottom'
      //   onOpenChange={(open) => {
      //     setIsOpen(open);
      //   }}
      content={
        <div className='min-w-56 px-3.5 py-2.5'>
          {!getSingleFilterData()?.filter && (
            <div className='w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium'>
              <div className='text-[var(--font-400)]'>{label}</div>
              <div
                className={cn(
                  'text-[#F96056]',
                  selectedIds?.length > 0
                    ? 'cursor-pointer opacity-100'
                    : 'cursor-not-allowed opacity-50'
                )}
                onClick={() => {
                  if (selectedIds?.length > 0) clear();
                }}
              >
                Clear
              </div>
            </div>
          )}
          {lists?.map((t) => {
            return (
              <>
                {t?.type === 'title' ? (
                  <div
                    className={cn(
                      'text-sm weight-medium text-[var(--font-400)] pb-1 pt-2.5',
                      listTitleStyle
                    )}
                  >
                    {t?.label}
                  </div>
                ) : (
                  <div
                    key={t?.id}
                    className={cn(
                      'w-full h-9 flex items-center gap-2.5',
                      t?.isDisabled
                        ? 'cursor-not-allowed opacity-50'
                        : 'cursor-pointer',
                      t?.filter && 'pl-2.5'
                    )}
                    onClick={() => {
                      if (!disabled && onSelect && !t?.isDisabled) onSelect(t);
                      onFilterChange(t);
                    }}
                  >
                    {type === 'multiple' ? (
                      <CheckBoxSqure
                        value={t?.isSelected}
                        onChange={(e) => {}}
                        className={`size-4`}
                      />
                    ) : (
                      <RadioButton
                        selected={t?.isSelected}
                        rootClassName={`size-4`}
                      />
                    )}
                    {t?.icon && <img src={t?.icon} alt={t?.label} />}
                    <p className='text-sm weight-medium'>{t?.label}</p>
                  </div>
                )}
              </>
            );
          })}
        </div>
      }
    >
      <div
        className={cn(
          'min-w-32 group/goal flex items-center gap-2.5 bg-[var(--BG-25)] px-5 py-1.5 rounded-lg',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          rootClassName
        )}
        // onClick={() => setIsOpen(!isOpen)}
      >
        <div className='campaign-filters flex item-center gap-1'>
          {!getSingleFilterData()?.filter && (
            <p className={cn(labelClassName)}>{label}</p>
          )}
          {getSingleFilterData()?.icon && (
            <img
              src={getSingleFilterData()?.icon}
              alt={getSingleFilterData()?.label}
            />
          )}
          <div
            className={`ml-1 ${
              selectedIds?.length === lists?.length ||
              selectedIds?.length === 0 ||
              selectedIds?.length === 1
                ? ''
                : 'rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center'
            }`}
          >
            {getSingleFilterData()?.filter
              ? getSingleFilterData()?.label
              : selectedIds?.length === lists?.length ||
                selectedIds?.length === 0
              ? '(All)'
              : selectedIds?.length > 1 && selectedIds?.length !== lists?.length
              ? selectedIds?.length
              : `(${getSingleFilterData()?.label})`}
          </div>
        </div>
        {arrow && (
          <div
            className={cn(
              'transition-transform duration-200 ease-linear',
              false ? '-rotate-180' : 'rotate-0',
              isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
            )}
          >
            <div
              className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
            ></div>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default Filters;
