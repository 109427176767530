import { ICONS } from 'assets/icons';

export const STATUS_FILTERS = [
  {
    id: 1,
    label: 'Triggered',
    key: 'TRIGGERED',
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 2,
    label: 'Completed',
    key: 'COMPLETED',
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 3,
    label: 'Active',
    key: 'ACTIVE',
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 4,
    label: 'Failure',
    key: 'FAILED',
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 5,
    label: 'Abandoned',
    key: 'ABANDONED',
    isSelected: false,
    isDisabled: false,
  },
];

export const SORT_FILTERS = [
  {
    id: 1,
    type: 'title',
    label: 'Sort By',
    key: '',
    icon: '',
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 2,
    label: 'Newest first',
    filter: 'sort',
    key: 'ASC',
    icon: ICONS?.newestFirst,
    isSelected: true,
    isDisabled: false,
  },
  {
    id: 3,
    label: 'Oldest first',
    filter: 'sort',
    key: 'DESC',
    icon: ICONS?.oldestFirst,
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 4,
    type: 'title',
    label: 'Order By',
    key: '',
    icon: '',
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 5,
    label: 'Ascending',
    filter: 'order',
    key: 'ASC',
    icon: ICONS?.ascending,
    isSelected: false,
    isDisabled: false,
  },
  {
    id: 6,
    label: 'Descending',
    filter: 'order',
    key: 'DESC',
    icon: ICONS?.descending,
    isSelected: false,
    isDisabled: false,
  },
];

export const PERFORMENCE_DATE_FILTERS_LIST = [
  {
    id: 1,
    label: 'Last 7 days',
    key: 'last7days',
  },
  {
    id: 2,
    label: 'Last 14 days',
    key: 'last14days',
  },
  {
    id: 3,
    label: 'Last 28 days',
    key: 'last28days',
  },
  {
    id: 4,
    label: 'Custom',
    key: 'custom',
  },
];

export const PERFORMENCE_TABS_FILTER = [
  {
    id: 1,
    label: 'Triggered',
    icon: ICONS?.performanceClicks,
    count: 0,
    value: 9,
    color: '#9747FF',
    key: 'triggered',
  },
  {
    id: 2,
    label: 'Completed',
    icon: ICONS?.performanceReactions,
    count: 0,
    value: 8,
    color: '#F6A723',
    key: 'completed',
  },
  {
    id: 3,
    label: 'Failure',
    icon: ICONS?.performanceLeads,
    count: 0,
    value: 10,
    color: '#3E93FF',
    key: 'failure',
  },
  {
    id: 4,
    label: 'Abandoned',
    icon: ICONS?.performanceBlcoked,
    count: 0,
    value: -2,
    color: '#FF543E',
    key: 'abandoned',
  },
];
