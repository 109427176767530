import React, { useState, useEffect } from "react";
import "./HeaderDropDown.css";

// assets
import { ICONS } from "../../../assets/icons/index";
import ChevronDown from "../../../assets/customSVG/sideNav/ChevronDown";

// utils
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Popover, Tooltip } from "antd";

// API
import {
  currentBrandWhatsappConnected,
  currentUserDetails,
} from "../../../api/Api";

// hooks
import useToggle from "hooks/useToggle";

// redux
import { useAspSelector, useAspDispatch } from "../../../test/jest-redux-hooks";
import {
  setUpdateAccount,
  updateAccountDetails,
  updateCurrentBrand,
  updateCurrentUser,
} from "../../../reduxToolkit/appSlice";

// components
import Loader from "../Loader/Index";
import { ICONSS } from "../../../assets/icons/index1";

import OnBoarding from "../../../modules/auth/pages/OnBoarding";
import Header from "../modals/Header";
import { getRandomColorsBassedOnLetters } from "../../../helper/colors";

const plans = {
  "Free Plan": ICONSS?.freePlan,
  "Starter Plan": ICONSS?.starterPlan,
  "Growth Plan": ICONSS?.growthPlan,
  "Magnum Plan": ICONSS?.magnumPlan,
};

const plans1 = {
  "Account Manager": ICONSS?.freePlan,
  "Account Owner": ICONSS?.freePlan,
  "Brand Manager": ICONSS?.freePlan,
  "Account User": ICONSS?.freePlan,
  "Campiagn Manager": ICONSS?.freePlan,
};

const HeaderDropDown = ({ expand }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, currentAccount, currentBrand, accountUpdate } =
    useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isloading, setIsloading] = useToggle(false);
  const [isSelecting, setIsSelecting] = useToggle(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const [signupOpen, setSignupOpen] = useState(false);

  useEffect(() => {
    fetchCurrentUserDetails();
  }, [currentBrand, location.pathname.startsWith("/profile"), accountUpdate]);

  const fetchCurrentUserDetails = async () => {
    setIsloading(true);
    try {
      const res = await currentUserDetails();
      if (res && res?.data) {
        // dispatch(setUpdateAccount(false));

        const checkCurrentAccount = res?.data?.active_accounts?.filter(
          (b) => b?.account_id === currentAccount?.account_id
        );

        const checkBrandExist = res?.data?.active_accounts?.[0]?.brands?.filter(
          (b) =>
            b?.brand_id === currentBrand?.brand_id &&
            b?.is_whatsapp_connected === currentBrand?.is_whatsapp_connected
        );

        if (location.pathname?.startsWith("/profile")) {
          setAccountList(res?.data?.active_accounts);
        } else {
          setBrandList(checkCurrentAccount?.[0]?.brands);
        }

        setIsloading(false);

        if (currentBrand && checkBrandExist?.length > 0) {
          // dispatch(updateCurrentBrand(checkBrandExist?.[0]));
          dispatch(updateCurrentUser(res.data));
          if (!selectedBrand && brands.length > 0) {
            setSelectedBrand(brands[0]);
          }
        }

        if (!currentBrand) {
          dispatch(
            updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
          );
          dispatch(currentUser(res.data));
          if (!selectedBrand && brands.length > 0) {
            setSelectedBrand(brands[0]);
          }
        }
      } else {
        setIsloading(false);
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      setIsloading(false);
      // console.error('Error fetching current user details:', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBrandSelect = (brand) => {
    setIsSelecting(true);
    setSelectedBrand(brand);
    currentBrandWhatsappConnected(brand?.brand_id).then((res) => {
      const wap = res?.data?.is_whatsapp_active;
      setTimeout(() => {
        dispatch(updateCurrentBrand({ ...brand, is_whatsapp_connected: wap }));
        setIsDropdownOpen(false);
        setIsSelecting(false);
      }, 3000);
    });

    setTimeout(() => {
      navigate("/user/home");
    }, 3000);
  };

  const handleAccountSelect = (account) => {
    dispatch(updateAccountDetails({ currentAccount: account }));
    navigate(`/profile/account/overview`);
    setIsDropdownOpen(false);
  };

  // useEffect(() => {
  //   if (accountUpdate && accountList?.length > 0 && currentAccount) {
  //     const firstMatchingAccount = accountList
  //       ?.filter((item) => item?.account_id === currentAccount?.account_id)
  //       ?.map((item) => item)?.[0]; // Get the first match

  //     if (firstMatchingAccount) {
  //       dispatch(
  //         updateAccountDetails({ currentAccount: firstMatchingAccount })
  //       );
  //     }
  //   }
  // }, [accountUpdate, accountList]);

  const brandId = currentBrand
    ? currentBrand?.brand_id
    : selectedBrand?.brand_id;

  useEffect(() => {
    if (location.pathname === "/user/home" && brandId) {
      currentBrandWhatsappConnected(brandId).then((res) => {
        const wap = res?.data?.is_whatsapp_active;

        // dispatch(
        //   updateCurrentBrand({ ...selectedBrand, ...currentBrand, wap })
        // );
      });
    }
  }, [location, brandId]);

  const handleVisibleChange = (visible) => {
    setIsDropdownOpen(visible);
  };

  const handleNavigate = () => {
    if (location.pathname.startsWith("/user")) {
      navigate("/profile/account/overview");
    } else {
      setSignupOpen(true);
    }
  };

  const PopupContent = () => {
    return (
      <div className="w-full px-[15px] py-[8px] h-auto bg-[var(--white)] rounded-[0.7vw] flex flex-col shadow-[0px_4px_30px_0px_rgba(45, 48, 54, 0.1)] overflow-hidden">
        {currentAccount?.account_name &&
          location.pathname.startsWith("/user") && (
            <div
              className="w-full h-16 text-white  rounded-t-[0.7vw] flex items-center gap-2.5"
              style={{ borderBottom: "1px solid #E8E8EA" }}
            >
              <div className="size-10 border-2 border-[#6564CA] rounded-md text-[1.2rem] text-[#6564CA] font-[500] flex items-center justify-center">
                {currentAccount?.account_name?.slice(0, 1)}
              </div>
              <div className="max-w-[90%] font16px-500 text-[#4C515B] tracking-wide truncate">
                {currentAccount?.account_name}
              </div>
            </div>
          )}
        <span className="font14px-400 text-[var(--font-400)]">
          {location.pathname.startsWith("/profile") ? "Switch Account" : ""}
        </span>
        {location.pathname.startsWith("/user") ? (
          <div className="w-full h-full max-h-[28vh] overflow-y-scroll listScroll border-b-[1px]">
            {brandList?.map((brand) => (
              <div className="w-full py-[0.2vw] ">
                <Tooltip trigger={["hover"]} title={brand?.brand_name}>
                  <div
                    key={brand?.id}
                    className={`w-full flex items-center rounded-[0.4vw] cursor-pointer  mt-10 p-10 ${
                      brand?.brand_name === currentBrand?.brand_name
                        ? "bg-[#05805C1F]"
                        : "hover:bg-[var(--BG-25)]"
                    }`}
                    onClick={() => handleBrandSelect(brand)}
                  >
                    <div className="w-full flex items-center cursor-pointer text-[var(--textBlack)] capitalize gap-2.5 ">
                      <div className="">
                        <img
                          src={plans[brand?.brand_billing_plan_name]}
                          alt={`${brand?.brand_billing_plan_name} icon`}
                        />
                      </div>
                      <div className="">
                        <h4 className="w-[7.5vw] font14px-500 text-[#4C515B] truncate">
                          {brand?.brand_name}
                        </h4>
                        <div className="font13px-400 text-[var(--font-400)] mt-[0.1vw]">
                          <span>{brand?.brand_billing_plan_name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="size-[1.5vw] flex-center px-[1vw] mt-[0.3vw]">
                      {/* {!isSelecting &&
                        brand?.brand_name === currentBrand?.brand_name && (
                          <RadioButton selected={true} />
                        )} */}
                      {isSelecting &&
                        selectedBrand?.brand_name === brand?.brand_name && (
                          <div className="size-[2vw] flex items-center justify-center">
                            <Loader Width={20} Height={20} />
                          </div>
                        )}
                    </div>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full h-full max-h-[28vh] overflow-y-scroll listScroll ">
            {accountList?.map((account) => {
              const colors = getRandomColorsBassedOnLetters(
                account?.account_name?.slice(0, 1)
              );
              return (
                <div
                  key={account?.id}
                  className="w-full py-[0.1vw] pl-10 pr-10"
                >
                  <Tooltip trigger={["hover"]} title={account?.account_name}>
                    <div
                      key={account?.id}
                      className={`w-full flex items-center rounded-[0.4vw] cursor-pointer  mt-5 p-10  ${
                        account?.account_name === currentAccount?.account_name
                          ? // ? "bg-[#05805C1F]"
                            ""
                          : "hover:bg-[var(--BG-25)]"
                      }`}
                      onClick={() => handleAccountSelect(account)}
                    >
                      <div className="w-full flex items-center cursor-pointer text-[var(--textBlack)] capitalize gap-2.5 ">
                        <div
                          className="px-2 py-[0.1vw] b-radius-6 font16px-500 flex items-center justify-center"
                          style={{
                            color: colors.color,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: colors.color,
                          }}
                        >
                          {account?.account_name?.slice(0, 1)}
                        </div>

                        <div className="">
                          <h4 className="w-[7.5vw] font14px-500 text-[#4C515B] truncate">
                            {account?.account_name}
                          </h4>
                          {/* <div className="font13px-400 text-[var(--font-400)] mt-[0.1vw]">
                          <span>{brand?.brand_billing_plan_name}</span>
                        </div> */}
                        </div>
                      </div>
                      <div className="size-[1.5vw] flex-center px-[1vw] mt-[0.3vw]">
                        {/* {!isSelecting &&
                        brand?.brand_name === currentBrand?.brand_name && (
                          <RadioButton selected={true} />
                        )} */}
                        {isSelecting &&
                          selectedBrand?.account_name ===
                            account?.account_name && (
                            <div className="size-[2vw] flex items-center justify-center">
                              <Loader Width={20} Height={20} />
                            </div>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        )}
        <div className=" font-xs weight-semibold  ptb-5 ml-10 mr-10">
          <button
            className={`w-100 py-2 flex-row align-center justify-center gap-3 ${
              location.pathname.startsWith("/profile") ? "button-primary" : ""
            }`}
            onClick={() => {
              handleNavigate();
              toggleDropdown();
            }}
          >
            {location.pathname.startsWith("/profile") &&
            (currentAccount?.role === "Account Owner" ||
              currentAccount?.role === "Account Manager") ? (
              "Create New Account"
            ) : (
              <>
                <img src={ICONSS?.switchAccount} alt="switchAccount" />
                <span>Switch Accounts</span>
              </>
            )}
            {/* <span className="w-full border-[0.01vw] border-[var(--font-400)]" /> */}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={`px-[0.7vw] py-[0.4vw]`}>
      <Popover
        content={!isloading ? <PopupContent /> : <></>}
        trigger={!isloading ? ["click"] : []}
        arrow={false}
        placement="bottomLeft"
        open={isDropdownOpen}
        onOpenChange={(open) => {
          setIsDropdownOpen(open);
        }}
        overlayClassName="pt-15"
      >
        <div
          className={`w-fit ${
            isloading ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => {
            if (!isloading) toggleDropdown();
          }}
        >
          {/* {location.pathname.startsWith("/user")!currentBrand ? (
            <div className="flex items-center gap-[0.5vw]">
              <p className="w-[7vw] h-[2.1vw] font-normal flex items-center">
                Select Brand
              </p>
              <img
                src={ICONS.dropDownIcon}
                alt="dropdown"
                className={`dropdown-icon ${isDropdownOpen ? "open" : ""}`}
              />
            </div>
          ) : ( */}
          <Tooltip title={currentBrand?.brand_name}>
            <div className="flex items-center gap-[0.5vw] text-[var(--textBlack)]">
              {!expand && location.pathname.startsWith("/user") ? (
                <div
                  className={`size-[2.1vw] flex-center rounded-[0.37vw] capitalize`}
                >
                  <img
                    src={plans[currentBrand?.brand_billing_plan_name]}
                    alt={`${currentBrand?.brand_billing_plan_name} icon`}
                  />
                </div>
              ) : (
                // <div className="px-2 border-2 border-[#6564CA] rounded-md text-[1.2rem] text-[#6564CA] font-[500] flex items-center justify-center uppercase">
                //   {currentAccount?.account_name?.slice(0, 1)}
                // </div>
                <div className="size-[2.1vw] flex-center rounded-[0.37vw] capitalize">
                  <img
                    src={plans1[currentAccount?.role]}
                    alt={`${currentAccount?.role} icon`}
                  />
                </div>
              )}
              {expand && (
                <div className="journey14px-500 max-w-[7vw] min-w-[6vw] pl-5 w-full truncate">
                  {location.pathname.startsWith("/user") && currentBrand ? (
                    <span>{currentBrand?.brand_name}</span>
                  ) : (
                    <div className="flex-column">
                      {currentAccount?.account_name}
                      <span className="italic font12px-400 text-[var(--font-600)]">
                        {currentAccount?.role}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {expand && (
                // <img
                //   src={ICONS.dropDownIcon}
                //   alt="dropdown"
                //   className={`dropdown-icon pl-20 ${
                //     isDropdownOpen ? "open" : ""
                //   }`}
                // />
                <div className="pl-20">
                  <ChevronDown color={"var(--font-600)"} />
                </div>
              )}
            </div>
          </Tooltip>
          {/* )} */}
          {/* <img
            src={ICONS.dropDownIcon}
            alt='dropdown'
            className={`dropdown-icon ${isDropdownOpen ? 'open' : ''}`}
          /> */}
        </div>
      </Popover>
      <Modal
        open={signupOpen}
        afterOpenChange={(open) => {
          setSignupOpen(open);
        }}
        centered
        footer={null}
        closable={false}
        wrapClassName={"bg-[#00000095]"}
        destroyOnClose={true}
        width={"42vw"}
      >
        <div className="p-10">
          <Header
            title={"Create Account"}
            handleCancel={() => {
              dispatch({ type: "reset" });
              setSignupOpen(false);
            }}
          />
          <div className="w-100">
            <OnBoarding />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HeaderDropDown;
