import React from 'react';

// assets
import Broadcast from 'assets/customSVG/sequence/Broadcast';
import Whatsapp from 'assets/customSVG/Whatsapp';

// helper
import { cn } from '../../../../../helper/cn';

const nodeTypes = {
  TEXT: 'Text Message',
  MEDIA: 'Media Message',
  BUTTON: 'Interactive Message',
  LIST: 'List Message',
  TEMPLATE: 'Template Message',
};

const PreviewNodeContainer = ({ children, node, bgColor = '#0ACD95' }) => {
  return (
    <div
      className='w-fit h-full rounded-2xl overflow-hidden select-none z-[1] pointer-events-auto bg-white shadow-[0px_3.09px_23.21px_0px_#2D30361A]'
      style={{ background: bgColor }}>
      <div
        className={cn(
          'w-full h-[2.5rem] flex items-center gap-[7px] p-3 text-white'
        )}
        style={{ background: bgColor }}>
        <div className='w-full h-5 flex items-center gap-1.5'>
          {node?.is_trigger ? (
            <Broadcast
              color={'#ffffff'}
              bg={bgColor}
              width={20}
              height={20}
              strokeWidth={'1.5'}
            />
          ) : (
            <Whatsapp
              color={'#ffffff'}
              width={18}
              height={18}
            />
          )}

          {node?.is_trigger ? (
            <p className='w-10/12 max-w-52 truncate !capitalize text-base weight-semibold'>
              {node?.data?.broadcast?.name || 'Broadcast'}
            </p>
          ) : (
            <p className='!capitalize text-base weight-semibold'>
              {nodeTypes[node?.type]}
            </p>
          )}
        </div>
      </div>
      <div className='p-1 pt-0 rounded-2xl'>{children}</div>
    </div>
  );
};

export default PreviewNodeContainer;
