import React from "react";

const Profile = ({ color ,fillColor}) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.99984 1.04175C5.05229 1.04175 1.0415 5.05253 1.0415 10.0001C1.0415 14.9477 5.05229 18.9584 9.99984 18.9584C14.9474 18.9584 18.9582 14.9477 18.9582 10.0001C18.9582 5.05253 14.9474 1.04175 9.99984 1.04175ZM9.99284 5.20841C8.49634 5.20841 7.28157 6.42014 7.28157 7.91675C7.28157 9.41333 8.49634 10.6251 9.99284 10.6251C11.4894 10.6251 12.7042 9.41333 12.7042 7.91675C12.7042 6.42014 11.4894 5.20841 9.99284 5.20841ZM14.2006 13.7336C11.9778 11.3336 7.97877 11.4573 5.80302 13.7297L5.64676 13.886C5.5257 14.0071 5.4596 14.1725 5.4639 14.3437C5.4682 14.5148 5.54252 14.6767 5.6695 14.7916C6.81458 15.827 8.33425 16.4584 9.99992 16.4584C11.6656 16.4584 13.1853 15.827 14.3303 14.7916C14.4573 14.6767 14.5317 14.5148 14.5359 14.3437C14.5403 14.1725 14.4742 14.0071 14.3531 13.886L14.2006 13.7336Z"
          fill={fillColor}
          stroke={color}
        />
      </svg>
    </div>
  );
};

export default Profile;
