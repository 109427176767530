import React, { useEffect, useState } from "react";

import "../../styles/whatsappHeader.css";

// assets
import { ICONS } from "../../../../assets/icons";

// utils
import { Modal, Skeleton, Tooltip } from "antd";

// hooks
import useToggle from "../../../../hooks/useToggle";

// redux
import { useDispatch } from "react-redux";
import { resetTemplateDetails } from "../../../../reduxToolkit/templateSlice";

// components
import CopyLink from "../../../../components/commonComponents/copyLink";
import Header from "../../../../components/commonComponents/modals/Header";
import { useAspSelector } from "../../../../test/jest-redux-hooks";

const ChannelHeader = ({ onClick, data, isLoading, syncing }) => {
  const [isModalVisible, setIsModalVisible] = useToggle(false);
  const { currentAccount } = useAspSelector((state) => state?.app);

  const dispatch = useDispatch();

  const handleModel = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="w-full bg-[var(--textWhite)] flex-row items-center space-between border-b-[0.08vw] border-[var(--font-50)] p-10">
      <div className="flex-row align-center gap-[0.5vw]">
        <img
          src={ICONS?.whatsApp}
          alt="whatsapp"
          className="w-[2.8vw] p-[0.15vw] rounded-full border-[0.1vw] border-[var(--borderPrimary)]"
        />
        <div className="flex-column pl-[0.5vw]">
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{}}
            loading={isLoading}
            className=""
          >
            <span className="font-larger weight-bold text-[var(--textBlack)] pb-[0.2vw] tracking-[0.02vw]">
              {data?.whatsapp_business_account_name}
            </span>
            <span className="font-md weight-small text-[var(--font-400)] tracking-[0.02vw]">
              WhatApp Account ID:{data?.whatsapp_business_account_id}
            </span>
          </Skeleton>
        </div>
      </div>
      <div className="flex-row align-center space-between gap-[0.8vw]">
        <Tooltip
          trigger="hover"
          title={
            currentAccount?.role !== "Campaigns Manager"
              ? "Sync account"
              : "You are not allowed to perform this action"
          }
          placement="top"
          className="!text-[0.2vw]"
        >
          {currentAccount?.role !== "Campaigns Manager" ? (
            <div
              className={`w-[2.3vw] p-[0.6vw] hover:bg-[var(--BG-25)] rounded-[0.6vw] ${
                syncing ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={() => {
                if (!syncing) onClick();
              }}
            >
              <img
                src={ICONS?.syncRefresh}
                alt="syncRefresh"
                className={`w-full h-full ${
                  syncing ? "animate-spin" : "animate-none"
                }`}
              />
            </div>
          ) : (
            <div className="w-[2.3vw] p-[0.6vw] hover:bg-[var(--BG-25)] rounded-[0.6vw] cursor-not-allowed">
              <img src={ICONS?.syncRefresh} alt="syncRefresh" />
            </div>
          )}
        </Tooltip>
        {/* <Tooltip trigger='hover' title='Configure' placement='topRight'>
          <div
            className='w-[2.3vw] cursor-pointer p-[0.6vw] hover:bg-[var(--BG-25)] rounded-[0.6vw]'
            onClick={handleModel}
          >
            <img
              src={ICONS?.configure}
              alt='configure'
              className='w-full h-full'
            />
          </div>
        </Tooltip> */}

        <Modal
          open={isModalVisible}
          footer={null}
          closable={false}
          centered={true}
          onCancel={handleCancel}
          width={"30vw"}
          maskClosable
          mask={true}
          wrapClassName={"bg-[#00000095]"}
          destroyOnClose
          style={{
            width: "100%",
            borderRadius: 20,
            boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
          }}
        >
          {/* <ModalHeader
            title='Connecting WhatsApp cloud API'
            handleCancel={handleCancel}
            titleStyle={{
              fontSize: '0.8vw',
            }}
          /> */}
          <Header
            title="Connecting WhatsApp cloud API"
            handleCancel={handleCancel}
          />
          <div className="flex-column gap-2 p-[1vw]">
            <div className="flex-column gap-[0.8vw]">
              <h1 className="font-md weight-medium text-[#898e99]">
                Add the following field in WhatsApp configuration page.
              </h1>
              <div className="flex flex-col gap-[0.3vw] my-[0.5vw]">
                <span className="font-md weight-medium text-[var(--font-400)] mb-[0.2vw] ">
                  Callback URL.
                </span>
                <CopyLink
                  text={"https://webhook.gainwix.app/api/meta_webhook/connect"}
                />
              </div>

              <div className="flex flex-col gap-[0.3vw]">
                <span className="font-md weight-medium text-[var(--font-400)] mb-[0.2vw]">
                  Verify token
                </span>
                <CopyLink text={"rf_fb_webhook"} />
              </div>

              <div className="w-full flex justify-end font-md pt-[1.7vw]">
                <button
                  className="bg-[var(--primary)] text-[var(--white)] w-20 h-7 rounded-md"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ChannelHeader;
