import React from 'react';

const EditIcon = ({
  width = 22,
  height = 22,
  color = '#616874',
  strokeWidth = '1.67',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5116 4.15199L14.6797 2.98386C15.3248 2.33871 16.3708 2.33871 17.016 2.98386C17.6611 3.629 17.6611 4.67498 17.016 5.32012L15.8478 6.48826M13.5116 4.15199L9.15 8.51358C8.27895 9.38467 7.84339 9.82016 7.54681 10.3509C7.25023 10.8817 6.95184 12.1349 6.6665 13.3333C7.86491 13.048 9.11817 12.7496 9.64892 12.453C10.1797 12.1564 10.6152 11.7209 11.4863 10.8498L15.8478 6.48826M13.5116 4.15199L15.8478 6.48826'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 10C17.5 13.5355 17.5 15.3033 16.4017 16.4017C15.3033 17.5 13.5355 17.5 10 17.5C6.46447 17.5 4.6967 17.5 3.59835 16.4017C2.5 15.3033 2.5 13.5355 2.5 10C2.5 6.46447 2.5 4.6967 3.59835 3.59835C4.6967 2.5 6.46447 2.5 10 2.5'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default EditIcon;
