export const INITIAL_STATE = {
  step: 0,
  account: {
    image: null,
    name: "",
    type: null,
    code: "IN",
    phone_number: "",
    otp: "",
    isOTP: false,
    totalMembers: null,
    knowAbout: null,
  },
  accountDetails: null,
  brandDetails: null,
  brand: {
    image: null,
    name: "",
    website: "",
    category: null,
    location: null,
    totalMembers: null,
    knowAbout: null,
  },
  teams: {
    list: [
      {
        id: 1,
        value: "",
      },
      {
        id: 2,
        value: "",
      },
      {
        id: 3,
        value: "",
      },
    ],
  },
};

export function onBoardingReducer(state, action) {
  const { type, primaryKey, key, value } = action;

  switch (type) {
    case "update":
      if (primaryKey)
        return {
          ...state,
          [primaryKey]: {
            ...state[primaryKey],
            [key]: value,
          },
        };
      else
        return {
          ...state,
          [key]: value,
        };

    case "update_step":
      return { ...state, [key]: value };
    case "change_number":
      return {
        ...state,
        step: 0,
        account: {
          ...state.account,
          otp: "",
        },
      };
    case "add_teams":
      return {
        ...state,
        teams: {
          list: [
            ...state.teams.list,
            {
              id: state.teams.list.length + 1,
              value: "",
            },
          ],
        },
      };
    case "update_teams":
      return {
        ...state,
        teams: {
          list: state?.teams?.list?.map((t) => {
            if (t.id === key) return { ...t, value: value };
            return t;
          }),
        },
      };
    case "reset_teams":
      return {
        ...state,
        teams: {
          list: state?.teams?.list?.map((t) => {
            return { ...t, value: "" };
          }),
        },
      };

    case "update_number":
      return {
        ...state,
        account: {
          ...state.account,
          phone_number: "",
          otp: "",
          isOTP: false,
        },
      };

    case "update_account":
      return {
        ...state,
        step: 1,
        accountDetails: value,
      };

    case "update_brand":
      return {
        ...state,
        step: 2,
        brandDetails: value,
      };

    case "skip":
      return { ...state, step: 3 };

    case "reset":
      return INITIAL_STATE;

    default:
      break;
  }
}
