import React, { useCallback, useState } from "react";

// assets
import AccountOverview from "../../../../assets/customSVG/accounts/AccountOverview";
import Education from "../../../../assets/customSVG/accounts/Education";
import Health from "../../../../assets/customSVG/accounts/Health";
import Other from "../../../../assets/customSVG/accounts/Other";
import Shopping from "../../../../assets/customSVG/accounts/Shopping";
import Travel from "../../../../assets/customSVG/accounts/Travel";
import { ICONS } from "../../../../assets/icons/index";
import { cn } from "../../../../helper/cn";

// API
import { CreateBrand } from "../../api/OnBoarding";

// hooks
import { useFileUploader } from "../../../../hooks/useFileUploader";

// helper
import { validateURL } from "../../../../helper/checkUrls";

// redux
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";

// components
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/commonComponents/Loader/Index";
import Select from "../../../../components/commonComponents/select";
import {
  UpdateOnboardingBrandId,
  updateToggleToast,
} from "../../../../reduxToolkit/appSlice";

export const category = [
  {
    id: 1,
    label: "Retail Industry",
    value: "Retail Industry",
    icon1: AccountOverview,
  },
  {
    id: 2,
    label: "E-Commerce",
    value: "E-Commerce",
    icon1: Shopping,
  },
  {
    id: 3,
    label: "Travel",
    value: "Travel",
    icon1: Travel,
  },
  {
    id: 4,
    label: "Education",
    value: "Education",
    icon1: Education,
  },
  {
    id: 5,
    label: "Health care",
    value: "Health care",
    icon1: Health,
  },
  {
    id: 6,
    label: "Others",
    value: "Other",
    icon1: Other,
  },

  // {
  //   id: 6,
  //   label: "Digital Agencies",
  //   value: "Digital Agencies",
  //   icon1: Digital,
  // },

  // {
  //   id: 6,
  //   label: "FMCG",
  //   value: "FMCG",
  //   icon1: Fmcg,
  // },
];

const categoryTypes = {
  "Health care": "Health care",
  "Retail Industry": "Retail Industry",
  "E-Commerce": "E-Commerce",
  "Digital Agencies": "Digital Agencies",
  Education: "Education",
  FMCG: "",
  Travel: "Travel",
  Other: "Other",
};

const countryList = [
  {
    label: "India",
    value: "+91",
    shortName: "IN",
  },
  // {
  //   label: 'United States',
  //   value: '+1',
  //   shortName: 'US',
  // },
  // {
  //   label: 'Japan',
  //   value: '+81',
  //   shortName: 'JP',
  // },
  // {
  //   label: 'Brazil',
  //   value: '+55',
  //   shortName: 'BR',
  // },
  // {
  //   label: 'Russia',
  //   value: '+7',
  //   shortName: 'RU',
  // },
  // {
  //   label: 'Canada',
  //   value: '+1',
  //   shortName: 'CA',
  // },
  // {
  //   label: 'Israel',
  //   value: '+972',
  //   shortName: 'IL',
  // },
];

export default function BrandCreation({
  data,
  onChange,
  isLoading,
  setIsLoading,
  className = "",
  setBrandPopover,
  setOtpError,
  otpError,
}) {
  const { acc_id, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();
  const navigate = useNavigate();

  const [country, setCountry] = useState(null);
  const [changed, setChanged] = useState({
    url: false,
  });

  const {
    uploading,
    selectedFile,
    responseFile,
    uploadFiles,
    error,
    clearFiles,
    dimensionError,
  } = useFileUploader();

  // styles
  const card = "w-full flex flex-col gap-2 mt-3";
  const label = "font14px-500 leading-6 text-[var(--font-600)]";
  const inputContainer =
    "w-full flex items-center gap-2 py-2 px-3 border border-[var(--border-input)] b-radius-6";
  const inputContainer1 = `w-full py-2 pl-10 flex items-center gap-2 border  b-radius-6 ${
    data?.name?.endsWith(".") ||
    data?.name?.endsWith("-") ||
    data?.name?.endsWith("'")
      ? "border-red-500"
      : "border-[var(--border-input)]"
  }`;
  const input = "w-full font14px-500";

  const validation = useCallback(() => {
    return (
      data?.name &&
      !data?.name?.endsWith(".") &&
      !data?.name?.endsWith("'") &&
      !data?.name?.endsWith("-") &&
      data?.website &&
      validateURL(data?.website) &&
      data?.category &&
      !error &&
      !dimensionError &&
      !otpError
    );
  }, [data, error, otpError, dimensionError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      setIsLoading(true);
      const datas = {
        account_id: acc_id,
        brand_name: data?.name,
        website_url: data?.website,
        brand_buss_vertical: categoryTypes[data?.category],
        country_alpha_code: data?.location,
      };
      if (data?.image) datas["brand_img_url"] = data?.image;
      const res = await CreateBrand(datas);

      if (res?.status === 200) {
        setBrandPopover(false);
        onChange("update_brand", null, null, res?.data?.brand);
        setIsLoading(false);
        dispatch(
          UpdateOnboardingBrandId({
            brand_id: res?.data?.brand?.id,
          }),
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Brand Created Successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        onChange("update_step", null, "step", 2);
        navigate("/profile/account/overview");
      } else {
        setIsLoading(false);
        setOtpError(res?.response?.data?.error);
      }
    }
  };

  return (
    <div className={cn("w-full flex flex-col items-center", className)}>
      <div
        className={cn(
          `w-full  flex items-center justify-between px-4 py-3 mt-4 bg-slate-50 rounded-[10px] border ${
            location.pathname.startsWith("/profile")
              ? "w-100 pl-20 pr-20"
              : "max-w-[470px]"
          }`,
          error || dimensionError
            ? "border-red-400 animate-shake"
            : "border-[var(--border-50)]"
        )}
      >
        <div className="flex-row align-center">
          <div className="rounded-[10px] size-12 bg-slate-100">
            {!responseFile && (
              <div className="p-[14px]">
                <img
                  src={ICONS.uploadStep}
                  alt="UploadStep"
                  className="upload-image-before"
                />
              </div>
            )}

            {selectedFile && (
              <div className="relative">
                <img
                  src={responseFile}
                  alt="SelectedImage"
                  className="uploadStep-image"
                />
                <img
                  className="absolute pointer -top-2 -right-2 size-4"
                  src={ICONS.Exit}
                  alt="popup"
                  onClick={() => {
                    clearFiles();
                    onChange("update", "brand", "image", null);
                  }}
                />
              </div>
            )}
          </div>
          <div className="upload-content">
            <p className="account-image">Brand Image</p>
            <p className="recommended-file">
              {selectedFile ? (
                <p className="truncate max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {selectedFile?.name}
                </p>
              ) : (
                <p>Recommended file 320*320 px, less than 1MB.</p>
              )}
            </p>
          </div>
        </div>

        <div>
          <button className="">
            <input
              type="file"
              // onChange={async (e) => {
              //   const res = await uploadFiles(e, "IMAGE", 1);
              //   if (res) {
              //     onChange("update", "brand", "image", res);
              //   }
              // }}
              onChange={async (e) => {
                const fileInput = e.target;
                if (fileInput.files.length > 0) {
                  const res = await uploadFiles(e, "IMAGE", 1);
                  if (res) {
                    onChange("update", "brand", "image", res);
                  }
                  fileInput.value = "";
                }
              }}
              accept=".jpg, .jpeg, .png"
              id="uploadImg"
              hidden
            />
            <label
              for="uploadImg"
              className="w-full flex-center cursor-pointer"
            >
              <div
                className={cn(
                  "min-w-[100px] text-sm weight-semibold text-[var(--background)] bg-[var(--new-primary)] flex items-center justify-center rounded-md px-4 py-1.5 tracking-wide"
                )}
              >
                {uploading ? (
                  <Loader Width={20} Height={20} loaderBg="white" />
                ) : (
                  <p>Upload</p>
                )}
              </div>
            </label>
          </button>
        </div>
      </div>
      {error && (
        <div className="max-w-[570px] w-full text-left text-xs mt-1 px-1 text-red-400">
          File Should be less than 1MB.
        </div>
      )}
      {dimensionError && (
        <div className="w-full text-left text-xs mt-1 px-1 text-red-400">
          Recommended file should be greater than 320*320 px
        </div>
      )}

      <form className="w-full max-w-[470px]" onSubmit={handleSubmit}>
        <div className={cn(card)}>
          <label htmlFor="name" className={cn(label)}>
            Brand Name<sup className="text-red-500">*</sup>
          </label>
          <div className={cn(inputContainer1)}>
            <input
              name="name"
              id="name"
              type="text"
              placeholder="Ex:- Nike"
              className={cn(input)}
              value={data?.name}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange("update", "brand", name, value);
              }}
              autoComplete="off"
              maxLength={64}
            />
            <span className="font14px-400 text-[var(--font-400)] pr-10">
              {data?.name?.length || 0}/64
            </span>
          </div>
        </div>
        <div className={cn(card)}>
          <label htmlFor="website" className={cn(label)}>
            Website<sup className="text-red-500">*</sup>
          </label>
          <div
            className={cn(
              inputContainer,
              changed?.url && !validateURL(data?.website) && "border-red-500"
            )}
          >
            <input
              name="website"
              id="website"
              type="text"
              placeholder="Enter a valid URL"
              className={cn(input)}
              value={data?.website}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange("update", "brand", name, value);
                if (!changed?.url)
                  setChanged((prev) => {
                    return { ...prev, url: true };
                  });
              }}
              autoComplete="off"
              maxLength={64}
            />
          </div>
        </div>

        <div className={cn(card)}>
          <label htmlFor="category" className={cn(label)}>
            Category<sup className="text-red-500">*</sup>
          </label>
          <Select
            value={data?.category}
            options={category}
            onChange={({ value }) => {
              if (value) onChange("update", "brand", "category", value);
            }}
            placeholder={"-- select category --"}
            rootClassName="w-full  px-3 py-2"
            position="top"
          />
        </div>
        <div className={cn(card)}>
          <label htmlFor="location" className={cn(label)}>
            Location
          </label>
          <Select
            value={country}
            options={countryList}
            onChange={(val) => {
              if (val) {
                onChange("update", "brand", "location", val?.shortName);
                setCountry(val);
              }
            }}
            placeholder={"Enter location"}
            rootClassName="w-full  px-3 py-2"
            position="top"
          />
        </div>
        <div
          className={`flex  mt-6 ${otpError ? "space-between" : "justify-end"}`}
        >
          {otpError && (
            <div className="flex-row align-center">
              <img src={ICONS?.alertIconError} alt="alertIconError" />
              <span className="error-message pl-5">{otpError}</span>
            </div>
          )}

          <button
            type="submit"
            className={cn(
              "px-4 py-1.5 b-radius-6 font16px-600 flex items-center justify-end",
              validation()
                ? isLoading
                  ? "cursor-not-allowed bg-[var(--new-primary)] text-[var(--white)]"
                  : "bg-[var(--new-primary)] text-[var(--white)] cursor-pointer"
                : "bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed"
            )}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Loader Width={100} Height={20} loaderBg="white" />
              </div>
            ) : (
              "Create Brand"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
