import { images } from 'assets/images';

import { ICONS } from '../../../assets/icons/index';

export const TYPES = [
  {
    id: 1,
    icon: '',
    label: 'Broadcast',
    key: 'broadcast',
    isSelected: false,
  },
  {
    id: 2,
    icon: '',
    label: 'Sequence',
    key: 'sequence',
    isSelected: false,
  },
];

export const STATUS = [
  {
    id: 1,
    icon: ICONS?.draft,
    label: 'Draft',
    key: 'draft',
    status: 0,
    isSelected: false,
    color: '#8B5CF6',
    background: 'var(--opacityBackground1)',
  },
  {
    id: 2,
    icon: ICONS?.scheduled,
    label: 'Scheduled',
    key: 'scheduled',
    status: 10,
    isSelected: false,
    background: 'var(--primaryOpacity)',
    border: 'var(--secondary)',
  },
  // {
  //   id: 3,
  //   icon: ICONS?.activeIcon,
  //   label: "Active",
  //   key: "active",
  //   status: 10,
  //   isSelected: false,
  //   background: "var(--primaryOpacity)",
  //   border: "var(--secondary)",
  // },
  // {
  //   id: 4,
  //   icon: '',
  //   label: 'In-active',
  //   key: 'in-active',
  //   status: 10,
  //   isSelected: false,
  // },
  {
    id: 5,
    icon: ICONS?.runningCampaign,
    label: 'Running',
    key: 'running',
    status: 11,
    isSelected: false,
    background: 'var(--opacityBackground3)',
    border: 'var(--primary)',
  },
  {
    id: 6,
    icon: ICONS?.completed,
    label: 'Completed',
    key: 'completed',
    status: 12,
    isSelected: false,
    background: 'var(--opacityBackground2)',
    border: 'var(--reviewStatus)',
  },
];

export const SORT = [
  {
    id: 1,
    icon: '',
    label: 'Newest first',
    key: 'newest',
    isSelected: false,
  },
  {
    id: 2,
    icon: '',
    label: 'Oldest first',
    key: 'oldest',
    isSelected: false,
  },
  {
    id: 3,
    icon: '',
    label: 'Ascending',
    key: 'ascending',
    isSelected: false,
  },
  {
    id: 4,
    icon: '',
    label: 'Descending',
    key: 'descending',
    isSelected: false,
  },
  {
    id: 5,
    icon: '',
    label: 'Starred',
    key: 'starred',
    isSelected: false,
  },
];

export const CREATION_TYPES = [
  {
    id: 1,
    label: 'Create New Broadcast',
    content:
      'Deliver targeted messages to multiple recipients at scheduled times.',
    key: 'broadcast',
    icon: ICONS?.broadcastCreate,
    disabled: false,
  },
  {
    id: 2,
    label: 'Create Seamless Flow Sequence',
    content:
      'Trigger automated sequences from customer actions to drive conversions.',
    key: 'sequence',
    icon: ICONS?.sequenceCreate,
    disabled: false,
  },
];

export const SEQUENCE_STYLES = {
  '-10': {
    icon: '',
    bg: '',
    color: '',
    text: 'Deleted',
  },
  0: {
    icon: ICONS?.draft,
    bg: '#EDEFF1',
    color: '#566F8F',
    text: 'Draft',
  },
  2: {
    icon: ICONS?.draft,
    bg: '#EDEFF1',
    color: '#566F8F',
    text: 'Draft',
  },
  7: {
    icon: ICONS?.BroadCastActive,
    bg: '#FFFBEB',
    color: '#F6A723',
    text: 'Scheduled',
  },
  10: {
    icon: ICONS?.BroadCastActive,
    bg: '#FFFBEB',
    color: '#F6A723',
    text: 'Running',
    // text: 'ACTIVE',
  },
  13: {
    icon: ICONS?.PausedOrange,
    bg: '#FFF4EB',
    color: '#FF7700',
    text: 'Paused',
  },
  15: {
    icon: ICONS?.CampaignRunningIcon,
    bg: '#F0FDF4',
    color: '#25C277',
    text: 'Running',
  },
  20: {
    icon: ICONS?.CampaignCompleteIcon,
    bg: '#EFF5FF',
    color: '#4C90F5',
    text: 'Completed',
  },
};
