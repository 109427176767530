import React, { useEffect, useState } from "react";
import "../../styles/profile.css";
import ProfileInfoCard from "../../../inbox/components/profile/ProfileInfoCard";
import { handleSingleColsAction } from "../../../../reduxToolkit/crmSlice";
import { useDispatch } from "react-redux";
import { Popover } from "antd";
import { ICONS } from "../../../../assets/icons";
import Edit from "../../../../assets/customSVG/Edit";
import Close from "../../../../assets/customSVG/Close";
import Input from "../commonComponents/input/Input";
import { Address } from "../commonComponents/location/Address";
import {
  cityListApi,
  countryListApi,
  crmClear,
  crmSingleContactEdit,
  stateListApi,
} from "../../api/Api";
import { convertName } from "../../helpers";
import { Province } from "../commonComponents/location/Province";
import { City } from "../commonComponents/location/City";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import { useParams } from "react-router-dom";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";

const AddressInfo = ({ data, onInfoChange }) => {
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const { id } = useParams();

  const [editStates, setEditStates] = useState({
    primaryEdit: false,
    secondaryEdit: false,
  });
  const [countryListss, setCountryListss] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [active, setActive] = useState(false);
  const [address, setAddress] = useState("");

  const [locations, setLocations] = useState({
    primary: {
      country: { name: "", id: null },
      state: { name: "", id: null },
      city: { name: "", id: null },
    },
    secondary: {
      country: { name: "", id: null },
      state: { name: "", id: null },
      city: { name: "", id: null },
    },
  });

  const [popoverVisible, setPopoverVisible] = useState({
    primaryCountry: false,
    primaryState: false,
    primaryCity: false,
    secondaryCountry: false,
    secondaryState: false,
    secondaryCity: false,
  });
  const [isDropdownOpenSelect, setIsDropdownOpenSelect] = useState(false);
  const [originalAddress, setOriginalAddress] = useState({
    primary: {
      primary_address_line_1: data?.address?.primary_address_line_1,
      primary_address_line_2: data?.address?.primary_address_line_2,
      primary_city: data?.address?.primary_city,
      primary_country: data?.address?.primary_country,
      primary_state: data?.address?.primary_state,
      primary_zip_code: data?.address?.primary_zip_code,
    },
    secondary: {
      secondary_address_line_1: data?.address?.secondary_address_line_1,
      secondary_address_line_2: data?.address?.secondary_address_line_2,
      secondary_city: data?.address?.secondary_city,
      secondary_country: data?.address?.secondary_country,
      secondary_state: data?.address?.secondary_state,
      secondary_zip_code: data?.address?.secondary_zip_code,
    },
  });

  useEffect(() => {
    setOriginalAddress({
      primary: {
        primary_address_line_1: data?.address?.primary_address_line_1,
        primary_address_line_2: data?.address?.primary_address_line_2,
        primary_city: data?.address?.primary_city,
        primary_country: data?.address?.primary_country,
        primary_state: data?.address?.primary_state,
        primary_zip_code: data?.address?.primary_zip_code,
      },
      secondary: {
        secondary_address_line_1: data?.address?.secondary_address_line_1,
        secondary_address_line_2: data?.address?.secondary_address_line_2,
        secondary_city: data?.address?.secondary_city,
        secondary_country: data?.address?.secondary_country,
        secondary_state: data?.address?.secondary_state,
        secondary_zip_code: data?.address?.secondary_zip_code,
      },
    });
  }, []);

  useEffect(() => {
    if (data?.address) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        ...data?.address,
      }));
    }
  }, [data]);

  const handleVisibleChange = (type, visible) => {
    setPopoverVisible((prev) => ({
      ...prev,
      [type]: visible,
    }));
  };

  const dispatch = useDispatch();

  const handlePrimaryEdit = () => {
    setEditStates((prevState) => ({
      ...prevState,
      primaryEdit: true,
    }));
  };

  const handleSecondaryEdit = () => {
    setEditStates((prevState) => ({
      ...prevState,
      secondaryEdit: true,
    }));
  };

  const [showSecondaryInputs, setShowSecondaryInputs] = useState(
    !!address?.secondary_location_id
  );

  const handleSecondary = () => {
    setShowSecondaryInputs(true);
  };

  const handleLocationClick = (type, name, id, locationType) => {
    setLocations((prev) => ({
      ...prev,
      [locationType]: {
        ...prev[locationType],
        [type]: { name, id },
      },
    }));

    const dispatchAction = (key, value) => {
      dispatch(
        handleSingleColsAction({
          key,
          value,
          field: "address",
        })
      );
    };

    if (type === "country") {
      dispatchAction("country", id);
    } else if (type === "state") {
      dispatchAction("state", id);
    } else if (type === "city") {
      dispatchAction("city", id);
    }

    // if (type === "country") {
    //   dispatchAction(`${locationType}_country`, id);
    // } else if (type === "state") {
    //   dispatchAction(`${locationType}_state`, id);
    // } else if (type === "city") {
    //   dispatchAction(`${locationType}_city`, id);
    // }

    //  setPopoverVisible((prev) => ({
    //    ...prev,
    //    [`${locationType}${type.charAt(0).toUpperCase() + type.slice(1)}`]: false,
    //  }));
    setPopoverVisible(false);
  };

  //primary country id
  const primaryCountry = countryListss.find(
    (country) => country.name === data?.address?.primary_country
  );
  const primaryCountryId = primaryCountry ? primaryCountry.id : "";

  //primary state id
  const primaryState = stateList.find(
    (state) => state.name === data?.address?.primary_state
  );
  const primaryStateId = primaryState ? primaryState.id : "";

  //primary city id
  const primaryCity = cityList.find(
    (city) => city.name === data?.address?.primary_city
  );
  const primaryCityId = primaryCity ? primaryCity.id : "";

  //secondary country id
  const secondaryCountry = countryListss.find(
    (country) => country.name === data?.address?.secondary_country
  );
  const secondaryCountryId = secondaryCountry ? secondaryCountry.id : "";

  //secondary state id
  const secondaryState = stateList.find(
    (state) => state.name === data?.address?.secondary_state
  );
  const secondaryStateId = secondaryState ? secondaryState.id : "";

  //secondary city id
  const secondaryCity = cityList.find(
    (city) => city.name === data?.address?.secondary_city
  );
  const secondaryCityId = secondaryCity ? secondaryCity.id : "";

  useEffect(() => {
    // countryLists("primary");
    if (editStates?.primaryEdit && !showSecondaryInputs) {
      countryLists("primary");
    }
    if (editStates?.secondaryEdit || showSecondaryInputs) {
      countryLists("secondary");
    }

    if (
      (locations.primary.country.id
        ? locations.primary.country.id
        : primaryCountryId) &&
      !showSecondaryInputs
    ) {
      stateLists(
        locations.primary.country.id
          ? locations.primary.country.id
          : primaryCountryId,
        "primary"
      );
    }

    if (
      (locations.primary.state.id
        ? locations.primary.state.id
        : primaryStateId) &&
      !showSecondaryInputs
    ) {
      cityLists(
        locations.primary.state.id
          ? locations.primary.state.id
          : primaryStateId,
        "primary"
      );
    }

    if (
      locations.secondary.country.id
        ? locations.secondary.country.id
        : secondaryCountryId
    ) {
      stateLists(
        locations.secondary.country.id
          ? locations.secondary.country.id
          : secondaryCountryId,
        "secondary"
      );
    }

    if (
      locations.secondary.state.id
        ? locations.secondary.state.id
        : secondaryStateId
    ) {
      cityLists(
        locations.secondary.state.id
          ? locations.secondary.state.id
          : secondaryStateId,
        "secondary"
      );
    }
  }, [
    locations.primary.country.id,
    locations.primary.state.id,
    locations.secondary.country.id,
    locations.secondary.state.id,
    primaryCountryId,
    primaryStateId,
    secondaryCountryId,
    secondaryStateId,
    editStates?.primaryEdit,
    editStates?.secondaryEdit,
    showSecondaryInputs,
  ]);

  const countryLists = async (type) => {
    const res = await countryListApi();
    if (res?.data) {
      if (type === "primary") {
        setCountryListss(res?.data?.data);
      } else if (type === "secondary") {
        setCountryListss(res?.data?.data);
      }
    }
  };

  const stateLists = async (countryId, type) => {
    const res = await stateListApi(countryId);
    if (res?.data) {
      if (type === "primary") {
        setStateList(res?.data?.data);
      } else if (type === "secondary") {
        setStateList(res?.data?.data);
      }
    }
  };

  const cityLists = async (stateId, type) => {
    const res = await cityListApi(stateId);
    if (res?.data) {
      if (type === "primary") {
        setCityList(res?.data?.data);
      } else if (type === "secondary") {
        setCityList(res?.data?.data);
      }
    }
  };

  const handleDropdownClick = (key, type) => {
    setIsDropdownOpenSelect((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
    setActive(true);
    setPopoverVisible((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handlePrimaryCancel = () => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      primary_address_line_1: originalAddress.primary.primary_address_line_1,
      primary_address_line_2: originalAddress.primary.primary_address_line_2,
      primary_city: originalAddress.primary.primary_city,
      primary_country: originalAddress.primary.primary_country,
      primary_state: originalAddress.primary.primary_state,
      primary_zip_code: originalAddress.primary.primary_zip_code,
    }));
  };

  const handleSecondaryCancel = () => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      secondary_address_line_1:
        originalAddress.secondary.secondary_address_line_1,
      secondary_address_line_2:
        originalAddress.secondary.secondary_address_line_2,
      secondary_city: originalAddress.secondary.secondary_city,
      secondary_country: originalAddress.secondary.secondary_country,
      secondary_state: originalAddress.secondary.secondary_state,
      secondary_zip_code: originalAddress.secondary.secondary_zip_code,
    }));
  };

  const handlePrimarySave = () => {
    const brandId = currentBrand?.brand_id;
    const payLoad = {
      brand_id: brandId,
      address: {
        address_line_1: data?.address?.primary_address_line_1,
        address_line_2: data?.address?.primary_address_line_2,
        country_id: locations.primary.country.id || primaryCountryId,
        province_id: locations.primary.state.id || primaryStateId,
        city_id: locations.primary.city.id || primaryCityId,
        postal_code: data?.address?.primary_zip_code,
        contact_person_location_id:
          data?.address?.primary_contact_person_location_id,
        location_id: data?.address?.primary_location_id,
        priority: 1,
      },
    };

    crmSingleContactEdit(payLoad, id).then((res) => {
      if (res && res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.data?.message,
              status: "Success",
              duration: 3000,
            },
          ])
        );
        window.location.reload();
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: 3000,
            },
          ])
        );
      }
    });
  };

  const handleSecondarySave = () => {
    const brandId = currentBrand?.brand_id;
    const payLoad = {
      brand_id: brandId,
      address: {
        address_line_1: data?.address?.secondary_address_line_1,
        address_line_2: data?.address?.secondary_address_line_2,
        country_id: locations.secondary.country.id || secondaryCountryId,
        province_id: locations.secondary.state.id || secondaryStateId,
        city_id: locations.secondary.city.id || secondaryCityId,
        postal_code: data?.address?.secondary_zip_code,
        contact_person_location_id:
          data?.address?.secondary_contact_person_location_id,
        location_id: data?.address?.secondary_location_id,
        priority: 2,
      },
    };

    crmSingleContactEdit(payLoad, id).then((res) => {
      if (res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Address saved successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        window.location.reload();
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  const handleClear = () => {
    const brandId = currentBrand?.brand_id;
    const contactPersonId = id;
    const locationId = data?.address?.secondary_location_id;

    crmClear(contactPersonId, locationId, brandId).then((res) => {
      if (res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.data?.status,
              status: "Success",
              duration: "",
            },
          ])
        );
        window.location.reload();
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  const areAllFieldsFilled =
    address?.primary_address_line_1 &&
    address?.primary_address_line_2 &&
    (locations.primary.country.name || address?.primary_country) &&
    (locations.primary?.state?.name || address?.primary_state) &&
    (locations.primary?.city?.name || address?.primary_city) &&
    address?.primary_zip_code?.length === 6;

  const addSecondaryButton =
    address?.primary_address_line_1 &&
    address?.primary_address_line_2 &&
    (locations.primary.country.name || address?.primary_country) &&
    (locations.primary?.state?.name || address?.primary_state) &&
    (locations.primary?.city?.name || address?.primary_city) &&
    address?.primary_zip_code?.length === 6 &&
    address?.primary_location_id;

  const secondaryField =
    address?.secondary_address_line_1 &&
    address?.secondary_address_line_2 &&
    (locations.secondary.country.name || address?.secondary_country) &&
    (locations.secondary?.state?.name || address?.secondary_state) &&
    (locations.secondary?.city?.name || address?.secondary_city) &&
    address?.secondary_zip_code?.length === 6;

  return (
    <div className="flex-column">
      <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw] bg-[var(--channelButton)] b-radius-6 pl-10 pt-5 pb-5 m-[20px]">
        Address Info
      </h3>
      <div className="border-b mx-[30px]">
        <div className="flex align-center">
          <h2 className="text-[1vw] font-[500] pr-3">Primary Address</h2>
          <div
            className="flex align-center bg-[var(--primary)] text-white font-[500] cursor-pointer b-radius-6 text-[0.85vw] px-3 py-1"
            onClick={handlePrimaryEdit}
          >
            <Edit
              width="0.85vw"
              height="0.85vw"
              color={"white"}
              strokeWidth={"1.9"}
              className="stroke-[#898E99] group-hover/eye:stroke-[var(--contentText)]"
            />
            <p className="pl-2">Edit</p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-5 mt-10  mb-[25px]">
          <div className="pt-3">
            <label className="font-large font-md weight-medium text-[var(--contentText)] ">
              Address Line1 <span style={{ color: "red" }}>*</span>
            </label>
            {editStates?.primaryEdit ? (
              <Input
                type="text"
                value={address?.primary_address_line_1}
                onChange={(e) => {
                  const value = e.target.value;
                  onInfoChange({
                    type: "update_info",
                    key: "primary_address_line_1",
                    value: value,
                  });
                }}
                className="!py-[2px] mt-0.5 !border-[var(--border-50)] !text-[0.9vw] !text-[var(--font-600)] !font-[400]"
                id="Address-change"
              />
            ) : (
              <p className="mt-5 text-[0.9vw] text-[var(--font-600)] font-[400]">
                {address?.primary_address_line_1 || "--"}
              </p>
            )}
          </div>

          <div className="pt-3">
            <label className="font-large font-md weight-medium text-[var(--contentText)] ">
              Address Line2
            </label>
            {editStates?.primaryEdit ? (
              <Input
                type="text"
                value={address?.primary_address_line_2}
                onChange={(e) => {
                  const value = e.target.value;
                  onInfoChange({
                    type: "update_info",
                    key: "primary_address_line_2",
                    value: value,
                  });
                }}
                className="!py-[2px] mt-0.5 !border-[var(--border-50)] !text-[0.9vw] !text-[var(--font-600)] !font-[400]"
              />
            ) : (
              <p className="mt-5 text-[0.9vw] text-[var(--font-600)] font-[400] ">
                {address?.primary_address_line_2 || "--"}
              </p>
            )}
          </div>

          <div className="pt-3">
            <label className="font-large font-md weight-medium text-[var(--contentText)] ">
              Country
            </label>
            {editStates?.primaryEdit ? (
              <Popover
                content={
                  <Address
                    countryListss={countryListss}
                    selectedLocation={locations.primary}
                    locationType="primary"
                    handleLocationClick={(type, name, id, locationType) =>
                      handleLocationClick(type, name, id, locationType)
                    }
                  />
                }
                trigger="click"
                arrow={false}
                placement="bottom"
                open={popoverVisible?.primaryCountry}
                onOpenChange={(visible) =>
                  handleVisibleChange("primaryCountry", visible)
                }
              >
                <div className="pointer">
                  <span className="text-[.7vw] font-[500] capitalize">
                    {convertName}
                  </span>
                  <div
                    className={`flex justify-between items-center  border text-[var(--border-50)] text-sm rounded-lg w-full px-[.8vw] py-[.4vw] mt-[.3vw]`}
                    onClick={() => handleDropdownClick("primaryCountry")}
                  >
                    <p className="!text-[.9vw] !font-[400] !text-[var(--font-600)]">
                      {locations.primary.country.name ||
                        address?.primary_country ||
                        "Select Country"}
                    </p>

                    <div
                      className={`duration-300 ${
                        isDropdownOpenSelect && popoverVisible?.primaryCountry
                          ? "-rotate-180"
                          : "rotate-0"
                      }`}
                    >
                      <img
                        src={ICONS?.modelDropdownIcon}
                        alt="modelDropdownIcon"
                        className="pointer"
                      />
                    </div>
                  </div>
                </div>
              </Popover>
            ) : (
              <p className="mt-5 text-[0.9vw] text-[var(--font-600)] font-[400]">
                {address?.primary_country || "--"}
              </p>
            )}
          </div>

          <div className="pt-3">
            <label className="font-large font-md weight-medium text-[var(--contentText)] ">
              State
            </label>
            {editStates?.primaryEdit ? (
              <Popover
                content={
                  <Province
                    stateList={stateList}
                    selectedLocation={locations.primary}
                    locationType="primary"
                    handleLocationClick={(type, name, id, locationType) =>
                      handleLocationClick(type, name, id, locationType)
                    }
                  />
                }
                trigger="click"
                arrow={false}
                placement="bottom"
                open={popoverVisible?.primaryState}
                onOpenChange={(visible) =>
                  handleVisibleChange("primaryState", visible)
                }
              >
                <div className="pointer">
                  <span className="text-[.7vw] font-[500] capitalize">
                    {convertName}
                  </span>
                  <div
                    className={`flex justify-between items-center  border border-[var(--border-50)] text-[var(--border-50)] text-sm rounded-lg w-full px-[.8vw] py-[.4vw] mt-[.3vw]`}
                    onClick={() => handleDropdownClick("primaryState")}
                  >
                    <p className="!text-[.9vw] !font-[400] !text-[var(--font-600)]">
                      {locations.primary.state.name ||
                        address?.primary_state ||
                        "Select State"}
                    </p>

                    <div
                      className={`duration-300 ${
                        isDropdownOpenSelect && popoverVisible?.primaryState
                          ? "-rotate-180"
                          : "rotate-0"
                      }`}
                    >
                      <img
                        src={ICONS?.modelDropdownIcon}
                        alt="modelDropdownIcon"
                        className="pointer"
                      />
                    </div>
                  </div>
                </div>
              </Popover>
            ) : (
              <p className="mt-5 !text-[0.9vw] !text-[var(--font-600)] !font-[400]">
                {address?.primary_state || "--"}
              </p>
            )}
          </div>

          <div className="pt-3">
            <label className="font-large font-md weight-medium text-[var(--contentText)] ">
              City
            </label>
            {editStates?.primaryEdit ? (
              <Popover
                content={
                  <City
                    cityList={cityList}
                    selectedLocation={locations.primary}
                    locationType="primary"
                    handleLocationClick={(type, name, id, locationType) =>
                      handleLocationClick(type, name, id, locationType)
                    }
                  />
                }
                trigger="click"
                arrow={false}
                placement="bottom"
                open={popoverVisible.primaryCity}
                onOpenChange={(visible) =>
                  handleVisibleChange("primaryCity", visible)
                }
              >
                <div className="pointer">
                  <span className="text-[.7vw] font-[500] capitalize">
                    {convertName}
                  </span>
                  <div
                    className={`flex justify-between items-center border border-[var(--border-50)] text-[var(--border-50)] text-sm rounded-lg w-full px-[.8vw] py-[.4vw] mt-[.3vw]`}
                    onClick={() => handleDropdownClick("primaryCity")}
                  >
                    <p className="!text-[.9vw] !font-[400] !text-[var(--font-600)]">
                      {locations.primary.city.name ||
                        address?.primary_city ||
                        "Select City"}
                    </p>

                    <div
                      className={`duration-300 ${
                        isDropdownOpenSelect && popoverVisible.primaryCity
                          ? "-rotate-180"
                          : "rotate-0"
                      }`}
                    >
                      <img
                        src={ICONS?.modelDropdownIcon}
                        alt="modelDropdownIcon"
                        className="pointer"
                      />
                    </div>
                  </div>
                </div>
              </Popover>
            ) : (
              <p className="mt-5 !text-[0.9vw] !text-[var(--font-600)] !font-[400] ">
                {address?.primary_city || "--"}
              </p>
            )}
          </div>

          <div className="pt-3">
            <label className="font-large font-md weight-medium text-[var(--contentText)] ">
              Zip Code / Postal Code
            </label>
            {editStates?.primaryEdit ? (
              <Input
                type="number"
                value={address?.primary_zip_code || "--"}
                onChange={(e) => {
                  const value = e.target.value;
                  onInfoChange({
                    type: "update_info",
                    key: "primary_zip_code",
                    value: value,
                  });
                }}
                className="!py-[2px] mt-0.5 !border-[var(--border-50)] !text-[0.9vw] !text-[var(--font-600)] !font-[400]"
              />
            ) : (
              <p className="mt-5 !text-[0.9vw] !text-[var(--font-600)] !font-[400] ">
                {address?.primary_zip_code || "--"}
              </p>
            )}
          </div>
        </div>

        {editStates?.primaryEdit && (
          <div className="flex text-[0.75vw] justify-end mb-[25px]">
            {/* <button
              className="flex align-center bg-[var(--channelButton)] text-[#616874] font-[500] cursor-pointer b-radius-6 ml-4 px-2 py-1"
              onClick={handlePrimaryCancel}
            >
              Cancel
            </button> */}
            <button
              className={`flex align-center font-[500] b-radius-6 ml-2 px-2 py-1 ${
                areAllFieldsFilled
                  ? "bg-[var(--primary)] text-white cursor-pointer"
                  : "!text-[#898e99] bg-[var(--lightGrey)] cursor-not-allowed"
              }`}
              onClick={handlePrimarySave}
              disabled={!areAllFieldsFilled}
            >
              Save
            </button>
          </div>
        )}
      </div>

      <div className="mt-6">
        {address?.secondary_location_id || showSecondaryInputs ? (
          <div className="border-b mx-[30px]">
            <div className="flex align-center">
              <h2 className="text-[1vw] font-[500] pr-3">Secondary Address</h2>

              {address?.secondary_location_id && (
                <div
                  className="flex align-center bg-[var(--primary)] text-white font-[500] cursor-pointer b-radius-6 text-[0.85vw] px-3 py-1"
                  onClick={handleSecondaryEdit}
                >
                  <Edit
                    width="0.85vw"
                    height="0.85vw"
                    color={"white"}
                    strokeWidth={"1.9"}
                    className="stroke-[#898E99] group-hover/eye:stroke-[var(--contentText)]"
                  />
                  <p className="pl-2">Edit</p>
                </div>
              )}
            </div>

            <div className="grid grid-cols-3 gap-5 mt-10  mb-[35px]">
              <div className="pt-3">
                <label className="font-large font-md weight-medium text-[var(--contentText)] ">
                  Address Line1 <span style={{ color: "red" }}>*</span>
                </label>
                {editStates?.secondaryEdit || showSecondaryInputs ? (
                  <Input
                    type="text"
                    value={address?.secondary_address_line_1}
                    onChange={(e) => {
                      const value = e.target.value;
                      onInfoChange({
                        type: "update_info",
                        key: "secondary_address_line_1",
                        value: value,
                      });
                    }}
                    className="!py-[2px] mt-0.5 !border-[var(--border-50)] !text-[.9vw] !font-[400] !text-[var(--font-600)]"
                  />
                ) : (
                  <p className="mt-5 !text-[.9vw] !font-[400] !text-[var(--font-600)]">
                    {address?.secondary_address_line_1 || "--"}
                  </p>
                )}
              </div>

              <div className="pt-3">
                <label className="font-large font-md weight-medium text-[var(--contentText)] ">
                  Address Line2
                </label>
                {editStates?.secondaryEdit || showSecondaryInputs ? (
                  <Input
                    type="text"
                    value={address?.secondary_address_line_2}
                    onChange={(e) => {
                      const value = e.target.value;
                      onInfoChange({
                        type: "update_info",
                        key: "secondary_address_line_2",
                        value: value,
                      });
                    }}
                    className="!py-[2px] mt-0.5 !border-[var(--border-50)] !text-[.9vw] !font-[400] !text-[var(--font-600)]"
                  />
                ) : (
                  <p className="mt-5 !text-[.9vw] !font-[400] !text-[var(--font-600)]">
                    {address?.secondary_address_line_2 || "--"}
                  </p>
                )}
              </div>

              <div className="pt-3">
                <label className="font-large font-md weight-medium text-[var(--contentText)] ">
                  Country
                </label>
                {editStates?.secondaryEdit || showSecondaryInputs ? (
                  <Popover
                    content={
                      <Address
                        countryListss={countryListss}
                        selectedLocation={locations.secondary}
                        locationType="secondary"
                        handleLocationClick={(type, name, id, locationType) =>
                          handleLocationClick(type, name, id, locationType)
                        }
                      />
                    }
                    trigger="click"
                    arrow={false}
                    placement="bottom"
                    open={popoverVisible.secondaryCountry}
                    onOpenChange={(visible) =>
                      handleVisibleChange("secondaryCountry", visible)
                    }
                  >
                    <div className="pointer">
                      <span className="text-[.7vw] font-[500] capitalize">
                        {convertName}
                      </span>
                      <div
                        className={`flex justify-between items-center bg-gray-50- border text-[var(--border-50)] text-sm rounded-lg w-full px-[.8vw] py-[.3vw] mt-[.2vw]`}
                        onClick={() => handleDropdownClick("secondaryCountry")}
                      >
                        <p className="!text-[.9vw] !font-[400] !text-[var(--font-600)]">
                          {locations.secondary.country.name ||
                            address?.secondary_country ||
                            "Select Country"}
                        </p>

                        {/* <div
                          className={`duration-300 ${
                            isDropdownOpenSelect ? "-rotate-180" : "rotate-0"
                          }`}
                        > */}
                        <div
                          className={`duration-300 ${
                            isDropdownOpenSelect &&
                            popoverVisible.secondaryCountry
                              ? "-rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <img
                            src={ICONS?.modelDropdownIcon}
                            alt="modelDropdownIcon"
                            className="pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </Popover>
                ) : (
                  <p className="mt-5 text-[0.9vw] text-[var(--font-600)] font-[400]">
                    {address?.secondary_country || "--"}
                  </p>
                )}
              </div>

              <div className="pt-3">
                <label className="font-large font-md weight-medium text-[var(--contentText)] ">
                  State
                </label>
                {editStates?.secondaryEdit || showSecondaryInputs ? (
                  <Popover
                    content={
                      <Province
                        stateList={stateList}
                        selectedLocation={locations.secondary}
                        locationType="secondary"
                        handleLocationClick={(type, name, id, locationType) =>
                          handleLocationClick(type, name, id, locationType)
                        }
                      />
                    }
                    trigger="click"
                    arrow={false}
                    placement="bottom"
                    open={popoverVisible.secondaryState}
                    onOpenChange={(visible) =>
                      handleVisibleChange("secondaryState", visible)
                    }
                  >
                    <div className="pointer">
                      <span className="text-[.7vw] font-[500] capitalize">
                        {convertName}
                      </span>
                      <div
                        className={`flex justify-between items-center bg-gray-50- border text-[var(--border-50)] text-sm rounded-lg w-full px-[.8vw] py-[.3vw] mt-[.2vw]`}
                        onClick={() => handleDropdownClick("secondaryState")}
                      >
                        <p className="!text-[.9vw] !font-[400] !text-[var(--font-600)]">
                          {locations.secondary.state.name ||
                            address?.secondary_state ||
                            "Select State"}
                        </p>

                        <div
                          className={`duration-300 ${
                            isDropdownOpenSelect &&
                            popoverVisible.secondaryState
                              ? "-rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <img
                            src={ICONS?.modelDropdownIcon}
                            alt="modelDropdownIcon"
                            className="pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </Popover>
                ) : (
                  <p className="mt-5 !text-[0.9vw] !text-[var(--font-600)] !font-[400]">
                    {address?.secondary_state || "--"}
                  </p>
                )}
              </div>

              <div className="pt-3">
                <label className="font-large font-md weight-medium text-[var(--contentText)] ">
                  City
                </label>
                {editStates?.secondaryEdit || showSecondaryInputs ? (
                  <Popover
                    content={
                      <City
                        cityList={cityList}
                        locationType="secondary"
                        selectedLocation={locations.secondary}
                        handleLocationClick={(type, name, id, locationType) =>
                          handleLocationClick(type, name, id, locationType)
                        }
                      />
                    }
                    trigger="click"
                    arrow={false}
                    placement="bottom"
                    open={popoverVisible?.secondaryCity}
                    onOpenChange={(visible) =>
                      handleVisibleChange("secondaryCity", visible)
                    }
                  >
                    <div className="pointer">
                      <span className="text-[.7vw] font-[500] capitalize">
                        {convertName}
                      </span>
                      <div
                        className={`flex justify-between items-center bg-gray-50- border text-[var(--border-50)] text-sm rounded-lg w-full px-[.8vw] py-[.3vw] mt-[.2vw]`}
                        onClick={() => handleDropdownClick("secondaryCity")}
                      >
                        <p className="!text-[.9vw] !font-[400] !text-[var(--font-600)]">
                          {locations.secondary.city.name ||
                            address?.secondary_city ||
                            "Select City"}
                        </p>

                        <div
                          className={`duration-300 ${
                            isDropdownOpenSelect && popoverVisible.secondaryCity
                              ? "-rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <img
                            src={ICONS?.modelDropdownIcon}
                            alt="modelDropdownIcon"
                            className="pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </Popover>
                ) : (
                  <p className="mt-5 !text-[0.9vw] !text-[var(--font-600)] !font-[400] ">
                    {address?.secondary_city || "--"}
                  </p>
                )}
              </div>

              <div className="pt-3">
                <label className="font-large font-md weight-medium text-[var(--contentText)] ">
                  Zip Code / Postal Code
                </label>
                {editStates?.secondaryEdit || showSecondaryInputs ? (
                  <Input
                    type="number"
                    value={address?.secondary_zip_code || "--"}
                    onChange={(e) => {
                      const value = e.target.value;
                      onInfoChange({
                        type: "update_info",
                        key: "secondary_zip_code",
                        value: value,
                      });
                    }}
                    className="!py-[2px] mt-0.5 !border-[var(--border-50)] !text-[.9vw] !font-[400] !text-[var(--font-600)]"
                  />
                ) : (
                  <p className="mt-5 !text-[.9vw] !font-[400] !text-[var(--font-600)]">
                    {address?.secondary_zip_code || "--"}
                  </p>
                )}
              </div>
            </div>

            <div className="flex-row w-100 justify-between">
              {(editStates?.secondaryEdit || showSecondaryInputs) && (
                <div className="flex mb-[25px] justify-between w-full">
                  {address?.secondary_location_id && (
                    <button
                      className="flex-row align-center"
                      onClick={handleClear}
                    >
                      <Close
                        stroke="#616874"
                        circle={false}
                        width="1.5vw"
                        height="1.5vw"
                      />
                      <p className="text-[0.75vw] text-[#616874]">
                        Clear Address
                      </p>
                    </button>
                  )}

                  <div className="flex text-[0.75vw] justify-end ml-auto">
                    {/* <button
                      className="flex align-center bg-[var(--channelButton)] text-[#616874] font-[500] cursor-pointer b-radius-6 ml-4 px-2 py-1"
                      onClick={handleSecondaryCancel}
                    >
                      Cancel
                    </button> */}
                    <button
                      className={`flex align-center font-[500] b-radius-6 ml-2 px-2 py-1 ${
                        secondaryField
                          ? "bg-[var(--primary)] text-white cursor-pointer"
                          : "!text-[#898e99] bg-[var(--lightGrey)] cursor-not-allowed"
                      }`}
                      disabled={!secondaryField}
                      onClick={handleSecondarySave}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <button
            className={`w-[12vw] flex align-center justify-center font-[500] b-radius-6 text-[0.85vw] py-1.5 mx-[30px] ${
              addSecondaryButton
                ? "bg-[var(--primary)] text-white cursor-pointer"
                : "!text-[#898e99] bg-[var(--lightGrey)] cursor-not-allowed"
            }`}
            onClick={handleSecondary}
            disabled={!addSecondaryButton}
          >
            Add Secondary Address
          </button>
        )}
      </div>

      <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw] bg-[var(--channelButton)] b-radius-6 pl-10 pt-5 pb-5 m-[20px]">
        Social Profiles
      </h3>
      <div className="grid grid-cols-3 gap-5 mx-[30px] mt-10">
        {data?.social_profile?.map((s) => {
          return (
            <ProfileInfoCard
              label={s?.label}
              labelClassName="font-semibold font-large"
              inputContainerClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
              value={s?.url ? s?.url : ""}
              link={true}
              onInputChange={(value) => {
                onInfoChange({
                  type: "update_social_profile",
                  id: s?.id,
                  // key: "facebook",
                  value: { ...s, url: value },
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddressInfo;
