import React from 'react';
import { addLineBreake, replaceFormating } from '../../helper';
import { cn } from '../../../../helper/cn';

export default function AudioMessagePreview({
  url,
  caption,
  urls,
  content,
  dateTime,
  rootClassName,
  className,
  audioClassName,
}) {
  return (
    <div
      className={cn(
        'flex flex-col relative w-auto max-w-[26vw] min-h-[3.5vh] bg-[white] rounded-[0.6vw] rounded-tr-none p-[0.8vw]',
        rootClassName
      )}
      style={{ boxShadow: '0px 4px 16px 0px #0000000A' }}>
      {content && (
        <div className='w-full flex-row align-center bg-[var(--channelButton)] inbox12px-400 text-[var(--font-600)] b-radius-6 mb-10'>
          <div className=' h-[7vh] bg-[#6564CA] w-1' />
          <div className='flex flex-1 items-center justify-between'>
            <div className='truncate max-w-[25ch] ml-10'>{content}</div>
            {urls?.[0] && (
              <audio
                className={cn(
                  'w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10',
                  audioClassName
                )}>
                <source
                  src={urls?.[0]}
                  type='audio/mp3'
                />
              </audio>
            )}
          </div>
        </div>
      )}
      <div className={cn('w-[20vw] h-full', className)}>
        <audio
          className={cn('object-cover rounded-[0.4vw]', audioClassName)}
          controls>
          <source
            src={url}
            type='audio/mp3'
          />{' '}
          Your browser does not support the audio element.
        </audio>
      </div>
      {caption && (
        <p
          className='font-md font-light px-[0.5vw] pt-[0.5vw] break-words'
          dangerouslySetInnerHTML={{
            __html: addLineBreake(replaceFormating(caption)),
          }}></p>
      )}
    </div>
  );
}
