import React from 'react';

const DropDown = ({ width = 24, height = 24, color }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6 10L12 16L18 10H6Z'
          fill={color}
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export default DropDown;
