import React, { useState } from "react";
import { Popover, Tooltip } from "antd";
import "../../../components/commonComponents/calendar/overviewCalender.css";
import { ICONS } from "../../../assets/icons/index";
import CardContent from "./CardContent";
import { rowData } from "../../../constant/app/calender/calender";
import moment from "moment";

const BoardCards = ({ card, pageNavigate, col, selectedStatus }) => {
  const [showAll, setShowAll] = useState(false);

  // const handleShowMore = (broadcastId) => {
  //   setShowMoreMap((prevState) => ({
  //     ...prevState,
  //     [broadcastId]: true,
  //   }));
  // };
  const [hoveredCard, setHoveredCard] = useState(null);
  const [hoveredSlot, setHoveredSlot] = useState(null);

  const [popoverVisibility, setPopoverVisibility] = useState(false);
  const handleClickToggle = () => {
    setShowAll(!showAll);
  };

  const handleCardClick = (cardData) => {
    setHoveredCard(cardData);
  };

  const handleHover = (cardId) => {
    setHoveredCard(cardId);
  };

  // const handleClose = () => {
  //   setPopoverVisibility(false);
  // };

  const handleClose = (broadcastId) => {
    setPopoverVisibility((prev) => ({
      ...prev,
      [broadcastId]: false,
    }));
  };

  const handleVisibleChange = () => {
    // setPopoverVisibility((prevState) => {
    //   const newState = {
    //     ...prevState,
    //     [cardId]: {
    //       ...prevState[cardId],
    //       [segmentId]: visible,
    //     },
    //   };
    //   return newState;
    // });
    setPopoverVisibility(true);
  };

  const shouldDisplayFlexRow = () => {
    if (!card || card.length <= 1) return false;

    const timeOccurrences = {};

    Object.values(card).forEach((broadcast) => {
      const broadcastTime = broadcast.schedule_on;
      if (broadcastTime) {
        const broadcastDate = moment(broadcastTime).format("YYYY-MM-DD");
        const broadcastTimeOnly = moment(broadcastTime).format("HH:mm");
        const key = `${broadcastDate}-${broadcastTimeOnly}`;

        if (!timeOccurrences[key]) {
          timeOccurrences[key] = [];
        }
        timeOccurrences[key].push(broadcast);

        // if (timeOccurrences[key].length > 1) {
        // }
      }
    });
    const displayFlexRows = Object.values(timeOccurrences).some(
      (broadcasts) => broadcasts.length > 1
    );

    return displayFlexRows;
  };

  const cardArray = pageNavigate === "Week" && Object.values(card);

  const statusExists =
    Array.isArray(card) &&
    card?.some((segment) => {
      if (selectedStatus.length > 0) {
        return selectedStatus.includes(segment?.status);
      }
      return true;
    });

  const statusExistss =
    Array.isArray(cardArray) &&
    cardArray?.some((segment) => {
      if (selectedStatus.length > 0) {
        return selectedStatus.includes(segment?.status);
      }
      return true;
    });

  return (
    <div>
      {pageNavigate === "Month" && (
        <>
          {card?.slice(0, showAll ? card?.length : 2).map((broadcast) => {
            const scheduleOn = new Date(broadcast?.schedule_on);
            const formattedDate = `${scheduleOn.getDate()}-${
              scheduleOn.getMonth() + 1
            }-${scheduleOn.getFullYear()}`;

            // const scheduleOn = moment(broadcast?.schedule_on).utc();
            // const formattedDate = scheduleOn?.format("DD-M-YYYY");

            if (col?.date === formattedDate && broadcast?.status !== 0) {
              if (
                (selectedStatus?.length > 0 &&
                  selectedStatus?.includes(broadcast?.status)) ||
                selectedStatus?.length === 0
              ) {
                const statusExists =
                  broadcast?.status === 10 ||
                  broadcast?.status === 11 ||
                  broadcast?.status === 12;

                return (
                  statusExists && (
                    <Popover
                      key={broadcast?.id}
                      content={
                        <CardContent
                          // handleClose={() => handleClose()}
                          handleClose={() => handleClose(broadcast?.id)}
                          broadcastList={broadcast}
                          col={col}
                        />
                      }
                      trigger="click"
                      open={popoverVisibility[broadcast.id]}
                      onOpenChange={(visible) =>
                        handleVisibleChange(visible, broadcast?.id)
                      }
                      arrow={false}
                    >
                      <div
                        className="boardCard p-5 pointer mb-5 flex-row"
                        onClick={() => handleCardClick(broadcast?.id)}
                      >
                        <div
                          style={{
                            borderLeft: broadcast?.display_color
                              ? `3px solid ${broadcast?.display_color}`
                              : undefined,
                            borderRadius: "2.5px",
                            marginLeft: "4px",
                          }}
                          className="flex pl-5"
                        >
                          <div>
                            <div className="flex-row align-center">
                              {broadcast?.display_emoji && (
                                <p className="pr-1 text-[0.9vw]">
                                  {broadcast?.display_emoji &&
                                    broadcast?.display_emoji}
                                </p>
                              )}
                              <img
                                src={ICONS?.whatsApp}
                                className="boardCardWhatsapp"
                              />
                              <p className="boardCardText">
                                {broadcast?.name
                                  ? broadcast.name?.charAt(0)?.toUpperCase() +
                                    broadcast?.name?.slice(1)
                                  : ""}
                              </p>
                            </div>

                            <div className="w-[8.5vw] flex-row space-between mt-1">
                              <div className="flex-row align-center">
                                {broadcast?.status === 10 && (
                                  <div className="flex-row align-center">
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: "12px" }}>
                                          Scheduled
                                        </span>
                                      }
                                      placement="right"
                                    >
                                      <div className="flex-row align-center">
                                        <img
                                          src={ICONS?.calenderScheduled}
                                          className="w-[17px]"
                                        />
                                        {broadcast && (
                                          // <p className="boardTime">
                                          //   {new Date(
                                          //     broadcast?.schedule_on
                                          //   ).toLocaleTimeString("en-US", {
                                          //     hour: "numeric",
                                          //     minute: "2-digit",
                                          //   })}
                                          // </p>
                                          <p className="boardTime">
                                            {moment(broadcast?.schedule_on)
                                              .utcOffset("+05:30")
                                              .format("hh:mm A")}
                                          </p>
                                          // <p className="boardTime">
                                          //   {moment(broadcast?.schedule_on)
                                          //     .utc()
                                          //     .format("hh:mm A")}
                                          // </p>
                                        )}
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}

                                {broadcast?.status === 11 && (
                                  <>
                                    <img
                                      src={ICONS?.CampaignRunningIcon}
                                      className="boardCardRunning"
                                    />
                                    <p
                                      style={{ color: "#0ACD95" }}
                                      className="calenderIcons"
                                    >
                                      Running
                                    </p>
                                  </>
                                )}

                                {broadcast?.status === 12 && (
                                  <div className="flex-row align-center">
                                    <img
                                      src={ICONS?.CampaignCompleteIcon}
                                      className="w-[14px]"
                                    />
                                    <p
                                      className="calenderIcons"
                                      style={{ color: "#4C90F5" }}
                                    >
                                      Completed
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className="flex-row align-center">
                                <div className="boardTime flex-row align-center">
                                  <img
                                    src={ICONS?.uploadFile}
                                    // className="boardCardSegment"
                                  />
                                  <p className="boardSegment pr-5">
                                    <span>
                                      {broadcast?.uploaded_files_count}
                                    </span>
                                  </p>
                                </div>

                                <div className="boardTime flex-row align-center">
                                  <img
                                    src={ICONS?.BroadcastSegmentIcons}
                                    className="w-[17px]"
                                  />
                                  <p className="boardSegment pr-5">
                                    <span>{broadcast?.segments_count}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover>
                  )
                );
              }
            }
            return null;
          })}
          {card?.length > 2 && (
            <button
              onClick={handleClickToggle}
              className="p-relative flex-row align-center show-button"
            >
              <img
                src={ICONS?.CalenderArrow}
                className={`boardCardArrow mr-1 ${showAll ? "rotate-180" : ""}`}
              />

              {showAll ? "Show Less" : "Show More"}
            </button>
          )}
        </>
      )}

      {pageNavigate === "Week" && (
        <>
          <div
            className={`board-cards-container  ${
              shouldDisplayFlexRow() ? "flex-row" : ""
            }`}
          >
            {Object.values(card).map((broadcast) => {
              const length = Object.keys(card).length;
              if (broadcast?.status !== 0) {
                return (
                  ((selectedStatus?.length > 0 &&
                    selectedStatus?.includes(broadcast?.status)) ||
                    selectedStatus?.length === 0) &&
                  statusExistss && (
                    <Popover
                      key={broadcast?.id}
                      content={
                        <CardContent
                          // handleClose={handleClose}
                          handleClose={() => handleClose(broadcast?.id)}
                          broadcastList={broadcast}
                        />
                      }
                      trigger="click"
                      open={popoverVisibility[broadcast.id]}
                      onOpenChange={(visible) =>
                        handleVisibleChange(visible, broadcast.id)
                      }
                      arrow={false}
                      placement="right"
                    >
                      <div
                        className={`boardCard pt-5 pb-5 pl-0 pointer mb-5 flex-row p-relative ${
                          shouldDisplayFlexRow() ? "flex-card" : ""
                        } ${
                          shouldDisplayFlexRow() && length > 2
                            ? "card-overlap"
                            : ""
                        }
                    ${!shouldDisplayFlexRow() && length > 1 ? "asc" : ""} 
                    ${hoveredCard === broadcast?.id ? "expanded" : ""}
                    ${
                      hoveredCard && hoveredCard !== broadcast?.id
                        ? "hidden"
                        : ""
                    }
                    `}
                        // onClick={() => handleCardClick(broadcast.id)}
                        onMouseEnter={() => {
                          handleHover(broadcast?.id, broadcast?.time);
                        }}
                        onMouseLeave={() => handleHover(null, null)}
                      >
                        <div
                          style={{
                            borderLeft: broadcast?.display_color
                              ? `3px solid ${broadcast?.display_color}`
                              : undefined,
                            borderRadius: "2.5px",
                            marginLeft: "4px",
                          }}
                          className="flex pl-5"
                        >
                          <div>
                            <div className="flex-row align-center">
                              {broadcast?.display_emoji && (
                                <p className="pr-1 text-[0.9vw]">
                                  {broadcast?.display_emoji &&
                                    broadcast?.display_emoji}
                                </p>
                              )}
                              <img
                                src={ICONS?.whatsApp}
                                className="boardCardWhatsapp"
                              />
                              <p
                                className={`boardCardText ${
                                  hoveredCard === broadcast?.id
                                    ? ""
                                    : shouldDisplayFlexRow()
                                    ? "boardCardText-flex"
                                    : ""
                                }`}
                              >
                                {broadcast?.name
                                  ? broadcast.name?.charAt(0)?.toUpperCase() +
                                    broadcast?.name?.slice(1)
                                  : ""}
                              </p>
                            </div>

                            <div className="w-[9vw] flex-row mt-1 space-between">
                              <div className="flex-row align-center">
                                {broadcast?.status === 10 && (
                                  <div className="flex-row align-center">
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: "12px" }}>
                                          Scheduled
                                        </span>
                                      }
                                      placement="right"
                                    >
                                      <div className="flex-row align-center">
                                        <img
                                          src={ICONS?.calenderScheduled}
                                          className="w-[17px]"
                                        />
                                        {broadcast && (
                                          // <p className="boardTime">
                                          //   {new Date(
                                          //     broadcast.schedule_on
                                          //   ).toLocaleTimeString("en-US", {
                                          //     hour: "numeric",
                                          //     minute: "2-digit",
                                          //   })}
                                          // </p>
                                          <p className="boardTime">
                                            {moment(broadcast?.schedule_on)
                                              .utcOffset("+05:30")
                                              .format("hh:mm A")}
                                          </p>

                                          // <p className="boardTime">
                                          //   {moment(broadcast?.schedule_on)
                                          //     .utc()
                                          //     .format("hh:mm A")}
                                          // </p>
                                        )}
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}

                                {broadcast?.status === 11 && (
                                  <>
                                    <img
                                      src={ICONS?.CampaignRunningIcon}
                                      className="boardCardRunning"
                                    />
                                    <p
                                      style={{ color: "#0ACD95" }}
                                      className="calenderIcons"
                                    >
                                      Running
                                    </p>
                                  </>
                                )}

                                {broadcast?.status === 12 && (
                                  <div className="flex-row items-center">
                                    <img
                                      src={ICONS?.CampaignCompleteIcon}
                                      className="w-[14px]"
                                    />
                                    <p
                                      className="calenderIcons"
                                      style={{ color: "#4C90F5" }}
                                    >
                                      Completed
                                    </p>
                                  </div>
                                )}
                              </div>

                              <div className="flex-row align-center">
                                <div className="boardTime flex-row align-center">
                                  <img
                                    src={ICONS?.uploadFile}
                                    // className="w-[18px]"
                                  />
                                  <p className="boardSegment">
                                    <span>
                                      {broadcast?.uploaded_files_count}
                                    </span>
                                  </p>
                                </div>

                                <div className="boardTime flex-row align-center">
                                  <img
                                    src={ICONS?.BroadcastSegmentIcons}
                                    className="w-[17px]"
                                  />
                                  <p className="boardSegment">
                                    <span>{broadcast?.segments_count}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover>
                  )
                );
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default BoardCards;
