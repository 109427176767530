import Interactive from 'assets/customSVG/sequence/actions/Interactive';
import Message from 'assets/customSVG/sequence/actions/Message';
import Media from 'assets/customSVG/sequence/actions/Media';
import List from 'assets/customSVG/sequence/actions/List';
import Template from 'assets/customSVG/sequence/actions/Template';
import Close from 'assets/customSVG/sequence/actions/Close';
import Key from 'assets/customSVG/sequence/actions/Key';
import Assignee from 'assets/customSVG/sequence/actions/Assignee';
import Nudge from 'assets/customSVG/sequence/actions/Nudge';

export const ACTIONS_LIST = [
  {
    id: 1,
    label: 'Text Message',
    key: 'TEXT',
    icon: Message,
    disabled: false,
    bg: '#0ACD95',
    color: 'white',
  },
  {
    id: 2,
    label: 'Media Message',
    key: 'MEDIA',
    icon: Media,
    disabled: false,
    bg: '#0ACD95',
    color: 'white',
  },
  {
    id: 3,
    label: 'Interactive Message',
    key: 'BUTTON',
    icon: Interactive,
    disabled: false,
    bg: '#0ACD95',
    color: 'white',
  },
  {
    id: 4,
    label: 'List Message',
    key: 'LIST',
    icon: List,
    disabled: false,
    bg: '#0ACD95',
    color: 'white',
  },
  {
    id: 5,
    label: 'Template Message',
    key: 'TEMPLATE',
    icon: Template,
    disabled: false,
    bg: '#0ACD95',
    color: 'white',
  },
];

export const NODE_TYPES_CONVERTION = {
  TEXT: 'Text Message',
  MEDIA: 'Media Message',
  BUTTON: 'Interactive Message',
  LIST: 'List Message',
  TEMPLATE: 'Template Message',
};

export const OPERATIONS_LIST = [
  {
    id: 1,
    label: 'Add Nudge',
    key: 'NUDGE',
    icon: Nudge,
    disabled: false,
    bg: '#FBBF24',
    color: 'white',
  },
  {
    id: 2,
    label: 'Add Follow-up',
    key: 'FOLLOW_UP',
    icon: Nudge,
    disabled: false,
    bg: '#3B82F6',
    color: 'white',
  },
  {
    id: 3,
    label: 'Set Assignee',
    key: 'ASSIGNEE',
    icon: Assignee,
    disabled: false,
    bg: '#6940F2',
    color: 'white',
  },
  {
    id: 4,
    label: 'Keyword',
    key: 'KEYWORD',
    icon: Key,
    disabled: false,
    bg: '#F43F5E',
    color: 'white',
  },
  {
    id: 5,
    label: 'Close Conversation',
    key: 'CLOSE_CONVERSATION',
    icon: Close,
    disabled: false,
    bg: '#F97E5E',
    color: 'white',
  },
];

const listData = [
  {
    rows: [
      {
        title: '',
        description: '',
      },
    ],
    title: '',
  },
];

export const INITIAL_NODE_TRIGGER_DATA = {
  TEXT: {
    type: 'text',
    text: {
      body: '',
    },
  },
  MEDIA: {
    type: 'image',
    image: {
      link: '',
    },
  },
  BUTTON: {
    type: 'interactive',
    interactive: {
      type: 'button',
      action: {
        buttons: [
          {
            type: 'reply',
            reply: {
              title: '',
            },
          },
        ],
      },
    },
  },
  LIST: {
    type: 'interactive',
    interactive: {
      type: 'list',
      body: {
        text: '',
      },
      action: {
        button: 'List',
        sections: listData,
      },
    },
  },
  TEMPLATE: {
    type: 'template',
    template: [],
    template_name: '',
    template_id: '',
    broadcast_name: '',
  },
};
