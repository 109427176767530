import React, { useEffect, useState } from 'react';

// components
import SubSideBar from '../../../../components/commonComponents/subSideBar';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const overview = ['/profile/account/overview'];
const list = [
  {
    id: 1,
    name: 'Overview',
    active: '/overview',
    link: '/profile/account/overview',
  },
];

export default function Home() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeTabName, setActiveTabName] = useState('Overview');

  useEffect(() => {
    updateTabName();
  }, []);

  const updateTabName = () => {
    if (overview.includes(pathname)) setActiveTabName('Overview');
  };

  const navListSelect = (item) => {
    setActiveTabName(item?.name);
    navigate(`/profile/account${item?.active}`);
  };

  return (
    <div className='w-full h-full flex'>
      {/* <SubSideBar
        isListShow={false}
        list={list}
        activeNav={activeTabName}
        onSelect={(item) => navListSelect(item)}
      /> */}
      <Outlet />
    </div>
  );
}
