import React from "react";
import "./Title.css";
import { ICONS } from "assets/icons";

export const Title = ({ paragraph, backgroundColor, content, width }) => {
  // const mode = primary ? 'storybook-Title--primary' : 'storybook-Title--secondary';

  return (
    <div
      className={`storybook-Title-container`}
      style={{ backgroundColor, width }}
    >
      {/* <h3 className="Title-title">
        Altiwise<span style={{ color: "#25C277" }}>X</span>
      </h3> */}
      <img src={ICONS?.logoGain} alt="logoGain" />
      {/* <img src={ICONS?.logoApton} alt="logoApton" /> */}
      {content && <h1 className="font24px-600 text-[var(--textBlack)] mt-20">{content}</h1>}
      {paragraph !== "" && <h1 className="Title-paragraph">{paragraph}</h1>}
    </div>
  );
};
