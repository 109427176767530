import React from "react";
import PaymentHostory from "./PaymentHostory";
import { ICONSS } from "assets/icons/index1";
import { useAspSelector } from "test/jest-redux-hooks";

const Subscription = ({
  setIsUpgrade,
  brandsData,
  yearFilterOpen,
  setYearFilterOpen,
  years,
  setSelectedYear,
  selectedYear,
  paymentHistoryData,
  activeNav,
  setProceed,
  handleProceedPlans,
  listLoading,
}) => {
  const { currentAccount } = useAspSelector((state) => state.app);
  return (
    <div className="w-100 flex-row  space-between gap-3">
      <div className="bg-white b-radius w-[42%] h-[60.5vh] p-10">
        <span className="font16px-600 text-[var(--textBlack)]">
          Brand Information
        </span>
        <div className="border border-[#E8E8EA] mt-20">
          <div className="bg-[#F9F9FC]  border-b-[1px] flex-row align-center space-between prl-10 py-1 b-radius-6 ">
            <span className="font15px-500 text-[var(--textBlack)]">
              Current Plan Summary
            </span>

            {(currentAccount?.role === "Account Owner" ||
              currentAccount?.role === "Account Manager") && (
              <>
                {brandsData?.current_plan !== "Magnum Plan" && (
                  <button
                    className="button-primary1 px-2 py-1"
                    onClick={() => {
                      // handleCancel();
                      setIsUpgrade(true);
                    }}
                  >
                    Upgrade
                  </button>
                )}
              </>
            )}
          </div>
          <div className="w-100 flex-row align-center space-between p-10 mt-10">
            <div className="flex-column">
              <p className="font12px-400 text-[var(--font-600)]">Plan name</p>
              <span className="font16px-500 text-[var(--textBlack)]">
                {brandsData?.current_plan}
              </span>
            </div>
            {brandsData?.current_plan !== "Starter Plan" && (
              <div className="flex-column">
                <p className="font12px-400 text-[var(--textBlack)]">
                  Billing cycle
                </p>
                <span className="font16px-500 text-[var(--textBlack)] capitalize">
                  {brandsData?.billing_cycle}
                </span>
              </div>
            )}
            <div className="flex-column">
              <p className="font12px-400 text-[var(--font-600)]">Plan cost</p>
              <span className="font16px-500 text-[var(--textBlack)]">
                {brandsData?.current_plan !== "Starter Plan"
                  ? `${brandsData?.settings?.amount}`
                  : "Free"}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="border border-[#E8E8EA] mt-20">
          <div className="bg-[#F9F9FC]  border-b-[1px] flex-row space-between p-10 b-radius-6">
            <span className="font15px-500 text-[var(--textBlack)]">
              Next Payment
            </span>
            <span className="font16px-500 text-[var(--textBlack)]">
              28 Dec, 2024
            </span>
          </div>
          <div className="w-100 flex-row align-center space-between p-10 mt-10">
            <div className="flex-column">
              <p className="font12px-400 text-[var(--font-600)]">Plan name</p>
              <span className="font16px-500 text-[var(--font-600)]">
                Growth Plan
              </span>
            </div>
            <div className="flex-column">
              <p className="font12px-400 text-[var(--font-600)]">
                Billing cycle
              </p>
              <span className="font16px-500 text-[var(--font-600)]">
                Monthly
              </span>
            </div>
            <div className="flex-column">
              <p className="font12px-400 text-[var(--font-600)]">Plan cost</p>
              <span className="font16px-500 text-[var(--font-600)]">$5698</span>
            </div>
          </div>
        </div> */}
        {(currentAccount?.role === "Account Owner" ||
          currentAccount?.role === "Account Manager") &&
          brandsData?.current_plan !== "Starter Plan" && (
            <div className="mt-20 flex-column">
              <span className="font15px-400 text-[var(--font-600)]">
                Other Payment Method
              </span>
              <div
                className="bg-[#072654] rounded-[4px] flex-row align-center justify-center py-1.5 mt-10 pointer"
                onClick={() => handleProceedPlans()}
              >
                <span className="pr-10 font14px-600 text-white">
                  Proceed with
                </span>
                <img src={ICONSS?.razorPay} alt="razorPay" />
              </div>
            </div>
          )}

        {/* <div className="mt-20 flex-column">
          <span className="font15px-400 text-[var(--font-600)] mb-10">
            Got any problems with your subscription?
          </span>
          <button className="bg-[#FF543E14] border border-[#FF543E] font14px-600 text-[#FF543E] py-1 b-radius-6">
            Cancel Subscription
          </button>
        </div> */}
      </div>
      <div className="w-[58%]">
        <PaymentHostory
          yearFilterOpen={yearFilterOpen}
          setYearFilterOpen={setYearFilterOpen}
          years={years}
          setSelectedYear={setSelectedYear}
          selectedYear={selectedYear}
          paymentHistoryData={paymentHistoryData}
          activeNav={activeNav}
          listLoading={listLoading}
        />
      </div>
    </div>
  );
};

export default Subscription;
