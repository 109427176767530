import React, { useCallback, useEffect, useReducer, useState } from "react";
// icons
import { ICONS } from "../../../../assets/icons";

// helper
import { cn } from "../../../../helper/cn";
import {
  textsOnly,
  textsOnlyNoSpaces,
  validateEmail,
} from "../../../../helper/validation";

// hooks
import { useFileUploader } from "../../../../hooks/useFileUploader";

// common-components
import Loader from "../../../../components/commonComponents/Loader/Index";

// reducer
import {
  currentUserDetails,
  profileInformationUpdate,
  resetPasswordPatch,
} from "../../../../api/Api";
import {
  INITIAL_STATE,
  onBoardingReducer,
} from "../../../../hooks/auth/onBoardingReducer";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import CountryCode from "../../../../components/commonComponents/countryCode";
import { countryList } from "../../../../constant/app/account/account";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import { Input } from "../../../../components/form/Input/Input";
import useToggle from "../../../../hooks/useToggle";
import {
  checkingValidation,
  passwordValidations,
} from "../../../../constant/auth/authValidation";
import CircleTick from "../../../../assets/customSVG/CircleTick";

const authenticate = [
  {
    id: 1,
    title: "Using phone number",
    content: "You will be receiving an OTP to that phone number ",
  },
  {
    id: 2,
    title: "Setup Google authenticator",
    content: "You will be entering code from Google authenticator",
  },
];

const ProfileInfo = ({ userData, setIsApiSuccess }) => {
  const [editInfo, setEditInfo] = useState({
    profileEdit: false,
    changePassword: false,
  });
  const [errorValues, setErrorValues] = useState(passwordValidations);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [loading, setLoading] = useToggle(false);
  const { currentUser, toggleToast } = useAspSelector((state) => state?.app);
  const dispatchData = useAspDispatch();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [data, dispatch] = useReducer(onBoardingReducer, INITIAL_STATE);

  const [profileInputs, setProfileInputs] = useState({});

  useEffect(() => {
    if (userData) {
      setProfileInputs((prev) => ({
        profile: [
          {
            id: 1,
            value: userData?.first_name,
            label: "First Name",
            placeholder: "Eg: First Name",
          },
          {
            id: 2,
            value: userData?.last_name,
            label: "Last Name",
            placeholder: "Eg: Last Name",
          },
          {
            id: 3,
            value: userData?.email,
            label: "Email ID",
            placeholder: "Eg: example@gmail.com",
          },
          {
            id: 4,
            value: userData?.phone_number || "--",
            label: "Phone Number",
            placeholder: "Eg: 987 654 3210",
          },
        ],
        resetPassword: [
          {
            id: 1,
            value: "",
            label: "Current Password",
            placeholder: "Enter current password",
          },
          {
            id: 2,
            value: "",
            label: "New Password",
            placeholder: "Please input your password",
          },
          {
            id: 3,
            value: "",
            label: "Confirm New Password",
            placeholder: "Confirm your password",
          },
        ],
      }));
    }
  }, [userData]);

  const handleEdit = (key) => {
    setEditInfo((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
      ...(key === "changePassword" && { profileEdit: false }),
      ...(key === "profileEdit" && { changePassword: false }),
    }));
  };

  const handleChange = (section, id, value) => {
    if (section === "resetPassword") {
      setErrorMessage("");

      setProfileInputs((prev) => {
        const updatedInputs = prev[section].map((item) =>
          item.id === id ? { ...item, value } : item
        );

        const password =
          updatedInputs.find((item) => item.id === 2)?.value || "";

        const updatedErrors = passwordValidations.map((validation) => {
          switch (validation.type) {
            case "min":
              return { ...validation, value: password.length >= 8 };
            case "numbers":
              return { ...validation, value: /\d/.test(password) };
            case "uppercase":
              return { ...validation, value: /[A-Z]/.test(password) };
            case "lowercase":
              return { ...validation, value: /[a-z]/.test(password) };
            case "special":
              return {
                ...validation,
                value: /[!@#$%^&*(),.?":{}|<>]/.test(password),
              };
            default:
              return validation;
          }
        });

        setErrorValues(updatedErrors);

        // Perform password validation after updating the state
        if (section === "resetPassword") {
          const password = updatedInputs.find((item) => item.id === 2)?.value;
          const confirmPassword = updatedInputs.find(
            (item) => item.id === 3
          )?.value;

          if (password && confirmPassword && password !== confirmPassword) {
            setErrorMessagePassword("Passwords do not match");
          } else {
            setErrorMessagePassword("");
          }
        }

        // Return the new state
        return {
          ...prev,
          [section]: updatedInputs,
        };
      });
    } else {
      if (id === 4 && value?.length > 10) {
        return;
      }

      if (id === 1) {
        value = textsOnly(value.trimStart());
      }

      if (id === 2) {
        value = textsOnlyNoSpaces(value.trimStart());
      }

      setProfileInputs((prev) => ({
        ...prev,
        [section]: prev[section].map((item) =>
          item.id === id ? { ...item, value } : item
        ),
      }));
    }
  };

  const onChange = (type, primaryKey, key, value) => {
    dispatch({ type, primaryKey, key, value });
  };

  const {
    uploading,
    selectedFile,
    responseFile,
    uploadFiles,
    error,
    clearFiles,
    dimensionError,
  } = useFileUploader();

  const isButtonDisabled = useCallback(() => {
    return (
      profileInputs?.profile?.[0]?.value &&
      !profileInputs?.profile?.[0]?.value?.endsWith(".") &&
      !profileInputs?.profile?.[0]?.value?.endsWith("-") &&
      !profileInputs?.profile?.[0]?.value?.endsWith("'") &&
      ((profileInputs?.profile?.[3]?.value?.length > 0 &&
        profileInputs?.profile?.[3]?.value?.length === 10) ||
        !profileInputs?.profile?.[3]?.value) &&
      (!errorMessage || !dimensionError)
    );
  }, [profileInputs, errorMessage, dimensionError]);

  const isPasswordButtonDisabled = useCallback(() => {
    return (
      profileInputs?.resetPassword?.[0]?.value?.length > 0 &&
      profileInputs?.resetPassword?.[1]?.value?.length > 0 &&
      profileInputs?.resetPassword?.[2]?.value?.length > 0 &&
      errorValues?.every(
        (item) => item?.value === true && !errorMessagePassword
      )
    );
  }, [profileInputs, errorMessagePassword, errorValues]);

  const buttonClassName = editInfo["changePassword"]
    ? isPasswordButtonDisabled()
      ? "button-primary"
      : "disable-button cursor-not-allowed"
    : "border border-[var(--border-input)] b-radius-6 font15px-500 text-[var(--font-600)]";

  const handleUpdate = () => {
    setLoading(true);

    const payLoad = editInfo["changePassword"]
      ? {
          current_password: profileInputs?.resetPassword?.[0]?.value,
          password: profileInputs?.resetPassword?.[1]?.value,
          password_confirmation: profileInputs?.resetPassword?.[2]?.value,
        }
      : {
          first_name: profileInputs?.profile?.[0]?.value,
          last_name: profileInputs?.profile?.[1]?.value,
          email: profileInputs?.profile?.[2]?.value,
          phone_number: profileInputs?.profile?.[3]?.value || "",
          phone_country_code: selectedCountry?.replace(/[()]/g, "") || "IN +91",
          profile_photo: responseFile || userData?.profile_photo,
        };

    const updateApis = editInfo["changePassword"]
      ? resetPasswordPatch
      : profileInformationUpdate;

    updateApis(currentUser?.id, payLoad).then((res) => {
      setLoading(false);

      if (res?.status === 200) {
        setErrorMessage("");
        setIsApiSuccess(true);
        setEditInfo(false);
        dispatchData(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.data?.message,
              status: "Success",
              duration: "",
            },
          ])
        );
      } else {
        setErrorMessage(res?.response?.data?.error);
        // dispatchData(
        //   updateToggleToast([
        //     ...toggleToast,
        //     {
        //       id: toggleToast?.length + 1,
        //       content: res?.response?.data?.error,
        //       status: "Error",
        //       duration: "",
        //     },
        //   ])
        // );
      }
    });
  };

  // styles ---------------
  const inputContainer = "w-full py-2 gap-2 b-radius-6 mt-5";
  const input =
    "w-full text-[20vw] font14px-500 text-[var(--textBlack)] px-3 rounded-lg";

  return (
    <div className="bg-white b-radius p-20 w-100">
      <p className="font16px-600 text-[var(--textBlack)]">
        Profile Information
      </p>
      <div className="w-75  h-[90vh]">
        <div
          className={cn(
            "w-full flex items-center justify-between px-4 py-3 mt-6 bg-slate-50 rounded-[10px]",
            error || dimensionError ? "border-red-400 animate-shake" : ""
          )}
        >
          <div className="flex-row align-center">
            <div className="rounded-[10px] size-16 bg-slate-100">
              {!responseFile && !userData?.profile_photo && (
                <div className="p-[18px]">
                  <img
                    src={ICONS.uploadStep}
                    alt="UploadStep"
                    className="upload-image-before "
                  />
                </div>
              )}

              {(selectedFile || userData?.profile_photo) && (
                <div className="relative">
                  <img
                    src={responseFile ? responseFile : userData?.profile_photo}
                    alt="SelectedImage"
                    className="uploadStep-profile-image"
                  />
                  {editInfo["profileEdit"] && (
                    <img
                      className="absolute pointer -top-2 -right-2 size-4"
                      src={ICONS.Exit}
                      alt="popup"
                      onClick={() => {
                        clearFiles();
                        onChange("update", "account", "image", null);
                        if (userData?.profile_photo) {
                          userData.profile_photo = null;
                          onChange("update", "account", "profile_photo", null);
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="upload-content ml-10">
              <p className="account-image">Profile Image</p>
              <p className="recommended-file">
                {selectedFile ? (
                  <p className="truncate max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {selectedFile?.name}
                  </p>
                ) : (
                  <p>Recommended file 320*320 px, less than 1MB.</p>
                )}
              </p>
            </div>
          </div>

          <>
            {editInfo["profileEdit"] && (
              <button className="">
                <input
                  type="file"
                  onChange={async (e) => {
                    const fileInput = e.target;
                    if (fileInput.files.length > 0) {
                      const res = await uploadFiles(e, "IMAGE", 1);
                      if (res) {
                        onChange("update", "account", "image", res);
                      }
                      fileInput.value = "";
                    }
                  }}
                  accept=".jpg, .jpeg, .png"
                  id="uploadImg"
                  hidden
                />
                <label
                  for="uploadImg"
                  className="w-full flex-center cursor-pointer"
                >
                  <div
                    className={cn(
                      "min-w-[100px] text-sm weight-semibold text-[var(--background)] bg-[var(--new-primary)] flex items-center justify-center rounded-md px-4 py-1.5 tracking-wide"
                    )}
                  >
                    {uploading ? (
                      <Loader Width={20} Height={20} loaderBg="white" />
                    ) : (
                      <p>Upload</p>
                    )}
                  </div>
                </label>
              </button>
            )}
          </>
        </div>
        {error && (
          <div className="w-full text-left text-xs mt-1 px-1 text-red-400">
            File Should be less than 1MB.
          </div>
        )}
        {dimensionError && (
          <div className="w-full text-left text-xs mt-1 px-1 text-red-400">
            Recommended file should be greater than 320*320 px
          </div>
        )}
        <div className="grid grid-cols-2 gap-5 pt-20">
          {profileInputs?.profile?.map((item) => {
            return (
              <div key={item?.id}>
                <span className="font14px-500 text-[var(--font-600)]">
                  {item?.label}

                  {item?.id === 1 && <span className="text-[red]">*</span>}
                </span>
                {editInfo["profileEdit"] ? (
                  <>
                    {item?.id !== 3 ? (
                      <div
                        className={cn(
                          inputContainer,
                          `${
                            item?.id !== 3
                              ? "border border-[var(--border-input)] flex-row align-center"
                              : ""
                          }`
                        )}
                      >
                        {item?.id === 4 && (
                          <div className="w-30 border-r-[1px] pl-5">
                            {/* <CountryCode
                              value={selectedCountry || "IN (+91)"}
                              list={countryList}
                              className="!min-h-[0vh] !min-w-[7vw] !mr-5 border-none py-0"
                              onChange={(value) => {
                                setSelectedCountry(
                                  `${value?.shortName} (${value?.value})`
                                );
                              }}
                            /> */}
                            <span className="font14px-400 text-[var(--textBlack)] pl-5">
                              IN (+91)
                            </span>
                          </div>
                        )}

                        <input
                          name="name"
                          id="name"
                          type={item?.id === 4 ? "number" : "text"}
                          placeholder={item?.placeholder}
                          className={cn(input)}
                          value={item?.value || ""}
                          onChange={(e) =>
                            handleChange("profile", item.id, e.target.value)
                          }
                          autoComplete="off"
                          maxLength={item?.id === 4 ? 10 : 40}
                        />
                      </div>
                    ) : (
                      <>
                        {item?.value?.length > 40 ? (
                          <Tooltip title={item?.value} placement="top">
                            <div
                              className={
                                "bg-[var(--BG-25)] pl-10 py-2.5  font14px-500 text-[var(--textBlack)] b-radius-6 mt-5"
                              }
                            >
                              <p className="max-w-[40ch] truncate">
                                {item?.id === 3 && item?.value}
                              </p>
                            </div>
                          </Tooltip>
                        ) : (
                          <div
                            className={
                              "bg-[var(--BG-25)] pl-10 py-2.5 cursor-not-allowed  font14px-500 text-[var(--textBlack)] b-radius-6 mt-5"
                            }
                          >
                            {item?.id === 3 && item?.value}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {item?.value?.length > 40 ? (
                      <Tooltip title={item?.value} placement="top">
                        <div
                          className={cn(
                            inputContainer,
                            "bg-[var(--BG-25)] pl-10  font14px-500 text-[var(--textBlack)]"
                          )}
                        >
                          <p className="max-w-[40ch] truncate">{item?.value}</p>
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className={cn(
                          inputContainer,
                          "bg-[var(--BG-25)] pl-10  font14px-500 text-[var(--textBlack)]"
                        )}
                      >
                        <p className="max-w-[40ch] truncate">{item?.value}</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
        <div
          className={`w-100  flex-row  mt-20 border-b-[1px] ${
            errorMessage ? "space-between" : "justify-end"
          }`}
        >
          {/* {errorMessage && !editInfo["changePassword"] && (
            <div className="flex-row align-center pt-5">
              <img src={ICONS?.alertIconError} alt="" />
              <span className="capitalize error-message pl-5">
                {errorMessage}
              </span>
            </div>
          )} */}
          <button
            className={`  px-2.5 py-1.5 mb-20 ${
              editInfo["profileEdit"] && isButtonDisabled()
                ? "button-primary"
                : !isButtonDisabled()
                ? "disable"
                : "border border-[var(--border-input)] b-radius-6 font15px-500 text-[var(--font-600)]"
            }`}
            onClick={() => {
              if (editInfo["profileEdit"]) {
                if (isButtonDisabled()) {
                  handleUpdate();
                }
              } else {
                handleEdit("profileEdit");
              }
            }}
          >
            {!editInfo["profileEdit"]
              ? "Edit Profile Info"
              : "Update Profile Info"}
          </button>
        </div>
        <p className="font16px-600 text-[var(--textBlack)] mt-20">
          Reset Password
        </p>
        <div className="grid grid-cols-2 gap-5 pt-15">
          {profileInputs?.resetPassword?.map((item, index) => {
            return (
              <div
                key={item?.id}
                className={
                  index === 0 ? "col-span-2 w-[48.5%]" : "flex flex-col"
                }
              >
                <span className="font14px-500 text-[var(--font-600)]">
                  {item?.label}
                  {item?.label !== "Current Password" && (
                    <span className="text-[red]">*</span>
                  )}
                </span>

                {editInfo["changePassword"] ? (
                  <div
                    // className={cn(
                    //   inputContainer,
                    //   "border border-[var(--border-input)]"
                    // )}
                    className="mt-2"
                  >
                    {/* <input
                      name="name"
                      id="name"
                      type="text"
                      placeholder={item?.placeholder}
                      className={cn(input)}
                      value={item?.value || ""}
                      onChange={(e) =>
                        handleChange("resetPassword", item.id, e.target.value)
                      }
                      autoComplete="off"
                      maxLength={40}
                    /> */}
                    <Input
                      iconSuffixPassword={ICONS.passwordEyeOpen}
                      type="password"
                      placeholder={item?.placeholder}
                      maxLength={20}
                      value={item?.value || ""}
                      className="px-2 py-1.5"
                      onChange={(e) =>
                        handleChange("resetPassword", item.id, e.target.value)
                      }
                      autoComplete="off"
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className={cn(
                      inputContainer,
                      "bg-[var(--BG-25)] pl-10  font14px-400 text-[var(--font-400)]"
                    )}
                  >
                    {item?.placeholder || "--"}
                  </div>
                )}

                {item?.label === "New Password" && errorMessagePassword && (
                  <div className="flex-row align-center">
                    <img src={ICONS?.alertIconError} alt="" />
                    <span className="error-message pl-5">
                      {errorMessagePassword}
                    </span>
                  </div>
                )}

                {errorMessage && item?.label === "New Password" && (
                  <div className="flex-row align-center pt-5">
                    <img src={ICONS?.alertIconError} alt="" />
                    <span className="capitalize error-message pl-5">
                      {errorMessage}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="w-100 flex-row space-between">
          <div className="mt-4 w-60">
            <h3 className="text-sm weight-medium pb-2">
              Your Password must contain
            </h3>
            <div className="grid grid-cols-2 gap-x-0">
              {errorValues?.map((e) => {
                return (
                  <div className="flex items-center gap-2 py-1">
                    <CircleTick
                      color={e.value ? "var(--new-primary)" : "var(--font-600)"}
                    />
                    <p
                      className={cn(
                        "text-xs weight-medium",
                        e.value
                          ? "text-[var(--new-primary)]"
                          : "text-[var(--font-600)]"
                      )}
                    >
                      {e.msg}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="space-between align-center flex-row mt-20">
            <button
              className={`px-2 py-1.5 mb-20 ${buttonClassName}`}
              onClick={() => {
                // if (editInfo["changePassword"] && isPasswordButtonDisabled()) {
                //   handleUpdate();
                // } else {
                //   handleEdit("changePassword");
                // }

                if (editInfo["changePassword"]) {
                  if (isPasswordButtonDisabled()) {
                    handleUpdate();
                  }
                } else {
                  handleEdit("changePassword");
                }
              }}
            >
              {loading ? (
                <div className=" flex items-center justify-center">
                  <Loader Width={110} Height={20} loaderBg="white" />
                </div>
              ) : (
                "Change Password"
              )}
            </button>
          </div>
        </div>

        {/* <div className="font16px-600 text-[var(--textBlack)] mt-20  border-t-[1px]">
        <p className="mt-20">Two-Factor Authentication (2FA)</p>
      </div>
      <div
        className="flex-row align-center mt-20"
        onClick={() => setAuthenticationPopover(true)}
      >
        <Switch
          style={{
            backgroundColor: "#F1F4F6",
          }}
        />
        <span className="pl-10">Enable Two-Factor Authentication (2FA)</span>
        <Modal
          open={authenticationPopover}
          wrapClassName={"bg-[#00000065]"}
          closable={false}
          centered
          footer={false}
        >
          <div className="pb-10">
            <Header
              title="Setup Two-Factor Authentication (2FA)"
              titleSize="largest"
              rootStyle="!px-[1.5vw] !py-[1vw] gap-2"
              handleCancel={() => setAuthenticationPopover(false)}
              className="new-header"
            />
            {authenticate?.map((item) => {
              return (
                <div
                  className="flex-row align-center space-between border border-[var(--border-input)] p-10 m-[20px] b-radius-6 pointer"
                  key={item?.id}
                  onClick={() => handleAuthenticate(item)}
                >
                  <div className="flex-column ">
                    <span className="font16px-500 text-[var(--textBlack)]">
                      {item?.title}
                    </span>
                    <span className="mt-5 font14px-400 text-[var(--font-600)]">
                      {item?.content}
                    </span>
                  </div>
                  <img src={ICONS?.accountsArrow} alt="" />
                </div>
              );
            })}
          </div>
        </Modal>
      </div> */}
      </div>
    </div>
  );
};

export default ProfileInfo;
