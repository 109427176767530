import React, { useEffect, useReducer, useState } from "react";

// assets
import FullArrow from "assets/customSVG/FullArrow";

// utils
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// API
import { verificationEmail } from "../api/Api";

// hooks
import useToggle from "../../../hooks/useToggle";

// redux
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";

// import OTP from '../../../../components/commonComponents/otp/OTP';
import Loader from "../../../components/commonComponents/Loader/Index";
import {
  INITIAL_STATE,
  signupReducer,
} from "../../../hooks/auth/signupReducer";

export default function EmailVerification() {
  const { toggleToast } = useAspSelector((state) => state.app);
  const [data, dispatchReducer] = useReducer(signupReducer, INITIAL_STATE);

  const dispatch = useAspDispatch();

  const [isLoading, setIsLoading] = useToggle(false);
  const [invalidLink, setInvalidLink] = useState(false);

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const token = queryParams.get("token");

  const onChange = (type, key, value, token) => {
    let datas = { type: type };
    if (key) datas["key"] = key;
    if (value) datas["value"] = value ? value : "";
    if (token) datas["token"] = token;
    dispatchReducer(datas);
  };

  useEffect(() => {
    const verifyEmail = async () => {
      if (token) {
        setIsLoading(true);
        try {
          const res = await verificationEmail({
            token: token,
          });

          if (res?.status === 200) {
            setIsLoading(false);
          } else {
            setIsLoading(false);
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: res?.response?.data?.error || "An error occurred",
                  status: "Error",
                  duration: "",
                },
              ])
            );
            setInvalidLink(true);
          }
        } catch (error) {
          setIsLoading(false);
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: "An unexpected error occurred",
                status: "Error",
                duration: "",
              },
            ])
          );
        }
      }
    };

    verifyEmail();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate("/email-verification");
  };

  return (
    <div className="max-w-[470px] w-full mx-auto text-[var(--textBlack)]">
      <div className="mx-auto flex flex-col items-center text-center">
        <h2 className="font24px-600 text-center mt-8 mb-9">
          {invalidLink
            ? "Verification Link Expired!"
            : "Verfication Successful"}
        </h2>
        <h4 className="w-85 font16px-400 text-[var(--textBlack)]">
          {invalidLink
            ? "Your invitation link has expired. Please send a new verification link."
            : "Your email is verified. Please proceed to set your profile password."}
        </h4>

        <div
          className="mt-3 flex items-center justify-center gap-2 font14px-500 text-[var(--BrandLink)] cursor-pointer"
          onClick={() => {
            onChange("change_email");
          }}
        >
          Change Email
          <FullArrow color="#6940F2" />
        </div>
      </div>

      <div className="flex flex-col items-center mt-8">
        <button
          type="submit"
          className="bg-[var(--new-primary)] text-white font16px-500 px-5 py-1.5 b-radius-6"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <div className="size-[2vw] flex items-center justify-center">
              <Loader Width={20} Height={20} loaderBg="white" />
            </div>
          ) : invalidLink ? (
            "Resend Link"
          ) : (
            "Setup Password"
          )}
        </button>
      </div>
      <div className="flex items-center justify-center gap-1 text-base weight-medium border-t border-[var(--border-50)] mt-4 pt-4">
        <p>Already have an account?</p>
        <Link to={"/login"} className="text-[var(--a)]">
          Login
        </Link>
      </div>
    </div>
  );
}
