import React from 'react';

const Flow = ({
  width = 20,
  height = 20,
  stroke = '#2D3036',
  fill = 'white',
  strokeWidth = '1.67',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_20186_135802)'>
        <path
          d='M7.5 4.16797C7.5 2.98946 7.5 2.4002 7.86612 2.03409C8.23223 1.66797 8.8215 1.66797 10 1.66797C11.1785 1.66797 11.7678 1.66797 12.1339 2.03409C12.5 2.4002 12.5 2.98946 12.5 4.16797C12.5 5.34648 12.5 5.93574 12.1339 6.30185C11.7678 6.66797 11.1785 6.66797 10 6.66797C8.8215 6.66797 8.23223 6.66797 7.86612 6.30185C7.5 5.93574 7.5 5.34648 7.5 4.16797Z'
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        <path
          d='M1.66675 15.834C1.66675 14.6555 1.66675 14.0662 2.03286 13.7001C2.39898 13.334 2.98824 13.334 4.16675 13.334C5.34526 13.334 5.93451 13.334 6.30063 13.7001C6.66675 14.0662 6.66675 14.6555 6.66675 15.834C6.66675 17.0125 6.66675 17.6017 6.30063 17.9679C5.93451 18.334 5.34526 18.334 4.16675 18.334C2.98824 18.334 2.39898 18.334 2.03286 17.9679C1.66675 17.6017 1.66675 17.0125 1.66675 15.834Z'
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        <path
          d='M13.3333 15.834C13.3333 14.6555 13.3333 14.0662 13.6993 13.7001C14.0655 13.334 14.6548 13.334 15.8333 13.334C17.0118 13.334 17.601 13.334 17.9672 13.7001C18.3333 14.0662 18.3333 14.6555 18.3333 15.834C18.3333 17.0125 18.3333 17.6017 17.9672 17.9679C17.601 18.334 17.0118 18.334 15.8333 18.334C14.6548 18.334 14.0655 18.334 13.6993 17.9679C13.3333 17.6017 13.3333 17.0125 13.3333 15.834Z'
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        <path
          d='M10.0001 6.66797V7.5013M10.0001 7.5013C10.0001 8.27787 10.0001 8.66614 10.1481 8.97247C10.3454 9.3808 10.724 9.7053 11.2004 9.87447C11.5577 10.0013 12.0107 10.0013 12.9167 10.0013C13.8227 10.0013 14.2757 10.0013 14.6331 10.1281C15.1095 10.2973 15.4881 10.6218 15.6854 11.0301C15.8334 11.3365 15.8334 11.7247 15.8334 12.5013V13.3346M10.0001 7.5013C10.0001 8.27787 10.0001 8.66614 9.85208 8.97247C9.65475 9.3808 9.27616 9.7053 8.79975 9.87447C8.44241 10.0013 7.98941 10.0013 7.08341 10.0013C6.17741 10.0013 5.72441 10.0013 5.36709 10.1281C4.89064 10.2973 4.51211 10.6218 4.31476 11.0301C4.16675 11.3365 4.16675 11.7247 4.16675 12.5013V13.3346'
          stroke={stroke}
          strokeWidth={strokeWidth}
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_20186_135802'>
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Flow;
