import React, { useCallback, useReducer, useState } from "react";
import { ICONS } from "../../../../assets/icons";
import { cn } from "../../../../helper/cn";
import { accountType } from "../../../auth/components/onboarding/AccountCreation";
import Select from "../../../../components/commonComponents/select/index";

import {
  INITIAL_STATE,
  onBoardingReducer,
} from "../../../../hooks/auth/onBoardingReducer";
import useTimerCount from "hooks/useTimerCount";

// reducers
import CountryCode from "../../../../components/commonComponents/countryCode/index";
import { countryList } from "../../../../constant/app/account/account";
import { numberOnly, textOnly } from "../../../../helper/validation";
import {
  accountContactInformationUpdate,
  accountInformationOtp,
  accountInformationUpdate,
  currentUserDetails,
} from "../../../../api/Api";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { getRandomColorsBassedOnLetters } from "../../../../helper/colors";
import { useFileUploader } from "../../../../hooks/useFileUploader";

import Loader from "../../../../components/commonComponents/Loader/Index";
import { ICONSS } from "../../../../assets/icons/index1";
import OtpInput from "../../../../components/commonComponents/otp";
import {
  setUpdateAccount,
  updateAccountDetails,
  updateToggleToast,
} from "../../../../reduxToolkit/appSlice";
import { Tooltip } from "antd";
import { validateURL } from "../../../../helper/checkUrls";

const otpLength = 6;
const minutes = 2;
const secounds = 0;

const AccountInfo = ({
  accountInfoData,
  setAccountInfoData,
  countryListss,
  editContact,
  setEditContact,
  stateList,
  cityList,
  fetchProfileInformation,
}) => {
  const [editAccount, setEditAccount] = useState(false);
  const [getOtp, setGetOtp] = useState(false);
  const [otpSend, setotpSend] = useState(false);
  const [clearOtp, setClearOtp] = useState(false);

  const numberOnly = (input) => input.replace(/[^0-9]/g, "");
  const accountInfo = accountInfoData?.accountInfo;
  const accountOwners = accountInfoData?.accountOwners;
  const accountContactInfo = accountInfoData?.contactInfo;

  const { currentAccount, toggleToast } = useAspSelector((state) => state?.app);
  const [data, dispatch] = useReducer(onBoardingReducer, INITIAL_STATE);

  const dispatchRedux = useAspDispatch();

  const {
    uploading,
    selectedFile,
    responseFile,
    uploadFiles,
    error,
    dimensionError,
    clearFiles,
  } = useFileUploader();

  const [
    remainingMinutes,
    remainingSeconds,
    setRemainingMinutes,
    setRemainingSeconds,
  ] = useTimerCount(0, 0);

  //styles
  const card = "w-full flex flex-col gap-1 mt-2";
  const label = "font14px-500 leading-6 text-[var(--font-600)]";
  const inputContainer =
    "w-full h-11  flex items-center gap-2 border border-[var(--border-input)] rounded-lg";
  const inputContainer1 = `w-full h-11 flex items-center gap-2 border  b-radius-6 ${
    accountInfo?.account_name?.endsWith(".") ||
    accountInfo?.account_name?.endsWith("-") ||
    accountInfo?.account_name?.endsWith("'")
      ? "border-red-500"
      : "border-[var(--border-input)]"
  }`;
  const input =
    "w-full h-full text-[20vw] text-sm weight-medium px-3 rounded-lg";

  const inputContainerNonEdit =
    "w-full h-11  flex items-center gap-2 bg-[#F9F9FC] rounded-lg px-3 text-[0.875rem] font-[500]";

  const accountTypeId = {
    1: "Marketing Agency",
    2: "Business Entity",
    3: "Content Creator",
  };

  const inputData = [
    {
      id: "account_name",
      label: "Account Name",
      sup: true,
      placeholder: "Ex:- Company org",
      value: editAccount
        ? accountInfo.account_name || ""
        : accountInfo.account_name || "--",
      type: "text",
    },
    {
      id: "account_type_id",
      label: "Account Type",
      sup: true,
      placeholder: "Select type",
      value: editAccount
        ? accountTypeId[accountInfo.account_type_id] || ""
        : accountTypeId[accountInfo.account_type_id] || "--",
      isSelect: true,
      options: accountType,
    },

    {
      id: "account_phone_number",
      label: "Phone Number",
      sup: true,
      placeholder: "EX:- 0000 000 000",
      value: editAccount
        ? `${accountInfo?.account_phone_number || ""}`
        : `${accountInfo?.account_phone_country_code || "--"} - ${
            accountInfo?.account_phone_number || "--"
          }`,
      isPhone: true,
      maxLength: 10,
    },
  ];

  const contactinputData = [
    {
      id: "account_legal_name",
      label: "Account legal name",
      sup: false,
      placeholder: "Ex:- Company org",
      value: editContact
        ? accountContactInfo?.account_legal_name || ""
        : accountContactInfo?.account_legal_name || "--",
      readOnlyValue: "hi",
      type: "text",
      optional: false,
    },
    {
      id: "website",
      label: "Website",
      sup: false,
      placeholder: "Ex:- Company org",
      value: editContact
        ? accountContactInfo?.website || ""
        : accountContactInfo?.website || "--",
      readOnlyValue: "hi",
      type: "text",
      optional: true,
    },
    {
      id: "phone_number",
      label: "Phone number",
      sup: false,
      placeholder: "Ex:- Company org",
      value: `${accountContactInfo?.phone_country_code || "--"} - ${
        accountContactInfo?.phone_number || "--"
      }`,
      readOnlyValue: "hi",
      type: "number",
      optional: true,
      maxLength: 10,
    },
    {
      id: "address_line_1",
      label: "Address / Street",
      sup:
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.address_line_1
          ? true
          : false,
      placeholder: "Ex:- Company org",
      value: editContact
        ? accountContactInfo?.address?.address_line_1 || ""
        : accountContactInfo?.address?.address_line_1 || "--",
      readOnlyValue: "hi",
      type: "text",
      optional: !(
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.address_line_1
      ),
      isAddressField: true,
    },
    {
      id: "country_id",
      label: "Country",
      sup:
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.country_id
          ? true
          : false,
      placeholder: "Select Country",
      value:
        countryListss?.find(
          (country) => country?.id === accountContactInfo?.address?.country_id
        )?.label || "--",
      isSelect: true,
      options: countryListss,
      optional: !(
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.country_id
      ),
      isAddressField: true,
    },
    {
      id: "province_id",
      label: "State",
      sup:
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.province_id
          ? true
          : false,
      placeholder: "Select state",
      value:
        stateList.find(
          (state) => state?.id === accountContactInfo?.address?.province_id
        )?.label || "--",
      isSelect: true,
      options: stateList,
      optional: !(
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.province_id
      ),
      isAddressField: true,
    },
    {
      id: "city_id",
      label: "City",
      sup:
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.city_id
          ? true
          : false,
      placeholder: "Select city",
      value:
        cityList.find(
          (city) => city?.id === accountContactInfo?.address?.city_id
        )?.label || "--",
      isSelect: true,
      options: cityList,
      optional: !(
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.postal_index_code ||
        accountContactInfo?.address?.city_id
      ),
      isAddressField: true,
    },
    {
      id: "postal_index_code",
      label: "Postal / Pin code",
      sup:
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.postal_index_code
          ? true
          : false,
      placeholder: "Enter postal code",
      value: editContact
        ? accountContactInfo?.address?.postal_index_code || ""
        : accountContactInfo?.address?.postal_index_code || "--",
      type: "number",
      optional: !(
        accountContactInfo?.address?.address_line_1 ||
        accountContactInfo?.address?.city_id ||
        accountContactInfo?.address?.province_id ||
        accountContactInfo?.address?.country_id ||
        accountContactInfo?.address?.postal_index_code
      ),
      isAddressField: true,
      maxLength: 6,
    },
  ];

  const handleChange = (field, value) => {
    const isAddressField = contactinputData.find(
      (item) => item.id === field
    )?.isAddressField;

    if (
      (field === "postal_index_code" && value?.length > 6) ||
      (field !== "account_name" && value?.length > 64) ||
      (field !== "account_legal_name" && value?.length > 64)
    ) {
      return;
    }

    if (field === "account_name" || field === "account_legal_name") {
      value = textOnly(value);
    }

    setAccountInfoData((prevInfo) => ({
      ...prevInfo,
      accountInfo: {
        ...prevInfo?.accountInfo,
        ...(isAddressField ? {} : { [field]: value }),
      },
      contactInfo: {
        ...prevInfo.contactInfo,
        ...(isAddressField
          ? {
              address: {
                ...prevInfo.contactInfo?.address,
                [field]: value,
                ...(field === "address_line_1" &&
                  value === "" && {
                    country_id: null,
                    province_id: null,
                    city_id: null,
                    postal_index_code: null,
                  }),
              },
            }
          : { [field]: value }),
      },
    }));
  };

  const isButtonDisabled = useCallback(() => {
    return (
      accountInfo?.account_name &&
      !accountInfo?.account_name?.endsWith(".") &&
      !accountInfo?.account_name?.endsWith("'") &&
      !accountInfo?.account_name?.endsWith("-") &&
      accountInfo?.account_type_name &&
      accountInfo?.account_phone_number &&
      accountInfo?.account_phone_country_code &&
      (otpSend ? accountInfo?.otp?.length === 6 : accountInfo?.otp === "") &&
      (!error || !dimensionError)
    );
  }, [accountInfo, error, dimensionError, otpSend]);

  const updateAccountInfo = (type) => {
    const isAccountUpdate = type === "account";
    const payLoad = isAccountUpdate
      ? {
          account_name: accountInfo?.account_name,
          account_type_id: accountInfo?.account_type_id,
          phone_country_code:
            accountInfo?.account_phone_country_code?.replace(/[()]/g, "") ||
            "IN +91",
          phone_number: accountInfo?.account_phone_number || null,
          logo_photo: responseFile || accountInfo?.account_image || null,
          otp: accountInfo?.otp || null,
        }
      : {
          account_name: accountContactInfo?.account_legal_name,
          website_url: accountContactInfo?.website,
          address: accountContactInfo?.address?.address_line_1
            ? {
                address_line_1: accountContactInfo.address.address_line_1,
                country_id: accountContactInfo?.address?.country_id || 1,
                province_id: accountContactInfo?.address?.province_id || 1,
                city_id: accountContactInfo?.address?.city_id || 1,
                postal_index_code:
                  accountContactInfo?.address?.postal_index_code,
                ...(accountContactInfo?.address?.id && {
                  id: accountContactInfo.address.id,
                }),
              }
            : "",
        };

    const updateFunction = isAccountUpdate
      ? accountInformationUpdate
      : accountContactInformationUpdate;

    updateFunction(currentAccount?.account_id, payLoad).then((res) => {
      if (res?.status === 200) {
        dispatchRedux(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: isAccountUpdate
                ? "Account information updated successfully"
                : "Contact information updated successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        fetchProfileInformation();
        dispatchRedux(setUpdateAccount(true));
        setEditAccount(false);
        setEditContact(false);
        setGetOtp(false);
      } else {
        dispatchRedux(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  const sendOtp = () => {
    const phoneNumber = accountInfo?.account_phone_number;
    const phoneCountryCode =
      accountInfo?.account_phone_country_code?.replace(/[()]/g, "") || "IN +91";

    const payLoad = {
      phone_number: [`${phoneNumber}`],
      phone_country_code: phoneCountryCode,
    };

    accountInformationOtp(currentAccount?.account_id, payLoad).then((res) => {
      if (res?.status === 200) {
        dispatchRedux(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "OTP has sent successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        setotpSend(true);
        setRemainingMinutes(minutes);
        setRemainingSeconds(secounds);
      } else {
        dispatchRedux(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  const onChange = (type, primaryKey, key, value) => {
    dispatch({ type, primaryKey, key, value });
  };

  // const isButtonDisabledContact = !(
  //   accountContactInfo?.account_legal_name &&
  //   (!accountContactInfo?.address?.address_line_1 ||
  //     (accountContactInfo?.address?.country_id &&
  //       accountContactInfo?.address?.province_id &&
  //       accountContactInfo?.address?.city_id &&
  //       accountContactInfo?.address?.postal_index_code?.length === 6))
  // );

  const isAddressComplete = (address) => {
    const {
      address_line_1,
      country_id,
      province_id,
      city_id,
      postal_index_code,
    } = address || {};

    const isAnyFieldFilled =
      address_line_1 ||
      country_id ||
      province_id ||
      city_id ||
      postal_index_code;

    const areAllFieldsFilled =
      address_line_1 &&
      country_id &&
      province_id &&
      city_id &&
      postal_index_code?.length === 6;

    return !isAnyFieldFilled || areAllFieldsFilled;
  };

  // const isButtonDisabledContact = !(
  //   accountContactInfo?.account_legal_name &&
  //   isAddressComplete(accountContactInfo?.address)
  // );

  const isButtonDisabledContact = !(
    accountContactInfo?.account_legal_name &&
    ((accountContactInfo?.website?.length > 0 &&
      validateURL(accountContactInfo?.website)) ||
      !accountContactInfo?.website) &&
    isAddressComplete(accountContactInfo?.address)
  );

  // const validation = useCallback(() => {
  //   return (
  //     (accountContactInfo?.account_legal_name &&
  //       !accountContactInfo?.address?.address_line_1) ||
  //     (accountContactInfo?.address?.country_id &&
  //       accountContactInfo?.address?.province_id &&
  //       accountContactInfo?.address?.city_id &&
  //       accountContactInfo?.address?.postal_index_code?.length === 6)
  //   );
  // }, []);

  return (
    <div className="bg-white b-radius-6 p-3 h-[90vh] overflow-y-scroll">
      <div className="w-[75%]">
        <>
          <div className="flex items-center">
            <h2 className="font16px-600 pr-1">Account Information</h2>
          </div>

          <div
            className={cn(
              "w-full flex items-center justify-between px-4 py-3 mt-6 bg-slate-50 rounded-[10px]",
              error || dimensionError ? "border-red-400 animate-shake" : ""
            )}
          >
            <div className="flex-row align-center">
              <div className="rounded-[10px] size-16 bg-slate-100">
                {!responseFile && !accountInfo?.account_image && (
                  <div className="p-[18px]">
                    <img
                      src={ICONS.uploadStep}
                      alt="UploadStep"
                      className="upload-image-before "
                    />
                  </div>
                )}

                {(selectedFile || accountInfo?.account_image) && (
                  <div className="relative">
                    <img
                      src={
                        responseFile ? responseFile : accountInfo?.account_image
                      }
                      alt="SelectedImage"
                      className="uploadStep-profile-image"
                    />
                    {editAccount && (
                      <img
                        className="absolute pointer -top-2 -right-2 size-4"
                        src={ICONS.Exit}
                        alt="popup"
                        onClick={() => {
                          clearFiles();
                          onChange("update", "account", "image", null);
                          if (accountInfo?.account_image) {
                            accountInfo.account_image = null;
                            onChange(
                              "update",
                              "account",
                              "account_image",
                              null
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="upload-content ml-10">
                <p className="account-image">Account Image</p>
                <p className="recommended-file ">
                  {selectedFile ? (
                    <p className="truncate max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {selectedFile?.name}
                    </p>
                  ) : (
                    <p>Recommended file 320*320 px, less than 1MB.</p>
                  )}
                </p>
              </div>
            </div>

            <>
              {editAccount && (
                <button className="">
                  <input
                    type="file"
                    // onChange={async (e) => {
                    //   const res = await uploadFiles(e, "IMAGE", 1);
                    //   if (res) {
                    //     onChange("update", "account", "image", res);
                    //   }
                    // }}
                    onChange={async (e) => {
                      const fileInput = e.target;
                      if (fileInput.files.length > 0) {
                        const res = await uploadFiles(e, "IMAGE", 1);
                        if (res) {
                          onChange("update", "account", "image", res);
                        }
                        fileInput.value = "";
                      }
                    }}
                    accept=".jpg, .jpeg, .png"
                    id="uploadImg"
                    hidden
                  />
                  <label
                    for="uploadImg"
                    className="w-full flex-center cursor-pointer"
                  >
                    <div
                      className={cn(
                        "min-w-[100px] text-sm weight-semibold text-[var(--background)] bg-[var(--new-primary)] flex items-center justify-center rounded-md px-4 py-1.5 tracking-wide"
                      )}
                    >
                      {uploading ? (
                        <Loader Width={20} Height={20} loaderBg="white" />
                      ) : (
                        <p>Upload</p>
                      )}
                    </div>
                  </label>
                </button>
              )}
            </>
          </div>
          {error && (
            <div className="w-full text-left text-xs mt-1 px-1 text-red-400">
              File Should be less than 1MB.
            </div>
          )}
          {dimensionError && (
            <div className="w-full text-left text-xs mt-1 px-1 text-red-400">
              Recommended file should be greater than 320*320 px
            </div>
          )}

          <div className="grid grid-cols-2 gap-4">
            {inputData?.map((field) => (
              <div className={cn(card)} key={field.id}>
                <label htmlFor={field.id} className={cn(label)}>
                  {field.label}
                  {field.sup && <sup className="text-red-500">*</sup>}
                </label>

                {editAccount ? (
                  field.isSelect ? (
                    <Select
                      name={field.id}
                      value={field.value}
                      options={field.options}
                      onChange={(selectedOption) => {
                        handleChange(field.id, selectedOption?.account_type_id);
                      }}
                      placeholder={field.placeholder}
                      rootClassName="w-full h-11"
                      position="bottom"
                    />
                  ) : field.isPhone ? (
                    <>
                      {!editAccount || getOtp ? (
                        <div
                          className={cn(
                            inputContainer,
                            "border border-[var(--border-input)] flex-row align-center"
                          )}
                        >
                          <div className="w-30 border-r-[1px] pl-5">
                            {/* <CountryCode
                              value={
                                accountInfo?.account_phone_country_code ||
                                "IN +91"
                              }
                              options={countryList}
                              className="!min-h-[1vh] !min-w-[8vw] !mr-5 border-none"
                              onChange={(value) => {
                                setAccountInfoData((prevData) => ({
                                  ...prevData,
                                  accountInfo: {
                                    ...prevData?.accountInfo,
                                    account_phone_country_code: `${value?.shortName} (${value?.value})`,
                                  },
                                }));
                              }}
                            /> */}
                            <span className="font14px-400 text-[var(--textBlack)]">
                              {accountInfo?.account_phone_country_code ||
                                "IN +91"}
                            </span>
                          </div>

                          <input
                            name="name"
                            id="name"
                            type="text"
                            placeholder="Ex:- Company org"
                            className={cn(input)}
                            value={field?.value || ""}
                            // onChange={(e) =>
                            //   handleChange(field.id, e.target.value)
                            // }
                            onChange={(e) => {
                              const value = numberOnly(e.target.value);
                              if (value.length <= 10) {
                                handleChange(field.id, value);
                              }
                            }}
                            autoComplete="off"
                            maxLength={40}
                          />
                        </div>
                      ) : (
                        <div
                          className={cn(
                            inputContainerNonEdit,
                            "flex justify-between"
                          )}
                        >
                          <div>
                            {`${accountInfo?.account_phone_country_code} - ${field?.value}`}
                          </div>
                          <div className="flex">
                            <img src={ICONSS?.verifiedTick} alt="verfied" />
                            <p className="font14px-500 text-[#2D3036] pl-2">
                              Verified
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="w-full flex items-center justify-between">
                        <div className="w-full flex flex-1 items-center justify-end text-sm weight-semibold gap-4">
                          {otpSend && (
                            <>
                              <p className="text-[var(--new-primary)]">
                                {remainingMinutes?.toString().padStart(2, "0")}:
                                {remainingSeconds?.toString().padStart(2, "0")}
                              </p>

                              <p
                                className={`${
                                  remainingMinutes === 0 &&
                                  remainingSeconds === 0
                                    ? "text-[#2D3036] cursor-pointer"
                                    : "text-[var(--font-600)] cursor-not-allowed"
                                }`}
                                onClick={async () => {
                                  if (
                                    remainingMinutes === 0 &&
                                    remainingSeconds === 0
                                  ) {
                                    sendOtp();
                                    setClearOtp(true);
                                  }
                                }}
                              >
                                Resend code?
                              </p>
                            </>
                          )}

                          {getOtp && !otpSend && (
                            <p
                              className={cn(
                                "text-sm weight-bold",
                                [10, 11].includes(
                                  accountInfo?.account_phone_number?.length
                                )
                                  ? "text-[var(--new-primary)] cursor-pointer"
                                  : "text-[var(--font-600)] cursor-not-allowed"
                              )}
                              onClick={() => {
                                if (
                                  [10, 11].includes(
                                    accountInfo?.account_phone_number?.length
                                  )
                                ) {
                                  sendOtp();
                                }
                              }}
                            >
                              Get OTP
                            </p>
                          )}

                          {!getOtp && (
                            <div
                              className="text-[#6940F2] font14px-600 cursor-pointer"
                              onClick={() => setGetOtp(true)}
                            >
                              Change Number
                            </div>
                          )}
                        </div>
                      </div>
                      {otpSend && (
                        <>
                          <div className="mt-6">
                            <p
                              className={cn(
                                "font14px-500",
                                accountInfo?.otp
                                  ? "text-[var(--contentTexts)]"
                                  : "text-[var(--font-600)]"
                              )}
                            >
                              Check your WhatsApp with the code
                              <sup className="text-red-500">*</sup>
                            </p>
                          </div>
                          <div className="w-full mt-4 pb-9">
                            <OtpInput
                              length={otpLength}
                              // data={accountInfo?.otp || ""}
                              // onChange={(data) => {
                              //   onChange("update", "account", "otp", data);
                              // }}
                              onChange={(value) => handleChange("otp", value)}
                              className={cn(
                                "w-11 h-11",
                                accountInfo?.otp
                                  ? "text-[var(--new-primary)]"
                                  : "text-[var(--font-600)]"
                              )}
                              clearOtp={clearOtp}
                            />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className={cn(inputContainer1)}>
                      <input
                        name={field.id}
                        id={field.id}
                        type={field.type}
                        placeholder={field.placeholder}
                        className={cn(input)}
                        value={field.value}
                        onChange={(e) => handleChange(field.id, e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  )
                ) : (
                  <>
                    {field?.value?.length > 40 ? (
                      <Tooltip title={field?.value} placement="top">
                        <div
                          className={cn(
                            inputContainerNonEdit,
                            "flex justify-between"
                          )}
                        >
                          <div className="max-w-[40ch] truncate">
                            {field.value}
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className={cn(
                          inputContainerNonEdit,
                          "flex justify-between"
                        )}
                      >
                        <div> {field.value}</div>
                        {field?.isPhone && (
                          <div className="flex">
                            <img src={ICONSS?.verifiedTick} alt="verfied" />
                            <p className="font14px-500 text-[#2D3036] pl-2">
                              Verified
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>

          {(currentAccount?.role === "Account Owner" ||
            currentAccount?.role === "Account Manager") && (
            <div className="w-100 justify-end flex-row mt-20 border-b-[1px] pb-8">
              <button
                className={` px-2 py-1.5 ${
                  editAccount && isButtonDisabled()
                    ? "button-primary"
                    : !isButtonDisabled()
                    ? "disable"
                    : "border border-[var(--border-input)] b-radius-6 font15px-500 text-[var(--font-600)]"
                }`}
                onClick={() => {
                  if (editAccount && isButtonDisabled()) {
                    updateAccountInfo("account");
                  } else {
                    setEditAccount(true);
                  }
                }}
                // disabled={isButtonDisabled}
              >
                {editAccount ? "Update Account Info" : "Edit Account Info"}
              </button>
            </div>
          )}
        </>

        <>
          <div className="flex items-center mt-8">
            <h2 className="font16px-600 pr-1">Account Owners</h2>
            <img src={ICONS?.conversationAction} alt="conversation" />
          </div>

          <div className="flex mt-10 gap-3 border-b-[1px] pb-8">
            {accountOwners?.map((data) => {
              const colors = data?.user_name
                ? getRandomColorsBassedOnLetters(data?.user_name)
                : "";
              return (
                <div className=" border px-4 rounded-lg" key={data?.user_id}>
                  <div className="flex items-center pb-4">
                    <div
                      className="w-9 h-9 rounded-full flex justify-center text-white items-center capitalize bg-red-700 mt-4"
                      style={{
                        background: colors?.backgroundColor,
                        color: colors?.color,
                      }}
                    >
                      {data?.user_name?.charAt(0) || data?.email?.charAt(0)}
                    </div>
                    <div className="pl-4">
                      <div className="flex justify-between">
                        <p className="font15px-600 mt-4">
                          {data?.user_name ? data?.user_name : "--"}
                        </p>
                        <div className="flex justify-end">
                          {data?.priority === 1 && (
                            <img
                              src={ICONSS?.accountOwnerPriority}
                              className="w-[2vw]"
                            />
                          )}
                        </div>
                      </div>
                      <p className="font13px-500 text-[#2D3036] pt-3">
                        {data?.email}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>

        <>
          <div className="flex items-center mt-8">
            <h2 className="font16px-600 pr-1">Contact Information</h2>
            <img src={ICONS?.conversationAction} alt="conversation" />
          </div>

          <div className="grid grid-cols-2 gap-4">
            {contactinputData?.map((field) => (
              <div className={cn(card)} key={field.id}>
                <label htmlFor={field.id} className={cn(label)}>
                  {field.label}
                  {field.sup && <sup className="text-red-500">*</sup>}
                  {field.optional && (
                    <span className="font14px-500 pl-1">(Optional)</span>
                  )}
                </label>

                {editContact && field?.id !== "phone_number" ? (
                  field.isSelect ? (
                    <Select
                      name={field.id}
                      value={field.value}
                      options={field.options}
                      onChange={(selectedOption) => {
                        handleChange(field.id, selectedOption?.id);
                      }}
                      placeholder={field.placeholder}
                      rootClassName="w-full h-11"
                      position="bottom"
                    />
                  ) : (
                    <div
                      className={cn(
                        inputContainer,
                        field?.id === "website" &&
                          field?.value?.length > 0 &&
                          !validateURL(field.value) &&
                          "border-red-500"
                      )}
                    >
                      <input
                        name={field.id}
                        id={field.id}
                        type={field.type}
                        placeholder={field.placeholder}
                        // maxLength={field.maxLength}
                        className={cn(input)}
                        value={field.value}
                        onChange={(e) => {
                          handleChange(field.id, e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  )
                ) : (
                  <>
                    {field?.value?.length > 40 ? (
                      <Tooltip title={field?.value} placement="top">
                        <div className={cn(inputContainerNonEdit)}>
                          <p className="max-w-[40ch] truncate">
                            {field?.value}
                          </p>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={cn(inputContainerNonEdit)}>
                        {field?.value}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>

          {(currentAccount?.role === "Account Owner" ||
            currentAccount?.role === "Account Manager") && (
            <div className="w-100 justify-end flex-row mt-20">
              <button
                className={` px-2 py-1.5 ${
                  editContact && !isButtonDisabledContact
                    ? "button-primary"
                    : editContact && isButtonDisabledContact
                    ? "disable-button cursor-not-allowed"
                    : "border border-[var(--border-input)] b-radius-6 font15px-500 text-[var(--font-600)]"
                }`}
                onClick={() => {
                  if (editContact && !isButtonDisabledContact) {
                    updateAccountInfo("contact");
                  } else {
                    setEditContact(true);
                  }
                }}
              >
                {editContact ? "Update Info" : "Edit Info"}
              </button>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default AccountInfo;
