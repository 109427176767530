import React from 'react';

export default function Broadcast({
  width = 22,
  height = 22,
  color = 'white',
  strokeWidth = '2.16',
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_19090_125088)'>
        <path
          d='M17.057 6.82699C17.8868 6.82699 18.6826 7.15663 19.2694 7.74339C19.8562 8.33015 20.1858 9.12597 20.1858 9.95578C20.1858 10.7856 19.8562 11.5814 19.2694 12.1682C18.6826 12.7549 17.8868 13.0846 17.057 13.0846M8.71358 6.82699L8.71358 18.2992C8.71358 18.5758 8.6037 18.8411 8.40812 19.0367C8.21253 19.2323 7.94726 19.3421 7.67066 19.3421H6.62773C6.35113 19.3421 6.08585 19.2323 5.89027 19.0367C5.69468 18.8411 5.5848 18.5758 5.5848 18.2992L5.5848 13.0846M10.7994 6.82699L15.5176 2.89515C15.6547 2.78095 15.8216 2.7082 15.9985 2.68542C16.1755 2.66264 16.3553 2.69076 16.5168 2.7665C16.6784 2.84224 16.815 2.96246 16.9107 3.11307C17.0063 3.26367 17.0571 3.43843 17.057 3.61685V16.2947C17.0571 16.4731 17.0063 16.6479 16.9107 16.7985C16.815 16.9491 16.6784 17.0693 16.5168 17.145C16.3553 17.2208 16.1755 17.2489 15.9985 17.2261C15.8216 17.2033 15.6547 17.1306 15.5176 17.0164L10.7994 13.0846L2.45601 13.0846C2.17941 13.0846 1.91414 12.9747 1.71855 12.7791C1.52297 12.5835 1.41309 12.3182 1.41309 12.0416L1.41309 7.86991C1.41309 7.59331 1.52297 7.32804 1.71855 7.13245C1.91414 6.93687 2.17941 6.82699 2.45601 6.82699L10.7994 6.82699Z'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      {/* <defs>
        <clipPath id='clip0_19090_125088'>
          <rect y='0.199219' width='21.6' height='21.6' rx='3.24' fill={fill} />
        </clipPath>
      </defs> */}
    </svg>
  );
}
