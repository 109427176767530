import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Popover } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

// assets
import { ICONS } from 'assets/icons';

// api
import { broadcastList } from 'api/apiClients/campaign';

// hooks
import useToggle from 'hooks/useToggle';

// redux
import { useAspSelector } from 'test/jest-redux-hooks';

// components
import Loader from '../../../../../../components/commonComponents/Loader/Index';
import moment from 'moment';

let count = 0;
let page = 1;
let limit = 10;

export default function Existing({
  data,
  onChange,
  selectedBroadcast,
  setSelectedBroadcast,
  isOpen,
  setIsOpen,
}) {
  const { campaign_id } = useParams();

  const { currentBrand } = useAspSelector((state) => state.app);

  const [isLoading, setIsLoading] = useToggle(false);
  const [isFilter, setIsFilters] = useToggle(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    const payload = {
      page: page,
      limit: limit,
      type: 'broadcast',
      status: 'SCHEDULED',
    };

    getCampaignList(false, currentBrand?.brand_id, campaign_id, payload);
  }, []);

  const getCampaignList = async (loading, brand_id, id, payload) => {
    if (loading) setIsLoading(true);
    else setIsFilters(true);
    try {
      const res = await broadcastList(brand_id, id, payload);
      count = res?.data?.count;
      setList(res?.data?.data);
      const selected = res?.data?.data?.filter((b) => b?.id === data?.data?.id);
    } catch (error) {
      console.warn(error);
    } finally {
      if (loading) setIsLoading(false);
      else setIsFilters(false);
    }
  };

  const loadNextPage = async () => {
    limit += 10;
    const payload = {
      page: page,
      limit: limit,
      type: 'broadcast',
      status: 'SCHEDULED',
    };
    await getCampaignList(
      isLoading,
      currentBrand?.brand_id,
      campaign_id,
      payload
    );
  };

  const selectOption = (option) => {
    if (option?.id !== selectedBroadcast?.id) {
      setSelectedBroadcast(option);
      onChange({
        type: 'UPDATE_NODE',
        id: data?.uniq_id,
        key: 'data',
        data: { broadcast_id: option?.id },
      });
    }
  };

  const isOptionSelected = (option) => {
    return option?.id === selectedBroadcast?.id;
  };

  return (
    <div className='flex flex-col gap-1.5'>
      <p>Broadcast</p>
      <Popover
        trigger={['click']}
        open={isOpen}
        placement='bottomLeft'
        arrow={false}
        onOpenChange={(open) => setIsOpen(open)}
        content={
          <ul
            className={`w-full min-w-[25rem] m-0 list-none rounded-2xl flex-col gap-1.5 overflow-y-scroll listScroll bg-[var(--white)] z-50 p-2.5`}>
            <InfiniteScroll
              dataLength={list?.length}
              next={() => {
                loadNextPage();
              }}
              hasMore={list?.length < count}
              scrollableTarget='scrollableDiv'
              height={isLoading ? '200px' : '200px'}
              className='!pr-1 mb-2 listScroll'>
              {list?.length > 0 ? (
                list?.map((option) => {
                  return (
                    <li
                      key={option?.id}
                      className='w-full h-[80px] flex items-center justify-between px-2.5 py-1.5 border-b border-[var(--border-100)] last:border-0 cursor-pointer'
                      onClick={(event) => {
                        selectOption(option);
                        setIsOpen(false);
                      }}>
                      <div className='flex flex-col gap-2.5'>
                        <div className='flex items-center justify-between gap-5'>
                          <p className='w-full max-w-56 truncate'>
                            {option?.name}
                          </p>
                          {isOptionSelected(option) && (
                            <img
                              src={ICONS?.Selected}
                              alt='selected'
                              className='size-5'
                            />
                          )}
                        </div>
                        <div className='flex items-center gap-5 justify-between'>
                          <div className='flex items-center gap-1.5 text-sm weight-medium text-[#616874]'>
                            <p>Ph. No:</p>
                            <p>
                              {option?.settings?.country_code}{' '}
                              {option?.settings?.send_from_phone_number}
                            </p>
                          </div>
                          <div className='flex items-center gap-1.5 text-xs weight-semibold text-[#616874]'>
                            <p>Starts:</p>
                            <p className='px-2.5 py-0.5 bg-[var(--primary)] rounded-lg'>
                              {/* 24 Jul, 2024 */}
                              {option?.settings?.send_setting === 'send_later'
                                ? moment(
                                    option?.settings?.send_later_dt
                                  ).format('DD MMM, YYYY')
                                : moment().format('DD MMM, YYYY')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className='w-full h-full flex items-center justify-center'>
                  No Data
                </div>
              )}
              {(isFilter || isLoading) && (
                <div className='w-full h-auto flex items-center justify-center py-2.5'>
                  <Loader
                    Width={25}
                    Height={25}
                  />
                </div>
              )}
            </InfiniteScroll>
          </ul>
        }>
        <div
          className='w-full h-12 flex items-center justify-between border boder-[var(--border-50)] px-5 py-1 rounded-lg cursor-pointer'
          onClick={(event) => {
            setIsOpen(!isOpen);
          }}>
          {selectedBroadcast ? (
            <div
              className={`flex items-center gap-2.5 text-[var(--contentText)] w-full overflow-y-scroll`}>
              {selectedBroadcast?.name}
            </div>
          ) : (
            <div className='w-full flex items-center font-md weight-medium text-[var(--font-300)]'>
              Please select
            </div>
          )}

          <div
            className={`transition-transform duration-200 ease-linear cursor-pointer ${
              isOpen ? '-rotate-180' : 'rotate-0'
            }`}>
            <div
              className={`border rounded-t-1.5 border-transparent border-t-[#777] translate-y-[25%]`}></div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
