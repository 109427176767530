import React, { useState } from "react";
import "./Input.css";

import { ICONS } from "../../../assets/icons/index";

export const Input = ({
  primary,
  backgroundColor,
  size,
  placeholder,
  iconPrefix,
  iconprefixstyle,
  iconSuffix,
  iconSuffixPassword,
  onChange,
  value,
  type,
  readOnly,
  disabled,
  name,
  rightDropDown,
  handleChange,
  maxLength,
  autoComplete,
  ...props
}) => {
  const [showEye, setShowEye] = useState(false);
  return (
    <div className={`storybook-input-container`} style={{ backgroundColor }}>
      {iconPrefix && (
        <img
          alt="leftImage"
          className="leftImage"
          src={iconPrefix}
          style={iconprefixstyle}
        />
      )}
      <input
        className="storybook-input"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        aria-label={name}
        type={type === "password" ? (showEye ? "text" : "password") : type}
        {...props}
        maxLength={maxLength}
        autoComplete={autoComplete}
      />
      {iconSuffix && (
        <img
          alt="rightImage1"
          className="rightImage1"
          src={iconSuffix}
          onChange={handleChange}
        />
      )}
      {iconSuffixPassword && (
        <img
          alt="rightImage"
          className="rightImage"
          src={showEye ? iconSuffixPassword : ICONS.passwordEyeClose}
          onClick={() => setShowEye(!showEye)}
        />
      )}
    </div>
  );
};
