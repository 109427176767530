import { useCallback, useEffect, useState } from 'react';

import useToggle from './useToggle';

export default function useList({ data }) {
  const [lists, setLists] = useState(data);
  const [selectedRows, setSelectedRows] = useState([]);
  const [intermediate, setIntermediate] = useToggle(false);
  const [selectAll, setSelectAll] = useToggle(false);

  useEffect(() => {
    const isAllRowSelected = checkAllRowsAreSelected();
    if (isAllRowSelected) {
      setSelectAll(true);
      setIntermediate(false);
    } else if (!isAllRowSelected && selectedRows?.length > 0) {
      setIntermediate(true);
      setSelectAll(false);
    } else if (!isAllRowSelected && selectedRows?.length === 0) {
      setIntermediate(false);
      setSelectAll(false);
    } else if (!isAllRowSelected && selectedRows?.length === lists?.length) {
      setIntermediate(false);
      setSelectAll(true);
    }
  }, [lists, selectedRows]);

  const checkAllRowsAreSelected = () => {
    const res = lists?.map((l) => isRowSelected(l?.id));
    return res?.length > 0 && selectedRows?.length === lists?.length
      ? true
      : false;
  };

  const isRowSelected = useCallback(
    (id) => {
      const isSelected = selectedRows.filter((r) => r === id);
      return isSelected?.length > 0 ? true : false;
    },
    [selectedRows]
  );

  const toggleSelectAll = useCallback(
    (value) => {
      if (value) {
        const selectAllRows = lists?.map((l) => l?.id);
        setSelectedRows(selectAllRows);
      } else {
        setSelectedRows([]);
      }
      setSelectAll(value);
    },
    [lists]
  );

  const onSelectRow = useCallback(
    (row) => {
      if (selectedRows?.includes(row?.id)) {
        const rows = selectedRows?.filter((r) => r !== row?.id);
        setSelectedRows(rows);
      } else {
        setSelectedRows([...selectedRows, row?.id]);
      }
    },
    [selectedRows, intermediate, lists]
  );

  const getSelectedLists = useCallback(() => {
    const res = lists?.filter((l) => isRowSelected(l?.id));
    return res;
  }, [lists, selectedRows]);

  return {
    lists,
    setLists,
    selectAll,
    setSelectAll,
    intermediate,
    setIntermediate,
    selectedRows,
    setSelectedRows,
    onSelectRow,
    checkAllRowsAreSelected,
    isRowSelected,
    toggleSelectAll,
    getSelectedLists,
  };
}
