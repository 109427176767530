import React, { useState } from "react";
import Underline from "../underline";
import { crmFontColor } from "../../../constants";
import Tick from "../../../../../assets/customSVG/Check";
import { Popover } from "antd";
import { ICONS } from "../../../../../assets/icons";
import { contactSourceIcons } from "../../../../../constant/app/crm/crm";
const index = ({
  button,
  onCancel,
  editFilter = () => {},
  data = [],
  heading = "COLUMNS",
  width = "min-w-[11vw]",
  height = "max-h-[50vh]",
  containerClassNames = "",
  content = "",
  showCheck = () => {
    return false;
  },
  ...rest // contains open
}) => {
  const [visible, setVisible] = useState(false);
  const handleItemClick = (id, item) => {
    editFilter(id, item);
    setVisible(false);
  };

  const handle = (item) => {
    setVisible(item);
  };
  return (
    <Popover
      {...rest}
      arrow={false}
      placement="bottomRight"
      trigger="click"
      open={visible}
      onOpenChange={handle}
      onClick={() => setVisible(!visible)}
      onCancel={onCancel}
      content={
        <div
          className={`rounded-md bg-white text-[.68vw] ${height} overflow-scroll ${width} px-[.5vw] capitalize crm-dropdown scroll_visible py-[1vh] ${containerClassNames}`}
        >
          {heading && (
            <h3 className={`text-[${crmFontColor}] text-[.65vw] mb-[1vh]`}>
              {heading}
            </h3>
          )}
          {content
            ? content
            : data?.map((item, index) => {
                const {
                  label,
                  name,
                  id,
                  selected,
                  default: def,
                  icon: Icon,
                  ...rest
                } = item;

                // const Icon1 = contactSourceIcons[name];

                const check = showCheck({ label, name, id });
                return (
                  <React.Fragment key={label}>
                    <div
                      className=" duration-200 bg-ed-300 py-[.7vw] px-[.5vw] cursor-pointer hover:bg-gray-100 rounded-lg flex justify-between items-center"
                      // onClick={() => {
                      //   editFilter(id, { label, selected });
                      // }}
                      onClick={() =>
                        handleItemClick(id, { label, name, selected })
                      }
                    >
                      <div className="flex items-center gap-[.5vw]">
                        {Icon && <Icon />}
                      
                        <p className="">{name ? name : label}</p>
                      </div>

                      <span
                        className={`${
                          selected || check ? "scale-100" : "scale-0"
                        } duration-200`}
                      >
                        <Tick />
                      </span>
                      {/* {selected && } */}
                    </div>
                    {index < data?.length - 1 && (
                      <Underline className="my-[.5vh]" />
                    )}
                  </React.Fragment>
                );
              })}
        </div>
      }
    >
      {button}
    </Popover>
  );
};

export default index;
