import moment from 'moment';

var monthsFullName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

var monthsShortName = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function dateConverter(value, utc = false) {
  const d = utc ? moment(value)?.utc() : moment(value);

  const date = d.date();
  const day = d.day();
  const month = d.month() + 1;
  const monthInFull = monthsFullName[d.month()];
  const monthInShort = monthsShortName[d.month()];
  const year = d.year();

  const hours = d.hour();
  const formattedHours = hours % 12 || 12;
  const minutes = d.minute();
  const milliSec = d.millisecond();
  const meridiem = hours > 12 ? 'PM' : 'AM';
  const formatedDate = d.format('MMMM DD, YYYY');
  const formatedShortDate = d.format('MMM DD, YYYY');
  const formatedDateWithYear = d.format('YYYY-MM-DD');
  const formatedDateWithoutYear = d.format('MMMM, DD');
  const formatedDateWithoutDate = d.format('MMM, YYYY');

  const timeWithMeridiem = d.format('hh:mm A');

  return {
    date,
    day,
    month,
    monthInFull,
    monthInShort,
    year,
    hours,
    formattedHours: formattedHours?.toString().padStart(2, '0'),
    minutes,
    milliSec,
    meridiem,
    timeWithMeridiem,
    formatedDate,
    formatedShortDate,
    formatedDateWithYear,
    formatedDateWithoutYear,
    formatedDateWithoutDate,
  };
}

export function convertToCustomFormat(dateString) {
  const date = new Date(dateString);

  // Get local time zone offset in minutes
  const timezoneOffset = date.getTimezoneOffset();

  // Adjust the time to local time based on the offset
  const localDate = new Date(date.getTime() - timezoneOffset * 60000);

  // Extract the components of the date
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0');
  const day = String(localDate.getDate()).padStart(2, '0');
  const hours = String(localDate.getHours()).padStart(2, '0');
  const minutes = String(localDate.getMinutes()).padStart(2, '0');
  const seconds = String(localDate.getSeconds()).padStart(2, '0');
  const milliseconds = String(localDate.getMilliseconds()).padStart(3, '0');

  // Get the offset in hours and minutes from UTC (e.g., "+05:30")
  const offsetHours = String(Math.abs(timezoneOffset) / 60).padStart(2, '0');
  const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
  const offsetSign = timezoneOffset > 0 ? '-' : '+';
  const timezone = `${offsetSign}${offsetHours}:${offsetMinutes}`;

  // Combine into the final format
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezone}`;
}
