import React, { useEffect, useState } from "react";

// antd
import { Modal, Skeleton } from "antd";

// components
import PhoneNumbersCard from "./PhoneNumbersCard";
import { ICONS } from "../../../../assets/icons";
import Header from "../../../../components/commonComponents/modals/Header";

import { inboxSidebar } from "../../../../constant/app/inbox/Inbox";

import CountryCode from "components/commonComponents/countryCode/index";
import { countryList } from "constant/app/countryList";
import { Button } from "../../../../components/form/Button/Button";
import { validateEmail } from "../../../../helper/validation";
import SubSideBar from "../../../../components/commonComponents/subSideBar";
import SendForm from "../../../campaign/components/BroadcastSetting/SendForm";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import useToggle from "../../../../hooks/useToggle";
import TemplateListContainer from "../../../../components/commonComponents/templates/TemplateListContainer";

import {
  FEATURE_FLAG_TEMPLATE_CHANGE_MEDIA,
  FEATURE_FLAG_TEMPLATE_CHANGE_VARIABLE,
} from "../../constants/FeatureFlag";
import { getLanguageName } from "../../../../helper/setLanguageCode";
import { createConversationInbox } from "../../api/Api";
import { formatCarouelDataToAPI } from "../../../channels/utils/carousel";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";

const category = {
  MARKETING: "Marketing",
  UTILITY: "Utility",
};

const cleanParams = (value) =>
  value?.replaceAll("-", "")?.replaceAll(" ", "")?.trim();

export default function SideBar({
  list,
  handleSelect,
  className,
  handleClickNumbers,
  selectedRole,
  setSelectedRole,
  activeTabName,
  setActiveTabName,
  setActiveTabItem,
  loading,
  listCounts,
}) {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openTemplateList, setOpenTemplateList] = useToggle(false);
  const [selectTemplate, setSelectTemplate] = useState(null);
  const [selectTemplateData, setSelectTemplateData] = useState(null);
  const [isSending, setIsSending] = useToggle(false);

  const [selectedCode, setSelectedCode] = useState("+91");

  // Handle change in country code
  const handleCountryChange = (value) => {
    setSelectedCode(value);
  };

  const countryCode = selectedCode?.value?.replace("+", "") || "";

  const { currentBrand, toggleToast } = useAspSelector((state) => state?.app);

  const initialData = [
    { id: 1, "First Name": "", label: "First Name" },
    { id: 2, "Last Name": "", label: "Last Name" },
    { id: 3, "Phone Number": "", label: "Phone Number" },
    { id: 4, Email: "", label: "Email" },
  ];

  const [dynamicInputs, setDynamicInputs] = useState(initialData);

  const handleChange = (id, value) => {
    setDynamicInputs((prevInputs) =>
      prevInputs.map((input) => (input.id === id ? { ...input, value } : input))
    );
    if (id === 4) {
      if (!validateEmail(value) && value !== "") {
        setErrorMessage("Enter a valid email address");
      } else {
        setErrorMessage("");
      }
      if (value.length > 40) {
        return;
      }
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      handleSelect(list?.[0]);
    }
  }, [list]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setOpenTemplateList(false);
    setSelectTemplate(null);
    setDynamicInputs(initialData);
  };

  const navListSelect = (item) => {
    setActiveTabItem(item);
    setActiveTabName(item?.name || item?.send_from_phone_name);
  };

  const values = dynamicInputs.map(({ value }) => value);

  const startConversation = () => {
    const payLoad = {
      brand_id: currentBrand?.brand_id,
      first_name: dynamicInputs?.[0]?.value,
      last_name: dynamicInputs?.[1]?.value,
      mobile_number: dynamicInputs?.[2]?.value,
      mobile_number_code: countryCode || 91,
      email: dynamicInputs?.[3]?.value?.length > 0 && dynamicInputs?.[3]?.value,
      from_number: selectedRole?.[0]?.send_from_phone_number_id,
      subcategory_type: selectTemplate?.subcategory_type,
      template_id: selectTemplate?.message_template_id,
      variables: selectTemplateData?.variables,
    };

    createConversationInbox(payLoad).then((res) => {
      if (res?.status === 200) {
        setOpenTemplateList(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Conversation created successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Conversation creation failed",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  return (
    <div
      className={`w-[17.5vw] h-full overflow-y-scroll bg-[var(--background)] border border-[var(--border-input)]  px-[0.5vw] ${className}`}
    >
      {/* <span>Inbox</span> */}
      {/* <button
        style={{
          // cursor: "not-allowed",
          backgroundColor: "var(--new-primary)",
          color: "white",
          fontSize: "0.975rem",
          fontWeight: "500",
          // padding: "7px",
          borderRadius: "20px",
          width: "100%",
        }}
        onClick={handleClick}
      >
        <span className="flex-row align-center p-10">
          <img
            src={ICONS.createInboxNew}
            alt="speakerphoneWhite"
            className="w-5 mr-5 ml-5"
          />
          Create Conversation
        </span>
      </button> */}
      <div className="">
        <SubSideBar
          list={inboxSidebar}
          activeNav={activeTabName}
          onSelect={(item) => navListSelect(item)}
          className=" border-none !px-0.5"
          numberDetails={list}
          loading={loading}
          listCounts={listCounts}
        />
      </div>
      {/* <Modal
        open={open}
        afterOpenChange={(open) => {
          setOpen(open);
        }}
        width={"38vw"}
        // centered
        footer={null}
        closable={false}
        wrapClassName={"bg-[#00000095]"}
        destroyOnClose={true}
        trigger="click"
      >
        <Header
          title="Create new conversation"
          subTitle="Build relationships with your audience through sequences."
          handleCancel={handleCancel}
        />
        <div className="w-100 flex-column prl-20 ptb-10">
          {dynamicInputs.map((item) => (
            <div className="flex-column mt-5">
              <span className="mt-5 input-label-text">
                {item?.label}
                {item?.label !== "Email" && item?.label !== "Last Name" && (
                  <span className="text-[red]"> * </span>
                )}
              </span>

              <div className="flex-row align-center mt-10 mb-5">
                {item.id === 3 && (
                  <CountryCode
                    value={"+91"}
                    options={countryList}
                    className="!min-h-[4.5vh] !min-w-[7vw] !mr-5"
                    onChange={handleCountryChange}
                  />
                )}
                <div
                  key={item.id}
                  className="w-100 flex-row align-center border border-[var(--border-input)] rounded-lg"
                >
                  <input
                    type={item.id === 3 ? "number" : "text"}
                    className="w-100 h-[2.5rem] rounded-lg pl-10"
                    value={item?.value || ""}
                    placeholder={item.label}
                    onChange={(e) => {
                      if (item.id === 3) {
                        if (e.target.value.length <= 10) {
                          handleChange(item.id, e.target.value);
                        }
                      } else if (item?.id === 4) {
                        handleChange(item.id, e.target.value);
                      } else {
                        handleChange(item.id, e.target.value);
                      }
                    }}
                    autoComplete="off"
                    maxLength={62}
                  />
                </div>
              </div>
              <span
                style={{ color: "red" }}
                className="text-[.8vw] font-[500] pt-2"
              >
                {item?.id === 4 && errorMessage}
              </span>
            </div>
          ))}
          <SendForm
            numberDetails={list}
            handleClick={handleClickNumbers}
            setSelectedRole={setSelectedRole}
            list={selectedRole}
            // selectedItemId={selectedItemId}
            // setSelectedItemId={setSelectedItemId}
            // broadCasts={broadCasts}
            className="!w-[38vw]"
            spanClass="!hidden"
            inputClass="!mt-2"
          />
          <div className="flex-column  mt-10">
            <span className="mb-10 input-label-text flex-row gap-1">
              Select Template
              <span className="text-[red]"> * </span>
            </span>

            {!selectTemplate ? (
              <div
                className="w-100 gap-2 flex-row align-center justify-center border border-[var(--border-input)] rounded-lg sidebar-sub-header ptb-10 pointer"
                onClick={() => setOpenTemplateList(true)}
              >
                <img
                  src={ICONS?.chooseTemplateInbox}
                  alt="chooseTemplateInbox"
                />
                <span>Choose Template</span>
              </div>
            ) : (
              <div className="w-100 bg-[var(--BG-25)] b-radius-6 flex-column p-10">
                <div className="w-100 flex space-between ">
                  <span className="popover-title flex-row align-center gap-2">
                    <img
                      src={
                        selectTemplate?.category === "MARKETING"
                          ? ICONS?.marketingInbox
                          : ICONS?.utilityInbox
                      }
                      alt="sync"
                      className="w-7"
                    />
                    {selectTemplate?.name}
                  </span>
                  <span
                    className="bg-[white] flex-row align-center gap-1 rounded-md px-2 py-0.5 inbox-conversation1 pointer"
                    onClick={() => setOpenTemplateList(true)}
                  >
                    <img src={ICONS?.syncChange} alt="sync" className="w-4" />
                    Change
                  </span>
                </div>
                <div className="w-[30%] pt-10 flex-row space-between">
                  <span
                    className={`bg-[white] rounded-md px-2 py-0.5 text-[#ED4F9D] ${
                      selectTemplate?.category === "MARKETING"
                        ? "marketing-title-13"
                        : "utility-title-13"
                    }`}
                  >
                    {category[selectTemplate?.category]}
                  </span>
                  <span className="border-r-2" />
                  <span className="bg-[white] rounded-md px-2 py-0.5 language-title-13">
                    {getLanguageName(selectTemplate?.language)}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="w-100 flex-row align-end justify-end mt-20">
            {values?.[0]?.length > 0 &&
            values?.[2]?.length > 0 &&
            selectTemplate ? (
              <button
                className="button-title prl-10 ptb-5 b-radius-6"
                onClick={startConversation}
              >
                Start Conversation
              </button>
            ) : (
              <span className="disable-button">Start Conversation</span>
            )}
          </div>
        </div>
      </Modal> */}
      <Modal
        open={openTemplateList}
        trigger={["click"]}
        afterOpenChange={(open) => {
          setOpenTemplateList(open);
        }}
        centered
        footer={null}
        closable={false}
        mask={true}
        wrapClassName={"bg-[#00000095]"}
        onCancel={handleCancel}
        destroyOnClose
        width={"82vw"}
        style={{
          width: "100%",
          borderRadius: 20,
          boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
        }}
      >
        <Header
          title="Templates"
          titleSize="largest"
          rootStyle="!px-[1.5vw] !py-[1vw] gap-2"
          handleCancel={handleCancel}
          className="new-header"
          prefix={ICONS?.templateInoboxIcon}
        />

        <TemplateListContainer
          className="h-[58vh]"
          isSending={isSending}
          setIsSending={setIsSending}
          changeMedia={FEATURE_FLAG_TEMPLATE_CHANGE_MEDIA}
          changeVariable={FEATURE_FLAG_TEMPLATE_CHANGE_VARIABLE}
          open={open}
          setSelectTemplate={setSelectTemplate}
          setOpenTemplateList={setOpenTemplateList}
          sendTemplate={(data) => {
            const {
              bodyVariables = [],
              headerVariable = [],
              message_template_id,
              subcategory_type,
              ...rest
            } = data;

            let variables = [...new Set([...bodyVariables, ...headerVariable])];

            variables = Object.fromEntries(
              variables.map((item) => [
                item?.title?.replace(/{{|}}/g, ""),
                item?.value,
              ])
            );
            const updatedTemplate = {
              ...rest,
              variables,
              message_template_id,
              subcategory_type,
            };

            setSelectTemplateData(updatedTemplate);
            setOpenTemplateList(false);
          }}
        />
      </Modal>
    </div>
  );
}

{
  /* <Skeleton
        rootClassName="w-[12.8vw]"
        loading={loading}
        paragraph={{ rows: 15 }}
        active
      >
        {list?.map((item) => {
          return (
            <PhoneNumbersCard
              data={item}
              selectedPhoneNumber={selectedPhoneNumber}
              key={item?.id}
              handleSelect={handleSelect}
            />
          );
        })}
      </Skeleton> */
}
