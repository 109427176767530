import React, { useEffect, useReducer, useState } from "react";

// utils
import { useLocation, useNavigate } from "react-router-dom";

// hooks
import useToggle from "../../../hooks/useToggle";
import {
  INITIAL_STATE,
  onBoardingReducer,
} from "../../../hooks/auth/onBoardingReducer";

// redux
import { currentUserDetails } from "../../../api/Api";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  updateCurrentAccount,
  updateCurrentBrand,
  updateCurrentUser,
} from "../../../reduxToolkit/appSlice";

// components
import HorizontalSteps from "../components/HorizontalSteps";
import AccountCreation from "../components/onboarding/AccountCreation";
import BrandCreation from "../components/onboarding/BrandCreation";
import TeamInvite from "../components/onboarding/TeamInvite";
import { textOnly, textsOnly } from "../../../helper/validation";

export default function OnBoarding() {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, acc_id, brand_id } = useAspSelector(
    (state) => state.app
  );

  const AspDispatch = useAspDispatch();

  const [data, dispatch] = useReducer(onBoardingReducer, INITIAL_STATE);
  const [showOnUnloadPopup, setshowOnUnloadPopup] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);
  const [loading, setLoading] = useState(false);

  const [otpError, setOtpError] = useState("");

  const [brandPopover, setBrandPopover] = useState(false);

  useEffect(() => {
    const TOKEN = localStorage.getItem("authToken");
    if (!TOKEN) {
      localStorage.clear();
      navigate("/login");
    }
    fetchCurrentUserDetails();
  }, []);

  const fetchCurrentUserDetails = async () => {
    const res = await currentUserDetails();
    if (res?.status === 200) {
      AspDispatch(updateCurrentUser(res?.data));
      if (location.pathname.startsWith("/profile")) {
        onChange("update_step", null, "step", 0);
      } else {
        if (res?.data?.active_accounts?.length > 0) {
          AspDispatch(updateCurrentAccount(res?.data?.active_accounts?.[0]));
          if (res?.data?.active_accounts?.[0]?.brands?.length > 0) {
            onChange("update_step", null, "step", 2);
            AspDispatch(
              updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
            );
            setIsLoading(false);
          } else {
            onChange("update_step", null, "step", 1);
            setIsLoading(false);
          }
        } else {
          onChange("update_step", null, "step", 0);
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((data?.step > 0 && !showOnUnloadPopup) || data?.step === 0) return;
    else {
      const handleonBeforeUnload = (e) => {
        e.preventDefault();
        return (e.returnValue = "");
      };

      window.addEventListener("beforeunload", handleonBeforeUnload, {
        capture: true,
      });

      return () => {
        window.removeEventListener("beforeunload", handleonBeforeUnload, {
          capture: true,
        });
      };
    }
  }, [data, showOnUnloadPopup]);

  useEffect(() => {
    checkValueHasChanged();
  }, [data]);

  const checkValueHasChanged = () => {
    const datas = JSON.stringify(INITIAL_STATE) !== JSON.stringify(data);
    setshowOnUnloadPopup(datas);
  };

  const onChange = (type, primaryKey, key, value) => {
      if (key === "firstname") {
        value = textsOnly(value.trimStart());
      }

      if (key === "lastname") {
        value = textsOnlyNoSpaces(value.trimStart());
      }
    if (
      (primaryKey === "account" && key === "name") ||
      (primaryKey === "brand" && key === "name")
    ) {
      value = textOnly(value);
    }
    setOtpError("");
    setIsLoading(false);
    dispatch({ type, primaryKey, key, value });
  };

  return (
    <div
      className={`flex flex-col items-center justify-center overflow-hidden $${
        location.pathname.startsWith("/profile")
          ? "w-100"
          : " w-full max-w-[500px]"
      }`}
    >
      <div className="w-100 pt-8 font24px-600 text-[var(--textBlack)] text-center">
        {data?.step === 0 ? (
          <span>Let`s Setup Your Account</span>
        ) : data?.step === 1 ? (
          <span>Invite & Collaborate with Your Team Members</span>
        ) : (
          <span>Let`s Setup Your Brand</span>
        )}
      </div>
      <div className="w-full my-2">
        <HorizontalSteps data={data} />
      </div>
      <div className="w-full min-h-[55vh] h-auto max-h-[67vh] overflow-y-scroll listScroll pr-1 pb-9">
        {data?.step === 0 ? (
          <AccountCreation
            data={data?.account}
            onChange={onChange}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setOtpError={setOtpError}
            otpError={otpError}
            setLoading={setLoading}
            loading={loading}
          />
        ) : data?.step === 1 ? (
          <TeamInvite
            data={data?.teams}
            onChange={onChange}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            acc_id={acc_id}
            brand_id={brand_id}
          />
        ) : (
          data?.step === 2 && (
            <BrandCreation
              data={data?.brand}
              onChange={onChange}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              acc_id={acc_id}
              setBrandPopover={setBrandPopover}
              setOtpError={setOtpError}
              otpError={otpError}
            />
          )
        )}
      </div>
    </div>
  );
}
