import React from 'react';

// assets
import { ICONS } from '../../../../../assets/icons';

// helpers
import { cn } from '../../../../../helper/cn';
import { convertSecondsToHours, convertToK } from '../../../helpers';

// components
import Tooltip from '../../../../../components/commonComponents/tooltip';

const Cards = ({ data }) => {
  return (
    <div className='w-full h-full bg-white rounded-2xl px-4 py-9 flex flex-col justify-center gap-3'>
      <div className='flex flex-col gap-2.5'>
        <img
          src={data?.icon}
          alt=''
          className='size-8'
        />
        <div
          className={cn(
            'w-full text-[15px] weight-medium max-[1440px]:text-xs flex items-center',
            data?.id === 4 ? 'w-11/12' : 'w-11/12 max-[1500px]:w-full'
          )}>
          <p className='flex-1'>{data?.label}</p>
          {data?.description && (
            <div className='w-auto'>
              <Tooltip
                content={data?.description}
                position='right'>
                <img
                  src={ICONS?.conversationAction}
                  alt='info'
                  className='size-5 block'
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <p className='text-[22px] weight-bold max-[1500px]:text-xl'>
        {data?.key === 'avg_wait_time'
          ? `${convertSecondsToHours(data?.value)} Hrs`
          : data?.key === 'active_conversations'
          ? `${convertToK(data?.value)}`
          : data?.value || 0}
      </p>
    </div>
  );
};

export default Cards;
