import Teams from "../../../assets/customSVG/accounts/Teams";
import AccountOverview from "../../../assets/customSVG/accounts/AccountOverview";
import Credits from "../../../assets/customSVG/accounts/Credits";
import Subscription from "../../../assets/customSVG/accounts/Subscription";
import Others from "../../../assets/customSVG/accounts/Others";
import Profile from "../../../assets/customSVG/accounts/Profile";

import { ICONS } from "../../../assets/icons/index";
import { ICONSS } from "../../../assets/icons/index1";

export const pricing = [
  {
    id: 1,
    title: "Starter",
    custom: false,
    popular: false,
    icon: ICONS?.Starter,
    icon1: ICONSS?.starterVerify,
    icon2: ICONSS?.starterVerifyication,
    monthly: {
      price: "Free",
      yearly: "Free",
    },
    quarterly: {
      price: "Free",
      yearly: "Free",
    },
    annual: {
      price: "Free",
      yearly: "Free",
    },
    content: (
      <ul
        style={{ listStyleType: "disc", margin: "0", padding: "0 1rem" }}
        className="font14px-400 text-[var(--font-600)]"
      >
        <li style={{ marginBottom: "0.5rem" }}>
          ₹0.17/Marketing Conversation*
        </li>
        <li style={{ marginBottom: "0.5rem" }}>₹0.12/Utility Conversation*</li>
        <li style={{ marginBottom: "0.5rem" }}>
          ₹0.08/Authentication Conversation*
        </li>
      </ul>
    ),
    btnText: "Current",
  },
  {
    id: 2,
    title: "Growth",
    custom: false,
    popular: true,
    icon: ICONS?.Growth,
    icon1: ICONSS?.growthVerify,
    // monthly: {
    //   price: "2,500",
    //   yearly: "30,000",
    // },
    quarterly: {
      price: "2,499",
      yearly: "30,000",
    },
    annual: {
      price: "1,999",
      yearly: "2",
    },
    content: (
      <ul
        style={{ listStyleType: "disc", margin: "0", padding: "0 1rem" }}
        className="font14px-400 text-[var(--font-600)]"
      >
        <li style={{ marginBottom: "0.5rem" }}>
          ₹0.06/Marketing Conversation*
        </li>
        <li style={{ marginBottom: "0.5rem" }}>₹0.04/Utility Conversation*</li>
        <li style={{ marginBottom: "0.5rem" }}>
          ₹0.02/Authentication Conversation*
        </li>
      </ul>
    ),
    btnText: "Choose Plan",
  },
  {
    id: 3,
    title: "Magnum",
    custom: true,
    popular: false,
    icon: ICONS?.Magnum,
    icon1: ICONSS?.magnumVerify,
    monthly: {},
    quarterly: {},
    annual: {},
    content: (
      <ul
        className="font14px-400 text-[var(--font-600)]"
        style={{ listStyleType: "disc", margin: "0", padding: "0 1rem" }}
      >
        <li style={{ marginBottom: "0.5rem" }}>
          ₹0.06/Marketing Conversation*
        </li>
        <li style={{ marginBottom: "0.5rem" }}>₹0.04/Utility Conversation*</li>
        <li style={{ marginBottom: "0.5rem" }}>
          ₹0.02/Authentication Conversation*
        </li>
      </ul>
    ),
    btnText: "Contact Sales",
  },
];

export const ContactDetailList = [
  {
    id: 1,
    name: "Mobile number",
    icon: ICONS?.PhoneIcon,
    content: "Ex:- 000 000 0000",
  },
  {
    id: 2,
    name: "Email",
    icon: ICONS?.EmailIcon,
    content: "Ex:-yourmail@email.com",
  },
  {
    id: 3,
    name: "Contact address",
    icon: ICONS?.AccountHomeIcon,
    content: "Enter address",
  },
  {
    id: 4,
    name: "Country",
    icon: ICONS?.LocationMarkIcon,
    content: "Enter state & city",
  },
];
export const PasswordList = [
  {
    id: 1,
    name: "Current Password",
    content: "Password",
  },
  {
    id: 2,
    name: "New Password",
    content: "Password",
  },
  {
    id: 3,
    name: "Re-enter  Password",
    content: "Password",
  },
];

export const account_center = [
  {
    id: 1,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
  {
    id: 2,
    title: "VVD & Sons Pvt Ltd",
    content: "01 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Vvd,
  },
  {
    id: 3,
    title: "Aura Properties",
    content: "06 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aura,
  },
  {
    id: 4,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
  {
    id: 5,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
  {
    id: 6,
    title: "Aachi groups",
    content: "03 Brands",
    icon: ICONS?.brands,
    icon1: ICONS?.accountsArrow,
    icon2: ICONS?.accountsArrowHover,
    icon3: ICONS?.Aachi,
  },
];

export const conversationPricing = [
  {
    id: 1,
    title: "Marketing",
    msg: "0.17/msg",
    msg1: "0.12/msg",
    msg2: "0.08/msg",
    border: "",
  },
  {
    id: 2,
    title: "Utility",
    msg: "0.06/msg",
    msg1: "0.04/msg",
    msg2: "0.02/msg",
    border: "",
  },
  {
    id: 3,
    title: "Authentication",
    msg: "0.06/msg",
    msg1: "0.04/msg",
    msg2: "0.02/msg",
    border: "",
  },
  {
    id: 4,
    title: "Service",
    msg: "Free",
    msg1: "Free",
    msg2: "Free",
    border: "",
  },
];

export const brandAccountOverview = [
  {
    id: 1,
    icon: AccountOverview,
    name: "Overview",
  },
  {
    id: 2,
    icon: Credits,
    name: "Credits",
  },
  {
    id: 3,
    icon: Teams,
    name: "Users",
  },
  {
    id: 4,
    icon: Subscription,
    name: "Subscriptions",
  },
  {
    id: 5,
    icon: Others,
    name: "Invoices",
  },
];
export const brandStatus = [
  {
    id: 1,
    icon: ICONSS?.businessVerification,
    icon1: ICONSS?.paymentMethod,
    title: "Connect WhatsApp Business API",
    content:
      "To apply WhatsApp Business API, you'll need a registered business and a functioning website",
    btnTitle: "Continue with Facebook",
    status: true,
  },
  {
    id: 2,
    icon: ICONSS?.businessVerification,
    icon1: ICONSS?.paymentMethod,
    title: "Start Business Verification",
    content:
      "To apply WhatsApp Business API, you'll need a registered business and a functioning website",
    btnTitle: "Start Business Verification",
    status: true,
  },
  {
    id: 3,
    icon1: ICONSS?.paymentMethod,
    icon: ICONSS?.businessVerification,
    title: "Add payment method",
    content:
      "To apply WhatsApp Business API, you'll need a registered business and a functioning website",
    btnTitle: "Manage Payment",
    status: true,
  },
  // {
  //   id: 4,
  //   icon: ICONSS?.businessVerification,
  //   title: "Subscription Plan",
  //   content:
  //     "To apply WhatsApp Business API, you'll need a registered business and a functioning website",
  // },
];

export const creditHistory = [
  {
    id: 1,
    icon: ICONSS?.creditMarketing,
    label: "Marketing",
  },
  {
    id: 2,
    icon: ICONSS?.creditUtility,
    label: "Utility",
  },
  {
    id: 3,
    icon: ICONSS?.creditAuthentication,
    label: "Authentication",
  },
  {
    id: 4,
    icon: ICONSS?.creditService,
    label: "Service",
    msg3: "Free",
  },
];

export const billingHistory = [
  {
    id: 1,
    icon: "Starter Plan",
    date: "Dec 19, 2024",
    paidOn: "Jul 06, 2024 - Jan 09, 2025",
    payment: "89*****@paytm",
    amount: "₹2999 + GST",
  },
  {
    id: 2,
    icon: "Starter Plan",
    date: "Dec 19, 2024",
    paidOn: "Jul 06, 2024 - Jan 09, 2025",
    payment: "89*****@paytm",
    amount: "₹2999 + GST",
  },
  {
    id: 3,
    icon: "Starter Plan",
    date: "Dec 19, 2024",
    paidOn: "Jul 06, 2024 - Jan 09, 2025",
    payment: "89*****@paytm",
    amount: "₹2999 + GST",
  },
  {
    id: 4,
    icon: "Starter Plan",
    date: "Dec 19, 2024",
    paidOn: "Jul 06, 2024 - Jan 09, 2025",
    payment: "89*****@paytm",
    amount: "₹2999 + GST",
  },
];

export const credits = [
  {
    id: 1,
    label: "+ 1,000.00",
  },
  {
    id: 2,
    label: "+ 2,500.00",
  },
  {
    id: 3,
    label: "+ 10,000.00",
  },
  {
    id: 4,
    label: "+ 50,000.00",
  },
];

export const teamsList = [
  {
    id: 1,
    name: "Cody Fisher",
  },
  {
    id: 2,
    name: "Cody Fisher",
  },
  {
    id: 3,
    name: "Cody Fisher",
  },
  {
    id: 4,
    name: "Cody Fisher",
  },
];

// SIDE BAR

export const myProfile = [
  // {
  //   id: 1,
  //   icon: ICONSS?.profile,
  //   label: "Profile",
  // },
  // {
  //   id: 2,
  //   icon: ICONSS?.notifications,
  //   label: "Notifications",
  // },
  // {
  //   id: 3,
  //   icon: ICONSS?.helpSupport,
  //   label: "Help & Support",
  // },
  {
    id: 4,
    icon: ICONSS?.logout,
    label: "Logout",
  },
];

export const accountSettingsSidebar = [
  {
    id: 1,
    icon: AccountOverview,
    name: "Account Info",
  },
  {
    id: 2,
    icon: Profile,
    name: "Profile Info",
  },
];

export const countryList = [
  {
    label: "India",
    value: "+91",
    shortName: "IN",
  },
  // {
  //   label: 'United States',
  //   value: '+1',
  //   shortName: 'US',
  // },
  // {
  //   label: 'Japan',
  //   value: '+81',
  //   shortName: 'JP',
  // },
  // {
  //   label: 'Brazil',
  //   value: '+55',
  //   shortName: 'BR',
  // },
  // {
  //   label: 'Russia',
  //   value: '+7',
  //   shortName: 'RU',
  // },
  // {
  //   label: 'Canada',
  //   value: '+1',
  //   shortName: 'CA',
  // },
  // {
  //   label: 'Israel',
  //   value: '+972',
  //   shortName: 'IL',
  // },
];
