import { ICONSS } from "../../../assets/icons/index1";
import ArrowWithCircle from "../../../assets/customSVG/ArrowWithCircle";
import Invoice from "../../../assets/customSVG/Invoice";
import Shop from "../../../assets/customSVG/Shop";
import User from "../../../assets/customSVG/User";

export const ACCOUNT_COUNTS = [
  {
    id: 1,
    label: "No. of brands",
    value: "6",
    key: "total_brands",
    icon: Shop,
    color: "#ED4F9D",
    bg: "#FDF2F8",
    route: "",
  },
  {
    id: 2,
    label: "No. of people",
    value: "6",
    key: "total_users",
    icon: User,
    color: "#4AACEA",
    bg: "#E7F4FC",
    route: "",
  },
  {
    id: 3,
    label: "Active brands",
    value: "16",
    key: "active_brands",
    icon: Shop,
    color: "#0ACD95",
    bg: "#F0FDF4",
    route: "",
  },
  {
    id: 4,
    label: "In-active brands",
    value: "3",
    key: "inactive_brands",
    icon: Shop,
    color: "#F96056",
    bg: "#FFEEED",
    route: "",
  },
  {
    id: 5,
    label: "Pending Invoices",
    value: "6",
    key: "pendinng_invoices",
    icon: Invoice,
    color: "#F6A723",
    bg: "#FFFBEB",
    route: "",
  },
  {
    id: 6,
    label: "View All Brands",
    value: "",
    icon: ArrowWithCircle,
    color: "#0ACD95",
    bg: "",
    route: "",
  },
];

export const brandCounts = [
  {
    id: 1,
    label: "Active Brands",
    icon: "#D2F1DE",
    count: 10,
    value: 10,
  },
  {
    id: 2,
    label: "Total Brands",
    icon: "#EADAFF",
    count: 18,
    value: [2, 3, 4, 5, 9, 10],
  },
  {
    id: 3,
    label: "Inactive Brands",
    icon: ICONSS?.inactive_brand,
    count: "05",
    value: 9,
  },
  {
    id: 4,
    label: "Onboarding",
    icon: "#DEE2FF",
    count: "03",
    value: [2, 3, 4, 5],
  },
];

export const ROLELIST = [
  {
    id: 1,
    label: "Account Owner",
    role_ids: 1,
    isSelected: false,
  },
  {
    id: 2,
    label: "Account Manager",
    role_ids: 2,
    isSelected: false,
  },
  {
    id: 3,
    label: "Account User",
    role_ids: 3,
    isSelected: false,
  },
];

export const BRANDLIST = [
  {
    id: 1,
    label: "Brand Manager",
    role_ids: 4,
    isSelected: false,
  },
  {
    id: 2,
    label: "Campaign Manager",
    role_ids: 5,
    isSelected: false,
  },
];

export const ACCOUNT_STATUS_LIST = [
  {
    id: 1,
    label: "Active",
    status: 10,
    isSelected: false,
  },
  {
    id: 2,
    label: "Inactive",
    status: 5,
    isSelected: false,
  },
  {
    id: 3,
    label: "Pending",
    status: 1,
    isSelected: false,
  },
  {
    id: 4,
    label: "Declined",
    status: 2,
    isSelected: false,
  },
  {
    id: 5,
    label: "Suspended",
    status: 4,
    isSelected: false,
  },
  {
    id: 6,
    label: "Expired",
    status: 3,
    isSelected: false,
  },
];

export const BRAND_STATUS_LIST = [
  {
    id: 1,
    label: "Active",
    status: 10,
    isSelected: false,
  },
  {
    id: 2,
    label: "Inactive",
    status: 5,
    isSelected: false,
  },
  {
    id: 3,
    label: "Assigned",
    status: 6,
    isSelected: false,
  },
];

export const ACCOUNT_STATUS = {
  10: {
    text: "Active",
    color: "#25C277",
    background: "#25C27714",
    border: "#25C277",
  },

  3: {
    text: "Expired",
    color: "#566F8F",
    background: "#566F8F14",
    border: "#566F8F",
  },
  1: {
    text: "Pending",
    color: "#007AFF",
    background: "#007AFF14",
    border: "#007AFF",
  },
  2: {
    text: "Declined",
    color: "#FF543E",
    background: "#FF543E14",
    border: "#FF543E",
  },
  4: {
    text: "Suspended",
    color: "#FF8000",
    background: "#FF800014",
    border: "#FF8000",
  },
  5: {
    text: "In-active",
    color: "#FBBF24",
    background: "#FBBF2414",
    border: "#FBBF24",
  },
  6: {
    text: "Assigned",
    color: "#FBBF24",
    background: "#FBBF2414",
    border: "#FBBF24",
  },
};

export const ACCOUNT_ROLE = {
  1: {
    text: "Account Owner",
    border: "#518FF5",
  },
  2: {
    text: "Account Manager",
    border: "#B49FF9",
  },
  3: {
    text: "Account User",
    border: "#FFC107",
  },
};

export const BRAND_ROLE = {
  4: {
    text: "Brand Manager",
    border: "#518FF5",
  },
  5: {
    text: "Campaign Manager",
    border: "#B49FF9",
  },
};

export const Credit_usage = [
  // { id: 1, title: "Opening Balance" },
  { id: 2, title: "Amount Added" },
  { id: 3, title: "Amount Used" },
  // { id: 4, title: "Closing Balance" },
];

export const reAssignData = [
  {
    role_id: 2,
    role: "Account Manager",
    targetRole: "Account User",
    target_role_id: 3,
    description:
      "Are you sure you want to convert the manager into 'Account User'?",
  },
  {
    role_id: 3,
    role: "Account User",
    targetRole: "Account Manager",
    target_role_id: 2,
    description:
      "Are you sure you want to convert the user into 'Account Manager'?",
  },
];

export const reAssignBrandData = [
  {
    role_id: 4,
    role: "Brand Manager",
    targetRole: "Campaign Manager",
    target_role_id: 5,
    description:
      "Are you sure you want to convert the manager into 'Campaign Manager'?",
  },
  {
    role_id: 5,
    role: "Campaign Manager",
    targetRole: "Brand Manager",
    target_role_id: 4,
    description:
      "Are you sure you want to convert the user into 'Brand Manager'?",
  },
];
export const planListPurchaseFilter = [
  {
    id: 1,
    label: "Starter",
    role_ids: 1,
    isSelected: false,
  },
  {
    id: 2,
    label: "Growth",
    role_ids: 2,
    isSelected: false,
  },
  {
    id: 3,
    label: "Magnum",
    role_ids: 3,
    isSelected: false,
  },
];

export const inputsArray = [
  {
    id: 1,
    // value: brandsData?.name,
    value: "",
    label: "Brand Name",
    key: "name",
    secondaryKey: null,
    locations_id: "",
  },
  {
    id: 2,
    // value: brandsData?.category,
    value: "",
    label: "Category",
    key: "category",
    secondaryKey: null,
    locations_id: "",
  },
  // Uncomment if needed
  // {
  //   id: 3,
  //   value: brandsData?.locations_with_names?.[0]?.address_line_3,
  //   label: "Phone Number",
  // key:'',
  // secondaryKey:'',
  // locations_id:'',
  // },
  {
    id: 4,
    // value: brandsData?.website_url || "--",
    value: "",
    label: "Website",
    key: "website_url",
    secondaryKey: null,
    locations_id: "",
  },
  {
    id: 5,
    // value: brandsData?.locations_with_names?.[0]?.address_line_1,
    value: "",
    label: "Address / Street",
    key: "locations_with_names",
    secondaryKey: "address_line_1",
    locations_id: "",
  },
  {
    id: 6,
    value: "",
    //   countryListss?.find(
    //     (country) =>
    //       country?.id === brandsData?.locations_with_names?.[0]?.country_id
    //   )?.label || "--",
    label: "Country",
    key: "locations_with_names",
    secondaryKey: "country",
    locations_id: "",
    // locations_id: brandsData?.locations_with_names?.[0]?.country_id,
  },
  {
    id: 7,
    value: "",
    // stateList?.find(
    //   (state) =>
    //     state?.id === brandsData?.locations_with_names?.[0]?.province_id
    // )?.label || "--",
    label: "State",
    key: "locations_with_names",
    secondaryKey: "province",
    locations_id: "",
    // locations_id: stateList?.find(
    //   (state) =>
    //     state?.id === brandsData?.locations_with_names?.[0]?.province_id
    // )?.id,
  },
  {
    id: 8,
    value: "",
    //   cityList?.find(
    //     (city) => city?.id === brandsData?.locations_with_names?.[0]?.city_id
    //   )?.label || "--",
    label: "City",
    key: "locations_with_names",
    secondaryKey: "city",
    locations_id: "",
    // locations_id: cityList?.find(
    //   (city) => city?.id === brandsData?.locations_with_names?.[0]?.city_id
    // )?.id,
  },
  {
    id: 9,
    // value: brandsData?.locations_with_names?.[0]?.postal_index_code,
    value: "",
    label: "Postal / Pin Code",
    key: "locations_with_names",
    secondaryKey: "postal_index_code",
    locations_id: "",
  },
];
