import attachments from '../icons/attachments.svg';
import bold from '../icons/bold.svg';
import email from '../icons/email.svg';
import italic from '../icons/italic.svg';
import noTemplateSelected from '../icons/noTemplateSelected.svg';
import phone from '../icons/phone.svg';
import phoneWithBg from '../icons/phoneWithBg.svg';
import selectTemplate from '../icons/selectTemplate.svg';
import starGroup from '../icons/star-group.svg';
import underline from '../icons/underline.svg';

import BroadcastAddIcon from './AddSegment.svg';
import AlertIcon from './Alert.svg';
import AudienceDownloadIcon from './AudienceDownload.svg';
import AudienceFileIcon from './AudienceFile.svg';
import BGChevronRightIcon from './BGchevorn.svg';
import BroadcastRoundGreenTick from './BGroundTick.svg';
import ActiveIcon from './Balance.svg';
import BrandArrowIcon from './BrandArrow.svg';
import BroadcastSegmentsIcon from './BroadSegments.svg';
import BroadCastSettingIcon from './Broadcast .svg';
import BroadCastActive from './BroadcastActive.svg';
import BroadcastCopyIcon from './BroadcastCopy.svg';
import BroadcastMessageIcon from './BroadcastMessage.svg';
import BroadcastProfileIcon from './BroadcastProfile.svg';
import BroadcastReviewIcon from './BroadcastReview.svg';
import CampaignBudgetIcon from './Budget .svg';
import BudgetIcon from './Budget.svg';
import CalenderArrow from './CalendarArrow.svg';
import calenderAudience from './CalendarAudience.svg';
import CalenderIcon from './CalenderIcon.svg';
import BroadcastCameraIcon from './Camera.svg';
import CreateCampaignNew from './CreateCampaignNew.svg';
import BroadcastDefineIcon from './Define.svg';
import BroadcastDelayIcon from './DelayIcon.svg';
import DeliveredIcon from './Delivered.svg';
import DigitalAgencies from './DigitalAgencies.svg';
import Download from './Download.svg';
import CampaignDraftIcon from './Draft.svg';
import editCrm from './Editcrm.svg';
import Education from './Education.svg';
import EmailArrowIcon from './EmailArrow.svg';
import endNodeFlag from './EndNodeFlag.svg';
import Exit from './ExitIcon.svg';
import BroadcastDownloadIcon from './Export.svg';
import campaignEye from './Eye.svg';
import StrikeThrough from './Font_Strikethrough.svg';
import BroadcastGalleryIcon from './Gallery.svg';
import BroadcastGreenTickIcon from './Greentick.svg';
import HealthCare from './HealthCare.svg';
import Industry from './Industry.svg';
import BillingGreyWarningIcon from './Info.svg';
import LeadIcon from './Lead.svg';
import MaximumIcon from './MaxIcon.svg';
import BroadcastNewSegmentIcon from './NewSegmentIcon.svg';
import OpenedIcon from './Opened.svg';
import BillingPdfIcon from './PDFIcon.svg';
import CampaignPausedIcon from './PausedIcon.svg';
import pencil from './Pencil.svg';
import PersonIcon from './PersonIcon.svg';
import HeaderPlanIcon from './PlanIcon.svg';
import BrandPlusIcon from './PlusIcon.svg';
import BroadcastMickIcon from './Record Audio.svg';
import BroadcastRedInfoIcon from './RedCircleInfo.svg';
import BroadcastRedDeleteIcon from './RedDelete.svg';
import xCircleIcon from './Rejected.svg';
import AudienceRoundIcon from './RoundBlus.svg';
import BroadcastSegmentIcon from './SegmentIcon.svg';
import BroadcastTickIcon from './Segmenttick.svg';
import SendingIcon from './Sending.svg';
import BroadcastWhatsappAddIcon from './Shape.svg';
import BrandInviteShareIcon from './ShareIcon.svg';
import SpentIcon from './Spent.svg';
import StarIcon from './Stars copy.svg';
import TickGreenIcon from './TickGreen.svg';
import TickIcon from './TickIcon.svg';
import BroadcastReplayIcon from './Vector.svg';
import WhatsApps from './WhatsApps.svg';
import Aachi from './aachi.svg';
import brands from './account_brand.svg';
import accountsArrow from './accounts_arrow.svg';
import accountsArrowHover from './accounts_hover_arrow.svg';
import activeIcon from './active-icon.svg';
import addBGwhite from './add-bg-white.svg';
import createBrandIcon from './add-square.svg';
import addTags from './add-tags-icon.svg';
import addWhitePlus from './add-white-plus.svg';
import AddTag from './addTag.svg';
import addColumn from './add_column.svg';
import alertIconError from './alert-triangle-error.svg';
import alertFilled from './alert-triangle-filled.svg';
import allCampaignsWhite from './all-campaigns-black.svg';
import allCampaigns from './all-campaigns.svg';
import analytics from './analytics.svg';
import arrowLeftCampaign from './arrow-left-campaign.svg';
import ascending from './ascending.svg';
import Aura from './aura.svg';
import awarenessIcon from './awareness-icon.svg';
import awarenessWhite from './awareness-white.svg';
import awarenessSidebar from './awareness.svg';
import BackIcon from './backIcon.svg';
import BillingUploadIcon from './billingUpload.svg';
import BouncedIcon from './bonuced.svg';
import bouncedOverview from './bounced-overview.svg';
import BroadcastTimerIcon from './broadcaseClock.svg';
import broadcastCreate from './broadcast-create.svg';
import broadcastNoData from './broadcast-no-data.svg';
import campaignCalendarIcon from './calendar-01.svg';
import calenderContact from './calendarContact.svg';
import calenderDinner from './calendarDinner.svg';
import calenderScheduled from './calenderScheduled.svg';
import call from './call.svg';
import activeCampaign from './campaign-active 2.svg';
import CampaignActiveIcon from './campaign-active.svg';
import completedCampaign from './campaign-completed.svg';
import draftCampaign from './campaign-draft.svg';
import campaignNoData from './campaign-no-data.svg';
import BroadcastCalendarIcon from './campaignCalendar.svg';
import campaignCompleteIconNew from './campaignCompleteIconNew.svg';
import campaignScheduledNew from './campaignScheduledNew.svg';
import carouselAddCardIamge from './carousel-add-card-image.svg';
import carouselBulb from './carousel-bulb.svg';
import carouselDraft from './carousel-draft.svg';
import CarouselOptions from './carousel-options.svg';
import checkSquare from './check-square.svg';
import chevronDownCarousel from './chevron-down-carousel.svg';
import chevronDown from './chevron-down.svg';
import chevronRightIconWhite from './chevron-right-white.svg';
import chevronRightIcon from './chevron-right.svg';
import chevronGreen from './chevronGreen.svg';
import BroadcastChevronDownIcon from './chrveonDown.svg';
import BroadcastChevronUpIcon from './chrveonup.svg';
import CloseIcon from './close.svg';
import completed from './completed.svg';
import CampaignCompleteIcon from './completedIcon.svg';
import configure from './configure.svg';
import CopyLeft from './copy-left.svg';
import CopyCodeIcon from './copyCode.svg';
import crmGoogleAds from './crm-ads.svg';
import crmEmpty from './crm-empty.svg';
import crmFaceBook from './crm-facebook.svg';
import crmStore from './crm-group.svg';
import crmInstagram from './crm-instagram.svg';
import crmLinkedIn from './crm-linkedin.svg';
import crmOthers from './crm-option.svg';
import crmTikTok from './crm-tiktok.svg';
import crmTwitter from './crm-twitter.svg';
import crmWebsite from './crm-website.svg';
import crmWhatsApp from './crm-whatsapp.svg';
import delayIcon from './delay.svg';
import deleteIcon from './delete.svg';
import deliveredOverview from './delivered-overview.svg';
import descending from './descending.svg';
import disConnect from './disConnect.svg';
import discardCarousel from './discard-carousel.svg';
import discardframe from './discard-frame.svg';
import draft from './draft-icon.svg';
import dropDownIcon from './dropdown.svg';
import duplicate from './duplicate.svg';
import eCommerce from './eCommerce.svg';
import crmTagDots from './edit-tag-dots.svg';
import crmTagEdit from './edit-tag.svg';
import edit from './edit.svg';
import emptySearch from './empty-search.svg';
import noTemplate from './empty_messages_template 1.svg';
import engagementIcon from './engagement-icon.svg';
import engagementWhite from './engagement-white.svg';
import engagementSidebar from './engagement.svg';
import FilterSelectedIcon from './filter.svg';
import FMCG from './fmcg.svg';
import logoGain from './gain-wix-logo.svg';
import goalTargetInfo from './goal-info-target.svg';
import goalRemainingDays from './goal-remaining-days.svg';
import goalTrack from './goal-track.svg';
import growth from './growth.svg';
import helpCircle from './helpCircle.svg';
import createInboxNew from './inbox-new-create.svg';
import IndiaIcon from './india.svg';
import infoMail from './info-mail.svg';
import infoPhone from './info-phone.svg';
import BrandInviteAddWhiteIcon from './inviteWhiteAddIcon.svg';
import leadsGreen from './leads-green.svg';
import leadsIcon from './leads-icon.svg';
import leadsWhite from './leads-white.svg';
import leadsSidebar from './leads.svg';
import BroadcastLightChevronDown from './lightchevrondown.svg';
import BroadcastSegmentDownloadIcon from './link-external.svg';
import lock from './lock.svg';
import locked from './locked.svg';
import logoApton from './logo-apton.svg';
import magnum from './magnum.svg';
import markPrimary from './mark-primary.svg';
import messageFailed from './message-failed.svg';
import messageSent from './message-sent.svg';
import duplicateModal from './modal-duplicate.svg';
import modelDropdownIcon from './model-dropdown.svg';
import mostPopular from './mostPopular.svg';
import CreateCampaign from './new-campaign.svg';
import newestFirst from './newest-first.svg';
import noBroadcast from './no-broadcast.svg';
import noChartData from './no-chart-data.svg';
import noMessage from './no-message.svg';
import NoImageIcon from './no_image_contact.svg';
import notificationActive from './notification.svg';
import nounLink from './noun-link.svg';
import oldestFirst from './oldest-first.svg';
import other from './other.svg';
import performanceBlcoked from './performance-blocked.svg';
import performanceClicks from './performance-clicks.svg';
import performanceConversation from './performance-conversatrion.svg';
import performanceIcon from './performance-icon.svg';
import performanceLeads from './performance-leads.svg';
import performanceReactions from './performance-reactions.svg';
import perpetualWhite from './perpetual-white.svg';
import perpetual from './perpetual.svg';
import pricingArrow from './pricingArrow.svg';
import readOverview from './read-overview.svg';
import redDelete from './red-delete.svg';
import RedDeleteIcon from './redelete.svg';
import reportDownload from './report-download.svg';
import runningCampaign from './running-campaign.svg';
import CampaignRunningIcon from './running-icon-active.svg';
import CampaignRunningIconWhite from './runningIconWhite.svg';
import scheduled from './scheduled.svg';
import SeeAllOptionIcon from './seeall_Icon.svg';
import selected from './selected.svg';
import send from './send.svg';
import sentOverview from './sent-overview.svg';
import sentIcon from './sent.svg';
import sequenceCreate from './sequence-create.svg';
import shareLink from './share-link.svg';
import sidebarChevronDown from './sidebar-chevron-down.svg';
import speakerphoneWhite from './speakerphone-white.svg';
import Speaker from './speakerphone.svg';
import BroadcastStarIcon from './star.svg';
import starredClicked from './starred-clicked.svg';
import starredUnclicked from './starred-unclicked.svg';
import starred from './starred.svg';
import startEndDate from './start_end_date.svg';
import starter from './starter.svg';
import stepperRoundIcon from './stepperRound.svg';
import stepperTickIcon from './stepperTick.svg';
import syncRefreshTemplate from './syncRefresh.svg';
import syncRefresh from './sync_refresh.svg';
import templateInoboxIcon from './template-inbox-icon.svg';
import templateName from './template_name.svg';
import timeSpecififedWhite from './time-specified-white.svg';
import timeSpecififed from './time-specified.svg';
import travel from './travel.svg';
import unlock from './unlock.svg';
import unlocked from './unlocked.svg';
import upgradeArrow from './upgrade-arrow.svg';
import uploadStep from './uploadStep.svg';
import uploadFile from './upload_file.svg';
import usage from './usage.svg';
import BroadcastSegmentIcons from './variant.svg';
import Vvd from './vvd.svg';
import whatsApp from './whatsapp.svg';
import whatsappLeft from './whatsapp_left.svg';
import xSquare from './x-square.svg';

import homeDownload from './home/HomeDownload.svg';
import active from './home/active.svg';
import alertArrow from './home/alertArrow.svg';
import bell from './home/bell.svg';
import calendarScheduled from './home/calendarScheduled.svg';
import calender from './home/calender.svg';
import chat from './home/chat.svg';
import checkDoubleBlue from './home/check_double_blue.svg';
import checkDoubleOrange from './home/check_double_orange.svg';
import click from './home/click.svg';
import conversationAction from './home/conversationAction.svg';
import heartEyeEmoji from './home/heartEyeEmoji.svg';
import homeAlert from './home/homeAlert.svg';
import homeBlog from './home/homeBlog.svg';
import homeBlogGainwix from './home/homeBlogGainwix.svg';
import homeHelp from './home/homeHelp.svg';
import homeNotComplete from './home/homeNotComplete.svg';
import homeSuccess from './home/homeSuccess.svg';
import homeVideoTour from './home/homeVideoTour.svg';
import homeWarning from './home/homeWarning.svg';
import homeWatch from './home/homeWatch.svg';
import homeWhatsapp from './home/homeWhatsapp.svg';
import key from './home/key.svg';
import lead from './home/lead.svg';
import marketing from './home/marketing.svg';
import noNotification from './home/no_notification.svg';
import profile from './home/profile.svg';
import resolved from './home/resolved.svg';
import segment from './home/segment.svg';
import services from './home/services.svg';
import totalConversations from './home/totalConversations.svg';
import unread from './home/unread.svg';
import viewCalendar from './home/viewCalendar.svg';
import viewCalender from './home/view_calender.svg';
import audioInbox from './inbox/audio-inbox.svg';
import backArrow from './inbox/back-arrow.svg';
import inboxBounced from './inbox/bounced-inbox.svg';
import carouselEdit from './inbox/carousel-edit.svg';
import chooseTemplateInbox from './inbox/choose-template-inbox.svg';
import contactDetailsOpen from './inbox/contact-details-open.svg';
import copyIcon from './inbox/copyIcon.svg';
import customerJourneyInbox from './inbox/customer-journey-inbox.svg';
import customerJourneyOpen from './inbox/customer-journey-open.svg';
import documentInbox from './inbox/document-inbox.svg';
import emptyLoader from './inbox/empty-loader.svg';
import inboxFiltersActive from './inbox/filter-inbox-active.svg';
import gifInbox from './inbox/gif-inbox.svg';
import imageInbox from './inbox/image-inbox.svg';
import inboxEdit from './inbox/inbox-edit.svg';
import inboxEmpty from './inbox/inbox-empty.svg';
import inboxFilters from './inbox/inbox-filters.svg';
import inboxOpen from './inbox/inbox-open.svg';
import infoIconViolet from './inbox/infoIconViolet.svg';
import marketingInbox from './inbox/marketing-choose.svg';
import marketingIcon from './inbox/marketing.svg';
import replyIcon from './inbox/replyIcon.svg';
import contactRightInbox from './inbox/right-contact-inbox.svg';
import rightImageScroll from './inbox/right-image-scroll.svg';
import syncChange from './inbox/sync-inbox-change.svg';
import utilityInbox from './inbox/utility-choose.svg';
import utilityIcon from './inbox/utility.svg';
import videoInbox from './inbox/video-inbox.svg';
import whatsappInbox from './inbox/whatsapp-inbox.svg';
import sequenceAbandoned from './sequence/abandoned.svg';
import sequenceActive from './sequence/active.svg';
import activeConversion from './sequence/activeConversion.svg';
import sequenceCompleted from './sequence/completed.svg';
import conversionCost from './sequence/conversionCost.svg';
import convertionWaitTime from './sequence/convertionWaitTime.svg';
import editBlack from './sequence/editBlack.svg';
import sequenceFailed from './sequence/failed.svg';
import firstReply from './sequence/firstReply.svg';
import paused from './sequence/paused.svg';
import pausedBlack from './sequence/pausedBlack.svg';
import pausedOrange from './sequence/pausedOrange.svg';
import running from './sequence/running.svg';
import tickCircle from './sequence/tickCircle.svg';
import sequenceTriggered from './sequence/triggered.svg';
import templateButtonShareLink from './template/share-link.svg';

export const ICONS = {
  searchLightIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/search_light.svg',
  usageIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/usage_plan.svg',

  HeaderSwitchingCalender:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/header_switching_calendar.svg',
  whiteRightIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/arrowright.svg',
  googleIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/google.svg',
  code: 'https://storage.googleapis.com/asp-v1-images/assets/ui/otp.svg',
  leftArrow:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/arrowleft.svg',
  emailBox:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/emailauth.svg',
  passwordLock:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/lockauth.svg',
  passwordEyeOpen:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/eyeauth.png',
  passwordEyeClose:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/eye_closed.png',
  globalIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/global.svg',
  logout: 'https://storage.googleapis.com/asp-v1-images/assets/ui/logout.svg',

  popupX: 'https://storage.googleapis.com/asp-v1-images/assets/ui/x.svg',

  logout: 'https://storage.googleapis.com/asp-v1-images/assets/ui/logout.svg',

  ExitIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/exit_icon.png',
  CircleCheck:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/Circle_Check.svg',
  cancelX:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/cancel_x.svg',
  TaskDownload:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/task_download.svg',
  WhatsappCsvIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/whastappcsv.svg',

  WarningIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/Circle_Warning.svg',
  AlertIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/alert_triangle.png',
  // AlertIcon:
  //   "https://storage.googleapis.com/asp-v1-images/assets/ui/alert_triangle.png",
  TrianglewarningIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/Triangle_Warning.svg',

  searchIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/search.svg',
  CopyText:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/copy_right.svg',

  filterIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/filter.svg',
  campaignCalendar:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/campaignCalendar.svg',
  SelectDropdown:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/selectDropdown.svg',
  bell: 'https://storage.googleapis.com/asp-v1-images/assets/ui/bell.svg',
  BoldIcon: 'https://storage.googleapis.com/asp-v1-images/assets/ui/bold.svg',
  ItalicIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/italics.svg',
  step3EmojiIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/step3_emoji.svg',
  // addBGwhite:
  //   "https://storage.googleapis.com/asp-v1-images/assets/ui/addBGwhite.svg",
  step2PopupIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/step2_popup_information.svg',
  CaretDown:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/caret_down.svg',
  CalendarIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/calendar.svg',
  addBGtransparent:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/addBGtransparent.svg',
  chevronRight:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/chevronRight.png',
  chevronLeft:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/chevronLeft.png',
  navCalendar:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/navCalendar.png',
  dragVertical:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/Drag_Vertical.svg',
  campaignDelete:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/campaignDelete.svg',
  greenTickVerticalSteps:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/greenTickVerticalSteps.svg',

  AtIcon: 'https://storage.googleapis.com/asp-v1-images/assets/ui/At.svg',
  ReplayIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/Vector.svg',
  inboxProfile:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/inbox_profile.svg',

  HighAlert:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/highWarning.svg',
  addPlusIcon:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/plus_campaign_add_filter.svg',

  accountsArrowHover:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/accounts_hover_arrow.svg',
  accountsArrow:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/accounts_arrow.svg',
  brands:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/account_brand.svg',

  // home
  Message: 'https://storage.googleapis.com/asp-v1-images/assets/ui/chat.svg',
  Marketing:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/marketing.svg',
  Bell: 'https://storage.googleapis.com/asp-v1-images/assets/ui/bell.svg',
  Services:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/services.svg',
  logoGain:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/gainwix-logo.svg',
  logoMinimize:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/logo-minimize.svg',
  sideNavExpand:
    'https://storage.googleapis.com/asp-v1-images/assets/ui/sidenav.svg',

  logoApton: logoApton,
  usage: usage,

  upgradeArrow: upgradeArrow,

  // Accounts -Login
  Aachi: Aachi,
  Aura: Aura,
  Vvd: Vvd,

  dropDownIcon: dropDownIcon,
  notificationActive: notificationActive,
  helpCircle: helpCircle,
  createBrandIcon: createBrandIcon,

  CloseIcon: CloseIcon,
  modelDropdownIcon: modelDropdownIcon,

  whatsApp: whatsApp,
  chevronDown: chevronDown,
  whatsappLeft: whatsappLeft,
  syncRefresh: syncRefresh,
  configure: configure,
  syncRefreshTemplate: syncRefreshTemplate,
  disConnect: disConnect,

  BudgetIcon: BudgetIcon,
  ActiveIcon: ActiveIcon,

  SpentIcon: SpentIcon,
  xCircleIcon: xCircleIcon,

  lock: lock,
  unlock: unlock,
  edit: edit,
  deleteIcon: deleteIcon,
  duplicate: duplicate,

  Speaker: Speaker,
  StrikeThrough: StrikeThrough,
  StarIcon: StarIcon,

  AddColumn: addColumn,

  //new sidebar
  // sideNavExpand: sideNavExpand,

  //new home
  homeWhatsapp: homeWhatsapp,
  homeDownload: homeDownload,
  totalConversations: totalConversations,
  active: active,
  unread: unread,
  resolved: resolved,
  conversationAction: conversationAction,
  calendarScheduled: calendarScheduled,
  viewCalendar: viewCalendar,
  heartEyeEmoji: heartEyeEmoji,
  homeSuccess: homeSuccess,
  homeWarning: homeWarning,
  homeNotComplete: homeNotComplete,
  homeAlert: homeAlert,
  homeWatch: homeWatch,
  homeVideoTour: homeVideoTour,
  allCampaignsWhite: allCampaignsWhite,
  timeSpecififedWhite: timeSpecififedWhite,
  perpetualWhite: perpetualWhite,
  homeBlogGainwix: homeBlogGainwix,
  homeHelp: homeHelp,
  alertArrow: alertArrow,
  homeBlog: homeBlog,

  //campaign
  speakerphoneWhite: speakerphoneWhite,
  campaignCalendarIcon: campaignCalendarIcon,

  CampaignActiveIcon: CampaignActiveIcon,
  CampaignDraftIcon: CampaignDraftIcon,
  CampaignPausedIcon: CampaignPausedIcon,
  CampaignCompleteIcon: CampaignCompleteIcon,
  CampaignRunningIcon: CampaignRunningIcon,
  CampaignRunningIconWhite: CampaignRunningIconWhite,
  pencil: pencil,
  redDelete: redDelete,

  //new-campaign
  campaignNoData: campaignNoData,
  emptySearch: emptySearch,
  CreateCampaign: CreateCampaign,
  arrowLeftCampaign: arrowLeftCampaign,
  engagementIcon: engagementIcon,
  leadsIcon: leadsIcon,
  awarenessIcon: awarenessIcon,
  newestFirst: newestFirst,
  oldestFirst: oldestFirst,
  ascending: ascending,
  descending: descending,
  allCampaigns: allCampaigns,
  engagementSidebar: engagementSidebar,
  awarenessSidebar: awarenessSidebar,
  leadsSidebar: leadsSidebar,
  timeSpecififed: timeSpecififed,
  perpetual: perpetual,
  scheduled: scheduled,
  runningCampaign: runningCampaign,
  completed: completed,
  draft: draft,
  awarenessWhite: awarenessWhite,
  engagementWhite: engagementWhite,
  leadsWhite: leadsWhite,
  analytics: analytics,
  activeIcon: activeIcon,
  starredUnclicked: starredUnclicked,
  starredClicked: starredClicked,
  starred: starred,
  CreateCampaignNew: CreateCampaignNew,
  campaignCompleteIconNew: campaignCompleteIconNew,
  campaignScheduledNew: campaignScheduledNew,

  //campaign-overview
  sentOverview: sentOverview,
  deliveredOverview: deliveredOverview,
  readOverview: readOverview,
  bouncedOverview: bouncedOverview,
  performanceClicks: performanceClicks,
  performanceReactions: performanceReactions,
  performanceLeads: performanceLeads,
  performanceBlcoked: performanceBlcoked,
  performanceConversation: performanceConversation,
  performanceIcon: performanceIcon,
  goalTargetInfo: goalTargetInfo,
  goalRemainingDays: goalRemainingDays,
  goalTrack: goalTrack,
  leadsGreen: leadsGreen,
  noChartData: noChartData,
  reportDownload: reportDownload,

  //new-broadcast
  broadcastNoData: broadcastNoData,
  broadcastCreate: broadcastCreate,
  sequenceCreate: sequenceCreate,

  //Broadcast
  noBroadcast: noBroadcast,
  noMessage: noMessage,

  BroadCastSettingIcon: BroadCastSettingIcon,
  BroadcastSegmentsIcon: BroadcastSegmentsIcon,
  BroadcastMessageIcon: BroadcastMessageIcon,
  BroadcastReviewIcon: BroadcastReviewIcon,
  chevronRightIcon: chevronRightIcon,
  BroadcastDefineIcon: BroadcastDefineIcon,

  BGChevronRightIcon: BGChevronRightIcon,

  BroadcastCalendarIcon: BroadcastCalendarIcon,
  BroadcastTimerIcon: BroadcastTimerIcon,

  BroadcastDelayIcon: BroadcastDelayIcon,
  BroadcastSegmentIcon: BroadcastSegmentIcon,
  BroadcastAddIcon: BroadcastAddIcon,

  BroadcastChevronUpIcon: BroadcastChevronUpIcon,
  BroadcastChevronDownIcon: BroadcastChevronDownIcon,

  shareLink: shareLink,
  AddTag: AddTag,
  shareLink: shareLink,
  call: call,
  CopyLeft: CopyLeft,
  nounLink: nounLink,
  xSquare: xSquare,
  checkSquare: checkSquare,

  BroadcastRedDeleteIcon: BroadcastRedDeleteIcon,
  BroadcastTickIcon: BroadcastTickIcon,
  BroadcastProfileIcon: BroadcastProfileIcon,
  BroadcastCopyIcon: BroadcastCopyIcon,
  BroadcastRedInfoIcon: BroadcastRedInfoIcon,
  BroadcastLightChevronDown: BroadcastLightChevronDown,
  MaximumIcon: MaximumIcon,

  RedDeleteIcon: RedDeleteIcon,

  BroadcastReplayIcon: BroadcastReplayIcon,
  BroadcastGreenTickIcon: BroadcastGreenTickIcon,

  BroadcastWhatsappAddIcon: BroadcastWhatsappAddIcon,
  BroadcastCameraIcon: BroadcastCameraIcon,
  BroadcastMickIcon: BroadcastMickIcon,
  BroadcastGalleryIcon: BroadcastGalleryIcon,
  BroadcastSegmentDownloadIcon: BroadcastSegmentDownloadIcon,

  BroadcastRoundGreenTick: BroadcastRoundGreenTick,
  CampaignBudgetIcon: CampaignBudgetIcon,

  BroadcastSegmentIcons: BroadcastSegmentIcons,
  BroadcastStarIcon: BroadcastStarIcon,

  BroadcastDownloadIcon: BroadcastDownloadIcon,

  addBGwhite: addBGwhite,

  IndiaIcon: IndiaIcon,
  BroadcastNewSegmentIcon: BroadcastNewSegmentIcon,

  HeaderPlanIcon: HeaderPlanIcon,

  TickIcon: TickIcon,

  SeeAllOptionIcon: SeeAllOptionIcon,
  CopyCodeIcon: CopyCodeIcon,

  BillingUploadIcon: BillingUploadIcon,
  BillingPdfIcon: BillingPdfIcon,
  campaignEye: campaignEye,
  BrandPlusIcon: BrandPlusIcon,
  BrandInviteShareIcon: BrandInviteShareIcon,
  BillingGreyWarningIcon: BillingGreyWarningIcon,
  BrandArrowIcon: BrandArrowIcon,

  noTemplate: noTemplate,
  TemplateName: templateName,

  send: send,
  chevronRightIconWhite: chevronRightIconWhite,
  alertIconError: alertIconError,
  messageSent: messageSent,
  messageFailed: messageFailed,

  //calender
  CalenderIcon: CalenderIcon,
  CalenderArrow: CalenderArrow,
  calenderAudience: calenderAudience,
  calenderContact: calenderContact,
  calenderDinner: calenderDinner,
  calenderScheduled: calenderScheduled,

  BrandInviteAddWhiteIcon: BrandInviteAddWhiteIcon,

  AudienceRoundIcon: AudienceRoundIcon,
  AudienceDownloadIcon: AudienceDownloadIcon,
  AudienceFileIcon: AudienceFileIcon,

  Key: key,
  Profile: profile,
  Lead: lead,
  Click: click,
  Delivered: checkDoubleBlue,
  Opened: checkDoubleOrange,
  Segment: segment,
  ViewCalender: viewCalender,
  Calender: calender,
  NoNotification: noNotification,
  Selected: selected,

  //  inbox
  Email: email,
  PhoneWithBg: phoneWithBg,
  Bold: bold,
  Italic: italic,
  Underline: underline,
  StarGroup: starGroup,
  SelectTemplate: selectTemplate,
  Attachments: attachments,
  NoTemplateSelected: noTemplateSelected,

  WhatsApps: WhatsApps,

  chevronGreen: chevronGreen,

  delayIcon: delayIcon,

  AlertIcon: AlertIcon,

  //inbox-new
  createInboxNew: createInboxNew,
  templateInoboxIcon: templateInoboxIcon,
  sidebarChevronDown: sidebarChevronDown,
  whatsappInbox: whatsappInbox,
  utilityIcon: utilityIcon,
  marketingIcon: marketingIcon,
  backArrow: backArrow,
  inboxOpen: inboxOpen,
  imageInbox: imageInbox,
  videoInbox: videoInbox,
  audioInbox: audioInbox,
  gifInbox: gifInbox,
  documentInbox: documentInbox,
  chooseTemplateInbox: chooseTemplateInbox,
  syncChange: syncChange,
  marketingInbox: marketingInbox,
  utilityInbox: utilityInbox,
  contactRightInbox: contactRightInbox,
  customerJourneyInbox: customerJourneyInbox,
  inboxEdit: inboxEdit,
  inboxFilters: inboxFilters,
  inboxFiltersActive: inboxFiltersActive,
  rightImageScroll: rightImageScroll,
  contactDetailsOpen: contactDetailsOpen,
  customerJourneyOpen: customerJourneyOpen,
  inboxEmpty: inboxEmpty,
  inboxBounced: inboxBounced,
  emptyLoader: emptyLoader,
  carouselEdit: carouselEdit,
  infoIconViolet: infoIconViolet,
  replyIcon: replyIcon,
  copyIcon: copyIcon,

  //contact-view
  FilterSelectedIcon: FilterSelectedIcon,

  DeliveredIcon: DeliveredIcon,
  sentIcon: sentIcon,
  OpenedIcon: OpenedIcon,
  LeadIcon: LeadIcon,
  BouncedIcon: BouncedIcon,
  SendingIcon: SendingIcon,
  Download: Download,
  NoImageIcon: NoImageIcon,

  BroadCastActive: BroadCastActive,

  //stepper
  uploadStep: uploadStep,
  HealthCare: HealthCare,
  eCommerce: eCommerce,
  DigitalAgencies: DigitalAgencies,
  Education: Education,
  stepperTickIcon: stepperTickIcon,
  stepperRoundIcon: stepperRoundIcon,
  Industry: Industry,
  FMCG: FMCG,
  travel: travel,
  other: other,
  Exit: Exit,
  TickGreenIcon: TickGreenIcon,

  //signup
  PersonIcon: PersonIcon,
  EmailArrowIcon: EmailArrowIcon,
  BackIcon: BackIcon,

  //Calendar

  uploadFile: uploadFile,
  startEndDate: startEndDate,

  // pricing
  Starter: starter,
  Growth: growth,
  Magnum: magnum,
  MostPopular: mostPopular,
  PricingArrow: pricingArrow,

  //crm
  crmEmpty: crmEmpty,
  crmWhatsApp: crmWhatsApp,
  crmFaceBook: crmFaceBook,
  crmInstagram: crmInstagram,
  crmLinkedIn: crmLinkedIn,
  crmTwitter: crmTwitter,
  crmTikTok: crmTikTok,
  crmGoogleAds: crmGoogleAds,
  crmWebsite: crmWebsite,
  crmStore: crmStore,
  crmOthers: crmOthers,
  addTags: addTags,
  editCrm: editCrm,
  addWhitePlus: addWhitePlus,
  markPrimary: markPrimary,
  infoPhone: infoPhone,
  infoMail: infoMail,
  crmTagEdit: crmTagEdit,
  crmTagDots: crmTagDots,

  //template
  chevronDownCarousel: chevronDownCarousel,
  CarouselOptions: CarouselOptions,
  carouselDraft: carouselDraft,
  carouselAddCardIamge: carouselAddCardIamge,
  carouselBulb: carouselBulb,
  duplicateModal: duplicateModal,
  discardCarousel: discardCarousel,
  discardframe: discardframe,
  alertFilled: alertFilled,
  locked: locked,
  unlocked: unlocked,
  TemplateButtonShareLink: templateButtonShareLink,

  // sequence
  EndNodeFlag: endNodeFlag,
  ActiveConversion: activeConversion,
  ConversionCost: conversionCost,
  ConvertionWaitTime: convertionWaitTime,
  FirstReply: firstReply,
  Running: running,
  Paused: paused,
  PausedBlack: pausedBlack,
  PausedOrange: pausedOrange,
  TickCircle: tickCircle,
  SequenceTriggered: sequenceTriggered,
  SequenceCompleted: sequenceCompleted,
  SequenceActive: sequenceActive,
  SequenceFailed: sequenceFailed,
  SequenceAbandoned: sequenceAbandoned,
  EditBlack: editBlack,
};
