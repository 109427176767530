import React from 'react';
import { Popover } from 'antd';

// helpetrs
import { cn } from '../../../../../helper/cn';

// components
import CheckBoxSqure from '../../../../../components/commonComponents/checkbox/CheckBoxSqure';
import useToggle from '../../../../../hooks/useToggle';
import { ICONS } from '../../../../../assets/icons';

const getStatusStyles = {
  TRIGGERED: {
    color: '#9747FF',
    bg: '#F7F1FF',
    icon: ICONS?.SequenceTriggered,
  },
  COMPLETED: {
    color: '#00D998',
    bg: '#EBFCF7',
    icon: ICONS?.SequenceCompleted,
  },
  PAUSED: {
    color: '#FB6E42',
    bg: '#FB6E4214',
    icon: ICONS?.PausedOrange,
  },
  FAILED: { color: '#FF543E', bg: '#FFF2F0', icon: ICONS?.SequenceFailed },
  ACTIVE: { color: '#1778F2', bg: '#EDF5FE', icon: ICONS?.SequenceActive },
  ABANDONED: {
    color: '#F6A723',
    bg: '#FFF8EE',
    icon: ICONS?.SequenceAbandoned,
  },
};

const ContactListCard = ({
  data,
  isRowSelected,
  isAllRowSelected,
  trStyle,
  onSelectRow,
}) => {
  const [isActionsOpen, setIsActionsOpen] = useToggle(false);

  const dotStyle =
    'size-[5px] bg-[#898E99] contents-[""] rounded-full group-hove/dots:!bg-[var(--BG-100)]';

  return (
    <div className='w-full h-full flex items-center gap-2.5'>
      <div className={cn('w-[4%] !px-0', trStyle)}>
        <CheckBoxSqure
          value={isRowSelected(data?.id) || isAllRowSelected}
          onChange={(e) => {
            onSelectRow(data);
          }}
          className={`size-4`}
        />
      </div>
      <div className={cn('w-[33%]', trStyle)}>{data?.profile_name}</div>
      <div className={cn('w-[17%] flex justify-start gap-2.5', trStyle)}>
        <div
          className='px-2.5 py-1 rounded-md flex items-center gap-1.5'
          style={{
            bg: getStatusStyles[data?.status]?.bg,
            color: getStatusStyles[data?.status]?.color,
            border: `1.5px solid ${getStatusStyles[data?.status]?.color}`,
          }}>
          <img
            src={getStatusStyles[data?.status]?.icon}
            alt={data?.status}
          />
          <span>{data?.status}</span>
        </div>
      </div>
      <div className={cn('w-[27%]', trStyle)}>{data?.phone_number}</div>
      <Popover
        trigger={['click']}
        open={isActionsOpen}
        onOpenChange={(open) => {
          setIsActionsOpen(open);
        }}>
        <div className={cn('w-[13%]', trStyle)}>
          <div className='w-fit group/dots px-2.5 py-3 flex items-center gap-0.5 hover:cursor-pointer hover:bg-gray-100 rounded-md'>
            <span className={`${dotStyle}`}></span>
            <span className={`${dotStyle}`}></span>
            <span className={`${dotStyle}`}></span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ContactListCard;
