import { Modal, Popover, Skeleton } from "antd";

import React, { useCallback, useRef, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { useLocation, useNavigate } from "react-router-dom";

import { SEQUENCE_STYLES } from "../../constants/Execution";

import { changeSequenceStatus, deleteSequence } from "../../api/sequence";
import { CAMPAIGN_STATUS } from "../../constants/campaigns";
import { ICONS } from "../../../../assets/icons";
import { cn } from "../../../../helper/cn";
import { dateConverter } from "../../../../helper/dateConverter";
import useToggle from "../../../../hooks/useToggle";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import { broadcastDeleteApi } from "../../../campaign/api/Api";
import EditDelete from "../../../../components/commonComponents/EditDeletePopover/EditDelete";
import NoData from "../../../../components/commonComponents/Empty/NoData";
import Alert from "../../../../components/commonComponents/modals/Alert";
import NewAlert from "../../../../components/commonComponents/modal/alert/NewAlert";

export default function List({
  isLoading,
  list,
  setList,
  count,
  loadNextPage,
  isLoadingSkeleton,
  onCreate,
  searchKey,
  selectedTypes,
  statusTrue,
  setSearchKey,
  clearType,
  clearStatus,
}) {
  const listContainerRef = useRef(null);
  const [startPopoverVisible, setStartPopoverVisible] = useState({});
  const [discardAlertOpen, setDiscardAlertOpen] = useToggle(false);
  const [broadcastId, setBroadcastId] = useState(null);

  const [isSequenceStatusUpdate, setIsSequenceStatusUpdate] = useToggle(false);
  const [selectedSequence, setSelectedSequence] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const broadcastdata = location?.state?.data;

  const { currentBrand } = useAspSelector((state) => state.app);

  const togglePopoverVisibility = (id) => {
    setStartPopoverVisible((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleEditBroadcast = (e, item) => {
    e.preventDefault();
    setStartPopoverVisible({});
    navigate(`/user/campaign/time_specified/settings/${item?.campaign_id}`, {
      state: { item: item, data: broadcastdata },
    });
  };

  const handleNavigate = (item) => {
    if (item?.type === "sequence") {
      if (item?.status !== -10)
        navigate(`/user/campaign/${item?.campaign_id}/sequence/${item?.id}`);
    } else {
      if (item?.status !== 0 && item?.status !== -1) {
        navigate(`/user/campaign/time_specified/segment_list/${item?.id}`, {
          state: { item, data: broadcastdata },
        });
      }
    }
  };

  const handleDeleteBroadcast = (item) => {
    setDiscardAlertOpen(true);
    setStartPopoverVisible({});
    setBroadcastId(item);
  };

  const deleteCampaign = (item) => {
    setDiscardAlertOpen(true);
    broadcastDeleteApi(
      currentBrand?.brand_id,
      item?.campaign_id,
      item?.id
    ).then((res) => {
      if (res?.status === 200) {
        window.location.reload();
      }
    });
  };

  const changeStatus = useCallback(
    async ({ sequence }) => {
      try {
        const payload = {
          brand_id: currentBrand?.brand_id,
          campaign_id: sequence?.campaign_id,
          state_action: [0, 2]?.includes(sequence?.status)
            ? "activate"
            : [7, 10, 15]?.includes(sequence?.status)
            ? "pause"
            : sequence?.status === 13 && "start",
        };
        const res = await changeSequenceStatus(sequence?.id, payload);

        if (res?.status === 200) {
          // setSelectedSequence((prev) => ({
          //   ...prev,
          //   status: res?.data?.sequence_status,
          // }));
          const updateList = list?.map((l) => {
            if (l?.id === sequence?.id)
              return {
                ...l,
                status:
                  res?.data?.sequence_status === "PAUSED"
                    ? 13
                    : res?.data?.sequence_status === "ACTIVE"
                    ? 10
                    : res?.data?.sequence_status === "SCHEDULED"
                    ? 7
                    : res?.data?.sequence_status === "RUNNING"
                    ? 15
                    : "",
              };
            return l;
          });
          setList(updateList);
          setIsSequenceStatusUpdate(false);
          // setSelectedSequence(null);
          // togglePopoverVisibility(selectedSequence?.id);
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [currentBrand, selectedSequence]
  );

  const handleEditSequence = (item) => {
    localStorage.setItem(
      "isEditMode",
      [7, 10, 13, 15]?.includes(item?.status) ? true : false
    );
    navigate(`/user/campaign/${item?.campaign_id}/sequence/${item?.id}/flow`, {
      // state: {
      //   isPaused: [7, 10, 15]?.includes(item?.status) ? true : false,
      // },
    });
  };

  const handleDeleteSequence = async (item) => {
    try {
      const res = await deleteSequence(
        item?.id,
        currentBrand?.brand_id,
        item?.campaign_id
      );
      if (res?.status === 200) window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  // styles
  const headerStyle =
    "text-sm weight-semibold text-[var(--font-600)] text-left";
  const bodyStyle =
    "text-sm weight-medium text-[var(--font-600)] text-left pl-1";
  const dotStyle =
    'size-1.5 bg-[var(--BG-100)] contents-[""] rounded-full group-hove/dotsr:!bg-[var(--BG-100)]';

  return (
    <div className="w-full h-full relative overflow-hidden">
      <Skeleton loading={isLoadingSkeleton} active paragraph={{ rows: 6 }}>
        {list?.length > 0 && (
          <div className="w-full h-14 sticky top-0 flex items-center pr-1">
            <div className={cn("new-header2", "w-[50%]")}>Name</div>
            <div className={cn("new-header2", "w-[25%]")}>Status</div>
            <div className={cn("new-header2", "w-[16%]")}>Type</div>
            <div className={cn("new-header2")}>Actions</div>
          </div>
        )}
        <div
          className={`min-w-full h-full min-h-[60vh] overflow-hidden overflow-y-scroll`}
          id="scrollableDiv"
          ref={listContainerRef}
        >
          <InfiniteScroll
            dataLength={count}
            next={() => {
              loadNextPage();
            }}
            hasMore={list?.length < count}
            scrollableTarget="scrollableDiv"
            height={isLoading ? "53vh" : "58vh"}
            className="!pr-1 max-[1600px]:pb-24 min-[1600px]:pb-24 listScroll"
          >
            {/* {isLoading && (
              <div className="w-full h-[5vh] flex items-center justify-center bg-white rounded-lg">
                <Loader Width={30} Height={30} />
              </div>
            )} */}

            {list?.length > 0 ? (
              list?.map((l) => {
                const date = dateConverter(l?.created_at);
                const status = CAMPAIGN_STATUS[l?.status];
                const item = l;

                return (
                  <div
                    key={l?.id}
                    className="w-full odd:bg-gray-50 my-1 py-4 flex items-center rounded-lg pointer"
                  >
                    <div
                      className={cn(bodyStyle, "w-[50%]")}
                      onClick={() => {
                        handleNavigate(item);
                      }}
                    >
                      <div className="flex flex-col gap-2.5 justify-between text-left">
                        <div className="flex items-center gap-3.5">
                          <div className="new-header">
                            {l?.name &&
                              l.name?.trim()?.charAt(0).toUpperCase() +
                                l.name?.trim()?.slice(1).toLowerCase()}
                          </div>
                        </div>
                        <div className="flex items-center gap-3.5">
                          {/* <div className='size-5 rounded-[4px]'>i</div> */}
                          <div className="text-xs">
                            Created @ {date?.monthInShort} {date?.date}{" "}
                            {date?.hours}:{date?.minutes}
                            {date?.meridiem}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={cn(
                        bodyStyle,
                        "w-[25%] flex items-center gap-1.5"
                      )}
                      style={{
                        color: status?.color,
                      }}
                      onClick={() => {
                        handleNavigate(item);
                      }}
                    >
                      {l?.type === "sequence" ? (
                        <div
                          className="flex items-center gap-2.5 px-2.5 py-1 rounded-full"
                          style={{
                            background: SEQUENCE_STYLES[l?.status]?.bg,
                            color: SEQUENCE_STYLES[l?.status]?.color,
                            border: `1px solid ${
                              SEQUENCE_STYLES[l?.status]?.color
                            }`,
                          }}
                        >
                          {SEQUENCE_STYLES[l?.status]?.icon && (
                            <img
                              src={SEQUENCE_STYLES[l?.status]?.icon}
                              alt={SEQUENCE_STYLES[l?.status]?.text}
                            />
                          )}
                          <p className="capitalize">
                            {SEQUENCE_STYLES[l?.status]?.text}
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{
                            background: status?.background,
                            border: `1px solid ${status?.border}`,
                          }}
                          className="flex-row align-center rounded-full  p-1 pl-10 pr-10 gap-1.5"
                          onClick={() => {
                            handleNavigate(item);
                          }}
                        >
                          {status?.icon && (
                            <img src={status?.icon} alt={status?.text} />
                          )}
                          {status?.text}
                        </div>
                      )}
                    </div>
                    <div
                      className={cn("new-body-text", "w-[15%]")}
                      onClick={() => {
                        handleNavigate(item);
                      }}
                    >
                      {l?.type ?? "Broadcast"}
                    </div>
                    <div
                      className={cn(
                        "new-body-text",
                        "w-[10%] flex items-center gap-3"
                      )}
                    >
                      <img
                        src={ICONS?.analytics}
                        alt="analytics"
                        onClick={() => {
                          handleNavigate(item);
                        }}
                        className="hover:bg-gray-100 p-10 b-radius-6"
                      />
                      <Popover
                        content={
                          <div className="min-w-40 px-2 py-1 mb-3.5 flex flex-col">
                            {l?.type === "sequence" && (
                              <div className="w-full flex-column">
                                {[7, 10, 13, 15]?.includes(item?.status) && (
                                  <div className="py-1 tags-border">
                                    <button
                                      className="w-full h-11 px-2.5 py-1.5 flex items-center justify-center gap-2.5 rounded-md hover:bg-[var(--BG-50)]"
                                      onClick={() => {
                                        setSelectedSequence(l);
                                        if (item?.status !== 13)
                                          setIsSequenceStatusUpdate(true);
                                        else changeStatus({ sequence: l });

                                        togglePopoverVisibility(l?.id);
                                      }}
                                    >
                                      <img
                                        src={ICONS?.PausedBlack}
                                        alt=""
                                        className=""
                                      />
                                      <span className="text-[0.8vw] text-[var(--contentText)] font-500">
                                        {item?.status === 13
                                          ? "Resume"
                                          : "Pause"}
                                      </span>
                                    </button>
                                  </div>
                                )}

                                <div className="py-1 tags-border">
                                  <button
                                    className="w-full h-11 px-2.5 py-1.5 flex items-center justify-center gap-2.5 rounded-md hover:bg-[var(--BG-50)]"
                                    onClick={() => {
                                      handleEditSequence(l);
                                    }}
                                  >
                                    <img
                                      src={ICONS?.crmTagEdit}
                                      alt=""
                                      className=""
                                    />
                                    <span className="text-[0.8vw] text-[var(--contentText)] font-500">
                                      Edit
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )}
                            {l?.status !== 12 && l?.status !== 11 && (
                              <EditDelete
                                onEditClick={(e) => handleEditBroadcast(e, l)}
                                onDeleteClick={() => {
                                  handleDeleteBroadcast(l);
                                }}
                                showEdit={
                                  l?.type !== "sequence" &&
                                  // broadcastdata?.status === 11 &&
                                  l?.status !== 11 &&
                                  l?.status !== 12 &&
                                  true
                                }
                                rootClassName="p-0"
                              />
                            )}
                          </div>
                        }
                        trigger="click"
                        arrow={false}
                        open={startPopoverVisible[l?.id] || false}
                        onOpenChange={() => togglePopoverVisibility(l?.id)}
                      >
                        {/* <div className="group/dots px-2.5 py-4 flex items-center gap-0.5 hover:cursor-pointer hover:bg-gray-100 rounded-md">
                            <span className={`${dotStyle}`}></span>
                            <span className={`${dotStyle}`}></span>
                            <span className={`${dotStyle}`}></span>
                          </div> */}
                        <div
                          className={`dots group/dots px-2.5 py-4 flex items-center gap-0.5 hover:bg-gray-100 rounded-md ${
                            l?.status === 12 || l?.status === 11
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                        >
                          <span
                            className={`${dotStyle} ${
                              l?.status === 12
                                ? "bg-[#E8E8EA]"
                                : "bg-[var(--BG-200)]"
                            }`}
                          ></span>
                          <span
                            className={`${dotStyle} ${
                              l?.status === 12
                                ? "bg-[#E8E8EA]"
                                : "bg-[var(--BG-200)]"
                            }`}
                          ></span>
                          <span
                            className={`${dotStyle} ${
                              l?.status === 12
                                ? "bg-[#E8E8EA]"
                                : "bg-[var(--BG-200)]"
                            }`}
                          ></span>
                        </div>
                      </Popover>
                    </div>
                  </div>
                );
              })
            ) : !searchKey && !selectedTypes && !statusTrue ? (
              <div className="w-full flex justify-center campaign-no-data">
                <NoData
                  title="Create New Broadcast"
                  content="Go ahead, create your first broadcast to start engaging with your visitors and turn them into customers!"
                  modal={
                    <div>
                      {broadcastdata?.status !== 12 && (
                        <div
                          className={cn(
                            "w-[10vw] text-center py-1.5 bg-[var(--new-primary)] text-white border border-[var(--new-primary)] rounded-lg cursor-pointer"
                          )}
                          onClick={() => {
                            onCreate(true);
                          }}
                        >
                          Create New
                        </div>
                      )}
                    </div>
                  }
                  icon={ICONS?.broadcastNoData}
                  className="h-full"
                />
              </div>
            ) : (
              <div className="h-[60vh] flex justify-center mt-20 pt-20">
                <div className="flex-row h-10 mt-20">
                  <img
                    src={ICONS?.emptySearch}
                    alt="emptySearch"
                    className="w-14"
                  />
                  <div className="flex-column gap-3 pl-10">
                    <span className="new-header3 ">
                      Sorry, there are no matching results
                    </span>
                    <span className="no-search-body">
                      Use different filters or{" "}
                      <span
                        className="text-[#6940F2] pointer"
                        onClick={() => {
                          setSearchKey("");
                          clearType();
                          clearStatus();
                        }}
                      >
                        reset to default.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </InfiniteScroll>
        </div>
      </Skeleton>

      <Modal
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.discardCarousel}
          title={
            broadcastId?.type === "sequence"
              ? "Delete Sequence?"
              : "Delete Broadcast?"
          }
          setOpen={setDiscardAlertOpen}
          content={
            <p>
              Are you sure you want to delete this <b>"{broadcastId?.name}"</b>?
            </p>
          }
          noName="Yes, Delete"
          yesName="No"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => setDiscardAlertOpen(false)}
          handleYes={() => {
            if (broadcastId?.type === "sequence")
              handleDeleteSequence(broadcastId);
            else deleteCampaign(broadcastId);
          }}
        />
      </Modal>

      <Alert
        open={isSequenceStatusUpdate}
        setOpen={setIsSequenceStatusUpdate}
        icon={ICONS?.PausedBlack}
        title="Pause this sequence?"
        content="Pausing this sequence may disrupt actions and delay tasks. Proceed only if necessary."
        yesContent="Pause Sequence"
        noContent="Cancel"
        handleYes={() => {
          if (selectedSequence?.status !== 20)
            changeStatus({ sequence: selectedSequence });
        }}
        handleNo={() => setIsSequenceStatusUpdate(false)}
      />
    </div>
  );
}
