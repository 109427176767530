import { images } from "assets/images";
import { ICONS } from "../../../assets/icons/index";

export const campaignTypes = [
  {
    id: 2,
    label: 2,
    value: "Time Specified",
    selected: false,
  },
  // {
  //   id: 1,
  //   label: 1,
  //   value: "Unspecified Campaign",
  //   selected: false,
  // },
  // {
  //   id: 3,
  //   label: 3,
  //   value: "Perpetual Campaign",
  //   selected: false,
  // },
];

export const campaignLeftList = [
  {
    id: 1,
    name: "All Campaigns",
    paramsId: "All Campaigns",
    isVisible: true,
    route: "all",
  },
  {
    id: 2,
    name: "Time Specified Campaigns",
    paramsId: "Time Specified Campaigns",
    isVisible: true,
    route: "time_specified",
  },
  {
    id: 3,
    name: "Perpetual Campaigns",
    paramsId: "Perpetual Campaigns",
    isVisible: true,
    route: "perpetual",
  },
];
export const SettingList = [
  {
    id: 1,
    name: "Brand Settings",
    paramsId: "Settings",
  },
];

// ========

export const posterlist = [
  {
    id: 1,
    icon: ICONS?.BudgetIcon,
    title: "Total Template",
    status: [],
  },
  {
    id: 2,
    icon: ICONS?.ActiveIcon,
    title: "Active",
    status: ["APPROVED", 10],
  },
  {
    id: 3,
    icon: ICONS?.SpentIcon,
    title: "In-review",
    status: ["PENDING", "IN_REVIEW"],
  },
  {
    id: 4,
    icon: ICONS?.xCircleIcon,
    title: "Rejected",
    status: ["REJECTED"],
  },
];
export const campaignBroadcastList = [
  {
    id: 1,
    icon: images?.CampaignProspectsIcon,
    title: "Contact",
    content: "0",
  },
  {
    id: 2,
    icon: images?.CampaignDeliveredIcon,
    title: "Delivered",
    content: "0",
  },
  {
    id: 3,
    icon: images?.CampaignOpenedIcon,
    title: "Opened",
    content: "0",
  },
  {
    id: 4,
    icon: images?.CampaignShareIcon,
    title: "Lead",
    content: "0",
  },
  {
    id: 5,
    icon: ICONS?.CampaignBudgetIcon,
    title: "Clicks",
    content: "0",
    pageNavigate: false,
  },
];
export const campaignApiList = [
  {
    id: 1,
    icon: images?.CampaignProspectsIcon,
    title: "Contact",
    content: "0",
  },
  {
    id: 2,
    icon: images?.CampaignDeliveredIcon,
    title: "Delivered",
    content: "0",
  },
  {
    id: 3,
    icon: images?.CampaignOpenedIcon,
    title: "Opened",
    content: "0",
  },
  {
    id: 4,
    icon: images?.CampaignShareIcon,
    title: "Lead",
    content: "0",
  },
  {
    id: 5,
    icon: ICONS?.CampaignBudgetIcon,
    title: "Bounced",
    content: "0",
  },
];
export const inputCalendar = [
  { id: 1, label: "Duration", value: "" },
  { id: 2, label: "To", value: "", color: "red" },
];
export const CampaignType = [
  {
    value: "time_specified",
  },
  // {
  //   label: "Awareness",
  //   value: "Awareness",
  // },
];
export const CalendarList = [
  {
    id: 1,
    label: "Start Date",
  },
  {
    id: 2,
    label: "End Date",
  },
];
export const campaignFooter = [
  {
    id: 1,
    name: "Choose color",
    label: "Color",
    icons: ICONS?.addBGtransparent,
    iconType: "big",
  },
];

export const campaignNameAction = [
  // { id: 1, name: 'eye', icon: ICONS?.campaignEye },
  // { id: 2, name: 'copy', icon: ICONS?.campaignCopy },
  // { id: 3, name: 'share', icon: ICONS?.campaignShare },
  { id: 1, name: "edit", icon1: ICONS?.edit, status: ["APPROVED"] },
  {
    id: 2,
    name: "delete",
    icon: ICONS?.campaignDelete,
    icon2: ICONS?.redDelete,
    status: [10],
  },
];

export const campaignTableHeaderList = [
  { id: 1, name: "Status", icon: ICONS?.navCalendar, selected: true },
  {
    id: 2,
    name: "Campaign Type",
    icon: ICONS?.tableHeaderBudget,
    selected: true,
  },
  { id: 3, name: "Duration", icon: ICONS?.refresh, selected: true },
];

export const CampaignRightList = [
  {
    id: 1,
    deal: "New year sale campaign",
    icon: ICONS?.CampaignActiveIcon,
    status: "Active",
    CampaignType: "Acquisition",
    image: ICONS?.navCalendar,
    Duration: "Nov 01 - Jan 31",
    statusColor: "var(--approvedStatus)",
    borderColor: "var(--campaignColor)",
    is_selected: false,
  },
  {
    id: 2,
    deal: "Vinayagar chadhurthi offer sale",
    icon: ICONS?.CampaignDraftIcon,
    status: "Draft",
    CampaignType: "Awareness",
    image: ICONS?.navCalendar,
    Duration: "Aug 01 - Sep 31",
    statusColor: "var(--font-600)",
    borderColor: "var(--approvedStatus)",
    is_selected: false,
  },
  {
    id: 3,
    deal: "Diwali sale campaign",
    icon: ICONS?.CampaignPausedIcon,
    status: "Paused",
    CampaignType: "conversation",
    image: ICONS?.navCalendar,
    Duration: "Sep 01 -Nov 31",
    statusColor: "var(--a)",
    borderColor: "var(--fontRed)",
    is_selected: false,
  },
  {
    id: 4,
    deal: "Independence sale offer",
    icon: ICONS?.CampaignCompleteIcon,
    status: "Completed",
    CampaignType: "Acquisition",
    image: ICONS?.navCalendar,
    Duration: "Jul 25 - Aug 31",
    borderColor: "var(--dashboardprimary)",
    statusColor: "var(--reviewStatus)",
    is_selected: false,
  },
  {
    id: 5,
    deal: "Big billion day special",
    icon: ICONS?.CampaignRunningIcon,
    status: "Running",
    CampaignType: "Awareness",
    image: ICONS?.navCalendar,
    Duration: "Jul 25 - Aug 31",
    borderColor: "var(--primary)",
    statusColor: "var(--primary)",
    is_selected: false,
  },
  {
    id: 6,
    deal: "Black Friday sale combo offer with extra Discounts",
    icon: ICONS?.CampaignDraftIcon,
    status: "Draft",
    CampaignType: "Awareness",
    image: ICONS?.navCalendar,
    Duration: "Jul 25 - Aug 31",
    borderColor: "var(--statusFont)",
    statusColor: "var(--font-600)",
    is_selected: false,
  },
  {
    id: 7,
    deal: "New year sale campaign ",
    icon: ICONS?.CampaignPausedIcon,
    status: "Paused",
    CampaignType: "Acquisition",
    image: ICONS?.navCalendar,
    Duration: "Nov 01 - Jan 31",
    borderColor: "var(--campaignColor)",
    statusColor: "var(--a)",
    is_selected: false,
  },
  {
    id: 8,
    deal: "Vinayagar chadhurthi offer sale",
    icon: ICONS?.CampaignPausedIcon,
    status: "Paused",
    CampaignType: "Awareness",
    image: ICONS?.navCalendar,
    Duration: "Aug 01 - Sep 31",
    borderColor: "var(--approvedStatus)",
    statusColor: "var(--a)",
    is_selected: false,
  },
  {
    id: 9,
    deal: "Diwali sale campaign",
    icon: ICONS?.CampaignCompleteIcon,
    status: "completed",
    CampaignType: "Conversation",
    image: ICONS?.navCalendar,
    Duration: "Sep 01 - Nov 31",
    borderColor: "var(--fontRed)",
    statusColor: "var(--reviewStatus)",
    is_selected: false,
  },
];


export const posterList = [
  {
    id: 1,
    icon: ICONS?.BudgetIcon,
    title: "Total Template",
    content: "53",
  },
  {
    id: 2,
    icon: ICONS?.ActiveIcon,
    title: "Active",
    content: "28",
  },
  {
    id: 3,
    icon: ICONS?.SpentIcon,
    title: "In-review",
    content: "18",
  },
  {
    id: 4,
    icon: ICONS?.xCircleIcon,
    title: "Rejected",
    content: "18",
  },
];

export const SendPeopleList = [
  {
    id: 1,
    name: "Gochew Grill",
    quality_rating: "High",
    Duration: "10k / 24hrs",
    icon: ICONS?.BroadcastGreenTickIcon,
    phone_num: "+91 9876543210",
  },
  {
    id: 2,
    name: "VGN Homes",
    quality_rating: "Medium",
    Duration: "10k / 24hrs",
    phone_num: "+91 9876543210",
  },
  {
    id: 3,
    name: "Aachi Groups",
    quality_rating: "Low",
    Duration: "10k / 24hrs",
    icon: ICONS?.BroadcastGreenTickIcon,
    phone_num: "+91 9876543210",
  },
];

export const calendarHeader = [
  {
    id: 1,
    icon1: ICONS?.chevronRight,
    image: ICONS?.chevronLeft,
    icon: ICONS?.HeaderSwitchingCalender,
  },
];

export const calendarOption = [
  {
    id: 1,
    name: "Week",
  },

  {
    id: 2,
    name: "Month",
  },
];

export const cardActionsList = [
  { id: 1, name: "Share", icon: ICONS?.Share },
  { id: 2, name: "Duplicate", icon: ICONS?.CopyText },
  { id: 3, name: "Delete", icon: ICONS?.RedDelete },
];

export const campaignCreate = [
  // { id: 1, icon: ICONS.okrCampaignIcon, name: 'OKR' },
  { id: 1, icon: ICONS.postCampaignIcon, name: "Post" },
  { id: 2, icon: ICONS.CopyText, name: "Time Specified" },
  // { id: 3, icon: ICONS.campaign2Icon, name: 'Campaign' },
  // { id: 3, icon: ICONS.taskCampaignIcon, name: 'Task' },
];

export const CampaignActions = [
  { id: 1, count: 2, icon: ICONS?.Attachment },
  { id: 2, count: 2, icon: ICONS?.Chat },
  { id: 3, count: 2, icon: ICONS?.subtask },
];
