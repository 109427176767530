import { ICONS } from 'assets/icons';
import SequencePreview from 'assets/customSVG/sequence/SequencePreview';
import Whatsapp from 'assets/customSVG/Whatsapp';
import Website from 'assets/customSVG/sequence/Website';
import Instagram from 'assets/customSVG/sequence/Instagram';
import Meta from 'assets/customSVG/sequence/Meta';
import QRCode from 'assets/customSVG/sequence/QRCode';
import FB from 'assets/customSVG/sequence/FB';
import Broadcast from 'assets/customSVG/sequence/Broadcast';

export const MAX_SEQUENCE_LENGTH = 64;

export const MAX_NODE_BASED_ON_PLANS = {
  'Starter Plan': 0,
  'Growth Plan': 40,
  'Magnum Plan': 80,
};

export const ALLOWED_STATUS_TO_EDIT_FLOW = ['DRAFT', 'NEW'];

export const SEQUENCE_STATUS_STYLES = {
  DRAFT: {
    bg: '#CAD3E8',
    color: 'var(--contentText)',
    text: 'Draft',
    icon: null,
  },
  NEW: {
    bg: '#CAD3E8',
    color: 'var(--contentText)',
    text: 'Draft',
    icon: null,
  },
  ACTIVE: {
    bg: '#25C277',
    color: '#ffffff',
    text: 'Running',
    icon: ICONS?.Running,
  },
  RUNNING: {
    bg: '#25C277',
    color: '#ffffff',
    text: 'Running',
    icon: ICONS?.Running,
  },
  PAUSED: {
    bg: '#FF7700',
    color: '#ffffff',
    text: 'Paused',
    icon: ICONS?.Paused,
  },
  SCHEDULED: {
    bg: '#F6A723',
    color: '#ffffff',
    text: 'Scheduled',
    icon: ICONS?.Running,
  },
  COMPLETED: {
    bg: '#4C90F5',
    color: '#ffffff',
    text: 'Completed',
    icon: ICONS?.TickCircle,
  },
};

export const PREVIEW_CONTENTS = [
  {
    id: 1,
    title: 'In App Preview',
    content:
      'Play the Sequence right here. Useful for checking message order, texts and making quick changes.',
    icon: SequencePreview,
    width: 24,
    height: 24,
  },
  {
    id: 2,
    title: 'In WhatsApp Preview',
    content:
      'Test the Sequence in WhatsApp exactly as your customers see it. For final check before publishing.',
    icon: Whatsapp,
    width: 20,
    height: 20,
  },
];

export const TRIGGERS_ICONS = {
  CAMP_WAPP_BCAST: {
    icon: Broadcast,
    color: '#FFFFFF',
    bg: '#04B8A2',
  },
  BROADCAST: {
    icon: Broadcast,
    color: '#FFFFFF',
    bg: '#04B8A2',
  },
  WHATSAPP: {
    icon: Whatsapp,
    color: '#04B8A2',
    bg: '#ffffff',
  },
  QR_CODE: {
    icon: QRCode,
    color: '#FFFFFF',
    bg: '#FFC261',
  },
  FB: {
    icon: FB,
    color: '#FFFFFF',
    bg: '#0082FB',
  },
  META: {
    icon: Meta,
    color: '#FFFFFF',
    bg: '#0082FB',
  },
  INSTAGRAM: {
    icon: Instagram,
    color: '#FFFFFF',
    bg: 'linear-gradient(45deg, #FFC107 9.15%, #F44336 50.57%, #9C27B0 90.03%)',
  },
  WEBSITE: {
    icon: Website,
    color: '#FFFFFF',
    bg: '#0082FB',
  },
};

export const TRIGGERS = [
  {
    id: 1,
    title: 'Triggers',
    list: [
      {
        id: 1,
        label: 'Broadcast',
        text: 'Engage your audience with sequences',
        key: 'CAMP_WAPP_BCAST',
        disabled: false,
        warning: false,
        error: false,
        bg: 'var(--primary)',
      },
    ],
  },
  {
    id: 2,
    title: 'Suggested Triggers',
    list: [
      {
        id: 1,
        label: 'QR Code scan',
        text: 'Bring users from the QR code scan',
        key: 'qr_code',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 2,
        label: 'Message via Post',
        text: 'Response when a user message from Post',
        key: 'fb',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 3,
        label: 'Message via Profile',
        text: 'Lorem ipsum dolor sit amet consectetu',
        key: 'instagram',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 4,
        label: 'Website',
        text: 'Bring users from the website.',
        key: 'website',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 5,
        label: 'Message via Page',
        text: 'Response when a user message from Page',
        key: 'fb',
        disabled: true,
        warning: false,
        error: false,
      },
    ],
  },
  {
    id: 3,
    title: 'Ads Trigger',
    list: [
      {
        id: 1,
        label: 'Meta Ads (CTWA)',
        text: 'Bring users from Facebook ads',
        key: 'meta',
        disabled: true,
        warning: false,
        error: false,
      },
    ],
  },
];

export const NODE_TYPES = {};

export const INITIAL_TRIGGER_DATA = {
  CAMP_WAPP_BCAST: {
    trigger_type: 'CAMP_WAPP_BCAST',
    label: 'Broadcast',
    component_type: 'CUSTOM',
  },
};

export const INITIAL_NODE_COMPONENT_DATA = {
  CAMP_WAPP_BCAST: {
    broadcast_name: '',
  },
  TEXT: {
    type: 'text',
    text: {
      link: '',
    },
  },
  VIDEO: {
    type: 'video',
    video: {
      link: '',
    },
  },
  interactive: { type: 'interactive' },
  list: {
    type: 'list',
    template: [],
  },
  template: {
    type: 'template',
    template: [],
  },
  nudge: {},
  follow_up: {},
};

export const ALLOWED_HEADER_NODE_TYPES = ['MEDIA', 'BUTTON', 'LIST'];
export const ALLOWED_BODY_NODE_TYPES = ['TEXT', 'BUTTON', 'LIST'];
export const ALLOWED_FOOTER_NODE_TYPES = ['BUTTON', 'LIST'];
export const ALLOWED_BUTTONS_NODE_TYPES = ['BUTTON', 'LIST'];

export const INITIAL_NEW_BROADCAST_DATA = {
  isExisting: false,
  file: null,
  fileUrl: null,
  sendFrom: null,
  template: null,
  settings: null,
};

export const INITIAL_EXISTING_BROADCAST_DATA = {
  selectedBroadcast: null,
  isExisting: true,
};

export const SEND_MESSAGE_TYPES = [
  {
    id: 1,
    label: 'Text Message',
    key: 'text',
  },
  {
    id: 2,
    label: 'Media Message',
    key: 'media',
  },
  {
    id: 3,
    label: 'Interactive Message',
    key: 'interactive',
  },
  {
    id: 4,
    label: 'List Message',
    key: 'list',
  },
  {
    id: 5,
    label: 'Template Message',
    key: 'template',
  },
];

export const NONE_TYPE = {
  id: 1,
  label: 'None',
  key: 'NONE',
};

export const TEXT_TYPE = {
  id: 2,
  label: 'Text',
  key: 'TEXT',
};

export const IMAGE_TYPE = {
  id: 3,
  label: 'Image',
  key: 'IMAGE',
};

export const VIDEO_TYPE = {
  id: 4,
  label: 'Video',
  key: 'VIDEO',
};

export const AUDIO_TYPE = {
  id: 5,
  label: 'Audio',
  key: 'AUDIO',
};

export const DOCUMENT_TYPE = {
  id: 6,
  label: 'Document',
  key: 'DOCUMENT',
};

export const MEDIA_TYPES = [IMAGE_TYPE, VIDEO_TYPE, AUDIO_TYPE, DOCUMENT_TYPE];

export const FOOTER_TYPES = [NONE_TYPE, TEXT_TYPE];

export const HEADER_DETAILS = {
  IMAGE: {
    label: 'Drop a JPG or PNG here',
    note: 'Image size should be less than 5MB',
    accept: '.jpg, .jpeg, .png',
  },
  VIDEO: {
    label: 'Drop a MP4 video here',
    note: 'Video size should be less than 16MB',
    accept: '.mp4',
  },
  DOCUMENT: {
    label: 'Drop a PDF file here',
    note: 'Document size should be less than 100MB',
    accept: '.pdf, .doc',
  },
  AUDIO: {
    label: 'Drop an audio here',
    note: 'Audio size should be less than 16MB',
    accept: '.mp3',
  },
};

export const DEFAULT_MEDIA_SIZE = {
  image: 160,
  video: 160,
  document: 160,
  audio: 45,
  text: 7,
};

export const maxButtonCount = 3;

export const BUTTON_TYPES = [
  NONE_TYPE,
  {
    id: 2,
    label: 'Quick Reply',
    key: 'QUICK_REPLAY',
  },
];
