import React, { useCallback, useEffect, useRef } from 'react';
import { Popover } from 'antd';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

// helpers
import { cn } from '../../../../../helper/cn';
import useToggle from '../../../../../hooks/useToggle';
import { ICONS } from '../../../../../assets/icons';

const BodyMessage = ({
  placeholder,
  value,
  onChange,
  labelClassName,
  limit,
}) => {
  const textareaRef = useRef(value ?? '');
  const [showEmojiPicker, setShowEmojiPicker] = useToggle(false);

  // useEffect(() => {
  //   textareaRef.current.focus();
  // }, []);

  const handleEmojiClick = (emoji) => {
    const textarea = textareaRef.current;
    const { selectionStart, selectionEnd, value } = textarea;

    const beforeSelection = value.slice(0, selectionStart);
    const afterSelection = value.slice(selectionEnd);
    const newText = beforeSelection + emoji?.native + ' ' + afterSelection;

    textarea.value = newText;
    textarea.setSelectionRange(selectionStart + 3, selectionStart + 3);
    textarea.focus();

    onChange(newText?.slice(0, limit));
    setShowEmojiPicker(false);
  };

  const totalBodyMessageTextCount = useCallback(() => {
    if (!value) return 0;
    return value?.length || 0;
  }, [value]);

  return (
    <div className=' flex flex-col gap-1'>
      <h5 className={cn('', labelClassName)}>Body</h5>
      <div className='w-full border border-[#E8E8EA] rounded-md'>
        <textarea
          className='w-full h-32 resize-none bg-transparent text-sm weight-medium px-2.5 py-1 outline-none overflow-y-scroll listScroll'
          placeholder={placeholder ?? 'Enter text'}
          value={value}
          ref={textareaRef}
          onClick={(e) => textareaRef?.current?.focus()}
          maxLength={limit}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
        />
        <div className='w-full h-7 px-2.5 py-0.5'>
          <div className='flex items-center justify-end gap-2.5'>
            <Popover
              trigger={showEmojiPicker ? ['click'] : []}
              open={showEmojiPicker}
              onOpenChange={(open) => {
                setShowEmojiPicker(open);
              }}
              content={
                <Picker
                  data={data}
                  onEmojiSelect={(emoji) => {
                    handleEmojiClick(emoji);
                  }}
                  searchPosition='none'
                  previewPosition='none'
                  data-testid='emoji-clicks'
                />
              }>
              <button
                className='flex items-center justify-center'
                type='button'
                onClick={() => {
                  setShowEmojiPicker(!showEmojiPicker);
                }}
                data-testid='emoji-click'>
                <img
                  src={ICONS?.step3EmojiIcon}
                  alt='emoji'
                  className='size-4'
                />
              </button>
            </Popover>
            <div className='w-auto h-5 pl-2.5 flex items-center text-[0.688rem] border-l border-[var(--border-100)] weight-semibold text-[#898E99]'>
              {totalBodyMessageTextCount()}/{limit}
            </div>
          </div>
        </div>
      </div>
      <p className='text-sm weight-medium mt-1'>
        You can send manual messages within the{' '}
        <span className='text-[#6564CA]'>
          24-hour
          <br /> conversation window.
        </span>
      </p>
    </div>
  );
};

export default BodyMessage;
