import React, { useState } from 'react';
import { cn } from '../../../../helper/cn';

const Switch = ({
  label,
  isChecked,
  toggleSwitch,
  rootClassName,
  className,
  allow = true,
}) => {
  return (
    <div className='flex items-center'>
      <div
        className={cn(
          'relative inline-block w-7 h-5 rounded-full',
          isChecked ? 'bg-[var(--primary)]' : 'bg-gray-300',
          allow ? 'cursor-pointer' : 'cursor-not-allowed',
          rootClassName
        )}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (allow) toggleSwitch(e);
        }}
      >
        <span
          className={cn(
            'absolute left-[3px] top-[3px] size-3.5 bg-white rounded-full transition-all duration-300 ease-in-out',
            isChecked ? 'transform translate-x-2' : '',
            className
          )}
        ></span>
      </div>
      {label && (
        <label htmlFor='toggle' className='mr-2 text-gray-700'>
          {label}
        </label>
      )}
    </div>
  );
};

export default Switch;
