import React, { useState } from "react";
import ListViewSearch from "../../../../../components/commonComponents/ListViewHeader/ListViewSearch";
import RadioButton from "../../../../../components/form/radio/RadioButton";

export const City = ({
  cityList,
  selectedLocation,
  handleLocationClick,
  locationType,
}) => {
  const [searchKey2, setSearchKey2] = useState("");

  const handleSearch2 = (value) => {
    setSearchKey2(value);
  };
  const filteredCityList = cityList.filter((data) =>
    data.name.toLowerCase().includes(searchKey2.toLowerCase())
  );
  return (
    <div className="country-popover flex-column w-100 p-10">
      <ListViewSearch searchingText={(text) => handleSearch2(text)} />

      {filteredCityList?.map((data) => {
        return (
          <div
            className="flex-column pointer p-10"
            key={data?.id}
            onClick={() => {
              handleLocationClick("city", data?.name, data?.id, locationType);
            }}
          >
            <div className="flex-row align-center justify-between">
              <span className="">{data?.name}</span>
              {selectedLocation?.city?.id === data?.id && (
                <RadioButton selected={true} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
