import React, { useEffect, useState } from 'react';

// assets
import { ICONS } from 'assets/icons';

// utils
import { Popover } from 'antd';

// helper
import { cn } from 'helper/cn';

// hooks
import useToggle from 'hooks/useToggle';

// constants
import { countryList } from 'constant/app/countryList';

// components
import SearchBar from '../searchBar/index';
import RadioButton from 'components/form/radio/RadioButton';

export default function CountryCode({
  list = countryList,
  value,
  onChange,
  className = '',
  modalClassName = '',
  expandable = true,
}) {
  const [selected, setSelected] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [open, setOpen] = useToggle(false);

  useEffect(() => {
    if (typeof value === 'string') {
      const res = list.filter((l) => l.value === value);
      setSelected(res[0]);
    } else {
      setSelected(value);
    }
  }, [value]);

  return (
    <Popover
      onOpenChange={(open) => {
        setOpen(open);
        setSearchKey('');
      }}
      open={open}
      trigger={['click']}
      content={
        <div
          className={cn(
            'w-[15vw] pl-[0.5vw] pb-[0.5vw] pt-[0.7vw] pr-[0.2vw]',
            modalClassName
          )}
        >
          <div className='h-[5vh] pr-[0.5vw] mb-[0.4vw]'>
            <SearchBar
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              onChange={(e) => setSearchKey(e)}
              expandable={true}
              className='w-full h-7'
            />
          </div>
          <div
            className={`max-h-[35vh] pt-[0.3vw] overflow-y-scroll listScroll pr-[0.3vw]`}
          >
            {list?.map((l, i) => {
              const codefilter = l?.value.includes(searchKey);
              const name = l?.label
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase());
              const shortNameFilter = l?.shortName
                .toLocaleLowerCase()
                .includes(searchKey.toLocaleLowerCase());
              return (
                (codefilter || name || shortNameFilter) && (
                  <div
                    key={`country-code-for-${l.shortName}`}
                    className='border-b-[0.08vw] border-[var(--border-50)] cursor-pointer last:border-b-0'
                    onClick={() => {
                      setSelected(l);
                      setOpen(false);
                      setSearchKey('');
                      if (onChange) onChange(l);
                    }}
                  >
                    <div
                      className={`h-auto flex items-center justify-between px-[0.5vw] rounded-[0.5vw] py-[0.3vw] my-[0.3vw] hover:bg-slate-100`}
                    >
                      <div className=''>
                        <div className='font-xs weight-semibold text-[#898E99]'>
                          {l?.label}
                        </div>
                        <div className='flex items-center gap-[0.7vw] font-md weight-semibold'>
                          <div>{l?.shortName}</div>
                          <div>{l?.value}</div>
                        </div>
                      </div>
                      {selected?.shortName === l?.shortName && (
                        <div className='mt-[0.5vw]'>
                          <RadioButton selected={true} />
                        </div>
                      )}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      }
    >
      <div
        className={cn(
          `w-full h-full border-[0.08vw] flex items-center justify-between gap-[1vw] font-md weight-medium rounded-[0.4vw] px-[0.5vw] py-[0.2vw] cursor-pointer`,
          className
        )}
      >
        <div>
          {typeof selected === 'string' ? selected : selected?.value ?? value}
        </div>
        <div>
          <img
            src={ICONS?.SelectDropdown}
            alt='icons'
            className={`transition-transform ease-linear duration-200 ${
              open ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </div>
      </div>
    </Popover>
  );
}
