import React from 'react';

// assets
import Delete from '../../../../../assets/customSVG/Delete';

// components
import Input from '../Input';

const QuickReplyButtons = ({
  index,
  deleteButton,
  onChange,
  onInputChange,
  title,
  limit,
  showDelete,
}) => {
  return (
    <div>
      <div className='flex items-center justify-between'>
        <h5 className='w-full h-5 text-sm weight-semibold text-[#616874]'>
          Button {index}
        </h5>
        {showDelete && (
          <button
            className={`size-5 bg-transparent flex items-center justify-center rounded-[0.4vw] hover:bg-red-200`}
            onClick={() => {
              deleteButton();
            }}
          >
            <Delete
              width='12'
              height='12'
              className={`stroke-[#898E99] group-hover/delete:stroke-red-500`}
              color=''
            />
          </button>
        )}
      </div>
      <div className='py-2'>
        <Input
          value={title}
          limit={limit}
          onInputChange={(value) => {
            onInputChange(value);
          }}
        />
      </div>
    </div>
  );
};

export default QuickReplyButtons;
