import React, { useCallback, useEffect, useReducer, useState } from "react";
import "../../styles/Preview.css";

import { images } from "../../../../assets/images";
import { ICONS } from "../../../../assets/icons";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import ListViewSearch from "../../../../components/commonComponents/ListViewHeader/ListViewSearch";
import { Skeleton } from "antd";

import "../../styles/Broadcast.css";
import { broadcastTemplateListApi } from "../../api/Api";

// import {
//   INITIAL_STATE,
//   templateReducer,
// } from "hooks/templates/templateReducers";

import TemplatePreview from "../../../../components/commonComponents/templates/TemplatePreview";
import { useLocation } from "react-router-dom";
import { useFileUploader } from "hooks/useFileUploader";

import Loader from "../../../../components/commonComponents/Loader/Index";
import {
  INITIAL_STATE,
  templateReducer,
} from "../../../channels/reducers/templateReducer";

const language = {
  en: "English",
  en_US: "English_US",
  ta: "Tamil",
  en_GB: "English_US",
  te: "Telugu",
  kn: "Kannada",
  bn: "Bengali",
  hi: "Hindi",
  mr: "Marathi",
  ml: "Malayalam",
};

const BroadcastMessage = ({
  isTemplateSelected,
  setIsTemplateSelected,
  selectedTemplate,
  setSelectedTemplate,
  selectedRole,
  setSelectedId3,
  broadCasts,
}) => {
  const [open, setOpen] = useState(false);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [isFilter, setIsFilter] = useState("");
  const { currentBrand } = useAspSelector((state) => state.app);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const location = useLocation();

  const { uploading, selectedFile, setSelectedFile, uploadFiles, error } =
    useFileUploader();

  const fetchTemplateLists = (brandId, payload) => {
    const status = "ACTIVE" || "TEMPLATE_LOCKED";

    if (brandId) {
      const newData = payload?.page === 1 ? [] : [...templateDetails];

      broadcastTemplateListApi(brandId, status, payload)
        .then((res) => {
          if (res && res?.data?.templates?.length > 0) {
            setIsLoading(false);

            newData.push(...res?.data?.templates);
            setTemplateDetails(newData);
            setCount(res?.data?.template_count);
            setPage(page + 1);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setTemplateDetails([]);
    }
  };

  const loadNextPage = () => {
    setIsLoading(false);

    if (templateDetails?.length < count) {
      fetchTemplateLists(currentBrand?.brand_id, {
        page: page,
        limit: 10,
      });
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      loadNextPage();
    }
  };

  useEffect(() => {
    setPage(1);
    fetchTemplateLists(currentBrand?.brand_id, {
      page: page,
      limit: 10,
      name: isFilter,
    });
  }, []);

  const handleClick = (item) => {
    setOpen(!open);
    setSelectedItemId(!selectedItemId);
    setIsTemplateSelected(true);
    setSelectedTemplate(item);
  };

  useEffect(() => {
    if (selectedTemplate?.status) {
      setSelectedId3(true);
    }
  }, [selectedTemplate]);

  const handleClose = () => {
    setIsTemplateSelected(false);
    setOpen(false);
    setSelectedId3(false);
  };

  const templateSearch = useCallback(
    (value) => {
      setIsFilter(value);
    },
    [setIsFilter]
  );

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (isFilter) {
        fetchTemplateLists(currentBrand?.brand_id, {
          page: 1,
          limit: 10,
          name: isFilter,
        });
      }
    }, 500);

    return () => clearTimeout(debounce);
  }, [isFilter, currentBrand?.brand_id]);

  const category = selectedTemplate?.category?.toLowerCase();
  const capitalizedCategory = category
    ? category.charAt(0).toUpperCase() + category.slice(1)
    : "";

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );
  const { custom, carousel, authentication } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  useEffect(() => {
    templateDispatch({
      type: "update_template",
      data: selectedTemplate,
    });
  }, [selectedTemplate]);

  const updateTemplate = (type, key, value) => {
    if (selectedTemplate)
      if (["HEADER", "BODY", "FOOTER", "BUTTONS"].includes(type)) {
        const filterType = selectedTemplate?.components?.filter(
          (c) => c.type === type
        );

        const filterWithoutType = selectedTemplate?.components?.filter(
          (c) => c.type !== type
        );

        const updatedData = {
          ...selectedTemplate,
          components: [
            ...filterWithoutType,
            {
              ...filterType?.[0],
              [key]: value,
            },
          ],
        };
        setSelectedTemplate(updatedData);
      } else {
        setSelectedTemplate({ ...selectedTemplate, [key]: value });
      }
  };

  const fileName = (url) => {
    const names = url?.split("/");
    return names?.[names.length - 1];
  };

  // styles
  const uploadContainer =
    "flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] cursor-pointer";
  const errorStyle = "text-[var(--fontRed)] font-md weight-400 my-[0.5vw]";

  return (
    <div className="BroadcastMessage-container w-100 space-between flex-row">
      <div className="broadcast-wrapper w-58 flex-column">
        <div className="select-heading flex-column">
          <span className="pt-5 ">Select Template</span>
          <span className="pt-5">
            Select the approved message templates to broadcast your customers.
          </span>
        </div>
        <div className="flex-column w-100 pt-10 mt-10">
          {!open && !isTemplateSelected && (
            <>
              <div className="select-template flex-column">
                <div
                  className="flex-row align-center  pointer "
                  onClick={handleClick}
                >
                  <img
                    src={images?.BroadcastWhatsappImage}
                    alt="whatsappLogo"
                  />
                  {templateDetails && (
                    <div className="selecting-template flex-row align-center pl-10">
                      <span>Select Template</span>
                      <img src={ICONS?.BroadcastLightChevronDown} alt="down" />
                    </div>
                  )}
                </div>
                <div className="select-name flex-column">
                  <p>No templates selected</p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="search-popover flex-column">
          {isTemplateSelected && (
            <div className="search-template-menu flex-column pointer">
              <div className="search">
                {selectedItemId && (
                  <div className="">
                    <div className="search-template-container flex-column p-10">
                      <div className="search-container flex-row align-center  ">
                        <ListViewSearch
                          searchingText={(text) => templateSearch(text)}
                        />
                      </div>
                    </div>
                    <Skeleton
                      active
                      style={{ width: "79vw", padding: "20px" }}
                      loading={isLoading}
                      paragraph={{ rows: 3 }}
                      className="loader1"
                    >
                      <div
                        className="search-template-data flex-column pointer"
                        onScroll={handleScroll}
                      >
                        {templateDetails?.map((data) => {
                          return (
                            ["APPROVED", "TEMPLATE_LOCKED", "PAM"]?.some(
                              (status) => data?.status?.includes(status)
                            ) &&
                            data?.name
                              .toLowerCase()
                              .includes(isFilter.toLowerCase()) && (
                              <div
                                className="search-template-info flex-row align-center "
                                key={data?.id}
                                onClick={() => handleClick(data)}
                              >
                                <p>{data?.name}</p>
                                <span
                                  className="status-color flex align-center justify-center"
                                  style={{
                                    color:
                                      data?.category === "MARKETING"
                                        ? "#ED4F9D"
                                        : "#f6a723",
                                    background:
                                      data?.category === "MARKETING"
                                        ? "#fdf2f8"
                                        : "#FFFBEB",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {data?.category.charAt(0).toUpperCase() +
                                    data?.category.slice(1).toLowerCase()}{" "}
                                </span>
                                <p
                                  className="status-Language flex-row align-center justify-center pl-5"
                                  style={{
                                    color: "var(--dashboardprimary)",
                                    backgroundColor: "hsla(203, 78%, 95%, 1)",
                                    borderRadius: "5px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {language[data?.language]}
                                </p>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </Skeleton>
                  </div>
                )}
                {!selectedItemId && (
                  <div className="Template-container flex-column">
                    <div className="Template-wrapper flex-row align-center w-100 space-between pb-10">
                      <div className="flex-row align-center">
                        <img
                          src={images?.BroadcastWhatsappImage}
                          alt="WhatsApp"
                        />
                        <p className="pl-5">{selectedTemplate?.name}</p>
                        <span
                          className="status-color flex align-center justify-center"
                          style={{
                            color:
                              selectedTemplate?.category === "MARKETING"
                                ? "var(--statusFont)"
                                : "#f6a723",
                            background:
                              selectedTemplate?.category === "MARKETING"
                                ? "var(--statusBg)"
                                : "#FFFBEB",
                          }}
                        >
                          {capitalizedCategory}
                        </span>
                        <p className="status-Language flex-row align-center justify-center pl-5 pr-5">
                          {language[selectedTemplate?.language]}
                        </p>
                      </div>

                      <img
                        src={ICONS?.deleteIcon}
                        alt="delete"
                        className="Delay-image"
                        onClick={(e) => handleClose()}
                        onMouseOver={(e) =>
                          (e.currentTarget.src = ICONS?.BroadcastRedDeleteIcon)
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.src = ICONS?.deleteIcon)
                        }
                      />
                    </div>
                    {/* <div className="w-full h-[15vh] pt-[1vw] border-t-[0.08vw] border-[var(--border-50)] p-10">
                      <h3 className="font-normal weight-bold text-[var(--contentText)]">
                        Select Media File
                      </h3>
                      <p className="font-md weight-medium text-[var(--font-400)] mt-[0.3vw]">
                        Upload a media for this template
                      </p>
                      {["IMAGE", "VIDEO", "DOCUMENT"]?.includes(
                        header?.format
                      ) ? (
                        <div className="mt-[0.7vw]">
                          <div className="flex items-center gap-[0.8vw]">
                            <div>
                              {header?.format === "IMAGE" && (
                                <img
                                  src={header?.example?.header_handle?.[0]}
                                  alt="preview images"
                                  className="size-[4vw] font-xs weight-medium rounded-[0.5vw]"
                                />
                              )}
                              {header?.format === "VIDEO" && (
                                <div className="h-[4vw] flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] rounded-[0.5vw] cursor-pointer">
                                  <VideoSvg
                                    color="var(--white)"
                                    width={"3vw"}
                                    height={"3vw"}
                                  />
                                </div>
                              )}
                              {header?.format === "DOCUMENT" && (
                                <iframe
                                  title="cover"
                                  src={header?.example?.header_handle?.[0]}
                                  type="application/pdf"
                                  width="100%"
                                  height="150px"
                                ></iframe>
                              )}
                            </div>
                            <div>
                              <p className="w-[90%] font-md weight-semibold text-[var(--font-600)] truncate">
                                {selectedFile
                                  ? selectedFile?.name
                                  : fileName(
                                      header?.example?.header_handle?.[0]
                                    )}
                              </p>
                              <button className="w-[7vw] font-xs weight-bold bg-[var(--primary)] text-[var(--white)] px-[0.6vw] py-[0.4vw] rounded-[0.37vw] mt-[0.4vw]">
                                <div className="flex items-center justify-center">
                                  <input
                                    type="file"
                                    accept={
                                      header?.format === "IMAGE"
                                        ? ".jpg, .png"
                                        : header?.format === "VIDEO"
                                        ? ".mp4"
                                        : header?.format === "DOCUMENT" &&
                                          ".pdf"
                                    }
                                    onChange={async (e) => {
                                      const res = await uploadFiles(
                                        e,
                                        header?.format
                                      );
                                      updateTemplate("HEADER", "example", {
                                        header_handle: [res],
                                      });
                                    }}
                                    id="uploadImg"
                                    hidden
                                  />

                                  <label
                                    for="uploadImg"
                                    className="w-full flex-center"
                                  >
                                    {uploading ? (
                                         <Loader Width={20} Height={20} loaderBg='white' />
                                    ) : (
                                      <div className={`${uploadContainer}`}>
                                        Choose media
                                      </div>
                                    )}
                                  </label>
                                </div>
                              </button>
                            </div>
                          </div>
                          <>
                            {header?.format === "IMAGE" && error && (
                              <div className={`${errorStyle}`}>
                                Image size should be less than 5MB
                              </div>
                            )}

                            {header?.format === "VIDEO" && error && (
                              <div className={`${errorStyle}`}>
                                Video size should be less than 16MB
                              </div>
                            )}

                            {header?.format === "DOCUMENT" && error && (
                              <div className={`${errorStyle}`}>
                                Document size should be less than 100MB
                              </div>
                            )}
                          </>
                        </div>
                      ) : (
                        <div className="w-full h-[10vh] flex-center font-md weight-medium text-[var(--font-600)]">
                          No media files found on this template.
                        </div>
                      )}
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="MessagePreview ">
        <div className="TestMessage">
          <p className="flex-row align-center">Message Preview</p>
        </div>
        {!open && isTemplateSelected && selectedTemplate ? (
          <div className="w-full min-h-[65vh] max-h-[70vh] h-full">
            <div className="w-[18vw] min-h-[57vh] max-h-[59vh] mx-auto border-[0.4vw] border-[var(--white)] rounded-[2vw] my-[0.7vw] shadow-[0px_4px_30px_0px_#2D303620]">
              <div className="h-[6vh] flex items-center gap-[0.5vw] bg-[#077C70] rounded-t-[1.5vw] px-[0.6vw]">
                <div className="size-[2.1vw] bg-[var(--white)] rounded-full flex items-center justify-center font-xs">
                  Logo
                </div>
                <div className="text-[var(--white)]">
                  <h3 className="font-md weight-bold capitalize leading-[1.2vw]">
                    {selectedRole && selectedRole?.send_from_phone_name}
                    {location?.state?.item &&
                      broadCasts?.settings?.send_from_phone_name}
                  </h3>
                  <span className="font-md weight-bold capitalize flex-row leading-[1.2vw]">
                    {selectedRole && selectedRole?.country_code}
                    {location?.state?.item &&
                      broadCasts?.settings?.country_code}
                    <p className="pl-5">
                      {selectedRole && selectedRole?.send_from_phone_number}
                      {location?.state?.item &&
                        broadCasts?.settings?.send_from_phone_number}
                    </p>
                  </span>
                </div>
              </div>
              <TemplatePreview
                header={header}
                headerVariable={headerVariable}
                body={body}
                bodyVariables={bodyVariables}
                footer={footer}
                buttons={buttons}
                buttonsOrder={buttonsOrder}
                ctaButtons={ctaButtons}
                // useTemplates={true}
                customButtons={customButtons}
                carousel={carousel}
                authentication={authentication}
                category={selectedTemplate?.subcategory_type}
                data={selectedTemplate}
                className={"rounded-none min-h-[45vh] max-h-[45vh]"}
                drawerType="small"
              />
              <img
                src={images?.TemplatePreviewBottom}
                alt="send msg"
                className="w-[17.35vw] h-[2.3vw] rounded-b-[1.5vw]"
              />
            </div>
          </div>
        ) : (
          <div className="no-message">
            <div className="h-70 flex-column align-center justify-center">
              <img src={ICONS?.noMessage} alt="noMessage" />
              <span>No template is selected for preview</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BroadcastMessage;
