import React from 'react';

// assets
import Delete from '../../../../../../assets/customSVG/Delete';
import Input from '../../Input';

const Row = ({
  nodeId,
  sectionId,
  rowId,
  title,
  description,
  onChange,
  deleteNode,
  showDelete,
}) => {
  return (
    <div className='bg-white rounded-[0.625rem] p-4 group/delete'>
      <div className='flex items-center justify-between'>
        <h5 className='w-full h-5 text-sm weight-semibold text-[#616874]'>
          Row {rowId}
        </h5>
        {showDelete && (
          <button
            className={`size-6 bg-transparent flex items-center justify-center rounded-[0.4vw] hover:bg-red-200`}
            onClick={() => {
              deleteNode();
            }}>
            <Delete
              width='14'
              height='14'
              className={`stroke-[#898E99] group-hover/delete:stroke-red-500`}
              color=''
            />
          </button>
        )}
      </div>
      <div className='py-2'>
        <Input
          value={title}
          limit={25}
          onInputChange={(value) => {
            onChange({
              type: 'UPDATE_LIST_NODE',
              id: nodeId,
              sectionId: sectionId,
              rowId: rowId,
              key: 'title',
              value: value,
            });
          }}
        />
      </div>
      <div className='text-xs weight-semibold'>
        <label
          htmlFor=''
          className='py-1'>
          Description(Optional)
        </label>
        <Input
          value={description}
          limit={25}
          onInputChange={(value) => {
            onChange({
              type: 'UPDATE_LIST_NODE',
              id: nodeId,
              sectionId: sectionId,
              rowId: rowId,
              key: 'description',
              value: value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Row;
