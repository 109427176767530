import React from "react";

const Shopping = ({ color }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 2.45455C1.5 1.92737 1.92737 1.5 2.45455 1.5H3.37664C4.69685 1.5 5.87608 2.37472 6.20754 3.66272L6.63355 5.31818H20.5769L20.5882 5.3181L20.6027 5.31818H21.5455C22.0726 5.31818 22.5 5.74555 22.5 6.27273C22.5 6.7999 22.0726 7.22727 21.5455 7.22727H21.4453L20.9321 11.8466C20.7889 13.1356 20.5554 14.254 19.7785 15.0097C19.0017 15.7655 17.8773 15.968 16.5849 16.0758L8.80538 16.724C8.73056 16.8648 8.64342 17.0001 8.54435 17.1285L8.22534 17.5416C8.55899 17.8528 8.81822 18.2427 8.97341 18.6818H14.072C14.4651 17.5696 15.5259 16.7727 16.7727 16.7727C18.3543 16.7727 19.6364 18.0548 19.6364 19.6364C19.6364 21.2179 18.3543 22.5 16.7727 22.5C15.5259 22.5 14.4651 21.7031 14.072 20.5909H8.97341C8.5803 21.7031 7.51957 22.5 6.27273 22.5C4.69119 22.5 3.40909 21.2179 3.40909 19.6364C3.40909 18.0548 4.69119 16.7727 6.27273 16.7727C6.31701 16.7727 6.36105 16.7738 6.40484 16.7757L7.03319 15.9618C7.21022 15.7325 7.26809 15.4442 7.1987 15.1745L4.35868 4.13851C4.25273 3.72678 3.85991 3.40909 3.37664 3.40909H2.45455C1.92737 3.40909 1.5 2.98172 1.5 2.45455ZM6.27273 18.6818C5.74555 18.6818 5.31818 19.1092 5.31818 19.6364C5.31818 20.1636 5.74555 20.5909 6.27273 20.5909C6.7999 20.5909 7.22727 20.1636 7.22727 19.6364C7.22727 19.1092 6.7999 18.6818 6.27273 18.6818ZM15.8182 19.6364C15.8182 20.1636 16.2455 20.5909 16.7727 20.5909C17.2999 20.5909 17.7273 20.1636 17.7273 19.6364C17.7273 19.1092 17.2999 18.6818 16.7727 18.6818C16.2455 18.6818 15.8182 19.1092 15.8182 19.6364Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Shopping;
