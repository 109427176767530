import React, { useEffect, useState } from "react";
import { cn } from "../../../helper/cn";
import { ICONSS } from "../../../assets/icons/index1";
import { dateConverter } from "../../../helper/dateConverter";
import { ICONS } from "../../../assets/icons";
import { Modal, Popover, Skeleton } from "antd";
import { invoicePreviewApi, invoicesListApi } from "../api/Api";
import { useParams } from "react-router-dom";
import Loader from "../../../components/commonComponents/Loader/Index";
import useToggle from "../../../hooks/useToggle";

export const invoiceList = [
  {
    id: 1,
    label: "Invoice No.",
  },
  {
    id: 2,
    label: "Invoice Date",
  },
  {
    id: 3,
    label: "Paid at",
  },
  {
    id: 4,
    label: "Transaction ID",
  },
  {
    id: 5,
    label: "Payment Method",
  },
];

export const billed = [
  {
    id: 1,
    label: "Billed By",
    name: "GainWix.ai",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    phone: "+91",
  },
  {
    id: 2,
    label: "Billed To",
    name: "",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    phone: "+91",
  },
];

const Invoice = ({
  activeNav,
  setYearFilterOpen,
  yearFilterOpen,
  setSelectedYear,
  selectedYear,
  years,
}) => {
  const { id } = useParams();
  const [invoiceData, setInvoiceata] = useState([]);
  const [popupLoading, setPopupLoading] = useState({
    Preview: false,
    Download: false,
  });
  const [listLoading, setListLoading] = useToggle(false);

  const handleModalOpen = (l, action) => {
    setPopupLoading((prevState) => ({
      ...prevState,
      [action]: true,
    }));

    invoicePreviewApi(l?.slug, id).then((res) => {
      setPopupLoading((prevState) => ({
        ...prevState,
        [action]: false,
      }));
      if (res?.status === 200) {
        const filePath = res?.data?.brand_invoice?.settings?.file_path;
        localStorage.setItem(
          "invoicePreview",
          JSON.stringify(res?.data?.brand_invoice)
        );

        if (action === "Download" && filePath) {
          const link = document.createElement("a");
          link.href = filePath;
          link.download = filePath.split("/").pop();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        if (action === "Preview") {
          window.open("/invoice_preview", "_blank");
        }
      } else {
        setPopupLoading((prevState) => ({
          ...prevState,
          [action]: false,
        }));
      }
    });
  };

  const fetchInvoiceList = () => {
    setListLoading(true);
    const payLoad = {
      year: selectedYear,
      limit: 50,
    };
    invoicesListApi(id, payLoad).then((res) => {
      if (res?.status === 200) {
        setListLoading(false);
        setInvoiceata(res?.data?.invoices);
      } else {
        setListLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchInvoiceList(id);
  }, [id, selectedYear]);

  return (
    <div
      className={`${
        activeNav ? "bg-white mt-0 p-20 b-radius h-full" : "mt-20"
      }`}
    >
      {invoiceData?.length > 0 && (
        <div
          className={`flex-row  w-100 ${
            activeNav ? "align-center space-between" : "justify-end"
          }`}
        >
          {activeNav && (
            <span className="font15px-600 text-[var(--textBlack)] invisible">
              Payment History
            </span>
          )}
          <Popover
            trigger={"click"}
            open={yearFilterOpen}
            onOpenChange={(open) => setYearFilterOpen(open)}
            arrow={false}
            content={
              <div className="p-15">
                {years.map((year) => (
                  <div
                    key={year}
                    onClick={() => {
                      setSelectedYear(year);
                      setYearFilterOpen(false);
                    }}
                    style={{
                      backgroundColor:
                        selectedYear === year
                          ? "var(--new-primary-bg)"
                          : "transparent",
                      color:
                        selectedYear === year
                          ? "var(--new-primary)"
                          : "text-[var(--textBlack)]",
                    }}
                    className="pointer p-5 b-radius-6 font14px-400 text-[var(--textBlack)] mb-5"
                  >
                    {year}
                  </div>
                ))}
              </div>
            }
          >
            <p className="bg-[#F4F6F8] align-end flex-row gap-3  pointer px-2 py-1  font15px-400 text-[var(--textBlack)] b-radius-6">
              {selectedYear}
              <img
                src={ICONS?.dropDownIcon}
                alt="dropDownIcon"
                className="w-4"
              />
            </p>
          </Popover>
        </div>
      )}
      <Skeleton active loading={listLoading} paragraph={{ rows: 6 }}>
        <div className="w-full relative overflow-hidden">
          {invoiceData?.length > 0 && (
            <div className="w-full h-14 sticky top-0 flex items-center pr-1 b-radius-6">
              <div className={cn("new-header2", "w-[30%]")}>Invoices</div>
              <div className={cn("new-header2", "w-[24%]")}>Paid on</div>
              <div className={cn("new-header2", "w-[18%]")}>Payment Method</div>
              <div className={cn("new-header2", "w-[18%]")}>Amount</div>
              <div className={cn("new-header2")}>Actions</div>
            </div>
          )}
        </div>
        <div
          className={`min-w-full bg-white h-full  overflow-hidden overflow-y-scroll ${
            activeNav === "Subscriptions"
              ? "max-h-[48vh] overflow-scroll"
              : "max-h-[40vh] overflow-scroll"
          }`}
          id="scrollableDiv"
          // ref={listContainerRef}
        >
          {/* <InfiniteScroll
          dataLength={count}
          next={() => {
            loadNextPage();
          }}
          hasMore={list?.length < count}
          scrollableTarget="scrollableDiv"
          height={isLoading ? "53vh" : "58vh"}
          className="!pr-1 max-[1600px]:pb-24 min-[1600px]:pb-24 listScroll"
        > */}
          {invoiceData?.length > 0 ? (
            <div
              className={`h-full  overflow-scroll ${
                activeNav === "Subscriptions" ? "max-h-[55vh]" : "max-h-[40vh]"
              }`}
            >
              {invoiceData?.map((l, index) => {
                const date = dateConverter(l?.created_at);

                return (
                  <div
                    key={index}
                    className="w-full odd:bg-gray-50 my-1 py-4 flex items-center  pointer"
                  >
                    <div
                      className={"w-[40%] flex-row align-center gap-2 pl-10"}
                    >
                      <img src={ICONSS?.billingHistory} alt="billingHistory" />

                      <div className="font15px-500 text-[var(--textBlack)]">
                        {date?.monthInShort} {date?.date} {date?.hours}:
                        {date?.minutes}
                        {date?.meridiem}
                      </div>
                    </div>

                    <div className="w-[32%] font12px-500 flex-row text-[var(--font-600)]">
                      {date?.monthInShort} {date?.date} {date?.hours}:
                      {date?.minutes}
                      {date?.meridiem}
                    </div>
                    <div className="w-[25%] font12px-500 flex-row text-[var(--font-600)] capitalize pl-10">
                      {l?.payment_method}
                    </div>
                    <div className="w-[26%] font12px-500 flex-row text-[var(--font-600)]">
                      ₹{parseFloat(l?.total_amount).toFixed(2)}
                    </div>

                    <div className="w-[12%]">
                      <Popover
                        trigger="click"
                        arrow={false}
                        content={
                          <div className="p-15 pointer flex-column">
                            <div
                              className="font14px-400  hover:bg-[--BG-50] text-center  prl-10 ptb-5 mb-10 rounded-[5px]"
                              onClick={() => handleModalOpen(l, "Preview")}
                            >
                              {popupLoading["Preview"] ? (
                                <div className="w-full flex items-center justify-center bg-white rounded-lg">
                                  <Loader Width={20} Height={20} />
                                </div>
                              ) : (
                                "Preview"
                              )}
                            </div>
                            <span className="border-b-[1px]" />
                            <span
                              className="font14px-400  hover:bg-[--BG-50]  prl-10 ptb-5 b-radius-6 mt-10"
                              onClick={() => handleModalOpen(l, "Download")}
                            >
                              {popupLoading["Download"] ? (
                                <div className="w-full flex items-center justify-center bg-white rounded-lg">
                                  <Loader Width={20} Height={20} />
                                </div>
                              ) : (
                                "Download"
                              )}
                            </span>
                          </div>
                        }
                        overlayClassName="mt-20"
                      >
                        <div
                          className="w-[12%] font12px-500 !text-[var(--font-600)]"
                          //   onClick={() => setPopupOpen(true)}
                        >
                          <img src={ICONSS?.dotStyleIcon} alt="dotStyleIcon" />
                        </div>
                      </Popover>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-full flex flex-col items-center justify-center">
              <img src={ICONS?.noChartData} alt="noChartData" />
              <p className="text-[#616874]">There’s no data yet</p>
            </div>
          )}
          {/* </InfiniteScroll> */}
        </div>
      </Skeleton>
    </div>
  );
};

export default Invoice;
