import React from "react";
import PurchaseTable from "../../components/purchase/PurchaseTable";

const Purchases = () => {
  return (
    <div className="w-full px-5 py-7">
      <h2 className="font24px-500 tesxt-[var(--textBlack)] mb-1">Purchases</h2>
      <PurchaseTable />
    </div>
  );
};

export default Purchases;
