import React from "react";

const AccountOverview = ({ color, lineColor, fillColor }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.08325 16.25V9.16663H3.74992V16.25C3.74992 16.7102 4.12302 17.0833 4.58325 17.0833H15.4166C15.8768 17.0833 16.2499 16.7102 16.2499 16.25V9.16663H17.9166V16.25C17.9166 17.6307 16.7973 18.75 15.4166 18.75H4.58325C3.20254 18.75 2.08325 17.6307 2.08325 16.25Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.87039 13.6134C7.17599 13.2692 7.70271 13.238 8.04684 13.5436C8.43475 13.8881 9.13134 14.1667 9.99225 14.1667C10.8532 14.1667 11.5498 13.8881 11.9378 13.5436C12.2818 13.238 12.8086 13.2692 13.1142 13.6134C13.4198 13.9575 13.3885 14.4842 13.0444 14.7898C12.2927 15.4574 11.175 15.8334 9.99225 15.8334C8.8095 15.8334 7.69184 15.4574 6.94016 14.7898C6.59603 14.4842 6.56479 13.9575 6.87039 13.6134Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.87686 13.6134C7.18238 13.2692 7.70909 13.2378 8.05329 13.5433C8.4415 13.8879 9.13858 14.1666 10.0001 14.1666C10.8616 14.1666 11.5587 13.8879 11.9469 13.5433C12.2911 13.2378 12.8178 13.2692 13.1233 13.6134C13.4288 13.9576 13.3975 14.4843 13.0533 14.7898C12.3013 15.4573 11.1832 15.8333 10.0001 15.8333C8.817 15.8333 7.69888 15.4573 6.94689 14.7898C6.60269 14.4843 6.57134 13.9576 6.87686 13.6134Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.70841 2.91671C2.70841 2.11129 3.36133 1.45837 4.16675 1.45837H15.8334C16.6388 1.45837 17.2917 2.11129 17.2917 2.91671V4.00073L18.8761 6.77329C18.93 6.86772 18.9584 6.97461 18.9584 7.08337C18.9584 8.80929 17.5593 10.2084 15.8334 10.2084C14.7794 10.2084 13.8473 9.68654 13.2812 8.88721C13.0802 9.11771 12.845 9.31604 12.5905 9.48196C11.8325 9.97612 10.8754 10.2084 10.0001 10.2084C9.12475 10.2084 8.16768 9.97612 7.40966 9.48196C7.1552 9.31604 6.91996 9.11771 6.71888 8.88721C6.15286 9.68654 5.22074 10.2084 4.16675 10.2084C2.44086 10.2084 1.04175 8.80929 1.04175 7.08337C1.04175 6.97461 1.07013 6.86772 1.1241 6.77329L2.70841 4.00073V2.91671Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default AccountOverview;
