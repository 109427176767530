import React from "react";

const Purchases = ({ color }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_22851_115526)">
          <path
            d="M16.68 1.66663C15.7522 1.66663 15 3.9052 15 6.66663H16.68C17.4897 6.66663 17.8945 6.66663 18.1451 6.38708C18.3957 6.10753 18.3521 5.7394 18.2648 5.00313C18.0345 3.05948 17.4119 1.66663 16.68 1.66663Z"
            stroke={color}
            stroke-width="1.67"
          />
          <path
            d="M15 6.71184V15.5381C15 16.7979 15 17.4277 14.615 17.6756C13.9859 18.0809 13.0134 17.2311 12.5243 16.9227C12.1201 16.6678 11.9181 16.5404 11.6938 16.533C11.4514 16.525 11.2458 16.6473 10.8091 16.9227L9.21669 17.927C8.7871 18.1978 8.57235 18.3333 8.33335 18.3333C8.09436 18.3333 7.87957 18.1978 7.45002 17.927L5.85763 16.9227C5.45348 16.6678 5.2514 16.5404 5.02713 16.533C4.78479 16.525 4.57913 16.6473 4.14241 16.9227C3.65331 17.2311 2.68075 18.0809 2.05165 17.6756C1.66669 17.4277 1.66669 16.7979 1.66669 15.5381V6.71184C1.66669 4.3335 1.66669 3.14433 2.39892 2.40548C3.13115 1.66663 4.30966 1.66663 6.66669 1.66663H16.6667"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 5H11.6667"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66667 8.33337H5"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.4167 9.06254C9.72635 9.06254 9.16669 9.55221 9.16669 10.1563C9.16669 10.7604 9.72635 11.25 10.4167 11.25C11.107 11.25 11.6667 11.7397 11.6667 12.3438C11.6667 12.9479 11.107 13.4375 10.4167 13.4375M10.4167 9.06254C10.9609 9.06254 11.4239 9.36687 11.5955 9.79171M10.4167 9.06254V8.33337M10.4167 13.4375C9.87244 13.4375 9.40944 13.1332 9.23785 12.7084M10.4167 13.4375V14.1667"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_22851_115526">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Purchases;
