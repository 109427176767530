import React, { useState } from "react";
import { ICONS } from "../../../assets/icons";
import Popup from "../../../assets/customSVG/accounts/Popup";

import { cn } from "helper/cn";

export default React.memo(function Header(props) {
  const {
    title,
    subTitle,
    close = true,
    rootStyle,
    titleStyle,
    titleSize = "normal",
    titleWeight = "bold",
    subTitleStyle,
    subTitleSize = "normal",
    subTitleWeight = "medium",
    suffix,
    prefixStyle,
    suffixStyle,
    closeStyle,
    prefix = null,
    handleCancel,
    className,
    iconClick,
  } = props;

  const TEXT_STYLE = {
    xs: "font-xs",
    sm: "font-sm",
    md: "font-md",
    medium: "font-medium",
    normal: "font-normal",
    large: "font-large",
    larger: "font-larger",
    largest: "font-largest",
    none: "",
  };

  const TEXT_WEIGHT = {
    small: "weight-small",
    medium: "weight-medium",
    semibold: "weight-semibold",
    bold: "weight-bold",
    extrabold: "weight-extrabold",
    none: "",
  };

  const [closeHover, setCloseHover] = useState(false);

  return (
    <div
      className={cn(
        "w-full flex !items-center justify-between px-5 py-4  border-[var(--border-50)]",
        rootStyle
      )}
    >
      {prefix && (
        <div className={cn(prefixStyle)}>
          {typeof prefix == "string" ? (
            <img src={prefix} alt="icons" onClick={iconClick} />
          ) : (
            prefix
          )}
        </div>
      )}
      <div
        className={cn(
          TEXT_STYLE[titleSize],
          TEXT_WEIGHT[titleWeight],
          "w-auto flex flex-1 flex-col gap-1",
          titleStyle
        )}
      >
        <h3 className={`main-header ${className}`}>{title}</h3>
        {subTitle && (
          <p
            // className={cn(
            //   TEXT_STYLE[subTitleSize],
            //   TEXT_WEIGHT[subTitleWeight],
            //   subTitleStyle
            // )}
            className="new-body-text"
          >
            {subTitle}
          </p>
        )}
      </div>
      {suffix ? (
        <div
          className={cn("flex items-center justify-center", suffixStyle)}
          onClick={() => {
            if (handleCancel) handleCancel();
          }}
        >
          {typeof suffix == "string" ? (
            <img src={suffix} alt="icons" />
          ) : (
            suffix
          )}
        </div>
      ) : (
        close && (
          <button
            className={cn(
              "size-[1.4vw] font-larger weight-semibold rounded-full  flex items-center justify-center !p-3.5",
              closeStyle
            )}
            onClick={() => {
              if (handleCancel) handleCancel();
            }}
            onMouseOver={() => setCloseHover(true)}
            onMouseOut={() => setCloseHover(false)}
          >
            {/* &times; */}
            {/* <img
              src={ICONS?.popupX}
              alt="icons"
              className="w-[1.8vw] h-[1.8vw]"
            /> */}
            <Popup
              color={!closeHover ? "var(--textBlack)" : "#FF543E"}
              fill={!closeHover ? "#F1F4F6" : "#FFE3E0"}
            />
          </button>
        )
      )}
    </div>
  );
});
