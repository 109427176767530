import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

// assets
import Xmark from "../../../../../assets/customSVG/Xmark";

// api
import {
  exportAllContacts,
  getSequenceOverviewContactlists,
} from "../../../api/SquenceOverview";

// hooks
import { useSearch } from "../../../../../hooks/useSearch";
import useToggle from "../../../../../hooks/useToggle";
import useList from "../../../../../hooks/useList";
import useJsonToExcel from "../../../../../hooks/useJsonToExcel";

// constants
import {
  SORT_FILTERS,
  STATUS_FILTERS,
} from "../../../constants/sequenceOverview";

// helpers
import { cn } from "../../../../../helper/cn";

// redux
import { useAspSelector } from "../../../../../test/jest-redux-hooks";

// components
import Filters from "../../../../../components/commonComponents/filters";
import SearchBar from "../../../../../components/commonComponents/searchBar";
import CheckBoxSqure from "../../../../../components/commonComponents/checkbox/CheckBoxSqure";
import ContactListCard from "./ContactListCard";
import Loader from "../../../../../components/commonComponents/Loader/Index";

const keys = [
  { from: "profile_name", to: "Name" },
  { from: "status", to: "Status" },
  { from: "phone_number", to: "Phone number" },
];

const ContactList = ({ sequenceDetails }) => {
  const { sequence_id, campaign_id } = useParams();
  const { currentBrand } = useAspSelector((state) => state.app);

  const { searchKey, setSearchKey, onChange } = useSearch();
  const [isLoadingContactList, setIsLoadingContactList] = useToggle(false);
  const [isLoadingExport, setIsLoadingExport] = useToggle(false);

  const { handleDownloadExcel } = useJsonToExcel({});
  const {
    lists,
    setLists,
    selectAll,
    intermediate,
    selectedRows,
    onSelectRow,
    isRowSelected,
    toggleSelectAll,
    getSelectedLists,
  } = useList({
    data: [],
  });

  const [statusFilterList, setStatusFilterList] = useState(STATUS_FILTERS);
  const [sortFilterList, setSortFilterList] = useState(SORT_FILTERS);

  useEffect(() => {
    const data = getAplliedFiltes();
    getContactLists(sequence_id, data);
  }, [sequenceDetails, statusFilterList, sortFilterList]);

  useEffect(() => {
    const data = getAplliedFiltes();
    if (searchKey?.length > 0) data["search"] = searchKey;

    const timeout = setTimeout(() => {
      getContactLists(sequence_id, data);
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchKey]);

  const getAplliedFiltes = useCallback(() => {
    const getSelectedStatus = statusFilterList?.filter((s) => s?.isSelected);
    const getSelectedSorts = sortFilterList?.filter((s) => s?.isSelected);

    const selectedStatusArray = getSelectedStatus?.map((s) => s?.key);
    const selectedSortsArray = getSelectedSorts?.map(
      (s) => s?.filter === "sort" && s?.key
    );
    const selectedOrderArray = getSelectedSorts?.map(
      (s) => s?.filter === "order" && s?.key
    );

    const data = {};
    if (
      selectedStatusArray?.length > 0 &&
      !selectedStatusArray?.includes(false)
    )
      data["status"] = selectedStatusArray;
    if (selectedSortsArray?.length > 0 && !selectedSortsArray?.includes(false))
      data["sort"] = selectedSortsArray;
    if (selectedOrderArray?.length > 0 && !selectedOrderArray?.includes(false))
      data["order"] = selectedOrderArray;

    return data;
  }, [statusFilterList, sortFilterList]);

  const getContactLists = async (seqId, data = {}) => {
    setIsLoadingContactList(true);
    try {
      const payload = {
        campaignId: campaign_id,
        brandId: currentBrand?.brand_id,
        ...data,
      };
      const res = await getSequenceOverviewContactlists(seqId, payload);
      if (res?.status === 200) setLists(res?.data?.data);
      else setLists([]);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingContactList(false);
    }
  };

  const getAllContactLists = async (seqId) => {
    setIsLoadingExport(true);
    try {
      const payload = {
        campaignId: campaign_id,
        brandId: currentBrand?.brand_id,
      };
      const res = await exportAllContacts(seqId, payload);
      return res?.data?.data || [];
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingExport(false);
    }
  };

  const trStyle = "text-sm weight-semibold text-[var(--font-600)] px-1";

  return (
    <div className="w-full h-fit flex-1 p-6 relative bg-white rounded-2xl">
      <div className="w-full h-11 sticky top-0 bg-white flex items-center justify-between">
        {!selectAll && selectedRows?.length > 0 ? (
          <>
            <div className="flex items-center gap-8">
              <p className="text-base weight-bold max-[1500px]:text-sm max-[1500px]:weight-semibold">
                {selectedRows?.length} contact selected
              </p>
              <button
                className="flex items-center gap-2.5"
                onClick={() => {
                  toggleSelectAll(false);
                }}
              >
                <Xmark
                  color="#6940F2"
                  width={10}
                  height={10}
                  strokeWidth={1.5}
                />
                <p className="text-[#6940F2] text-sm weight-medium max-[1500px]:text-xs">
                  Clear Selection
                </p>
              </button>
            </div>

            <button
              className={cn(
                "text-base weight-bold px-2 py-1.5 rounded-md max-[1500px]:text-sm max-[1500px]:weight-semibold",
                lists?.length > 0 && selectedRows?.length > 0
                  ? "bg-[#05805C] text-white"
                  : "bg-[#f4f6f8] text-[var(--font-600)]"
              )}
              onClick={() => {
                const list = getSelectedLists();
                if (lists?.length > 0 && list?.length > 0)
                  handleDownloadExcel({
                    fileName: sequenceDetails?.name?.replaceAll(" ", "_"),
                    data: list,
                    keys: keys,
                  });
              }}
              disabled={lists?.length === 0 && selectedRows?.length === 0}
            >
              {isLoadingExport ? (
                <Loader Width={20} Height={20} loaderBg="white" />
              ) : (
                <>Export</>
              )}
            </button>
          </>
        ) : (
          <>
            <SearchBar
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              onChange={onChange}
              expandable={false}
              className="bg-[var(--BG-25)]"
              disabled={lists?.length === 0}
            />
            <div className="flex items-center gap-4">
              <Filters
                type={"multiple"}
                label={"Status"}
                lists={statusFilterList}
                setLists={setStatusFilterList}
                disabled={lists?.length === 0}
              />
              <Filters
                type={"single"}
                label={"Sort"}
                lists={sortFilterList}
                setLists={setSortFilterList}
                disabled={lists?.length === 0}
              />
              <button
                className={cn(
                  "text-base weight-bold px-2 py-1.5 rounded-md max-[1500px]:text-sm max-[1500px]:weight-semibold",
                  lists?.length > 0 && selectAll
                    ? "bg-[#05805C] text-white"
                    : "bg-[#f4f6f8] text-[var(--font-600)]"
                )}
                onClick={async () => {
                  const list = await getAllContactLists(sequence_id);
                  if (lists?.length > 0 && selectAll && list?.length > 0)
                    handleDownloadExcel({
                      fileName: sequenceDetails?.name?.replaceAll(" ", "_"),
                      data: list,
                      keys: keys,
                    });
                }}
                disabled={lists?.length === 0 && !selectAll}
              >
                {isLoadingExport ? (
                  <Loader Width={20} Height={20} loaderBg="white" />
                ) : (
                  <>Export</>
                )}
              </button>
            </div>
          </>
        )}
      </div>
      <div className="w-full h-12">
        <div className="flex items-center gap-2.5 py-3 px-2.5">
          <div className={cn("w-[4%] !px-0", trStyle)}>
            <CheckBoxSqure
              value={selectAll}
              onChange={(e) => {
                toggleSelectAll(e);
              }}
              className={`size-4`}
              intermediate={!selectAll && intermediate}
              disabled={lists?.length === 0}
            />
          </div>
          <div className={cn("w-[33%]", trStyle)}>Name</div>
          <div className={cn("w-[17%]", trStyle)}>Status</div>
          <div className={cn("w-[27%]", trStyle)}>Mobile Number</div>
          <div className={cn("w-[13%]", trStyle)}>Actions</div>
        </div>
      </div>
      {isLoadingContactList ? (
        <div className="w-full min-h-[60vh] flex items-center justify-center">
          <Loader Width={35} Height={35} />
        </div>
      ) : (
        <>
          {lists?.length > 0 ? (
            lists?.map((list) => {
              return (
                <div className="w-full h-14 px-2.5 my-1.5 odd:bg-[#FAFAFA] rounded-lg">
                  <ContactListCard
                    data={list}
                    isRowSelected={isRowSelected}
                    isAllRowSelected={selectAll}
                    trStyle={trStyle}
                    onSelectRow={onSelectRow}
                  />
                </div>
              );
            })
          ) : (
            <div className="w-full h-44 flex items-center justify-center">
              No data
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContactList;
