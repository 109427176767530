import React from "react";
import { cn } from "../../../../helper/cn";

const PurchaseList = ({}) => {
  const bodyStyle =
    "text-sm weight-medium text-[var(--font-600)] text-left pl-2";

  const listData = [
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
  ];

  return (
    <>
      <div className="w-full relative overflow-hidden">
        {/* <Skeleton loading={isLoadingSkeleton} active paragraph={{ rows: 6 }}> */}
        <div className="w-full h-14 sticky top-0 flex items-center pr-1">
          <div className={cn("new-header2", "w-[25%]")}>Brand Name</div>
          <div className={cn("new-header2", "w-[15%]")}>Brand Plan</div>
          <div className={cn("new-header2", "w-[15%]")}>Plan Status</div>
          <div className={cn("new-header2", "w-[15%]")}>Subscription Cost</div>
          <div className={cn("new-header2", "w-[17%]")}>Message Cost</div>
          <div className={cn("new-header2", "w-[9%] flex justify-center")}>Total Cost</div>
        </div>

        <div
          className={`min-w-full overflow-y-scroll`}
          id="scrollableDiv"
          // ref={listContainerRef}
        >
          {/* <InfiniteScroll
              dataLength={list?.users?.length || 0}
              next={() => {
                loadNextPage();
              }}
              hasMore={list?.users?.length < count}
              scrollableTarget="scrollableDiv"
            > */}
          <>
            {listData?.map((l) => {
              return (
                <div
                  key={l?.id}
                  className="w-full odd:bg-gray-50 my-1 flex items-center rounded-lg pointer"
                >
                  <div
                    className={cn(
                      bodyStyle,
                      "w-[25%] cursor-pointer pl-3 flex py-4"
                    )}
                  >
                    <div className="flex items-center gap-2 pl-2">
                      <>
                        <div className="new-header">
                          {"The Snug Nook Boutique"}
                        </div>
                      </>
                    </div>
                  </div>

                  <div className={cn("new-body-text", "w-[15%] py-4")}>
                    {"Starter"}
                  </div>

                  <div className={cn("new-body-text", "w-[15%] py-4")}>
                    {"Expired"}
                  </div>

                  <div className={cn("new-body-text", "w-[15%] py-4")}>
                    {"₹0.00"}
                  </div>
                  <div className={cn("w-[17%] py-0")}>
                    <div className="flex border rounded-lg items-center oerflow-hidden w-[13vw]">
                    <div className="bg-[#F4F6F8] py-2 px-4 border-r-[1px]">₹</div>
                    <input
                        type="text"
                        defaultValue="1000"
                        className=" bg-transparent focus:outline-none border bg-red-600 w-28"
                      />
                    <div className="py-2 px-4 border-l-[1px]">₹</div>
                   </div>
                  
                  </div>

                  <div className={cn("new-body-text", "w-[9%] py-4 flex justify-center")}>
                    {"₹0.00"}
                  </div>
                </div>
              );
            })}
          </>
          {/* </InfiniteScroll> */}
        </div>
        {/* </Skeleton> */}
      </div>

      <div className="flex w-full justify-end px-[5vw] gap-[5vw] mt-20">
        <p className="font15px-600">Total Cost</p>
        <p className="font16px-600">₹14500.00</p>
      </div>

      <div className="flex w-full justify-end">
        <button className="button-primary px-6 py-2 b-radius-6 font16px-600 mt-8 flex items-center justify-center">
          Proceed to pay
        </button>
      </div>
    </>
  );
};

export default PurchaseList;
