import React from "react";

// assets
import FullArrow from "assets/customSVG/FullArrow";

// utils
import { Link } from "react-router-dom";

// API
import { EmailOTPValidation, ResendApi, SignUpApi } from "../../api/SignUp";

// hooks
import useToggle from "../../../../hooks/useToggle";

// redux
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";

// helper
import { cn } from "helper/cn";
import { shortenEmail } from "helper/validation";

// hooks
import useTimerCount from "hooks/useTimerCount";

// components
import OtpInput from "components/commonComponents/otp";
// import OTP from '../../../../components/commonComponents/otp/OTP';
import Loader from "../../../../components/commonComponents/Loader/Index";
import { ICONS } from "../../../../assets/icons";

const otpLength = 6;
const minutes = 2;
const secounds = 0;

export default function Step2({ data, onChange, setResendError, resendError }) {
  const { toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [isLoading, setIsLoading] = useToggle(false);

  const [
    remainingMinutes,
    remainingSeconds,
    setRemainingMinutes,
    setRemainingSeconds,
  ] = useTimerCount(minutes, secounds);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const res = await SignUpApi({
        first_name: data?.firstname,
        last_name: data?.lastname,
        email: data?.mail,
      });
      setResendError("");

      if (res?.status === 200) {
        // onChange("update_token", null, null, res?.data?.token);
        setIsLoading(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Email Sent Successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
      } else {
        setIsLoading(false);
        setResendError(res?.response?.data?.error);
        // dispatch(
        //   updateToggleToast([
        //     ...toggleToast,
        //     {
        //       id: toggleToast?.length + 1,
        //       content: res?.response?.data?.error,
        //       status: "Error",
        //       duration: "",
        //     },
        //   ])
        // );
      }
    }
  };

  return (
    <div className="max-w-[470px] w-full mx-auto text-[var(--textBlack)]">
      <div className="mx-auto flex flex-col items-center text-center">
        <h2 className="font24px-600 text-center mt-8 mb-7">
          Verify your E-mail
        </h2>
        <h4 className="w-90 font16px-400 text-[var(--textBlack)]">
          A link has been sent to{" "}
          <span className="font16px-500">{shortenEmail(data?.mail)}</span>
          <br />
          email address for your account verification.
        </h4>

        <div
          className="mt-3 flex items-center justify-center gap-2 font14px-500 text-[var(--BrandLink)] cursor-pointer"
          onClick={() => {
            onChange("change_email");
          }}
        >
          Change Email
          <FullArrow color="#6940F2" />
        </div>
      </div>
      {/* <div className="mt-9">
        <p className="text-base weight-semibold">
          Enter OTP to verify your email
        </p>
      </div> */}
      <form onSubmit={handleSubmit}>
        <div className="w-full mt-3">
          {/* <OtpInput
            length={otpLength}
            onChange={(data) => {
              onChange("update", "otp", data);
            }}
            className="w-11 h-11"
          /> */}
          {/* <OTP
            length={otpLength}
            onChange={(data) => {
              onChange('update', 'otp', data);
            }}
            className='w-11 h-11'
          />
          {/* <OTP
            length={otpLength}
            onChange={(data) => {
              onChange('update', 'otp', data);
            }}
            rootClassName='w-full'
          /> */}
        </div>
        <div className="flex flex-col items-center">
          {resendError && (
            <div className="flex-row align-center mb-10">
              <img src={ICONS?.alertIconError} alt="alertIconError" />
              <span className="error-message pl-5">{resendError}</span>
            </div>
          )}
          {!resendError ? (
            <button
              type="submit"
              className="bg-[var(--new-primary)] text-white font16px-500 px-7 py-[0.5vw] rounded-[8px]"
              onSubmit={handleSubmit}
            >
              {isLoading ? (
                <div className=" flex items-center justify-center">
                  <Loader Width={85} Height={20} loaderBg="white" />
                </div>
              ) : (
                "Resend Email"
              )}
            </button>
          ) : (
            <div className="bg-[var(--BG-50)] text-[#566F8F] font16px-500 px-7 py-[0.5vw] rounded-[8px] cursor-not-allowed">
              Resend Email
            </div>
          )}

          {/* <div className="flex items-center justify-center text-sm weight-semibold gap-4 mt-4">
            <p className="text-[var(--primary)]">
              {remainingMinutes?.toString().padStart(2, "0")}:
              {remainingSeconds?.toString().padStart(2, "0")}
            </p>
            <p
              className={`${
                remainingMinutes === 0 && remainingSeconds === 0
                  ? "text-[#2D3036] cursor-pointer"
                  : "text-[var(--font-600)] cursor-not-allowed"
              }`}
              onClick={async () => {
                const res = await ResendApi({ email: data?.mail });
                if (remainingMinutes === 0 && remainingSeconds === 0) {
                  setRemainingMinutes(minutes);
                  setRemainingSeconds(secounds);
                }
              }}
            >
              Resend code?
            </p>
          </div> */}
        </div>
      </form>
      <div className="flex items-center justify-center gap-1 border-t border-[#D1D3D8] mt-9 pt-8">
        <p className="font16px-400 text-[var(--textBlack)]">
          Already have an account?
        </p>
        <Link to={"/login"} className="text-[var(--a)]">
          Login
        </Link>
      </div>
    </div>
  );
}
