import React from "react";
import "../style/SignUp.css";

import useToggle from "../../../hooks/useToggle";

import { Form } from "antd";

import Label from "../../../components/form/label/label";
import { Input } from "../../../components/form/Input/Input";
import { Button } from "../../../components/form/Button/Button";

import { forgotPassword } from "../api/Api";

import { useAspSelector, useAspDispatch } from "../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

const button = {
  margin: "25px 0 15px 0",
};

function ForgotPassword() {
  const [value, toggleValue] = useToggle(false);

  const dispatch = useAspDispatch();
  const { toggleToast } = useAspSelector((state) => state.app);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [queryParams] = useSearchParams();
  const validationToken = queryParams?.get("token");

  const onFinish = (values) => {
    const payLoad = { email: values?.Email };
    toggleValue(true);
    forgotPassword(payLoad).then((res) => {
      forgotPasswordResponseUpdate(res);
    });
  };

  const forgotPasswordResponseUpdate = (res) => {
    toggleValue(false);
    form.setFieldsValue({ Email: "" });
    if (res?.status === 200) {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            // content: res?.data?.message,
            content: "Check the email to reset password",
            status: "Success",
            duration: "",
          },
        ])
      );
    } else {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: res?.response?.data?.error,
            status: "Error",
            duration: "",
          },
        ])
      );
    }
  };
  return (
    <>
      <span style={{ marginTop: "20px" }} />
      <Form
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        className="step2Wrapper"
      >
        <div className="" style={{ width: "55%" }}>
          <Label title="Email address" required />
        </div>

        <Form.Item name="Email" style={{ width: "55%" }}>
          <Input placeholder="Enter email" />
        </Form.Item>

        <Button
          size="large"
          htmltype="submit"
          loading={value}
          disabled={value}
          label={`Send Reset Link`}
          style={button}
        />

        <div className="flex items-center justify-center gap-1 font16px-400 text-[var(--textBlack)] mt-4">
          <p>Don’t have an account?</p>
          {/* <Link to={signUpLink} className='text-[var(--a)]'>
            Sign up
          </Link> */}
          <button
            type="submit"
            className="font16px-600 flex items-center justify-center text-[var(--a)]"
            onClick={() => {
              if (validationToken)
                navigate({
                  pathname: "/sign-up",
                  search: createSearchParams({
                    token: queryParams?.get("token"),
                  }).toString(),
                });
              else navigate("/sign-up");
            }}
          >
            Sign up
          </button>
        </div>
      </Form>
    </>
  );
}

export default ForgotPassword;
