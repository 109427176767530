import React from "react"
import AccountUsersTable from "../../components/AccountUsersTable";

const AccountUsers = () => {
 
  return (
    <div className="w-full px-5 py-7">
      <h2 className="font24px-500 tesxt-[var(--textBlack)] mb-1">
        Account Users
      </h2>
      <AccountUsersTable />
    </div>
  );
};

export default AccountUsers;
