import React, { useEffect, useRef, useState } from 'react';

// icons
import { ICONS } from '../../../assets/icons';

// hooks
import useToggle from '../../../hooks/useToggle';
import { cn } from '../../../helper/cn';

export default function SearchBar({
  loading = false,
  searchKey,
  setSearchKey,
  onChange,
  placeholder,
  autoFocus = false,
  close = true,
  className = '',
  expandable,
  search,
  searchCancel,
  disabled = false,
}) {
  const [isInputFocused, setInputFocused] = useToggle(false);
  const [isExpandable, setIsExpandable] = useToggle(false);

  const inputRefContainer = useRef();
  const inputRef = useRef();

  const handleCancel = () => {
    setSearchKey('');
    onChange('');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [searchKey]);

  useEffect(() => {
    setTimeout(() => {
      if (expandable && isExpandable) inputRef?.current?.focus();
    }, 200);
  }, [expandable, isExpandable]);

  const handleClickOutside = (e) => {
    if (
      inputRefContainer.current &&
      !inputRefContainer.current.contains(e.target)
    ) {
      if (expandable && !searchKey) {
        setIsExpandable(false);
        setInputFocused(false);
      } else if (!searchKey) {
        setInputFocused(false);
      }
    }
  };

  return (
    <div
      ref={inputRefContainer}
      className={cn(
        'w-full max-h-8 h-full flex items-center rounded-[0.4vw] overflow-hidden transition-colors duration-200 ease-linear hover:bg-[var(--BG-25)] p-[8px]',
        isInputFocused || searchKey ? 'bg-[var(--BG-25)]' : '',
        expandable
          ? 'bg-[var(--BG-25)] transition-all ease-linear duration-500'
          : '',
        isInputFocused || (expandable && isExpandable) ? '' : '',
        !expandable || (expandable && isExpandable) ? 'w-[17vw]' : 'w-[2.3vw]',
        disabled || loading ? 'cursor-not-allowed' : 'cursor-pointer',
        className
      )}
    >
      <div
        className={cn('', expandable && !isExpandable ? '' : '')}
        onClick={() => {
          if (!disabled && expandable) {
            setIsExpandable(!isExpandable);
            if (!isInputFocused) inputRef?.current?.focus();
          }
        }}
      >
        <img
          src={ICONS?.searchIcon}
          alt='search icon'
          className='w-[1vw] h-[1vw]'
        />
      </div>
      <>
        {(!expandable || (expandable && isExpandable)) && (
          <>
            <input
              type='text'
              placeholder={placeholder ?? 'Search...'}
              value={searchKey}
              ref={inputRef}
              className={cn(
                'w-full pl-[0.6vw] pr-[0.3vw] border-none outline-none bg-transparent text-[var(--textBlack)] font-md weight-medium placeholder:text-[var(--font-400)] placeholder:weight-small',
                !disabled && loading ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
              onChange={(e) => {
                if (!disabled && !loading && onChange) onChange(e.target.value);
              }}
              onFocus={() => {
                if (!disabled && !loading) setInputFocused(true);
              }}
              onBlur={() => {
                if (!disabled && !loading) setInputFocused(false);
              }}
              autoFocus={autoFocus}
              disabled={loading || disabled}
              data-testid={search}
            />

            {close && (
              <img
                src={ICONS?.popupX}
                alt='exit'
                className={`w-[1.1vw] h-[1.1vw] rounded-full cursor-pointer transition-transform duration-300 ease-linear ${
                  searchKey ? 'scale-125' : 'scale-0'
                }`}
                onClick={() => {
                  if (!loading) handleCancel();
                }}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}
