import React, { useCallback, useEffect, useState } from "react";
import "../../styles/Broadcast.css";
import "../../styles/Preview.css";

import { AudienceList } from "../../../../constant/app/campaign/Broadcast";
import { ICONS } from "../../../../assets/icons";
import { Popover } from "antd";
import Individual from "./Individual";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  setCallEnabled,
  setDelayValue,
  setError,
  setSelectedDateMerge,
  setSelectedTimeMerge,
  setUpdatedInputValue,
} from "../../../../reduxToolkit/broadcastSlice";

import {
  getTimeString,
  formatTimeWithAmPm,
} from "../../../../helper/timeUtils";
import TimePickers from "./TimePickers";

const BroadcastSegments = ({
  handleLabelSegmentsClick,
  selectedIndexSegments,
  setSelectedIndexSegments,
  selectedIndex,
  response,
  setResponse,
  setSelectedId2,
  broadCasts,
  response1,
  setResponse1,
  indexRem,
  setIndexRem,
  contactsRemaining1,
  contactsRemaining2,
  setContactsRemaining1,
  setContactsRemaining2,
  contactsRemainingMerge1,
  contactsRemainingMerge2,
  setContactsRemainingMerge1,
  setContactsRemainingMerge2,
  totalContact,
  totalContact1,
  totalContact2,
  setTotalContact,
  setTotalContact1,
  setTotalContact2,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState({});
  const [hasChange1, setHasChange1] = useState(false);
  const [hasChange2, setHasChange2] = useState(false);
  const [hours, setHours] = useState("12");
  const [minutes, setMinutes] = useState("00");
  const [periods, setPeriod] = useState("AM");
  const [rem, setRem] = useState({});
  const [add, setAdd] = useState(false);

  const dispatch = useAspDispatch();
  const location = useLocation();

  const { dateCombine } = useAspSelector((state) => state?.Campaign);

  const { error, selectedDate, selectedTime, callEnabled } = useAspSelector(
    (state) => state?.broadcast
  );

  const handleLabelClick = (dataName) => {
    const validsTotal = response?.reduce((total, item) => {
      const valids = item.valid_count || 0;
      return total + valids;
    }, 0);

    const valids = response1?.map((data) => data?.valid_count);
    const updatedDataName =
      dataName === "Use as Individual File"
        ? "individual"
        : dataName === "Merge Files"
        ? "merge"
        : dataName;

    if (updatedDataName === "merge" && response?.length === 2) {
      handleLabelSegmentsClick(updatedDataName);
      setSelectedIndexSegments(updatedDataName);
      if (response?.[0]?.segments?.[0]?.end === 0) {
        setContactsRemainingMerge1(validsTotal);
      }
      setTotalContact(validsTotal);
    } else if (
      response?.length > 0 &&
      updatedDataName === "individual" &&
      setSelectedIndexSegments
    ) {
      handleLabelSegmentsClick(updatedDataName);
      setSelectedIndexSegments(updatedDataName);
      if (response?.length === 1) {
        if (response1?.[0]?.segments?.[0]?.end === 0) {
          setContactsRemaining1(valids[0]);
        }
        setTotalContact1(valids[0]);
      } else if (response?.length > 1) {
        if (response1?.[0]?.segments?.[0]?.end === 0) {
          setContactsRemaining1(valids[0]);
          setContactsRemaining2(valids[1]);
        } else if (response1?.[1]?.segments?.[0]?.end === 0) {
          setContactsRemaining2(valids[1]);
        }
        setTotalContact1(valids[0]);
        setTotalContact2(valids[1]);
      }
    }

    if (
      updatedDataName === "merge" &&
      selectedIndexSegments === "merge" &&
      response?.length === 2
    ) {
      updateMergeFiles();
    } else if (
      updatedDataName === "individual" &&
      selectedIndexSegments === "individual"
    ) {
      updateMergeFiles();
    }
  };

  useEffect(() => {
    if (location?.state?.item) {
      const validsTotal = response?.reduce((total, item) => {
        const valids = item.valid_count || 0;
        return total + valids;
      }, 0);

      setTotalContact(validsTotal);
    }
  }, []);

  const handleTimePickerOpen = (dataId, segmentId) => {
    setIsDatePickerOpen((prev) => ({
      ...prev,
      [`${dataId}-${segmentId}`]: !prev[`${dataId}-${segmentId}`],
    }));
  };

  const updateMergeFiles = (id, index) => {
    let lastSegmentRemaining1, lastSegmentRemaining2;
    if (
      selectedIndexSegments === "merge" &&
      selectedIndexSegments !== "individual"
    ) {
      if (selectedIndexSegments === "merge") {
        if (index === 0) {
          lastSegmentRemaining1 = contactsRemainingMerge1;
        } else if (index === 1) {
          lastSegmentRemaining2 = contactsRemainingMerge2;
        }
        const res = response?.map((res) => {
          if (res?.id === id) {
            const newId = res?.segments?.length + 1;
            let newData;

            if (selectedIndex === "send_now") {
              newData = {
                id: newId,
                start: 0,
                end: 0,
                delay: 0,
                flag: false,
              };
            } else {
              newData = {
                id: newId,
                start: 0,
                end: 0,
                schedule_on: "",
                flag: false,
              };
            }

            const updatedSegments = (res?.segments ?? []).map((segment) => ({
              ...segment,
              flag: true,
            }));

            return {
              ...res,
              segments: [...updatedSegments, newData],
            };
          } else {
            return res;
          }
        });

        if (
          selectedIndexSegments === "merge" &&
          selectedIndexSegments !== "individual"
        ) {
          setResponse(res);
        }
        if (index === 0) {
          setContactsRemainingMerge1(lastSegmentRemaining1);
        } else if (index === 1) {
          setContactsRemainingMerge2(lastSegmentRemaining2);
        }
      }
    }
    dispatch(setDelayValue({}));
    dispatch(setUpdatedInputValue({}));
    setIndexRem(false);
  };

  const handleChange = (event, segmentId, id) => {
    setRem((prev) => ({
      ...prev,
      [`${segmentId}-${id}`]: false,
    }));
    dispatch(setCallEnabled(false));

    const inputValue = event.target.value;

    let newCount = 0;

    const newRes = response?.map((r) => {
      if (id === r.id) {
        const updatedSegments = r.segments?.map((s) => {
          const startValue =
            s.id > 1 ? r.segments.find((seg) => seg.id === s.id - 1)?.end : 0;

          if (s.id === segmentId) {
            const updatedEnd = Math.max(0, +inputValue);
            newCount += updatedEnd;
            return {
              ...s,
              start: startValue,
              end: updatedEnd,
            };
          }
          newCount += s.end;
          return s;
        });
        return { ...r, segments: updatedSegments };
      }
      return r;
    });

    const updatedContactsRemaining = Math.max(0, totalContact - newCount);
    setContactsRemainingMerge1(updatedContactsRemaining);

    if (newCount > totalContact) {
      const exceededBy = newCount - totalContact;
      dispatch(setError(`Contact count exceeded by ${exceededBy}`));
    } else {
      dispatch(setError(null));
    }

    dispatch(
      setUpdatedInputValue({
        id: id,
        segmentId: segmentId,
        inputValue: inputValue,
      })
    );

    setResponse(newRes);
  };

  const addBalanceContact = (segmentId, id) => {
    const newRes = response?.map((r) => {
      if (id === r.id) {
        const updatedSegments = r.segments?.map((s) => {
          if (s.id === segmentId) {
            if (id === 1) {
              return {
                ...s,
                end: s.end + contactsRemainingMerge1,
              };
            } else if (id === 2) {
              return {
                ...s,
                end: s.end + contactsRemainingMerge2,
              };
            }
          }
          return s;
        });
        return { ...r, segments: updatedSegments };
      }
      return r;
    });

    setResponse(newRes);

    setRem((prev) => ({
      ...prev,
      [`${segmentId}-${id}`]: true,
    }));

    if (id === 1) {
      setContactsRemainingMerge1(0);
    } else if (id === 2) {
      setContactsRemainingMerge2(0);
    }
  };

  const formattedDateCombine = moment(dateCombine).format("YYYY-MM-DD");
  const handleTimeChange = (id, segmentId) => {
    const newSelectedTime = getTimeString(hours, minutes, periods);

    if (!newSelectedTime) {
      return;
    }
    let formattedTime;
    if (hours === 12) {
      formattedTime = `${hours}:${minutes
        .toString()
        .padStart(2, "0")} ${periods}`;
    } else {
      formattedTime = formatTimeWithAmPm(newSelectedTime);
    }
    const formattedDateCombine = moment(dateCombine).format("YYYY-MM-DD");
    const combined = `${formattedDateCombine} ${formattedTime}`;
    const dateCombineDateTime = moment(dateCombine).format("hh:mm A");
    if (
      formattedDateCombine === moment(dateCombine).format("YYYY-MM-DD") &&
      moment(formattedTime, "hh:mm A").isSameOrBefore(
        moment(dateCombineDateTime, "hh:mm A")
      )
    ) {
      return;
    }

    const segmentsToUpdate =
      response.find((res) => res.id === id)?.segments || [];
    const prevSegmentIndex =
      segmentsToUpdate.findIndex((segment) => segment.id === segmentId) - 1;

    if (prevSegmentIndex >= 0) {
      const prevSegment = segmentsToUpdate[prevSegmentIndex];
      const prevSegmentTime = prevSegment.schedule_on;
      const timeDiff = moment(combined).diff(moment(prevSegmentTime), "hours");
      if (timeDiff < 1) {
        return;
      }
    }
    const updatedResponse = response.map((res) => {
      if (res?.id === id) {
        const updatedSegments = res.segments.map((segment1) => {
          if (segment1.id === segmentId) {
            if (selectedIndex === "send_later") {
              return { ...segment1, schedule_on: combined };
            }
          }
          if (segment1.id > segmentId) {
            if (selectedIndex === "send_later") {
              return { ...segment1, schedule_on: "" };
            }
          }
          return segment1;
        });
        return { ...res, segments: updatedSegments };
      }
      return res;
    });
    dispatch(
      setSelectedTimeMerge({
        id: id,
        segmentId: segmentId,
        time: formattedTime,
      })
    );

    setResponse(updatedResponse);
  };

  function handleDelayChange(newDelay, segmentId, id) {
    let delayValue;

    if (newDelay !== "") {
      delayValue = parseInt(newDelay, 10);
      if (isNaN(delayValue) || delayValue < 0) {
        delayValue = "";
      }
    } else {
      delayValue = "";
    }
    dispatch(setDelayValue(delayValue));

    const updatedDelay = response?.map((res) => {
      if (res?.id === segmentId) {
        const updatedSegmentSettings = res?.segments?.map((segment) => {
          if (segment?.id === id) {
            if (selectedIndex === "send_now") {
              return { ...segment, delay: delayValue };
            }
          }
          return segment;
        });
        return {
          ...res,
          segments: updatedSegmentSettings,
        };
      }
      return res;
    });

    setResponse(updatedDelay);
  }

  const handleIncrementDelay = (segmentId, id) => {
    const increment = response?.map((res) => {
      if (res.id === id) {
        const updateIncrement = res?.segments?.map((segment) => {
          if (segment?.id === segmentId) {
            if (segment.delay < 20) {
              return { ...segment, delay: segment.delay + 1 };
            }
          }
          return segment;
        });
        return {
          ...res,
          segments: updateIncrement,
        };
      } else {
        return res;
      }
    });
    const updatedSegment = increment
      ?.find((res) => res.id === id)
      ?.segments.find((segment) => segment.id === segmentId);

    const updatedDelayValue = updatedSegment?.delay;

    if (updatedDelayValue !== undefined) {
      dispatch(setDelayValue(updatedDelayValue));
    }

    setResponse(increment);
  };

  const handleDecrementDelay = (segmentId, id) => {
    const increment = response?.map((res) => {
      if (res.id === id) {
        const updateIncrement = res?.segments?.map((segment) => {
          if (segment?.id === segmentId) {
            if (segment.delay > 5) {
              return { ...segment, delay: segment.delay - 1 };
            }
          }
          return segment;
        });
        return {
          ...res,
          segments: updateIncrement,
        };
      } else {
        return res;
      }
    });

    const updatedSegment = increment
      ?.find((res) => res.id === id)
      ?.segments.find((segment) => segment.id === segmentId);

    const updatedDelayValue = updatedSegment?.delay;

    if (updatedDelayValue !== undefined) {
      dispatch(setDelayValue(updatedDelayValue));
    }
  };

  const handleDeleteClick = (segmentIdToDelete, dataId, event) => {
    const inputValue = event.target.value;

    let deletecontacts = 0;

    const updatedResponse = response?.map((data) => {
      if (data.id === dataId) {
        const deletedSegmentIndex = data.segments.findIndex(
          (segment) => segment.id === segmentIdToDelete
        );

        if (deletedSegmentIndex !== -1) {
          const deletedSegment = data.segments[deletedSegmentIndex];
          let remainingContacts = deletedSegment.end;

          if (inputValue === "") {
            remainingContacts = "";
            setSelectedId2(false);
          }

          deletecontacts =
            dataId === 1
              ? remainingContacts + contactsRemainingMerge1
              : remainingContacts + contactsRemainingMerge2;

          const updatedSegments = [...data.segments];
          updatedSegments.splice(deletedSegmentIndex, 1);

          // data.segments.splice(deletedSegmentIndex, 1);

          if (dataId === 1) {
            setContactsRemainingMerge1(deletecontacts);
            setHasChange1(true);
          } else if (dataId === 2) {
            setContactsRemainingMerge2(deletecontacts);
            setHasChange2(true);
          }

          if (deletedSegmentIndex === updatedSegments.length) {
            if (deletedSegmentIndex > 0) {
              const previousSegment = {
                ...updatedSegments[deletedSegmentIndex - 1],
                flag: false,
              };

              updatedSegments[deletedSegmentIndex - 1] = previousSegment;
            }
          }

          return {
            ...data,
            segments: updatedSegments,
          };
        }
      }

      return data;
    });

    dispatch(setSelectedDateMerge({ type: "clear" }));
    dispatch(setSelectedTimeMerge({ type: "clear" }));
    setResponse(updatedResponse);
  };

  const handleOkClick = (dataId, segmentId) => {
    setIsDatePickerOpen((prev) => ({
      ...prev,
      [`${dataId}-${segmentId}`]: false,
    }));
  };

  const checkToAllowNext = useCallback(() => {
    let handleInput = [];
    let handleDelay = [];
    let handleDate = [];
    let handleTime = [];

    if (selectedIndexSegments === "merge") {
      response?.[0]?.segments?.map((s) => {
        if (s.end === 0) handleInput.push(s);
        if (selectedIndex === "send_now") {
          if (s.delay < 5) handleDelay.push(s);
        } else {
          if (!s.schedule_on?.split(" ")?.includes("")) {
            const schecduleOn = s?.schedule_on?.split(" ");
            const date = schecduleOn?.[0];
            const time = schecduleOn?.[1];
            if (date && time) {
              handleDate.push(date);
              handleTime.push(time);
            }
          } else {
            handleDate.push("");
            handleTime.push("");
          }
        }
      });

      if (
        selectedIndex === "send_now" &&
        handleInput?.length === 0 &&
        handleDelay?.length === 1
      ) {
        setSelectedId2(true);
        setAdd(true);
      } else if (
        selectedIndex === "send_later" &&
        handleInput?.length === 0 &&
        !handleDate.includes("") &&
        !handleTime.includes("")
      ) {
        setSelectedId2(true);
        setAdd(true);
      } else {
        setSelectedId2(false);
        setAdd(false);
      }
    }

    if (error) {
      setSelectedId2(false);
      setAdd(false);
    }

    return handleInput?.length === 0 && handleDelay?.length <= 1;
  }, [response, selectedIndexSegments, selectedDate, selectedTime]);

  useEffect(() => {
    checkToAllowNext();
  }, [checkToAllowNext]);

  // Edit - flow ---------------------------------------

  const call = useCallback(() => {
    if (broadCasts?.settings?.segment_settings) {
      if (!indexRem && broadCasts && location?.state?.item) {
        const editContacts1 = broadCasts?.settings?.contactsRemaining1;
        setContactsRemainingMerge1(editContacts1);
      }
    }
  }, [location?.state?.item]);

  useEffect(() => {
    if (callEnabled) {
      call();
    }
  }, [call, callEnabled, location?.state]);

  return (
    <div className="Broadcast-segments ">
      <div className="BroadcastSegments-container">
        <div>
          <p>Define Contact Segments</p>
          <span>Select how you would like to segment the audience(s)</span>
        </div>

        <div className="SendSetting-container flex-column">
          <div className=" flex-row mb-6">
            {AudienceList?.map((data) => (
              <React.Fragment key={data?.id}>
                <div
                  className={`flex-row align-center ${
                    response?.length === 1 && data?.name === "Merge Files"
                      ? "disabled-row"
                      : ""
                  }`}
                  style={{
                    padding: "10px 10px 24px 20px",
                    borderBottom: "1px solid var(--font-50)",
                  }}
                >
                  <div
                    className={`flex-row align-center pointer ${
                      response?.length === 1 && data.name === "Merge Files"
                        ? "disabled-row"
                        : ""
                    }`}
                    onClick={() => handleLabelClick(data?.name)}
                  >
                    <label className="checkBoxContainer">
                      <input
                        type="checkbox"
                        name="checkbox"
                        checked={
                          (selectedIndexSegments === "individual" &&
                            data?.name === "Use as Individual File") ||
                          (selectedIndexSegments === "merge" &&
                            response?.length === 2 &&
                            data?.name === "Merge Files")
                        }
                        disabled={
                          response?.length === 1 && data?.name === "Merge Files"
                        }
                        onChange={() => {}}
                      />
                    </label>
                    <div className="setting-setting flex-column">
                      <span>{data?.name}</span>
                      <p>{data?.content}</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="flex-column pl-10">
            {selectedIndexSegments === "merge" && response?.length === 2 && (
              <>
                {error && (
                  <div
                    style={{ color: "red" }}
                    className="error-message flex-row align-center"
                  >
                    <img src={ICONS?.alertIconError} />
                    <p className="pl-2">{error}</p>
                  </div>
                )}
                <div className="merge-container flex-row mb-10 pt-5">
                  <img src={ICONS?.MaximumIcon} alt="" />
                  <p>Maximum of 5 segments can be added</p>
                </div>

                <div className="resp">
                  {response?.map((data) => {
                    const segments = data?.segments;
                    return (
                      <React.Fragment key={data?.id}>
                        {segments?.map((segment, index) => {
                          return (
                            <div className="" key={segment?.id}>
                              {segment?.id > 1 && data?.id === 1 && (
                                <>
                                  {selectedIndex === "send_now" ? (
                                    <div className="flex-column">
                                      <div className="flex-column">
                                        <div className=" Delay-container flex-row align-center   ">
                                          <img
                                            src={ICONS?.delayIcon}
                                            alt="delay"
                                            className="delay-image"
                                          />
                                          <p>Delay</p>
                                        </div>
                                        <div
                                          className="Delay-wrapper flex-row align-center  pl-5 gap-5 ml-10"
                                          style={{
                                            borderLeft:
                                              "1px solid var(--font-200)",
                                            margin: "4px",
                                          }}
                                        >
                                          <span>Wait for</span>
                                          <div
                                            className="flex-row align-center flex-end"
                                            style={{
                                              border:
                                                "1px solid var(--font-50)",
                                              padding: "10px",
                                            }}
                                          >
                                            <div className="flex-row flex-end align-center space-between">
                                              <input
                                                value={segment.delay}
                                                min="5"
                                                onChange={(e) => {
                                                  if (e.target.value <= 20) {
                                                    handleDelayChange(
                                                      e.target.value,
                                                      data?.id,
                                                      segment?.id
                                                    );
                                                  }
                                                }}
                                                onInput={(event) => {
                                                  event.target.value =
                                                    event.target.value.replace(
                                                      /[^0-9]/g,
                                                      ""
                                                    );
                                                }}
                                                className="no-spinner"
                                              />

                                              <div className="flex-column">
                                                <img
                                                  src={
                                                    ICONS?.BroadcastChevronUpIcon
                                                  }
                                                  alt="upIcon"
                                                  className="upIcon pointer"
                                                  onClick={() =>
                                                    handleIncrementDelay(
                                                      segment.id,
                                                      data.id
                                                    )
                                                  }
                                                />
                                                <img
                                                  src={
                                                    ICONS?.BroadcastChevronDownIcon
                                                  }
                                                  alt="downIcon"
                                                  className="downIcon pointer"
                                                  onClick={() =>
                                                    handleDecrementDelay(
                                                      segment.id,
                                                      data.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              border:
                                                "1px solid var(--border-50)",
                                              padding: "12px 30px 0px 5px ",
                                              fontSize: "0.75vw",
                                              fontWeight: "500",
                                              color: "var(--font-600)",
                                              width: "8vw",
                                            }}
                                          >
                                            <span>Minutes</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-row align-center pl-20">
                                        <img src={ICONS?.MaximumIcon} alt="" />

                                        <span className="delay-minutes pl-5">
                                          Delay should be greater than 5 minutes
                                          and less than 20 minutes
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="Calendar-container flex-row w-100 space-between align-center">
                                      <div className="aaaa  w-70 flex-row align-center">
                                        <img
                                          src={ICONS?.delayIcon}
                                          alt="delay"
                                          className="delay-image"
                                        />
                                        <span className="send_later pl-5">
                                          Scheduled on
                                        </span>
                                        <div className="ml-20">
                                          <div className="flex-row align-center">
                                            <input
                                              placeholder={"DD/MM/YY"}
                                              iconPrefix={
                                                ICONS?.BroadcastCalendarIcon
                                              }
                                              value={formattedDateCombine}
                                              readOnly={true}
                                              className="date-time"
                                            />
                                          </div>
                                        </div>
                                        <div className="broadcast-calendar  flex-row align-center">
                                          <div className="At p-5">@</div>
                                          <Popover
                                            content={
                                              <TimePickers
                                                hours={hours}
                                                minutes={minutes}
                                                periods={periods}
                                                setHours={setHours}
                                                setMinutes={setMinutes}
                                                setPeriod={setPeriod}
                                                onClick={() =>
                                                  handleTimeChange(
                                                    data?.id,
                                                    segment?.id
                                                  )
                                                }
                                                onOkClick={() =>
                                                  handleOkClick(
                                                    data?.id,
                                                    segment?.id
                                                  )
                                                }
                                              />
                                            }
                                            overlayClassName="time-popover"
                                            trigger="click"
                                            arrow={false}
                                            open={
                                              isDatePickerOpen[
                                                `${data.id}-${segment.id}`
                                              ] || false
                                            }
                                            className="ant-popover-content"
                                            onOpenChange={() =>
                                              handleTimePickerOpen(
                                                data?.id,
                                                segment?.id
                                              )
                                            }
                                          >
                                            <div className="flex-row align-center pointer ml-5">
                                              <input
                                                placeholder={"00.00 AM"}
                                                className="date-time"
                                                iconPrefix={
                                                  ICONS?.BroadcastTimerIcon
                                                }
                                                value={segment?.schedule_on
                                                  ?.split(" ")
                                                  ?.slice(1)
                                                  ?.join(" ")}
                                              />
                                            </div>
                                          </Popover>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {data?.id === 1 && (
                                <>
                                  <div className=" Delay-container flex-row align-center w-100 space-between ">
                                    <div className="flex-row align-center ">
                                      <img
                                        src={ICONS?.BroadcastSegmentIcon}
                                        alt=""
                                        className="delay-image"
                                      />
                                      <span>Segment - {index + 1}</span>
                                    </div>

                                    {index === segments.length - 1 &&
                                      index !== 0 && (
                                        <img
                                          src={ICONS?.deleteIcon}
                                          alt="delete"
                                          className="Delay-image pointer"
                                          onClick={(e) =>
                                            handleDeleteClick(
                                              segment.id,
                                              data.id,
                                              e
                                            )
                                          }
                                          onMouseOver={(e) =>
                                            (e.currentTarget.src =
                                              ICONS?.BroadcastRedDeleteIcon)
                                          }
                                          onMouseOut={(e) =>
                                            (e.currentTarget.src =
                                              ICONS?.deleteIcon)
                                          }
                                        />
                                      )}
                                  </div>
                                  <div
                                    className=""
                                    style={{
                                      borderLeft: "1px solid var(--font-200)",
                                      paddingLeft: "20px",
                                      margin: "5px",
                                    }}
                                  >
                                    <>
                                      <div className="flex-row w-100 ">
                                        <div className="Delay-wrapper w-100 align-center  ">
                                          <div className="segment-container flex-row align-center w-100 space-between pt-20">
                                            <span className="detail">
                                              Contact Count
                                            </span>

                                            <div className="increment w-100 flex-row align-center">
                                              <div
                                                key={segment.id}
                                                style={{
                                                  border:
                                                    "1px solid var(--font-50)",
                                                  padding: "10px",
                                                }}
                                              >
                                                <div
                                                  className="w-100 space-between flex-row align-center"
                                                  style={{
                                                    fontSize: "0.75vw",
                                                    fontWeight: "600",
                                                    color: "var(--textBlack)",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    placeholder="Should be greater than 1"
                                                    value={segment?.end}
                                                    onChange={(event) => {
                                                      handleChange(
                                                        event,
                                                        segment.id,
                                                        data.id
                                                      );
                                                    }}
                                                    onInput={(event) => {
                                                      event.target.value =
                                                        event.target.value.replace(
                                                          /[^0-9]/g,
                                                          ""
                                                        );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              <p className="contacts-details">
                                                <span>
                                                  {segment.flag !== true &&
                                                  !hasChange1 &&
                                                  data?.id === 1
                                                    ? contactsRemainingMerge1
                                                    : segment.flag !== true &&
                                                      !hasChange2 &&
                                                      data?.id === 2
                                                    ? contactsRemainingMerge2
                                                    : segment.flag !== true &&
                                                      hasChange1 &&
                                                      data?.id === 1
                                                    ? contactsRemainingMerge1
                                                    : segment.flag !== true &&
                                                      hasChange2 &&
                                                      data?.id === 2
                                                    ? contactsRemainingMerge2
                                                    : 0}
                                                </span>
                                                Contact remaining
                                              </p>
                                            </div>
                                            {index === segments.length - 1 && (
                                              <div
                                                onClick={() => {
                                                  addBalanceContact(
                                                    segment?.id,
                                                    data?.id
                                                  );
                                                }}
                                              >
                                                <div>
                                                  <label className="checkBoxContainer">
                                                    <input
                                                      type="checkbox"
                                                      name="checkbox"
                                                      checked={
                                                        rem[
                                                          `${segment.id}-${data.id}`
                                                        ] || false
                                                      }
                                                      onChange={() => {}}
                                                    />
                                                  </label>
                                                  <div className="setting-setting flex-column">
                                                    <span>{data?.name}</span>
                                                    <p>{data?.content}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="!h-8 flex-row align-center !p-0 pl-20 !m-0">
                                            <img
                                              src={ICONS?.MaximumIcon}
                                              alt=""
                                            />

                                            <span className="delay-minutes pl-5">
                                              Delay should be greater than one
                                              hour
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        })}

                        {data?.segments?.length < 5 &&
                          data?.id === 1 &&
                          contactsRemainingMerge1 > 0 && (
                            <div
                              className={`flex-row align-center pt-10 mr-10 pr-10 ${
                                add ? "cursor-pointer" : "cursor-not-allowed"
                              }`}
                            >
                              <img
                                src={ICONS?.BroadcastAddIcon}
                                alt="BroadcastAddIcon"
                                className="delay-image"
                                onClick={
                                  add ? () => updateMergeFiles(data.id) : null
                                }
                              />
                              <p
                                className="Add-task"
                                onClick={
                                  add ? () => updateMergeFiles(data.id) : null
                                }
                              >
                                Add New Segment
                              </p>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {selectedIndexSegments === "individual" &&
            selectedIndexSegments !== "merge" && (
              <Individual
                selectedIndexSegments={selectedIndexSegments}
                selectedIndex={selectedIndex}
                response1={response1}
                setResponse1={setResponse1}
                setSelectedId2={setSelectedId2}
                broadCasts={broadCasts}
                indexRem={indexRem}
                contactsRemaining1={contactsRemaining1}
                contactsRemaining2={contactsRemaining2}
                setContactsRemaining1={setContactsRemaining1}
                setContactsRemaining2={setContactsRemaining2}
                totalContact1={totalContact1}
                totalContact2={totalContact2}
                setTotalContact1={setTotalContact1}
                setTotalContact2={setTotalContact2}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default BroadcastSegments;
