import React from 'react';
import { Line } from 'react-chartjs-2';
import { ICONS } from '../../../../assets/icons';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import moment from 'moment';
import { cn } from '../../../../helper/cn';

// Register necessary components from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const Performance = ({
  performanceList,
  startDate,
  endDate,
  updatedPerformanceData,
  isBroadcast = true,
  rootClassName = '',
  canvasStyle,
}) => {
  const startDates = moment(startDate);
  const endDates = moment(endDate);

  // Generate labels from startDate to endDate
  const labels = [];
  let currentDate = startDates.clone();

  while (currentDate.isBefore(endDates) || currentDate.isSame(endDates)) {
    labels.push(currentDate.format('DD MMM'));
    currentDate.add(1, 'days');
  }

  const getCountForDate = (date, data) => {
    const entry = data?.find((d) => moment(d?.date).format('DD MMM') === date);
    return entry ? entry.count : 0;
  };

  const counts = updatedPerformanceData.reduce((acc, metric) => {
    acc[metric.key] = labels.map((date) =>
      getCountForDate(date, performanceList?.[metric.key])
    );
    return acc;
  }, {});

  const data = {
    labels: labels,
    datasets: updatedPerformanceData.map((metric) => ({
      label: metric?.label,
      data: counts[metric.key],
      fill: false,
      backgroundColor: metric?.color,
      borderColor: metric?.color,
      borderWidth: 2,
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          padding: 20,
        },
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,

        position: 'nearest',
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.raw; // Using raw data here
            return ` ${label}:  ${value || 0}`; // Show '0' for missing values
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            weight: '400',
            color: 'var(--font-600)',
          },
          maxTicksLimit: 7,
        },
        grid: {
          color: '#E8E8EA', // Change the X-axis grid line color
          borderColor: '#E8E8EA', // Change the X-axis line color
        },
      },
      y: {
        beginAtZero: true,

        ticks: {
          // stepSize: 200,
          callback: function (value) {
            if (value >= 1000) {
              return `${value / 1000}k`;
            }
            return value;
          },
          font: {
            size: 14,
            weight: '400',
            color: 'var(--font-600)',
          },
          padding: 20,
          maxTicksLimit: 7,
        },
        grid: {
          color: '#E8E8EA', // Change the X-axis grid line color
          borderColor: '#E8E8EA', // Change the X-axis line color
        },
      },
    },
    layout: {
      padding: {
        left: 20,
      },
    },
  };

  return (
    <div className={cn('!h-[28vh] mr-[20px]', rootClassName)}>
      {(isBroadcast && performanceList?.broadcast_started) ||
      (!isBroadcast && performanceList) ? (
        <Line data={data} options={options} />
      ) : (
        <div className='h-full flex flex-col items-center justify-center'>
          <img src={ICONS?.noChartData} alt='noChartData' />
          <p className='text-[#616874]'>There’s no data yet</p>
        </div>
      )}
    </div>
  );
};

export default Performance;

// //  Map counts to the respective dates
// const optOutCounts = labels.map((date) =>
//   getCountForDate(date, performanceList?.opt_out)
// );
// const clicksCounts = labels.map((date) =>
//   getCountForDate(date, performanceList?.clicks)
// );
// const leadsCounts = labels.map((date) =>
//   getCountForDate(date, performanceList?.leads)
// );
// const reactionCounts = labels.map((date) =>
//   getCountForDate(date, performanceList?.reactions)
// );

// const hasData = ["opt_out", "leads", "reactions", "clicks"]?.some(
//   (key) => performanceList?.[key]?.length > 0
// );

// const availableKey = ["opt_out", "leads", "reactions", "clicks"].find(
//   (key) => performanceList?.[key]
// );

// const sortedData = availableKey
//   ? performanceList[availableKey].sort(
//       (a, b) => new Date(a.date) - new Date(b.date)
//     )
//   : [];

// const labels =
//   sortedData?.length > 25
//     ? sortedData
//         .filter((_, index) => index % 4 === 0)
//         .map((item) => moment(item.date).format("DD MMM"))
//     : sortedData?.length > 7
//     ? sortedData
//         .filter((_, index) => index % 2 === 0)
//         .map((item) => moment(item.date).format("DD MMM"))
//     : sortedData?.slice(-7).map((item) => moment(item.date).format("DD MMM"));

// const blockedCounts = performanceList?.blocked
//   ?.slice(0, 7)
//   .map((item) => item.count);
// const clicksCounts = performanceList?.clicks
//   ?.slice(0, 7)
//   .map((item) => item.count);
// const leadsCounts = performanceList?.leads
//   ?.slice(0, 7)
//   .map((item) => item.count);
// const reactionCounts = performanceList?.reactions
//   ?.slice(0, 7)
//   .map((item) => item.count);

// const data = {
//   labels: labels,
//   datasets: [
//     {
//       label: "Clicks",
//       data: clicksCounts,
//       fill: false,
//       backgroundColor: "#9747FF",
//       borderColor: "#9747FF",
//       borderWidth: 2,
//     },
//     {
//       label: "Leads",
//       data: leadsCounts,
//       fill: false,
//       backgroundColor: "#3E93FF",
//       borderColor: "#3E93FF",
//       borderWidth: 2,
//     },
//     {
//       label: "Reactions",
//       data: reactionCounts,
//       fill: false,
//       backgroundColor: "#F6A723",
//       borderColor: "#F6A723",
//       borderWidth: 2,
//     },
//     {
//       label: "Opt-out",
//       data: optOutCounts,
//       fill: false,
//       backgroundColor: "#FF543E",
//       borderColor: "#FF543E",
//       borderWidth: 2,
//     },
//   ],
// };
