import React from 'react';

export default function Delete({
  width = '18',
  height = '18',
  color = '#898E99',
  className = '',
  strokeWidth = 2,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M1.3335 4.14706H14.6668M5.50016 1.5H10.5002M6.3335 12.9706V7.67647M9.66683 12.9706V7.67647M10.9168 16.5H5.0835C4.16302 16.5 3.41683 15.7099 3.41683 14.7353L3.03633 5.06614C3.0166 4.56486 3.39509 4.14706 3.86894 4.14706H12.1314C12.6052 4.14706 12.9837 4.56486 12.964 5.06614L12.5835 14.7353C12.5835 15.7099 11.8373 16.5 10.9168 16.5Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
