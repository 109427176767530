import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useToggle from "../../../../hooks/useToggle";
import { Alert, Modal, Popover } from "antd";
import { ICONS } from "../../../../assets/icons";
import { cn } from "../../../../helper/cn";
import Select from "../../../../components/commonComponents/select";
import { CATEGORIES_OPTIONS, LANGUAGE_OPTIONS } from "../../constant";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { FEATURE_FLAG_AUTHENTICATION_TEMPLATE } from "../../constant/featureFlags";

import { getLanguageName } from "helper/setLanguageCode";
import { capitalizeFirstLetter } from "../../../../helper/capitalizeFirstLetter";
import NewAlert from "../../../../components/commonComponents/modal/alert/NewAlert";
import {
  carouselDelete,
  carouselDiscard,
  checkTemplateExits,
  deleteTemplate,
  templateDiscard,
} from "../../api/Api";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import Loader from "../../../../components/commonComponents/Loader/Index";
import Delete from "../../../../assets/customSVG/Delete";

export default function TemplateDetailsHeader({
  category,
  loading,
  selectedLanguage,
  setSelectedLanguage,
  selectedCategory,
  setSelectedCategory,
  checkAllowToSubmit,
  createAndUpdateTemplateData,
  isCategorychanging,
  setIsCategorychanging,
  setIsDiscard,
  templateName,
  setTemplateName,
  templateNameError,
  setTemplateNameError,
  onChange,
  buttons,
  status,
  updateButtons,
}) {
  const { template } = useParams();
  const navigate = useNavigate();

  const dispatch = useAspDispatch();
  const templateDetails = useAspSelector((state) => state?.Template);
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);

  const [editTemplateName, setEditTemplateName] = useToggle(false);
  const [isTemplateNameChecking, setIsTemplateNameChecking] = useToggle(false);
  const [discardAlertOpen, setDiscardAlertOpen] = useToggle(false);
  const [isDeleteModal, setIsDeleteModal] = useToggle(false);
  const [isDelete, setIsDelete] = useToggle(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [popoverOpen, setPopoverOpen] = useToggle(false);
  const ref = useRef(null);

  const checkName = async (name) => {
    if (name) {
      setIsTemplateNameChecking(true);
      setTemplateNameError(false);
      try {
        const res = await checkTemplateExits({
          brandId: currentBrand?.brand_id,
          name: name,
        });
        if (res?.status === 200) {
          dispatch(update({ key: "name", value: templateName }));
          setEditTemplateName(false);
          setIsTemplateNameChecking(false);
        } else {
          setIsTemplateNameChecking(false);
          setTemplateNameError(true);
          setEditTemplateName(false);
        }
      } catch (e) {
        setIsTemplateNameChecking(false);
        setEditTemplateName(false);
        console.warn(e);
      }
    } else {
      setTemplateNameError(true);
      setIsTemplateNameChecking(false);
    }
  };

  const handleDiscard = () => {
    setDiscardAlertOpen(true);
  };

  const discardCarousel = () => {
    const payLoad = {
      brand_id: currentBrand?.brand_id,
    };

    if (templateDetails?.categoryType === "CAROUSEL") {
      carouselDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else {
      templateDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    }
  };

  const handleTemplateDelete = async () => {
    setIsDelete(true);
    const payload = {
      brand_id: currentBrand?.brand_id,
    };

    try {
      let res;
      if (templateDetails?.categoryType === "CAROUSEL") {
        res = await carouselDelete(templateDetails?.id, payload);
      } else {
        res = await deleteTemplate(templateDetails?.id, currentBrand?.brand_id);
      }

      if (res?.status === 200) {
        navigate("/user/channels/whatsapp/message-templates");

        setSelectedTemplate(null);
        setIsDeleteModal(false);
        loadTemplateList();
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Template deleted successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Unable to delete template. Please try again!",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
      return;
    } catch (e) {
      console.warn(e);
    } finally {
      setIsDelete(false);
    }
  };

  // styles
  const btnStyle =
    "w-auto px-[0.8vw] py-[0.5vw] font-md weight-semibold rounded-[0.37vw]";

  return (
    <>
      <div className="w-auto h-[3.7vw] bg-[var(--white)] m-[0.5vw] flex items-center justify-between px-[1vw] mb-0">
        <div className="w-full flex items-center">
          <div className="flex items-center justify-between gap-2.5 mr-4">
            <div
              className="w-full max-w-[16vw] h-full flex items-center gap-2.5 p-[0.5vw] border border-[var(--card-border)] rounded-[0.4vw]"
              ref={ref}
              onClick={() => {
                if (
                  (template !== "edit-template" ||
                    (template === "edit-template" && status === "DRAFT")) &&
                  !editTemplateName
                )
                  setEditTemplateName(true);
              }}
            >
              <img
                src={ICONS?.TemplateName}
                alt="template-name icons"
                className="size-4"
              />
              <div
                className={cn(
                  "w-full font-default weight-semibold flex flex-1",
                  template !== "edit-template" ||
                    (template === "edit-template" && status === "DRAFT")
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                )}
              >
                {(template !== "edit-template" ||
                  (template === "edit-template" && status === "DRAFT")) &&
                !editTemplateName ? (
                  <p className="w-full max-w-[12vw] truncate font-md weight-medium rounded-[0.4vw] outline-none ">
                    {templateName}
                  </p>
                ) : editTemplateName ? (
                  <div className="flex-row align-center">
                    <input
                      type="text"
                      value={templateName}
                      className="w-auto"
                      placeholder="Enter template name..."
                      maxLength={512}
                      onChange={(e) => {
                        const { value } = e.target;
                        const modifiedValue = value?.replace(/ /g, "_");
                        const sanitizedValue = modifiedValue
                          .replace(/[^\w\s]/gi, "")
                          .toLowerCase();
                        setTemplateName(sanitizedValue);
                      }}
                    />
                    <span className="w-[3.5vw] pl-10 text-[0.68vw] font-[400] text-[--font-600]">
                      {templateName?.length} / 512
                    </span>{" "}
                  </div>
                ) : (
                  <p className="w-full max-w-[12vw] truncate font-md weight-medium rounded-[0.4vw] outline-none">
                    {templateName ? (
                      <span
                        className={
                          template !== "edit-template" ||
                          (template === "edit-template" && status === "DRAFT")
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        }
                      >
                        {templateName}
                      </span>
                    ) : (
                      <span disabled className="text-[var(--font-400)]">
                        Enter template name...
                      </span>
                    )}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center gap-2.5">
              {isTemplateNameChecking ? (
                <Loader Width={20} height={20} />
              ) : editTemplateName ? (
                <img
                  src={ICONS?.greenTickVerticalSteps}
                  alt="confirm"
                  className={cn(
                    "w-[0.8vw] h-[0.8vw]",
                    templateName !== ""
                      ? "opacity-100 cursor-pointer"
                      : "cursor-not-allowed opacity-60"
                  )}
                  onClick={() => {
                    if (templateName !== "") checkName(templateName);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex items-center gap-[1.5vw] border-l-[0.08vw] border-[var(--card-border)] pl-[1.5vw]">
            {(["create-template"]?.includes(template) &&
              category() !== "CAROUSEL") ||
            (["edit-template", "duplicate-template"]?.includes(template) &&
              status === "DRAFT" &&
              category() !== "CAROUSEL") ? (
              <Select
                rootClassName="w-[10vw] border-[var(--card-border)]"
                placeholder="Select Category"
                options={
                  FEATURE_FLAG_AUTHENTICATION_TEMPLATE
                    ? CATEGORIES_OPTIONS
                    : CATEGORIES_OPTIONS?.slice(0, 2)
                }
                value={selectedCategory}
                allow={!loading}
                position="bottom"
                onChange={(value) => {
                  if (!loading) {
                    setIsCategorychanging(true);
                    if (template === "create-template")
                      onChange({ type: "reset" });

                    onChange({
                      type: "update",
                      data: {
                        category: value?.value,
                        categoryType:
                          value?.value === "MARKETING"
                            ? "CUSTOM"
                            : value?.value,
                        name: templateName,
                      },
                    });
                    setSelectedCategory(value);

                    if (value?.value === "UTILITY") {
                      const res = buttons?.filter(
                        (b) => b.format === "OPT_OUT"
                      );
                      if (res?.length > 0) {
                        updateButtons({
                          updateKey: "remove",
                          data: { id: res?.[0]?.id },
                        });
                      }
                    }
                  }
                }}
              />
            ) : (
              <>
                <span className="border-[0.08vw] border-[var(--card-border)] pl-10 pr-20 py-2 br-8 text-[0.75vw] w-[9vw] capitalize">
                  {templateDetails?.category}
                </span>
              </>
            )}
          </div>
          <div className="w-full bg-[var(--white)] px-[0.7vw]">
            {template === "create-template" ? (
              <Select
                value={selectedLanguage}
                options={
                  templateDetails?.categoryType !== "AUTHENTICATION"
                    ? LANGUAGE_OPTIONS
                    : LANGUAGE_OPTIONS.filter((option) =>
                        ["English", "English (US)", "English (UK)"].includes(
                          option.value
                        )
                      )
                }
                placeholder={"Select language"}
                allow={!loading}
                rootClassName="w-[10vw] border-[var(--card-border)]"
                optionsClassName="!w-[10vw]"
                cardStyle="!max-h-[1.5vw]"
                position="bottom"
                onChange={(val) => {
                  if (!loading) setSelectedLanguage(val);
                }}
                optionClick="selectLanguage"
              />
            ) : (
              <div className="w-[10vw] font-md weight-medium border-[0.08vw] border-[var(--border-100)] rounded-[0.4vw] p-[0.5vw] outline-none cursor-not-allowed">
                {selectedLanguage?.label}
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-[0.5vw]">
          <Popover
            trigger="click"
            open={popoverOpen}
            onOpenChange={(open) => setPopoverOpen(open)}
            placement="bottom"
            content={
              <div className="flex-column items-start rounded-lg pt-10 pb-10 pointer ">
                {(["create-template", "duplicate-template"]?.includes(
                  template
                ) ||
                  (template === "edit-template" &&
                    templateDetails?.details?.status !== "APPROVED" &&
                    templateDetails?.details?.status !== "PAM" &&
                    templateDetails?.details?.status !== "PRM" &&
                    templateDetails?.details?.status !== "REJECTED")) && (
                  <div
                    className="min-w-[155px] h-9 flex-row align-center gap-2.5 p-2.5 hover:bg-[var(--channelButton)] mx-2.5 b-radius-6"
                    onClick={() => {
                      createAndUpdateTemplateData(true);
                      setIsDiscard(false);
                    }}
                  >
                    <img
                      src={ICONS?.carouselDraft}
                      alt=""
                      className="w-4 h-4"
                    />
                    <span className="text-[0.8vw] font-[500]">
                      Save As Draft
                    </span>
                  </div>
                )}
                {template !== "duplicate-template" &&
                  (templateDetails?.details?.status === "APPROVED" ||
                    templateDetails?.details?.status === "PAM" ||
                    templateDetails?.details?.status === "PRM" ||
                    templateDetails?.details?.status === "REJECTED") && (
                    <div
                      className="min-w-[155px] h-9 flex-row align-center p-2.5 hover:bg-[var(--channelButton)] mx-2.5 b-radius-6 gap-2.5"
                      onClick={() => {
                        handleDiscard();
                        setPopoverOpen(false);
                      }}
                    >
                      <img
                        src={ICONS?.discardframe}
                        alt=""
                        className="w-4 h-4"
                      />
                      <span className="pl-5 text-[var(--font-600)] text-[0.8vw] font-[500]">
                        Discard
                      </span>
                    </div>
                  )}

                <div
                  className="min-w-[155px] h-9 flex items-center m-2.5 mb-0 gap-2.5 hover:bg-[var(--channelButton)] px-2.5 b-radius-6"
                  onClick={() => {
                    setIsDeleteModal(true);
                    setPopoverOpen(false);
                  }}
                >
                  {/* <img src={ICONS?.RedDeleteIcon} alt="" className="w-6 h-6" /> */}
                  <Delete width={18} height={18} color="var(--fontRed)" />
                  <span className="text-[red] text-[0.8vw] font-[500]">
                    Delete
                  </span>
                </div>
              </div>
            }
            arrow={false}
          >
            <div className="flex-row align-center gap-2 pointer border-2 border-[#E8E8EA] rounded-lg px-2 mr-10">
              <img src={ICONS?.CarouselOptions} alt="CarouselOptions" />
              <span className="text-[#616874] text-[0.8vw] font-[500] py-[0.4vw]  ">
                Options
              </span>
              <img src={ICONS?.chevronDownCarousel} alt="chevronDownCarousel" />
            </div>
          </Popover>

          <button
            className={`${btnStyle} ${
              checkAllowToSubmit()
                ? "bg-[var(--primary)] text-[var(--white)] cursor-pointer"
                : "bg-[var(--lightGrey)] text-[var(--darkgrey)] cursor-not-allowed"
            }`}
            onClick={() => {
              if (checkAllowToSubmit()) {
                createAndUpdateTemplateData(false);
              }
            }}
            disabled={!checkAllowToSubmit()}
          >
            {loading ? (
              <div className="w-[3vw] h-[1.15vw] flex-row align-center justify-center">
                <Loader Width={20} Height={20} loaderBg="white" />
              </div>
            ) : (
              <div className="w-[3vw] h-[1.15vw]">Submit</div>
            )}
          </button>
        </div>
      </div>
      {templateNameError && (
        <div className="px-[1.5vw] font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw]">
          This name already exists, please enter a different one.
        </div>
      )}
      <Modal
        // open={isDuplicateModal}
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.discardCarousel}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>
                You can't bring back once it's discard. What would you like to
                do?
              </p>
            </div>
          }
          title={"Discard Template?"}
          yesName="Continue Editing"
          noName="Yes Discard"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            setDiscardAlertOpen(false);
          }}
          handleYes={() => {
            discardCarousel();
          }}
        />
      </Modal>

      <Modal
        // open={isDuplicateModal}
        footer={null}
        open={isDeleteModal}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          icon={ICONS?.discardCarousel}
          title={"Delete Template?"}
          yesName="Continue Editing"
          noName="Yes, Delete"
          noButtonClassName="!font-md !weight-semibold"
          open={isDeleteModal}
          setOpen={setIsDeleteModal}
          content={"Are you sure you want to delete your template?"}
          yesLoading={isDelete}
          handleYes={async () => {
            await handleTemplateDelete(selectedTemplate);
          }}
          handleNo={() => {
            setIsDeleteModal(false);
          }}
        />
      </Modal>
    </>
  );
}
