import React from 'react';
import './checkbox.css';

// helpers
import { cn } from '../../../helper/cn';

export default function CheckBoxSqure({
  className = '',
  data,
  value,
  onChange,
  intermediate,
  disabled,
}) {
  return (
    <label
      className={cn(
        '!w-4 !h-4 custom-checkbox relative flex items-center justify-center',
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        className
      )}
    >
      <input
        type='checkbox'
        name='checkbox'
        checked={value}
        className={cn(
          'squre absolute',
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
          intermediate ? '!border-[var(--new-primary)]' : ''
        )}
        onChange={(e) => {
          if (data) onChange(data);
          else onChange(e.target.checked);
        }}
        disabled={disabled}
      />
      {intermediate && (
        <div className='contents-[""] w-1/2 border border-[var(--new-primary)] z-[1]'></div>
      )}
    </label>
  );
}
