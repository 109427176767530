import React, { useEffect, useState } from "react";

// assets
import { images } from "assets/images/index";
import { ICONS } from "assets/icons";
import WhatsAppWithBg from "../../../assets/customSVG/WhatsAppWithBg";

// utils
import { useNavigate } from "react-router-dom";
import { Modal, Skeleton, Tooltip } from "antd";
import moment from "moment";

// API
import { createWABA, retryCreateWABA } from "api/apiClients/meta/whatsapp";
import { currentUserDetails } from "../../../api/Api";
import { campaignList } from "../api/APi";
import { broadcastList } from "../../../api/apiClients/broadcast";

// hooks
import useToggle from "hooks/useToggle";

// helpers
import { capitalizeFirstLetter } from "helper/capitalizeFirstLetter";
import { useWeekCalender } from "hooks/calenders/useWeekCalender";

// redux
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  updateCurrentBrand,
  updateCurrentUser,
} from "../../../reduxToolkit/appSlice";
import { setPageNavigate } from "../../../reduxToolkit/CampaignSlice";

// components
import NoData from "components/commonComponents/Nodata/index";
import AboutCardsContainer from "../componenst/AboutCard";
import BroadcastListCard from "../componenst/BroadcastListCard";
import Calender from "../componenst/Calender";
import CampaignListCard from "../componenst/CampaignListCard";
import ConversationOverview from "../componenst/ConversationOverview";
import CountCards from "../componenst/CountCards";
import CreateWABALoader from "../../../components/commonComponents/createWABALoader";
import CreateWABAError from "components/commonComponents/createWABALoader/CreateWABAError";
import CampaignModal from "../../../components/commonComponents/campaign-popup/CampaignModal";

const Home = () => {
  // router
  const navigate = useNavigate();

  // redux
  const { currentBrand, currentUser,currentAccount } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  // calender
  const {
    selectedDate,
    weeks,
    next,
    prev,
    onChange: onSelect,
    resetToToday,
  } = useWeekCalender();

  // campaigns
  const [isCampaign, setIsCampaign] = useToggle(true);
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useToggle(false);
  const [campaigns, setCampaigns] = useState([]);

  // broadcast
  const [isBroadcast, setIsBroadcast] = useToggle(false);
  const [broadcast, setBroadcast] = useState([]);

  // create WABA
  const [sessionData, setSessionData] = useState(null);
  const [data, setData] = useState(null);
  const [isRes, setIsRes] = useToggle(false);
  const [isResError, setIsResError] = useState({
    error: false,
    data: "",
  });
  const [res, setRes] = useState(null);

  useEffect(() => {
    if (currentBrand) getcampaignList(currentBrand?.brand_id);
  }, [currentBrand]);

  useEffect(() => {
    if (selectedDate)
      getBroadCastList(
        currentBrand?.brand_id,
        moment(selectedDate).format("DD-MM-YYYY"),
        moment(selectedDate).format("DD-MM-YYYY")
      );
  }, [currentBrand, selectedDate]);

  useEffect(() => {
    window.addEventListener("message", sessionInfoListener);

    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    if (sessionData && data) {
      const payload = {
        brand_id: currentBrand?.brand_id,
        waba_id: sessionData?.waba_id,
        phone_number_id: sessionData?.phone_number_id,
        code: data?.authResponse?.code,
      };
      createWhatsappAccount(payload);
    }
  }, [data, sessionData]);

  const sessionInfoListener = (event) => {
    if (
      event.origin !== "https://www.facebook.com" &&
      event.origin !== "https://web.facebook.com"
    ) {
      return;
    }
    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data;
          setSessionData(data?.data);
        } else {
          const { current_step } = data.data;
        }
      }
    } catch {
      console.warn("Non JSON Response", event.data);
    }
  };

  async function launchWhatsAppSignup() {
    let data = null;
    await FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          setData(response);
        } else {
          console.warn("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "820380526237844",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          feature: "whatsapp_embedded_signup",
          sessionInfoVersion: 2,
          setup: {},
        },
      }
    );

    return data;
  }

  // connecting the webhook
  const createWhatsappAccount = async (payload) => {
    setIsRes(true);
    try {
      const res = await createWABA(payload);
      setRes(res?.data);
      if (res?.status === 200) {
        await fetchCurrentUserDetails();
        setIsRes(false);
        // setIsResError(false);
      } else {
        const data = {
          error: true,
          data: res?.response?.data?.error,
        };
        setIsResError(data);
      }
    } catch (e) {
      console.warn(e);
      setRes(null);
    }
  };

  // if failed to connect the webhook
  const retryWABACreate = async () => {
    setIsRes(true);
    setIsResError({
      error: false,
      data: "",
    });
    try {
      const res = await retryCreateWABA({ brand_id: currentBrand?.brand_id });
      if (res.status === 200) {
        await fetchCurrentUserDetails();
        setIsRes(false);
      } else {
        setIsResError({
          error: true,
          data: res?.response?.data?.error,
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const loadDatas = async () => {
    await fetchCurrentUserDetails();
    await getcampaignList(currentBrand?.brand_id);
    await getBroadCastList(
      currentBrand?.brand_id,
      moment(selectedDate).format("DD-MM-YYYY"),
      moment(selectedDate).format("DD-MM-YYYY")
    );
  };

  const getcampaignList = async (brandId) => {
    if (brandId) {
      setIsCampaign(true);
      try {
        const res = await campaignList(brandId, {
          limit: 5,
          page: 1,
          status: 11,
        });
        setCampaigns(res?.data);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsCampaign(false);
      }
    }
  };

  const getBroadCastList = async (brandId, startDate, enddate) => {
    if (brandId && startDate && enddate) {
      setIsBroadcast(true);
      setBroadcast([]);
      try {
        const res = await broadcastList(brandId, startDate, enddate);
        if (res?.data?.broadcasts?.length > 0) {
          const filter = res?.data?.broadcasts?.filter(
            (c) => ![0, 12].includes(c.status)
          );
          setBroadcast(filter);
        } else {
          setBroadcast([]);
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setIsBroadcast(false);
      }
    }
  };

  const fetchCurrentUserDetails = async () => {
    try {
      const res = await currentUserDetails();
      dispatch(updateCurrentUser(res?.data));
      const checkBrandExist = res?.data?.active_accounts?.[0]?.brands?.filter(
        (b) =>
          b?.brand_id === currentBrand?.brand_id &&
          b?.is_whatsapp_connected !== currentBrand?.is_whatsapp_connected
      );

      setIsRes(false);
      if (checkBrandExist?.length > 0) {
        dispatch(updateCurrentBrand(checkBrandExist?.[0]));
        dispatch(updateCurrentUser(res?.data));
      } else {
        dispatch(
          updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
        );
      }
    } catch (error) {
      setIsRes(false);
      console.warn("Error fetching current user details:", error);
    }
  };

  const handleCancel = () => {
    setIsCampaignModalOpen(false);
  };

  return (
    <div className="w-full h-full">
      <div
        className={`w-full h-full bg-[#f9f9fc] ${
          isRes ? "opacity-[16%] blur-sm" : "opacity-100"
        }`}
      >
        {currentBrand && !currentBrand?.is_whatsapp_connected && (
          <div className="pt-[1vw] px-[4vw]">
            <div className="w-full h-auto bg-[#6940F2] rounded-[0.4vw]">
              <div
                className="w-full h-[3.4vw] flex items-center justify-between px-[0.8vw]"
                style={{
                  background: `url(${images?.WhatsappInactiveBg})`,
                }}
              >
                <div className="text-[var(--white)]">
                  Get started with GainWix.ai - Connect or create your WhatsApp
                  Business API.
                </div>
                {currentAccount?.role !== "Campaigns Manager" ? (
                  <button
                    className="bg-[var(--white)] px-[0.7vw] py-[0.3vw] text-[var(--font-600)] font-normal weight-semibold rounded-[0.3vw] flex items-center align-center gap-[0.25vw]"
                    onClick={() => {
                      launchWhatsAppSignup();
                    }}
                  >
                    <WhatsAppWithBg
                      width="1.1vw"
                      height="1.1vw"
                      bg={"var(--primary)"}
                      color={"white"}
                    />
                    <p>Connect WhatsApp</p>
                  </button>
                ) : (
                  <Tooltip title={"You are not allowed to perform this action"}>
                    <span className=" cursor-not-allowed">
                      Connect WhatsApp
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className={`h-auto flex !py-[2.5vw] gap-x-[2.5vw] gap-y-[1vw] px-[4vw] overflow-hidden overflow-y-scroll  ${
            currentBrand === null
              ? "max-h-[98.5vh]"
              : currentBrand && currentBrand?.is_whatsapp_connected === false
              ? "max-h-[83.5vh]"
              : "max-h-[98.5vh]"
          }`}
        >
          <div className="w-[75%] h-full flex flex-col justify-between gap-x-[1.5vw] gap-y-[1.5vw]">
            <div
              className="w-full min-h-[13vw] bg-white px-[2vw] rounded-[1vw] py-[1.5vw] flex flex-col justify-between object-cover"
              style={{
                background: `url(${images?.AboutBg}) no-repeat center`,
              }}
            >
              <div className="w-full">
                <h1 className="text-[var(--textBlack)] mb-[0.3vw] font-larger font-bold">
                  👋 Welcome back
                  {(currentUser?.first_name || currentUser?.last_name) && (
                    <>
                      <>
                        ,{" "}
                        {currentUser?.first_name
                          ? capitalizeFirstLetter(currentUser?.first_name)
                          : ""}
                        {currentUser?.last_name
                          ? currentUser?.first_name
                            ? ` ${capitalizeFirstLetter(
                                currentUser?.last_name
                              )}`
                            : capitalizeFirstLetter(currentUser?.last_name)
                          : ""}
                      </>
                      <span className="">!</span>
                    </>
                  )}
                </h1>
                <p className="font-md weight-medium text-[var(--font-600)]">
                  Take a look at your recent updates and activities.
                </p>
              </div>
              <CountCards />
            </div>
            <div className="w-full h-[18vw] bg-white rounded-[1vw] pl-[1vw] py-[1vw] pr-[0.5vw]">
              <div className="flex items-center justify-between px-[1vw]">
                <h1 className="text-[var(--font-600)] font-normal weight-semibold">
                  Running Campaigns
                </h1>
                {campaigns?.campaigns?.length > 0 && (
                  <button
                    className="font-md weight-semibold text-[var(--font-600)] hover:text-[var(--BrandLink)] transition-colors duration-200 ease-linear"
                    onClick={() => {
                      dispatch(setPageNavigate("All Campaigns"));
                      navigate("/user/campaign/all");
                    }}
                  >
                    See all
                  </button>
                )}
              </div>
              <div className="mt-[1vw] h-[13.8vw] overflow-hidden">
                <Skeleton
                  rootClassName="px-[0.8vw]"
                  loading={isCampaign}
                  paragraph={{ rows: 4 }}
                  active
                >
                  {!isCampaign && campaigns?.campaigns?.length > 0 ? (
                    <div className="h-[13.8vw] flex flex-col gap-[0.5vw] pl-[1vw] pr-[0.8vw] overflow-hidden overflow-y-scroll listScroll">
                      {campaigns?.campaigns?.map((campaign) => {
                        return (
                          <div
                            key={campaign?.id}
                            className="mt-[0.5vw] first:mt-0"
                          >
                            <CampaignListCard
                              key={campaign?.id}
                              data={campaign}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="w-full h-4/6 flex flex-col items-center text-center gap-[0.5vw] mt-[0.6vw]">
                      <NoData
                        icon={images?.NoData}
                        content={
                          <>
                            No campaigns have been found. Click the <br />{" "}
                            button below to create one.
                          </>
                        }
                      />
                      <button
                        className="px-[0.9vw] py-[0.4vw] mt-[0.3vw] rounded-[0.5vw] bg-[var(--primary)] text-[var(--white)] font-normal weight-bold"
                        onClick={() => {
                          setIsCampaignModalOpen(true);
                        }}
                      >
                        Create Campaign
                      </button>
                    </div>
                  )}
                </Skeleton>
              </div>
            </div>
            <AboutCardsContainer />
          </div>
          <div className="w-[25%] h-full flex flex-col justify-between gap-x-[1.5vw] gap-y-[1.5vw]">
            <div className="w-full h-full min-h-[24vw] max-h-[50%] bg-white rounded-[1vw] py-[0.5vw] flex flex-col">
              <div className="px-[0.8vw] pt-[0.3vw]">
                <Calender
                  selectedDate={selectedDate}
                  weeks={weeks}
                  next={next}
                  prev={prev}
                  onSelect={onSelect}
                  resetToToday={resetToToday}
                />
              </div>
              <div className="mt-[0.8vw] h-full w-full flex flex-col  overflow-hidden pl-[1vw] pr-[0.5vw]">
                <Skeleton
                  rootClassName="pr-[0.5vw]"
                  loading={isBroadcast}
                  paragraph={{ rows: 4 }}
                  active
                >
                  {broadcast?.length > 0 ? (
                    <div className="w-full h-full flex flex-col justify-between">
                      <div className="w-full h-[10vw] gap-y-[0.5vw] pr-[0.5vw] overflow-hidden overflow-y-scroll listScroll">
                        {broadcast?.map((list) => {
                          return (
                            <BroadcastListCard key={list.id} broadcast={list} />
                          );
                        })}
                      </div>
                      <div className="w-full h-[17%] flex items-center justify-center pt-[0.5vw]">
                        <button
                          className="w-auto h-[3vw] font-md weight-semibold text-[var(--font-600)] flex items-center gap-[0.5vw]"
                          onClick={() => {
                            navigate("/user/calendar");
                          }}
                        >
                          <img
                            src={ICONS?.ViewCalender}
                            alt="calender icon"
                            className="w-[1vw] h-[1vw]"
                          />{" "}
                          <p>View Calender</p>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <NoData
                      icon={images?.NoData}
                      title={"No broadcast have been found."}
                      titleClassName="font-md weighit-medium"
                    />
                  )}
                </Skeleton>
              </div>
            </div>
            <ConversationOverview />
          </div>
        </div>
      </div>
      {isRes ? (
        <div
          className={`w-full h-full absolute top-0 left-0 flex flex-col items-center justify-center `}
        >
          {isResError?.error ? (
            <>
              <CreateWABAError width={"11vw"} height={"6vw"} />
              <p
                className={`font-large weight-semibold mt-6 ${
                  ["payment", "two-factor"]?.includes(isResError?.data)
                    ? "text-[var(--primary)]"
                    : "text-[#F96056]"
                }`}
              >
                {isResError?.data === "payment"
                  ? 'Please configure the payment method and click "continue".'
                  : isResError?.data === "two-factor"
                  ? "Please remove your 2-factor authentication from the WhatsApp manager and click continue."
                  : "Unexpected error happen, please retry!"}
              </p>
              <button
                className={`font-md weight-medium text-[#F96056] font-normal weight-bold px-[0.85vw] py-[0.4vw] rounded-[0.6vw] mt-[0.8vw] ${
                  ["payment", "two-factor"]?.includes(isResError?.data)
                    ? "bg-[var(--primary)] text-white"
                    : "bg-red-500 text-white"
                }`}
                onClick={() => {
                  retryWABACreate();
                }}
              >
                {["payment", "two-factor"]?.includes(isResError?.data)
                  ? "Continue"
                  : "Retry"}
              </button>
            </>
          ) : (
            <>
              <CreateWABALoader width={"11vw"} height={"6vw"} />
              <p className="font-large weight-semibold bg-gradient-to-r from-[#0ACD95] to-[#6940F2] bg-clip-text text-transparent">
                Hold on, we&apos;re setting up things for you.
              </p>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
      {isCampaignModalOpen && <div className="modal-overlay" />}
      <Modal
        open={isCampaignModalOpen}
        afterOpenChange={(open) => setIsCampaignModalOpen(open)}
        footer={null}
        closable={false}
      >
        <CampaignModal handleCancel={handleCancel} />
      </Modal>
    </div>
  );
};

export default Home;
