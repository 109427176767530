import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// api
import { InviteTeamMembers } from "../../api/OnBoarding";

// assets
import Mail from "assets/customSVG/Mail";
import FullArrow from "../../../../assets/customSVG/FullArrow";
import AddIcon from "../../../../assets/customSVG/AddIcon";
import DropDown from "../../../../assets/customSVG/accounts/DropDown";

// helper
import { cn } from "../../../../helper/cn";

// redux
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import {
  UpdateAccountAndBrandId,
  updateAccountDetails,
  updateToggleToast,
} from "../../../../reduxToolkit/appSlice";

// components
import Loader from "../../../../components/commonComponents/Loader/Index";
import { currentUserDetails } from "../../../../api/Api";
import { Popover } from "antd";
import { ICONS } from "../../../../assets/icons";
import { reAssignData } from "../../../accounts/constants/home";
import { validateEmail } from "../../../../helper/validation";

export default function TeamInvite({
  data,
  onChange,
  isLoading,
  setIsLoading,
  acc_id,
}) {
  const { currentAccount, currentUser, toggleToast } = useAspSelector(
    (state) => state.app
  );
  const users = {
    "Account User": "A_USER",
    "Account Manager": "A_MNGR",
  };

  const dispatch = useAspDispatch();
  const [inviteManagers, setInviteManagers] = useState("Account Manager");
  const [emailData, setEmailData] = useState({
    managers: [],
    users: [],
  });
  const [visiblePopoversInvite, setVisiblePopoversInvite] = useState(false);

  const [errorMail, setErrorMail] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const managerEmails = emailData?.managers?.map(
      (manager) => manager.email_id
    );
    const userEmails = emailData?.users?.map((user) => user.email_id);

    const duplicateEmails = managerEmails?.filter((email) =>
      userEmails.includes(email)
    );

    if (duplicateEmails?.length > 0) {
      setError(`Duplicate email found: ${duplicateEmails?.join(", ")}`);
    } else {
      setError("");
    }
  }, [emailData]);

  // const validation = useCallback(() => {
  //   const res = data?.list?.filter((l) => l.value);
  //   return res?.length > 0;
  // }, [data]);

  const validation = useCallback(() => {
    if (!emailData?.managers || !emailData?.users) {
      return false;
    }

    const hasManagers = emailData.managers.length > 0;
    const hasUsers = emailData.users.length > 0;

    const allEmails = [...emailData.managers, ...emailData.users];
    const allValid =
      allEmails.length > 0 ? allEmails.every((email) => email?.isValid) : true;

    const managerCountValid =
      emailData.managers.length > 0
        ? !emailData.managers.some(
            (manager) => manager?.count > 5 && manager?.role === "A_MNGR"
          )
        : true;

    return (hasManagers || hasUsers) && allValid && managerCountValid;
  }, [emailData]);

  const handleManagerInvite = (id, role) => {
    setInviteManagers((prev) => ({
      ...prev,
      [id]: role,
    }));
  };

  const handleSubmit = (e) => {
    if (validation()) {
      const combinedEmailList = [...emailData.managers, ...emailData.users];
      handleInvitation(e, combinedEmailList);
    }
  };

  // const handleInvitation = async (e, inviteData = []) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const usersList = inviteData
  //     .map((item) => {
  //       const email = item?.email_id;
  //       const role = item?.role_id || inviteManagers;
  //       const roleCode = users[inviteManagers] || users[role];

  //       if (email) {
  //         return { email, role_code: roleCode };
  //       }
  //       return null;
  //     })
  //     .filter(Boolean);

  //   if (!usersList.length) return;

  //   const datas = { users_list: usersList };
  //   const res = await InviteTeamMembers(
  //     currentAccount?.account_id || acc_id,
  //     datas
  //   );

  //   if (res?.status === 200) {
  //     setIsLoading(false);

  //     const successfulInvites = res?.data?.email_list?.filter(
  //       (e) => e.status === true
  //     );
  //     const failedInvites = res?.data?.email_list
  //       ?.filter((e) => e.status === false)
  //       ?.map((e) => ({
  //         ...e,
  //         isValid: validateEmail(e.email_id),
  //       }));
  //     // const failedInvites = res?.data?.email_list?.filter(
  //     //   (e) => e.status === false
  //     // );
  //     const successToast =
  //       successfulInvites?.length > 0
  //         ? [
  //             {
  //               id: toggleToast?.length + 1,
  //               content: `Invitation sent successfully`,
  //               status: "Success",
  //               duration: "",
  //             },
  //           ]
  //         : [];

  //     const errorToast = failedInvites?.map((e, i) => ({
  //       id: toggleToast?.length + 1 + successfulInvites?.length,
  //       content: `${e?.email_id} - ${
  //         e?.message || "Failed to send invitation"
  //       }`,
  //       status: "Error",
  //       duration: "",
  //     }));

  //     const hasError = failedInvites?.length > 0;

  //     if (!hasError) {
  //       dispatch(updateToggleToast([...toggleToast, ...successToast]));
  //       setEmailList([]);
  //     } else if (hasError) {
  //       dispatch(updateToggleToast([...toggleToast, ...successToast]));
  //       setEmailList(failedInvites);
  //     }

  //     setInviteManagers("Account Manager");

  //     onChange("reset_teams");
  //   } else {
  //     dispatch(
  //       updateToggleToast([
  //         ...toggleToast,
  //         {
  //           id: toggleToast?.length + 1,
  //           content: res?.response?.data?.error,
  //           status: "Error",
  //           duration: "",
  //         },
  //       ])
  //     );

  //     setIsLoading(false);
  //   }
  // };

  const handleInvitation = async (e, inviteData = []) => {
    e.preventDefault();

    const usersList = inviteData
      .map((item) => {
        const email = item?.email_id;
        const roleCode = item?.role;

        if (email) {
          return { email, role_code: roleCode };
        }
        return null;
      })
      .filter(Boolean);
    const datas = { users_list: usersList };

    const res = await InviteTeamMembers(
      currentAccount?.account_id || acc_id,
      datas
    );

    if (res?.status === 200) {
      setIsLoading(false);

      const successfulInvites = res?.data?.email_list?.filter(
        (e) => e.status === true
      );

      const failedInvites = res?.data?.email_list
        ?.filter((e) => e.status === false)
        ?.map((e) => ({
          ...e,
          isValid: validateEmail(e.email_id),
        }));

      const successToast =
        successfulInvites?.length > 0
          ? [
              {
                id: toggleToast?.length + 1,
                content: `Invitation sent successfully`,
                status: "Success",
                duration: "",
              },
            ]
          : [];

      const errorToast = failedInvites?.map((e, i) => ({
        id: toggleToast?.length + 1 + successfulInvites?.length,
        content: `${e?.email_id} - ${
          e?.message || "Failed to send invitation"
        }`,
        status: "Error",
        duration: "",
      }));

      const hasError = failedInvites?.length > 0;

      if (!hasError) {
        dispatch(updateToggleToast([...toggleToast, ...successToast]));
        setEmailData([]);
      } else if (hasError) {
        dispatch(updateToggleToast([...toggleToast, ...successToast]));
        const failedManagers =
          failedInvites?.filter((invite) => invite.role_code === "A_MNGR") ||
          [];
        const failedUsers =
          failedInvites?.filter((invite) => invite.role_code === "A_USER") ||
          [];

        setEmailData((prevData) => ({
          managers: [...failedManagers],
          users: [...failedUsers],
        }));
        // setEmailData(failedInvites);
      }

      onChange("reset_teams");
      // onChange("update_step", null, "step", 2);
    } else {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: res?.response?.data?.error,
            status: "Error",
            duration: "",
          },
        ])
      );
      setIsLoading(false);
    }
  };

  const handleKeyPress = (role) => (e) => {
    if (e.key !== "Enter") return;

    e.preventDefault();
    const emailValue = e.target.value.trim();

    if (!emailValue) return;

    const isValid = validateEmail(emailValue);
    const isManager = role === "A_MNGR";

    setEmailData((prevData) => {
      const safeData = {
        managers: Array.isArray(prevData?.managers) ? prevData.managers : [],
        users: Array.isArray(prevData?.users) ? prevData.users : [],
      };

      const targetList = isManager ? "managers" : "users";
      const currentList = safeData[targetList];

      let newCount = 0;

      const newEmail = {
        id: currentList.length + 1,
        email_id: emailValue,
        status: true,
        role,
        isValid,
        ...(isManager && {
          count:
            newCount +
            currentList.filter((item) => item.role === "A_MNGR").length +
            1,
        }),
      };

      return {
        ...safeData,
        [targetList]: [...currentList, newEmail].map((email) => ({
          ...email,
          status: true,
        })),
      };
    });

    e.target.value = "";
  };

  const togglePopoverVisibilityInvite = (open) => {
    setVisiblePopoversInvite(open);
  };

  const goTodashboard = async () => {
    onChange("update_step", null, "step", 2);

    const res = await currentUserDetails();
    if (res?.status === 200) {
      const selectedAccount = res?.data?.active_accounts?.filter(
        (a) => a?.account_id === acc_id
      );
      const selectedBrand = selectedAccount?.[0]?.brands?.filter(
        (b) => b?.brand_id === brand_id
      );
      const data = {
        currentAccount: selectedAccount?.[0],
        currentBrand: null,
      };
      if (selectedBrand?.length > 0) data["currentBrand"] = selectedBrand?.[0];
      dispatch(updateAccountDetails(data));
      onChange("update_step", null, "step", 2);
    }
  };

  const handleRemoveEmail = (role, index) => {
    setEmailData((prevData) => {
      const targetList = role === "A_MNGR" ? "managers" : "users";
      const newList = [...prevData[targetList]];
      newList.splice(index, 1);
      return {
        ...prevData,
        [targetList]: newList,
      };
    });
  };

  const handleInputChange = (e, role) => {
    const inputValue = e.target.value.trim();
    if (!inputValue) {
      setErrorMail(false);
    }
  };

  return (
    <div className="w-full mt-6 mx-auto h-[40vh]">
      <div className="h-full">
        <form
          className="w-full flex flex-col pb-8"
          onSubmit={(e) => handleInvitation(e, emailList)}
        >
          <div className="flex-row gap-4">
            <div className="w-[38%] h-12 font14px-400 text-[#4C515B] border border-[var(--border-input)] mt-10 b-radius-6  flex-row align-center  space-between prl-10">
              Account Manager
            </div>
            <div className="flex flex-col w-full">
              <div
                className={`h-[15vh] w-100 rounded-lg mt-10 overflow-auto px-2 border ${
                  emailData?.managers?.some(
                    (data) =>
                      data?.status === false ||
                      emailData?.managers?.some(
                        (data) =>
                          data?.count > 5 && data?.role === "Account Manager"
                      )
                  )
                    ? "border-[#FF543E]"
                    : "border-[#E8E8EA]"
                }`}
              >
                <div className="flex flex-wrap pt-10 gap-3">
                  {emailData?.managers?.length > 0 &&
                    emailData?.managers?.map((emailObj, index) => (
                      <div
                        key={index}
                        // className={`rounded-xl px-2 py-1 flex items-center ${
                        //   errorMail ? "bg-red-400" : "bg-[#F4F6F8]"
                        // }`}
                        className={`rounded-xl px-2 py-1 flex items-center ${
                          !emailObj.isValid
                            ? "bg-white border border-[#FF543E]"
                            : "bg-[#F4F6F8]"
                        }`}
                      >
                        <p>{emailObj?.email_id} </p>
                        <img
                          src={ICONS?.popupX}
                          alt="icons"
                          className="w-[1.2vw] h-[1.2vw] pointer"
                          onClick={() => handleRemoveEmail("A_MNGR", index)}
                        />
                      </div>
                    ))}

                  {emailData?.managers?.length < 5 && (
                    <div
                      className={`w-100 mr-1.5 ${
                        emailData?.managers?.length > 0 &&
                        "border-l-2 border-[#000000]"
                      }`}
                    >
                      <input
                        type="text"
                        placeholder="Type your email"
                        onChange={(e) => handleInputChange(e, "A_MNGR")}
                        onKeyDown={handleKeyPress("A_MNGR")}
                        className="ml-1.5  w-100"
                      />
                    </div>
                  )}
                </div>
              </div>
              <p className="text-[var(--font-600)] font14px-500">
                Add upto 5 members
              </p>
              {emailData?.managers?.some((data) => data?.status === false) && (
                <p className="text-[#FF543E] font14px-500">
                  {
                    emailData?.managers.find((data) => data?.status === false)
                      ?.message
                  }
                </p>
              )}
              {emailData?.managers?.some((data) => data?.count > 5) && (
                <p className="text-[#FF543E] font14px-500">
                  The number of account managers has exceeded the limit
                </p>
              )}
            </div>
          </div>

          <div className="flex-row gap-4">
            <div className="w-[38%] h-12 font14px-400 text-[#4C515B] border border-[var(--border-input)] mt-10 b-radius-6  flex-row align-center  space-between prl-10">
              Account User
            </div>
            <div className="flex-col w-full">
              <div
                className={`h-[15vh] rounded-lg mt-10 overflow-auto px-2 border ${
                  emailData?.users?.some((data) => data?.status === false)
                    ? "border-[#FF543E]"
                    : "border-[#E8E8EA]"
                }`}
              >
                <div className="flex flex-wrap pt-10 gap-3">
                  {emailData.users?.length > 0 &&
                    emailData.users?.map((emailObj, index) => (
                      <div
                        key={index}
                        className={`rounded-xl px-2 py-1 flex items-center ${
                          !emailObj.isValid
                            ? "bg-white border border-[#FF543E]"
                            : "bg-[#F4F6F8]"
                        }`}
                      >
                        <p>{emailObj?.email_id} </p>
                        <img
                          src={ICONS?.popupX}
                          alt="icons"
                          className="w-[1.2vw] h-[1.2vw] pointer"
                          onClick={() => handleRemoveEmail("A_USER", index)}
                        />
                      </div>
                    ))}

                  <div
                    className={`w-100 mr-1.5 ${
                      emailData.users?.length > 0 &&
                      "border-l-2 border-[#000000]"
                    }`}
                  >
                    <input
                      type="text"
                      placeholder={"Type your email"}
                      onChange={(e) => handleInputChange(e, "A_USER")}
                      onKeyDown={handleKeyPress("A_USER")}
                      className="w-100 ml-1.5"
                    />
                  </div>
                </div>
              </div>
              <div>
                {emailData?.users?.some((data) => data?.status === false) && (
                  <p className="text-[#FF543E] font14px-500">
                    {
                      emailData?.users?.find((data) => data?.status === false)
                        ?.message
                    }
                  </p>
                )}
              </div>
              <p className="text-[var(--font-600)] font14px-500">
                Add upto 20 members
              </p>
            </div>
          </div>

          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
      </div>

      <div className="flex-row align-center justify-end gap-3">
        <div
          className="px-4 py-1.5 b-radius-6 flex items-center justify-center border border-[var(--border-input)] gap-2 mt-9 cursor-pointer"
          onClick={() => {
            goTodashboard();
          }}
        >
          <p className="font16px-500 text-[var(--font-600)]">
            I'll do it later
          </p>
        </div>

        <button
          disabled={!validation() || !!error}
          className={cn(
            "px-4 py-1.5 b-radius-6 font16px-600 mt-9 flex items-center justify-center",
            !validation() || !!error
              ? "bg-[var(--disable-bg)] text-[var(--disable-color)] cursor-not-allowed"
              : isLoading
              ? "cursor-not-allowed bg-[var(--new-primary)] text-[var(--white)]"
              : "bg-[var(--new-primary)] text-[var(--white)] cursor-pointer"
          )}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <div className=" flex items-center justify-center">
              <Loader Width={80} Height={20} loaderBg="white" />
            </div>
          ) : (
            "Invite Members"
          )}
        </button>
      </div>
    </div>
  );
}
