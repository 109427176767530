import React from "react";

const Education = ({ color }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.33464 2.98082L6.39429 2.99234C8.28602 3.35751 9.76993 3.95644 10.8395 4.56834C11.0504 4.68897 11.1559 4.74929 11.2203 4.86074C11.2849 4.97218 11.2849 5.10184 11.2849 5.36117V21.182C11.2849 21.2661 11.1986 21.3219 11.1232 21.2851C10.8381 21.1462 10.4653 20.9422 9.9992 20.6871L9.98147 20.6774C9.07007 20.1788 7.78859 19.6706 6.12678 19.3499L6.08688 19.3422C5.14964 19.1612 4.39217 19.015 3.81674 18.8417C3.23758 18.6671 2.71026 18.4295 2.34996 17.9774C2.00373 17.5429 1.87061 17.0204 1.80936 16.4512C1.74998 15.8993 1.74999 15.2015 1.75001 14.3508L1.75 7.28274C1.74996 6.36206 1.74993 5.58767 1.84443 4.97235C1.94591 4.31147 2.16963 3.73328 2.68707 3.28375C3.19037 2.84651 3.74419 2.69958 4.36365 2.7037C4.91887 2.70739 5.57884 2.83485 6.33464 2.98082ZM12.7797 4.86074C12.8441 4.74929 12.9496 4.68897 13.1605 4.56834C14.2301 3.95645 15.714 3.35751 17.6057 2.99234L17.6653 2.98082C18.4212 2.83485 19.0812 2.70739 19.6364 2.7037C20.2559 2.69958 20.8097 2.84651 21.3129 3.28375C21.8304 3.73328 22.0541 4.31147 22.1556 4.97235C22.2501 5.58768 22.25 6.36208 22.25 7.28279V14.3508C22.25 15.2015 22.25 15.8993 22.1907 16.4512C22.1294 17.0204 21.9963 17.5429 21.6501 17.9774C21.2897 18.4295 20.7625 18.6671 20.1833 18.8417C19.6078 19.015 18.8502 19.1613 17.913 19.3422L17.8732 19.3499C16.2115 19.6706 14.93 20.1788 14.0185 20.6774L14.0007 20.6872C13.5347 20.9422 13.1619 21.1462 12.8768 21.2851C12.8014 21.3219 12.7151 21.2661 12.7151 21.182V5.36117C12.7151 5.10184 12.7151 4.97218 12.7797 4.86074Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Education;
