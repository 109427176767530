import React from "react";

const Travel = ({ color }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.737 6.60731C19.8592 4.04495 16.3761 3.193 13.5215 4.54661C12.3171 5.1177 11.116 5.70056 9.91736 6.28229C9.32234 6.57105 8.72785 6.85956 8.13386 7.14619C7.60202 7.40283 7.36938 7.44547 7.21031 7.41603C7.05522 7.38732 6.85621 7.26821 6.46335 6.83122C6.26611 6.61185 6.09505 6.37059 5.89348 6.15476C5.67082 5.91635 5.35546 5.63571 4.93996 5.55041C4.74852 5.5111 4.56893 5.52371 4.39087 5.55547C3.9149 5.64038 3.39731 5.7635 2.94731 5.94256C2.27584 6.20976 1.8974 6.7817 2.0011 7.50041C2.03642 7.74518 2.11434 8.03751 2.20416 8.37455L2.4937 9.46114C2.71101 10.2768 2.88676 10.9364 3.09622 11.4364C3.3143 11.9571 3.59482 12.3711 4.06033 12.634C4.5243 12.896 5.02707 12.9259 5.59266 12.8508C6.13829 12.7784 6.80855 12.5977 7.64065 12.3735L9.96367 11.7476C10.0325 11.7291 10.0966 11.7899 10.0817 11.8596L9.78198 13.2635C9.63234 13.9644 10.2832 14.5421 10.9529 14.3617L13.1086 13.7808C13.7118 13.6183 14.2291 13.2268 14.5457 12.6903C14.5509 12.6814 14.5559 12.6724 14.5605 12.6631L15.7306 10.3484C15.7884 10.2339 15.8915 10.1504 16.0131 10.1176L20.9316 8.79234C21.8283 8.54895 22.3461 7.45229 21.7392 6.61032L21.737 6.60731Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.50171 19.6384C1.50171 19.1119 1.92853 18.6851 2.45504 18.6851H17.7084C18.2349 18.6851 18.6617 19.1119 18.6617 19.6384C18.6617 20.1649 18.2349 20.5917 17.7084 20.5917H2.45504C1.92853 20.5917 1.50171 20.1649 1.50171 19.6384Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Travel;
