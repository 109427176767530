import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Popover } from 'antd';

// assets
import bg from '../../../../../assets/images/body-message.png';
import SendInbox from '../../../../../assets/customSVG/inbox/SendInbox';
import ArrowRotate from '../../../../../assets/customSVG/ArrowRotate';
import { ICONS } from '../../../../../assets/icons';

// api
import {
  getSequenceOverviewPreview,
  previewResetMessage,
  previewSendButtonClick,
  previewSendCustomTextMessage,
} from '../../../api/SquenceOverview';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../test/jest-redux-hooks';

// helpers
import { cn } from '../../../../../helper/cn';

// hooks
import useToggle from '../../../../../hooks/useToggle';

// components
import MediaMessage from '../preview/MediaMessage';
import Template from '../preview/Template';
import SendTextPreview from './SendTextPreview';

const Preview = ({ showHeader = true }) => {
  const { sequence_id, campaign_id } = useParams();
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const messageContainerRef = useRef();
  const [previewData, setPreviewData] = useState(null);
  const [customMessage, setCustomMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useToggle(false);
  const [lastId, setLastId] = useState(null);

  useEffect(() => {
    getPreviewData();
  }, []);

  useEffect(() => {
    setLastId(getLastPreviewSendMessageId());
  }, [previewData]);

  const getPreviewData = async () => {
    try {
      const payload = {
        brandId: currentBrand?.brand_id,
        campaignId: campaign_id,
      };
      const res = await getSequenceOverviewPreview(sequence_id, payload);
      if (res?.status === 200) {
        setPreviewData(res?.data?.data);
        scrollToBottom();
      } else {
        setPreviewData(null);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const onButtonClick = async ({ data, button }) => {
    try {
      const query = {
        brandId: currentBrand?.brand_id,
        campaignId: campaign_id,
        previewId: previewData?.id,
      };

      const buttonText =
        data?.type === 'BUTTON'
          ? button?.reply?.title
          : data?.type === 'LIST'
          ? button?.title
          : button?.text;

      const buttonId =
        data?.type === 'BUTTON'
          ? button?.reply?.id
          : data?.type === 'LIST'
          ? button?.id
          : button?.id;

      const payload = {
        preview_step_id: data?.id,
        clicked: buttonId,
        button_text: buttonText,
      };

      const res = await previewSendButtonClick(sequence_id, query, payload);
      if (res?.status === 200) {
        setPreviewData((prev) => ({
          ...prev,
          preview: [...prev?.preview, ...res?.data?.data],
        }));
        setTimeout(() => scrollToBottom(), 500);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const resetPreviewMessages = async () => {
    try {
      const query = {
        brandId: currentBrand?.brand_id,
        campaignId: campaign_id,
        previewId: previewData?.id,
      };

      const res = await previewResetMessage(sequence_id, query);
      if (res?.status === 200) {
        setPreviewData((prev) => ({
          ...prev,
          preview: res?.data?.data,
        }));
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getLastPreviewSendMessageId = useCallback(() => {
    const getSentMessage = previewData?.preview?.filter(
      (d) => d?.message_type === 'SENT'
    );
    return getSentMessage?.pop()?.id;
  }, [previewData?.preview]);

  const sendCustomText = async (message) => {
    try {
      const query = {
        brandId: currentBrand?.brand_id,
        campaignId: campaign_id,
        previewId: previewData?.id,
      };
      const payload = {
        message: message?.trim(),
        last_sent_preview_step_id: lastId,
      };

      const res = await previewSendCustomTextMessage(
        sequence_id,
        query,
        payload
      );
      if (res?.status === 200) {
        setPreviewData((prev) => ({
          ...prev,
          preview: [...prev?.preview, ...res?.data?.data],
        }));
        setCustomMessage('');
        setTimeout(() => scrollToBottom(), 500);
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.message,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const handleEmojiClick = (emoji) => {
    setCustomMessage(customMessage + emoji);

    setShowEmojiPicker(false);
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTo({
        top: messageContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const allowToSendMessage = useCallback(
    () =>
      previewData &&
      previewData?.preview?.length > 0 &&
      customMessage?.length > 0,
    [customMessage]
  );

  return (
    <div className='w-full h-full bg-white rounded-2xl overflow-hidden'>
      {showHeader && (
        <div className='w-full h-[clamp(56px,10%,56px)] px-4 pt-4 pb-2.5 flex items-center justify-between border-b border-[var(--border-100)]'>
          <h5 className='text-base weight-semibold text-[#2D3036]'>Preview</h5>
          <button
            className={cn(
              'w-auto h-8 py-1.5 px-2 flex items-center gap-1 rounded-lg',
              previewData && previewData?.preview?.length > 0
                ? 'cursor-pointer bg-[#05805C] text-white'
                : 'cursor-not-allowed bg-[var(--BG-50)] text-[var(--font-600)]'
            )}
            onClick={() => {
              if (previewData && previewData?.preview?.length > 0)
                resetPreviewMessages();
            }}>
            <ArrowRotate />
            <p className='text-sm weight-medium'>Reset</p>
          </button>
        </div>
      )}

      <div className='w-full h-[clamp(56px,20%,56px)] flex items-center px-4'>
        <p>{currentBrand?.brand_name}</p>
      </div>

      <div
        ref={messageContainerRef}
        className='w-full h-[clamp(70%,75%,75%)] flex flex-col max-[1600px]:h-[clamp(65%,70%,70%)] flex-1 gap-2.5 px-2.5 py-2.5 overflow-y-scroll bg-gray-100'
        style={{ backgroundImage: `url(${bg})` }}>
        {previewData?.preview?.map((data) => {
          return (
            <div className={cn('w-auto')}>
              {data?.message_type === 'REPLIED' ? (
                <div className='w-full flex justify-end'>
                  <SendTextPreview text={data?.data?.text} />
                </div>
              ) : (
                <div className='w-full'>
                  {data?.type === 'TEXT' ? (
                    <SendTextPreview
                      text={data?.data?.text?.body || data?.data?.text}
                    />
                  ) : ['CUSTOM', 'CAROUSEL']?.includes(data?.type) ? (
                    <Template
                      data={data?.data}
                      node={data}
                      category={data?.data?.category}
                      categoryType={data?.type}
                      allowToShowEdge={false}
                      onButtonClick={(button) => {
                        onButtonClick({ button, data });
                      }}
                      rootClassName='w-[75%] min-w-[75%]'
                      // rootClassName='!min-w-[75%] !max-w-[75%] max-[1600px]:!min-w-[100%] max-[1600px]:!max-w-[75%]'
                      className='rounded-bl-none'
                      btnClassName='w-full h-full bg-transparent border-t border-[var(--border-100)] rounded-none'
                      customBtnStyle='w-full h-9'
                      rootBtnStyle='gap-0'
                    />
                  ) : (
                    <MediaMessage
                      previewType='dashboard'
                      node={data}
                      allowToShowEdge={false}
                      rootClassName='w-[75%] h-full rounded-2xl rounded-bl-none p-3.5'
                      // rootClassName='!min-w-[75%] !max-w-[75%] max-[1600px]:!min-w-[100%] max-[1600px]:!max-w-[75%] h-full rounded-2xl rounded-bl-none p-3.5'
                      onButtonClick={(button) => {
                        onButtonClick({ button, data });
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className='w-full h-[clamp(56px,20%,80px)] bg-white px-4 flex items-center rounded-2xl'>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (previewData && previewData?.preview?.length > 0)
              sendCustomText(customMessage);
          }}
          className='w-full h-9 flex items-center gap-2.5 px-2.5 border border-[var(--border-100)] rounded-md'>
          <div className=''>
            <Popover
              trigger={['click']}
              content={
                <Picker
                  data={data}
                  onEmojiSelect={(emoji) => {
                    if (!emoji?.native) return;
                    handleEmojiClick(emoji?.native);
                  }}
                  theme={'green'}
                  searchPosition='none'
                  previewPosition='none'
                />
              }>
              <button
                className='flex items-center justify-center'
                type='button'
                onClick={() => {
                  setShowEmojiPicker(!showEmojiPicker);
                }}
                data-testid='emoji-click'>
                <img
                  src={ICONS?.step3EmojiIcon}
                  alt='emoji'
                  className='size-5'
                />
              </button>
            </Popover>
          </div>
          <input
            type='text'
            className={cn(
              'w-full flex-1',
              previewData && previewData?.preview?.length > 0
                ? 'cursor-pointer'
                : 'cursor-not-allowed'
            )}
            value={customMessage}
            onChange={(e) => {
              if (previewData && previewData?.preview?.length > 0)
                setCustomMessage(e.target.value);
            }}
            disabled={previewData && previewData?.preview?.length === 0}
          />
          <button
            type='submit'
            className='-rotate-45'>
            <SendInbox
              color={
                allowToSendMessage() ? 'var(--primary)' : 'var(--font-600)'
              }
              width={18}
              height={18}
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Preview;
