import React, { useState } from 'react';

// assets
import FilterSvg from '../../../assets/customSVG/Filter';

// utils
import { Popover } from 'antd';

// hooks
import useToggle from '../../../hooks/useToggle';
import RadioButton from '../../form/radio/RadioButton';

export default function Filter({
  loading = false,
  options,
  selectedOptions,
  onChange,
  disabled = false,
  onClearAll,
  rootStyle = '',
  optionsStyle = '',
  optionStyle = '',
}) {
  const [isOpen, setIsOpen] = useToggle(false);
  const [selectedId, setSelectedId] = useState([]);

  const handleSelect = (id) => {
    setSelectedId((selectedId) => {
      if (selectedId.includes(id)) {
        return selectedId.filter((ids) => ids !== id);
      } else {
        return [...selectedId, id];
      }
    });
  };

  const clearAllFilter = () => {
    setSelectedId([]);
    if (onClearAll) onClearAll();
  };

  const filterOptions = (
    <div
      className={`!w-fit h-[41vh] rounded-[0.8vw] flex-column listScroll ${optionsStyle}`}
    >
      <div className='!w-fit py-[0.2vw] px-[0.8vw] my-auto overflow-hidden overflow-y-scroll'>
        {options?.map((data) => {
          return (
            <div
              key={data?.id}
              className='!w-[10vw] border-t-[0.08vw] first:border-t-0 mt-[0.2vw] border-[var(--border-50)]'
            >
              <h4 className='text-[var(--font-600)] font-xs weight-medium mt-[1vw] mb-[0.3vw] px-[0.7vw]'>
                {data?.name}
              </h4>
              {data?.subFilter?.map((item) => {
                return (
                  <div
                    className={`py-[0.2vw] font-md weight-medium text-[var(--contentText)] last:pb-0 border-b-[0.08vw] border-[var(--border-50)] last:border-b-0 ${optionStyle}`}
                    key={item?.id}
                    onClick={() => {
                      handleSelect(item?.id);
                      onChange(data, item);
                      // setIsOpen(false);
                    }}
                  >
                    <div className='h-[2.4vw] flex items-center justify-between px-[0.7vw] rounded-[0.4vw] cursor-pointer hover:bg-slate-100'>
                      <div className='flex-row align-center'>
                        <span>{item?.label}</span>
                      </div>
                      {selectedId?.includes(item?.id) && (
                        <RadioButton
                          selected={true}
                          style={{
                            height: '2.4vw',
                          }}
                          onClick={() => {}}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <Popover
      content={filterOptions}
      trigger={!loading && !disabled && isOpen ? ['click'] : []}
      open={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      placement='bottom'
    >
      <div
        className={`relative px-[0.7vw] py-[0.3vw] border-[0.08vw] rounded-[0.37vw] hover:bg-[var(--BG-25)] group ${rootStyle} ${
          selectedOptions?.length > 0
            ? 'border-[#F6A723]'
            : 'border-transparent'
        } ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      >
        <div
          className='w-full h-full flex items-center gap-[0.5vw] relative z-20'
          onClick={() => {
            if (!loading) setIsOpen(true);
          }}
        >
          <FilterSvg
            width='1vw'
            height='1vw'
            color={selectedOptions?.length > 0 ? '#F6A723' : '#2D3036'}
          />
          {selectedOptions?.length > 0 ? (
            <span className='size-[1.3vw] bg-[#F6A723] text-[var(--white)] rounded-full flex items-center justify-center font-md weight-medium'>
              {selectedOptions?.length}
            </span>
          ) : (
            <span className='font-md weight-medium text-[var(--textBlack)]'>
              Filter
            </span>
          )}
        </div>
        {selectedOptions?.length > 0 && (
          <div
            className='absolute -top-[0.5vw] -right-[0.5vw] z-30'
            onClick={() => {
              clearAllFilter();
              setIsOpen(false);
            }}
          
          >
            <span className='size-[1.1vw] font-large weight-medium flex items-center justify-center scale-0 group-hover:scale-100 text-[#F96056] bg-red-200 rounded-full transition-transform duration-200 ease-linear'>
              &times;
            </span>
          </div>
        )}
      </div>
    </Popover>
  );
}
