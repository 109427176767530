import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import '../styles/sequence.css';

// api
import {
  createNewEdge,
  deleteEdge,
  getSequence,
  updateNodeCoordinates,
  updateSequence,
} from '../api/sequence';

//assets

// helpers
import { cn } from 'helper/cn';
import { generateRandomId, isObjectEmpty } from '../helpers';
import {
  updateEdgeCoordinatesFromAPI,
  updateNodeCoordinatesFromAPI,
} from '../helpers/sequence/edges';
import { allowToAddNodes } from '../helpers/sequence/node';

// constants
import {
  DefaultZoomValue,
  ZoomInValue,
  ZoomOutValue,
} from '../constants/defaults';

// hooks
import useToggle from '../../../hooks/useToggle';

// constants
import { ALLOWED_STATUS_TO_EDIT_FLOW } from '../constants/sequence';

// reducers
import { INITIAL_STATE, sequenceReducer } from '../reducer';

// redux
import { updateToggleToast } from '../../../reduxToolkit/appSlice';
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';

// components
import Controls from '../components/sequence/Controls';
import EdgesNode from '../components/sequence/edge';
import EdgeElements from '../components/sequence/edge/EdgeElements';
import Header from '../components/sequence/Header';
import NodeElements from '../components/sequence/nodes/NodeElements';

const newNode = {
  id: 800,
  uniq_id: '',
  is_trigger: false,
  component_type: null,
  trigger_type: null,
  type: null,
  label: 'Send Message',
  // order: 7,
  status: 'UN_SAVED',
  data: {},
  settings: {
    defaults: {
      isExpand: false,
      isEditMode: true,
      isSelected: false,
      showControlls: false,
    },
    connections: { count: 0, points: [] },
  },
  coordinates: {},
  is_last_node: false,
};

const Sequence = () => {
  const { sequence_id, campaign_id } = useParams();
  const location = useLocation();

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const aspDispatch = useAspDispatch();

  const [sequence, dispatch] = useReducer(sequenceReducer, INITIAL_STATE);
  const { nodes, edges, selectedNode } = sequence;

  const [sequenceDetails, setSequenceDetails] = useState(null);

  const [triggerData, setTriggerData] = useState(null);
  const [view, setView] = useState({ scale: 1, offsetX: 0, offsetY: 0 });
  const [newEdge, setNewEdge] = useState(null);
  const [selectedIds, setSelectedIds] = useState({ nodeId: null, edgesId: [] });
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [isLoading, setIsLoading] = useToggle(false);
  const [openTriggerModel, setOpenTriggerModel] = useToggle(false);
  const [isDraggingCanvas, setIsDraggingCanvas] = useToggle(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useToggle(false);
  const [isEditModeAlertModal, setIsEditModeAlertModal] = useToggle(false);
  const [triggerModelPosition, setTriggerModelPosition] = useState({
    top: 0,
    left: 0,
  });

  const undoStackRef = useRef([]);
  const redoStackRef = useRef([]);
  const containerRef = useRef(null);
  const draggingNodeRef = useRef(null);
  const dragStartPosRef = useRef(null);
  const isDraggingCanvasRef = useRef(false);

  // Update the state when the network goes online or offline
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    // Add event listeners for online and offline events
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('isEditMode');
    const parsedData = JSON.parse(data);
    if (parsedData) {
      setIsEditModeAlertModal(parsedData);
      localStorage.removeItem('isEditMode');
    }
  }, []);

  useEffect(() => {
    sequenseData();
  }, []);

  useEffect(() => {
    const data = nodes?.find((n) => n?.is_trigger);
    setTriggerData(data || null);
  }, [nodes, sequenceDetails]);

  const allowToAddNode = useCallback(() => {
    const allow = allowToAddNodes({
      nodes,
      currentPlans: currentBrand?.brand_billing_plan_name,
    });

    return {
      ...allow,
      nodesLength: nodes?.length,
    };
  }, [nodes, currentBrand]);

  // error toast handler
  const handleToastError = (message) => {
    aspDispatch(
      updateToggleToast([
        ...toggleToast,
        {
          id: toggleToast?.length + 1,
          content: message,
          status: 'Error',
          duration: '',
        },
      ])
    );
  };

  // Get sequence data
  const sequenseData = async () => {
    setIsLoading(true);
    const payload = {
      brandId: currentBrand?.brand_id,
      campaignId: campaign_id,
      flow: 'true',
    };
    try {
      const res = await getSequence(sequence_id, payload);

      setSequenceDetails(res?.data?.data?.sequence);

      const updatedEdges = updateEdgeCoordinatesFromAPI({
        edges: res?.data?.data?.edges,
        coordinates: res?.data?.data?.edge_coordinates,
      });

      const updatedNodes = updateNodeCoordinatesFromAPI({
        nodes: res?.data?.data?.nodes,
        coordinates: res?.data?.data?.node_coordinates,
        metrics: res?.data?.data?.node_metrics,
        trigger_metrics: res?.data?.data?.trigger_metrics,
      });

      onChange({
        type: 'UPDATE_DATA',
        data: { nodes: updatedNodes, edges: updatedEdges },
      });
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  // get selected node ids for edge animations
  const getSelectectedNodeId = useCallback(
    (id) => {
      if (nodes?.length > 0 && edges?.length > 0) {
        const res = nodes?.filter((node) => node?.uniq_id === id);
        const getSelectedEdgeIds = edges?.filter(
          (edge) => edge?.start_id === id || edge?.end_id === id
        );

        if (res?.length > 0)
          setSelectedIds({
            nodeId: res?.uniq_id,
            edgesId: getSelectedEdgeIds.map((edge) => edge?.uniq_id),
          });
        else setSelectedIds({ nodeId: null, edgesId: [] });
      }
    },
    [nodes, edges]
  );

  // find empty space in the canvas
  const findEmptySpace = () => {
    const containerWidth = containerRef?.current?.offsetWidth;
    const containerHeight = containerRef?.current?.offsetHeight;
    const nodeWidth = 400;
    const nodeHeight = 400;
    const margin = 50;

    let tries = 0;
    const maxTries = 100;

    while (tries < maxTries) {
      tries++;

      const x = Math.random() * (containerWidth - nodeWidth);
      const y = Math.random() * (containerHeight - nodeHeight);

      const overlap = nodes?.some((node) => {
        const dx = x - node?.coordinates?.x;
        const dy = y - node?.coordinates?.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance < nodeWidth + margin;
      });

      if (!overlap) {
        return { x, y };
      }
    }

    return { x: 800, y: 300 };
  };

  // update data to the reducer
  const onChange = ({
    type = 'UPDATE',
    key,
    primaryKey,
    secondaryKey,
    value,
    data = null,
    id = null,
    sectionId = null,
    rowId = null,
    coordinates,
    seqID,
  }) => {
    const payload = { key, value };
    if (type === 'ADD_NODE' && !id) {
      if (!data) {
        if (coordinates) payload['coordinates'] = coordinates;
        else coordinates = findEmptySpace();
      }
      if (seqID) payload['seqId'] = seqID;
    }

    if (type) payload['type'] = type;
    if (data) payload['data'] = data;
    if (id) payload['id'] = id;
    if (primaryKey) payload['primaryKey'] = primaryKey;
    if (secondaryKey) payload['secondaryKey'] = secondaryKey;
    if (sectionId) payload['sectionId'] = sectionId;
    if (rowId) payload['rowId'] = rowId;

    dispatch(payload);
  };

  // for future use
  // save the data to undo stack
  // const saveStateToUndo = () => {
  //   undoStackRef.current.push({ nodes: [...nodes], edges: [...edges] });
  //   redoStackRef.current = [];
  // };

  // const undo = () => {
  //   if (undoStackRef.current.length > 0) {
  //     const lastState = undoStackRef.current.pop();
  //     redoStackRef.current.push({ nodes: [...nodes], edges: [...edges] });
  //     setNodes(lastState.nodes);
  //     setEdges(lastState.edges);
  //   }
  // };

  // const redo = () => {
  //   if (redoStackRef.current.length > 0) {
  //     const lastState = redoStackRef.current.pop();
  //     undoStackRef.current.push({ nodes: [...nodes], edges: [...edges] });
  //     setNodes(lastState.nodes);
  //     setEdges(lastState.edges);
  //   }
  // };

  // const handleWheel = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   const zoomDelta = e.deltaY < 0 ? 0.1 : -0.1;
  //   const newScale = Math.min(Math.max(view?.scale + zoomDelta, 0.2), 3);
  //   const newOffsetX =
  //     (view?.offsetX - e.clientX) * (newScale / view?.scale) + e.clientX;
  //   const newOffsetY =
  //     (view?.offsetY - e.clientY) * (newScale / view?.scale) + e.clientY;

  //   // Adjust edge positions according to the new zoom level
  //   const updatedEdges = edges.map((edge) => {
  //     return {
  //       ...edge,
  //       coordinates: {
  //         start: {
  //           x: edge.coordinates.start.x * (newScale / view?.scale),
  //           y: edge.coordinates.start.y * (newScale / view?.scale),
  //         },
  //         end: {
  //           x: edge.coordinates.end.x * (newScale / view?.scale),
  //           y: edge.coordinates.end.y * (newScale / view?.scale),
  //         },
  //       },
  //     };
  //   });

  //   setView({ scale: newScale, offsetX: newOffsetX, offsetY: newOffsetY });
  // };

  // handle mouse down event for nodes and canvas
  const handleMouseDown = (e, nodeId = null) => {
    if (openTriggerModel) setOpenTriggerModel(false);

    setIsDraggingCanvas(true);

    if (nodeId) {
      draggingNodeRef.current = nodeId;
      dragStartPosRef.current = { x: e.clientX, y: e.clientY };

      // saveStateToUndo();

      onChange({
        type: 'UPDATE_NODE',
        id: nodeId,
        primaryKey: 'settings',
        secondaryKey: 'defaults',
        key: 'isSelected',
        data: true,
      });
    } else {
      isDraggingCanvasRef.current = true;
      dragStartPosRef.current = { x: e.clientX, y: e.clientY };
    }
  };

  // handle mouse move event for nodes and canvas
  const handleMouseMove = (e) => {
    if (newEdge) {
      const scrollLeft = containerRef?.current?.scrollLeft || 0;
      const scrollTop = containerRef?.current?.scrollTop || 0;

      const containerRect = containerRef?.current?.getBoundingClientRect();

      const adjustedX =
        e.clientX - containerRect.left + scrollLeft - view.offsetX;
      const adjustedY =
        e.clientY - containerRect.top + scrollTop - view.offsetY;

      const normalizedEndPosition = {
        x: adjustedX / view.scale,
        y: adjustedY / view.scale,
      };

      const newData = {
        ...newEdge,
        coordinates: {
          ...newEdge?.coordinates,
          end: normalizedEndPosition,
        },
      };

      setNewEdge(newData);
    } else {
      if (draggingNodeRef.current) {
        // Moving nodes
        const nodeId = draggingNodeRef.current;
        const dx = e.clientX - dragStartPosRef.current.x;
        const dy = e.clientY - dragStartPosRef.current.y;

        const updatedEdges = edges?.map((edge) => {
          if (edge?.end_id === nodeId) {
            return {
              ...edge,
              coordinates: {
                ...edge?.coordinates,
                end: {
                  x: edge?.coordinates?.end?.x + dx,
                  y: edge?.coordinates?.end?.y + dy,
                },
              },
            };
          } else if (edge?.start_id === nodeId) {
            return {
              ...edge,
              coordinates: {
                ...edge?.coordinates,
                start: {
                  x: edge?.coordinates?.start?.x + dx,
                  y: edge?.coordinates?.start?.y + dy,
                },
              },
            };
          }
          return edge;
        });

        onChange({
          type: 'UPDATE_DATA',
          data: { edges: updatedEdges },
        });

        onChange({
          type: 'UPDATE_NODE_COORDINATES',
          id: nodeId,
          data: { x: dx, y: dy },
        });

        dragStartPosRef.current = { x: e.clientX, y: e.clientY };
      } else if (isDraggingCanvasRef.current) {
        // Moving the entire canvas
        const dx = e.clientX - dragStartPosRef.current.x;
        const dy = e.clientY - dragStartPosRef.current.y;

        setView((prevView) => ({
          ...prevView,
          offsetX: prevView?.offsetX + dx,
          offsetY: prevView?.offsetY + dy,
        }));

        dragStartPosRef.current = { x: e.clientX, y: e.clientY };
      }
    }
  };

  // handle mouse up event for nodes and canvas
  const handleMouseUp = useCallback(
    async (event, position, allowToAddNewEdge = true) => {
      event.stopPropagation();
      event.preventDefault();

      setIsDraggingCanvas(false);

      if (newEdge !== null && allowToAddNewEdge) {
        const size = 86;

        const uniqID = generateRandomId();

        onChange({
          type: 'ADD_NODE',
          data: {
            ...newNode,
            label: 'Add Node',
            data: null,
            id: uniqID,
            uniq_id: uniqID,
            coordinates: newEdge?.coordinates?.end,
          },
        });

        onChange({
          type: 'ADD_EDGE',
          data: {
            ...newEdge,
            end_id: uniqID,
            coordinates: {
              ...newEdge?.coordinates,
              end: {
                x: newEdge?.coordinates?.end?.x,
                y: newEdge?.coordinates?.end?.y + size / 2,
              },
            },
          },
        });

        setNewEdge(null);
      } else if (!newEdge && draggingNodeRef.current) {
        setNewEdge(null);
        const draggedNode = nodes?.find(
          (n) => n?.uniq_id === draggingNodeRef.current
        );

        const updatedEdges = edges?.filter(
          (edge) =>
            edge?.end_id === draggingNodeRef.current ||
            edge?.start_id === draggingNodeRef.current
        );

        const filteredEdges = updatedEdges?.map((edge) => {
          return {
            id: edge?.uniq_id,
            coordinates: edge?.coordinates,
          };
        });

        if (draggedNode && draggedNode?.status !== 'UN_SAVED') {
          const payload = {
            brand_id: currentBrand?.brand_id,
            campaign_id: campaign_id,
            coordinates: draggedNode?.coordinates,
            edges: filteredEdges,
          };

          try {
            await updateNodeCoordinates(
              sequence_id,
              draggedNode?.uniq_id,
              payload
            );
          } catch (error) {
            console.error('Error updating node coordinates:', error);
          }
        } else {
          console.warn('No node to update or node is a trigger');
        }
      } else if (isDraggingCanvasRef.current) {
        setNewEdge(null);
        // updateSequenceData(event);
      }

      draggingNodeRef.current = null;
      isDraggingCanvasRef.current = null;
    },
    [edges, newEdge, draggingNodeRef, isDraggingCanvasRef]
  );

  // handle fit to view the all the nodes
  const fitView = () => {
    if (nodes?.length === 0) {
      console.error('No nodes to fit the view');
      return;
    }

    const allX = nodes?.map((node) => node?.coordinates?.x);
    const allY = nodes?.map((node) => node?.coordinates?.y);

    const minX = Math.min(...allX);
    const minY = Math.min(...allY);
    const maxX = Math.max(...allX);
    const maxY = Math.max(...allY);

    const padding = 2000;

    const width = maxX - minX + padding;
    const height = maxY - minY + padding;

    const containerWidth = containerRef?.current?.offsetWidth ?? 0;
    const containerHeight = containerRef?.current?.offsetHeight ?? 0;

    if (containerWidth === 0 || containerHeight === 0) {
      console.error('Container dimensions are invalid.');
      return;
    }

    let scale = Math.min(containerWidth / width, containerHeight / height);

    scale = Math.max(ZoomOutValue, Math.min(scale, ZoomInValue));

    const offsetX = (containerWidth - width * scale) / 2 - minX * scale;
    const offsetY = (containerHeight - height * scale) / 2 - minY * scale;

    setView({
      scale: scale,
      offsetX: offsetX,
      offsetY: offsetY,
    });
  };

  // handle zoom in the canvas
  const zoomIn = () => {
    const newScale = Math.min(view.scale + DefaultZoomValue, ZoomInValue);
    const newOffsetX =
      (view.offsetX - containerRef.current.offsetWidth / 2) *
        (newScale / view.scale) +
      containerRef.current.offsetWidth / 2;
    const newOffsetY =
      (view.offsetY - containerRef.current.offsetHeight / 2) *
        (newScale / view.scale) +
      containerRef.current.offsetHeight / 2;

    // Adjust edge positions according to the new zoom level
    const updatedEdges = edges.map((edge) => {
      return {
        ...edge,
        coordinates: {
          start: {
            x: edge.coordinates.start.x * (newScale / view.scale),
            y: edge.coordinates.start.y * (newScale / view.scale),
          },
          end: {
            x: edge.coordinates.end.x * (newScale / view.scale),
            y: edge.coordinates.end.y * (newScale / view.scale),
          },
        },
      };
    });

    setView({ scale: newScale, offsetX: newOffsetX, offsetY: newOffsetY });
  };

  // handle zoom out the canvas
  const zoomOut = () => {
    const newScale = Math.max(view.scale - DefaultZoomValue, ZoomOutValue);
    const newOffsetX =
      (view.offsetX - containerRef.current.offsetWidth / 2) *
        (newScale / view.scale) +
      containerRef.current.offsetWidth / 2;
    const newOffsetY =
      (view.offsetY - containerRef.current.offsetHeight / 2) *
        (newScale / view.scale) +
      containerRef.current.offsetHeight / 2;

    // Adjust edge positions according to the new zoom level
    const updatedEdges = edges.map((edge) => {
      return {
        ...edge,
        coordinates: {
          start: {
            x: edge.coordinates.start.x * (newScale / view.scale),
            y: edge.coordinates.start.y * (newScale / view.scale),
          },
          end: {
            x: edge.coordinates.end.x * (newScale / view.scale),
            y: edge.coordinates.end.y * (newScale / view.scale),
          },
        },
      };
    });

    setView({ scale: newScale, offsetX: newOffsetX, offsetY: newOffsetY });
  };

  const updateTriggerModelPosition = (event) => {
    const popup = document.getElementById('popup');
    if (!popup) return;

    const { offsetWidth: popupWidth, offsetHeight: popupHeight } = popup;
    const { innerWidth, innerHeight, scrollX, scrollY } = window;
    let { pageX, pageY } = event;

    let popupLeft = Math.max(
      scrollX,
      Math.min(pageX, innerWidth - popupWidth + scrollX)
    );

    let popupTop = Math.max(
      scrollY,
      Math.min(pageY, innerHeight - popupHeight + scrollY)
    );

    const currentLeft =
      popupLeft < innerWidth - 350
        ? Math.max(popupLeft - 75, 0)
        : Math.max(popupLeft - 420, 0);

    const currentTop =
      popupTop < innerHeight - 400
        ? Math.max(popupTop - 120, 0)
        : Math.max(popupTop - 480, 0);

    setTriggerModelPosition({ top: currentTop, left: currentLeft });
  };

  const handleOnMouseDownEdge = ({
    node,
    connection_point,
    position = { x: 0, y: 0 },
  }) => {
    const rect = containerRef?.current?.getBoundingClientRect();

    if (rect) {
      const mouseX = position.x - rect.left;
      const mouseY = position.y - rect.top;

      const adjustedX = mouseX - view.offsetX;
      const adjustedY = mouseY - view.offsetY;

      const normalizedPosition = {
        x: adjustedX / view.scale,
        y: adjustedY / view.scale,
      };

      const newData = {
        start_id: node?.uniq_id,
        end_id: '',
        connection_point,
        id: generateRandomId(),
        uniq_id: generateRandomId(),
        settings: { colors: { end: '#6940F2', start: '#0ACD95' } },
        status: 'UN_SAVED',
        coordinates: {
          start: normalizedPosition,
          end: normalizedPosition,
        },
      };

      setNewEdge(newData);
    } else {
      console.error('Container not found or not properly referenced.');
    }
  };

  const handleEdgeMouseUp = async ({ event, node, position }) => {
    if (newEdge && allowToEditFlow()) {
      if (node?.uniq_id === newEdge?.start_id) {
        setNewEdge(null);
        return;
      } else {
        const rect = containerRef?.current?.getBoundingClientRect();
        let normalizedPosition = position;

        if (rect) {
          normalizedPosition = {
            x: position.x - rect.left,
            y: position.y - rect.top,
          };

          normalizedPosition.x -= view.offsetX;
          normalizedPosition.y -= view.offsetY;

          normalizedPosition = {
            x: normalizedPosition.x / view.scale,
            y: normalizedPosition.y / view.scale,
          };
        }

        const updatedNewEdge = {
          ...newEdge,
          end_id: node?.uniq_id,
          coordinates: {
            ...newEdge?.coordinates,
            end: normalizedPosition,
          },
        };

        const payload = {
          brand_id: currentBrand?.brand_id,
          campaign_id: campaign_id,
          edges: {
            starting_node: {
              connection_point: updatedNewEdge?.connection_point?.id,
              node_id: updatedNewEdge?.start_id,
            },
            ending_node: { node_id: updatedNewEdge?.end_id },
          },
          coordinates: { ...updatedNewEdge?.coordinates },
        };

        const res = await createNewEdge(sequence_id, payload);

        setNewEdge(null);

        if (res?.status === 200) {
          onChange({
            type: 'UPDATE_DATA',
            data: { edges: [...edges, ...res?.data?.data] },
          });
          onChange({
            type: 'UPDATE_NODE_DATA',
            id: updatedNewEdge?.start_id,
            data: res?.data?.start_node,
          });
        } else {
          handleToastError(res?.response?.data?.message);
        }
      }
    }

    if (event) handleMouseUp(event, null, false);
  };

  const removeEdge = useCallback(
    (data) => {
      let updatedDatas = {};

      if (data?.status === 'UN_SAVED') {
        updatedDatas['nodes'] = nodes?.filter(
          (node) => node?.uniq_id !== data?.end_id
        );
      }

      const removedEdges = edges?.filter(
        (edge) => edge?.uniq_id !== data?.uniq_id
      );
      onChange({
        type: 'UPDATE_DATA',
        data: {
          edges: removedEdges,
          ...updatedDatas,
        },
      });
    },
    [edges, nodes]
  );

  const handleOnDeleteEdge = async (edge) => {
    if (edge?.status === 'UN_SAVED') {
      removeEdge(edge);
      return;
    }

    const payload = {
      brand_id: currentBrand?.brand_id,
      campaign_id: campaign_id,
    };

    const isEdgeExist = edges.find((e) => e.uniq_id === edge?.uniq_id);

    if (isEdgeExist) {
      const res = await deleteEdge(sequence_id, edge?.uniq_id, payload);
      if (res?.status === 200) {
        removeEdge(edge);
      }
    }
  };

  const allowToEditFlow = useCallback(() => {
    const { status, settings } = sequenceDetails || {};
    const { defaults } = settings || {};
    const isEditMode = defaults?.isEditMode;

    if (status === 'COMPLETED') return false;
    if (status === 'PAUSED' && isEditMode) return true;
    return ALLOWED_STATUS_TO_EDIT_FLOW?.includes(status);
  }, [sequenceDetails]);

  const allowToActivateSequence = useCallback(() => {
    const isTriggerNode = nodes?.find((node) => node?.is_trigger);

    // Early return if no trigger node is found
    if (!isTriggerNode) return false;

    const isActiveNodes = nodes?.filter((node) => node?.status === 'ACTIVE');
    const isTriggerConnected = edges?.filter(
      (edge) => edge?.start_id === isTriggerNode?.uniq_id
    );

    const hasRequiredConnections = isTriggerConnected?.length > 0;
    const hasMinimumActiveNodes = isActiveNodes?.length >= 5;
    const hasMinimumEdges = edges?.length >= 4;

    // Return result based on conditions
    return (
      setSequenceDetails?.status !== 'COMPLETED' &&
      isTriggerNode?.status === 'ACTIVE' &&
      hasRequiredConnections &&
      hasMinimumActiveNodes &&
      hasMinimumEdges
    );
  }, [nodes, edges, setSequenceDetails]);

  if (isLoading || !isOnline)
    return (
      <div className='w-screen h-screen flex items-center justify-center'>
        Loading...
      </div>
    );

  return (
    <div className='bg-white relative !select-none'>
      <Header
        sequenceDetails={sequenceDetails}
        setSequenceDetails={setSequenceDetails}
        triggerData={triggerData}
        allowToActivateSequence={allowToActivateSequence()}
        isOpenAlertModal={isOpenAlertModal}
        setIsOpenAlertModal={setIsOpenAlertModal}
        isEditModeAlertModal={isEditModeAlertModal}
        setIsEditModeAlertModal={setIsEditModeAlertModal}
      />
      <div
        className='w-screen h-[calc(100vh-80px)]'
        ref={containerRef}
        // onWheel={handleWheel}
        onMouseMove={handleMouseMove}
        onMouseUp={(event) => {
          event.stopPropagation();
          event.preventDefault();

          const rect = containerRef?.current?.getBoundingClientRect();
          const centerX = rect?.left + Math?.abs(rect?.right - rect?.left) / 2;
          const centerY = rect?.top + Math?.abs(rect?.bottom - rect?.top) / 2;

          const newPosition = {
            x: centerX,
            y: centerY,
          };
          if (handleMouseUp) handleMouseUp(event, newPosition);
        }}
        onMouseLeave={(event) => handleMouseUp(event)}
        onMouseDown={(e) => handleMouseDown(e)}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
          updateTriggerModelPosition(event);
          setOpenTriggerModel(!openTriggerModel);
        }}>
        <div
          className={cn(
            'w-full h-full relative overflow-hidden z-0 bg-[#F4F6F8] rounded-tl-2xl',
            isDraggingCanvas || newEdge ? 'cursor-grabbing' : 'cursor-default'
          )}>
          <div
            className='w-full h-full absolute top-0 left-0 pointer-events-none z-[1]'
            style={{
              transformOrigin: '0% 0%',
              transform: `translate(${view?.offsetX}px, ${view?.offsetY}px) scale(${view?.scale})`,
            }}>
            <div
              className={cn(
                'w-full h-full absolute top-0 left-0 pointer-events-none z-0'
              )}
              style={{
                transformOrigin: '0% 0%',
              }}>
              <NodeElements
                nodes={nodes}
                edges={edges}
                onChange={onChange}
                newEdge={newEdge}
                selectedNode={selectedNode?.[0]}
                handleMouseDown={handleMouseDown}
                handleMouseUp={handleMouseUp}
                sequenceDetails={sequenceDetails}
                handleOnMouseDownEdge={({
                  node,
                  connection_point,
                  position,
                }) => {
                  handleOnMouseDownEdge({
                    node,
                    connection_point,
                    position,
                  });
                }}
                findEmptySpace={findEmptySpace}
                handleEdgeMouseUp={handleEdgeMouseUp}
                allowToAddNode={allowToAddNode}
                selectedIds={selectedIds}
                getSelectectedNodeId={getSelectectedNodeId}
                allowToEditFlow={allowToEditFlow()}
              />
            </div>

            <div className='edges'>
              <EdgesNode
                nodes={nodes}
                edges={edges}
                scale={view?.scale}
                offsetX={view?.offsetX}
                offsetY={view?.offsetY}
                handleOnDeleteEdge={handleOnDeleteEdge}
                getSelectectedNodeId={getSelectectedNodeId}
                selectedIds={selectedIds}
                allowToEditFlow={allowToEditFlow()}
              />

              {!isObjectEmpty(newEdge) && (
                <EdgeElements
                  isNew={true}
                  position={{
                    x0: newEdge?.coordinates?.start?.x || 0,
                    y0: newEdge?.coordinates?.start?.y || 0,
                    x1: newEdge?.coordinates?.end?.x || 0,
                    y1: newEdge?.coordinates?.end?.y || 0,
                  }}
                  onMouseDownEdge={({ nodeId, position }) => {
                    handleOnMouseDownEdge({ nodeId, position });
                  }}
                  onClickDelete={() => {}}
                  scale={view?.scale}
                />
              )}
            </div>

            {/* Trigger Modal */}
            {/* {openTriggerModel && (
                <div className={cn('absolute !z-50')}>
                  <div id='popup'>
                    <TriggerModel
                      type={'tab'}
                      open={openTriggerModel}
                      setOpen={setOpenTriggerModel}
                      position={triggerModelPosition}
                    />
                  </div>
                </div>
              )} */}
          </div>

          {/* Controls */}
          <div className='w-auto h-auto flex items-center justify-center absolute !z-50 top-0 left-1/2 -translate-x-1/2'>
            <div className='flex flex-col items-center gap-2.5'>
              <Controls
                scale={view?.scale}
                handleZoomIn={zoomIn}
                handleZoomOut={zoomOut}
                handleFitView={fitView}
                // handleUndo={undo}
                // handleRedo={redo}
                onChange={onChange}
                disabled={{
                  undo: undoStackRef?.current?.length === 0,
                  redo: redoStackRef?.current?.length === 0,
                }}
                findEmptySpace={findEmptySpace}
                allowToAddNode={allowToAddNode}
                allowToEditFlow={allowToEditFlow()}
              />
              {['ACTIVE', 'RUNNING']?.includes(sequenceDetails?.status) && (
                <div className='bg-[#6940F2] text-white text-sm weight-medium py-1.5 px-4 rounded-lg'>
                  You are currently viewing the running sequence version
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sequence;
