import React, { useEffect, useRef } from 'react';

const Input = ({
  type = 'text',
  value = '',
  limit = null,
  name,
  placeholder = null,
  onInputChange,
  disabled,
  className,
}) => {
  const inputRef = useRef();
  return (
    <div className='w-full flex items-center gap-2.5 flex-1 px-2.5 py-1 border border-gray-200 rounded-md'>
      <input
        type={type}
        value={value}
        placeholder={placeholder ?? 'Enter text'}
        className='w-full h-7 text-xs weight-medium outline-none rounded-md flex-1 bg-transparent'
        maxLength={limit}
        ref={inputRef}
        onChange={(e) => {
          if (onInputChange) {
            onInputChange(e.target.value);
          }
        }}
        onClick={(e) => inputRef?.current?.focus()}
        disabled={disabled}
      />
      {limit && (
        <p className='text-sm weight-medium text-[var(--font-600)]'>
          {value?.length || 0}/{limit}
        </p>
      )}
    </div>
  );
};

export default Input;
