import React, { useEffect, useState } from "react";
import { accountSettingsSidebar } from "../../../constant/app/account/account";
import AccountInfo from "../components/settings/AccountInfo";
import ProfileInfo from "../components/settings/ProfileInfo";
import { useAspSelector } from "../../../test/jest-redux-hooks";
import {
  accountContactInfoApi,
  accountInformationApi,
  accountInformationOtp,
  accountOwnersInfoApi,
  profileInformationApi,
} from "../../../api/Api";
import { cityListApi, countryListApi, stateListApi } from "../../crm/api/Api";
import useToggle from "../../../hooks/useToggle";

export default function Settings() {
  const [activeNav, setActiveNav] = useState("Account Info");
  const [hoveredNav, setHoveredNav] = useState(null);
  const [isApiSuccess, setIsApiSuccess] = useToggle(false);

  const { currentUser, currentAccount } = useAspSelector((state) => state?.app);
  const [userData, setUserData] = useState("");
  const [accountInfoData, setAccountInfoData] = useState({
    accountInfo: "",
    accountOwners: [],
    contactInfo: "",
  });
  const [editContact, setEditContact] = useState(false);

  // LOCATIONS
  const [countryListss, setCountryListss] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const countryLists = () => {
    countryListApi().then((res) => {
      if (res && res.data?.data?.length > 0) {
        // const updatedData = res?.data?.data.map((p) => {
        //   return { ...p, label: p.name };
        // });
        const updatedData = res?.data?.data?.[0]
          ? { ...res.data.data[0], label: res.data.data[0].name }
          : null;
        setCountryListss([updatedData]);
        return updatedData;
      }
    });
  };

  const stateLists = async () => {
    stateListApi(accountInfoData?.contactInfo?.address?.country_id).then(
      (res) => {
        if (res && res?.data?.data?.length > 0) {
          const updateState = res?.data?.data?.map((item) => {
            return { ...item, label: item?.name };
          });
          setStateList(updateState);
        }
      }
    );
  };

  const cityLists = async () => {
    cityListApi(accountInfoData?.contactInfo?.address?.province_id).then(
      (res) => {
        if (res && res?.data?.data?.length > 0) {
          const updateCity = res?.data?.data?.map((item) => {
            return { ...item, label: item?.name };
          });
          setCityList(updateCity);
        }
      }
    );
  };

  useEffect(() => {
    countryLists();
    if (accountInfoData?.contactInfo?.address?.country_id) stateLists();
    if (accountInfoData?.contactInfo?.address?.province_id) cityLists();
  }, [
    accountInfoData?.contactInfo?.address?.country_id,
    accountInfoData?.contactInfo?.address?.province_id,
    editContact,
  ]);

  const handleNavigate = (item) => {
    setActiveNav(item?.name);
  };

  const fetchProfileInformation = () => {
    if (activeNav === "Profile Info") {
      profileInformationApi(currentUser?.id).then((res) => {
        setUserData(res?.data?.user);
      });
    } else if (activeNav === "Account Info") {
      accountInformationApi(currentAccount?.account_id).then((res) => {
        setAccountInfoData((prevState) => ({
          ...prevState,
          accountInfo: {
            ...prevState.accountInfo,
            ...res?.data?.account_data,
            otp: res?.data?.otp || "",
          },
        }));
      });

      accountOwnersInfoApi(currentAccount?.account_id).then((res) => {
        setAccountInfoData((prev) => ({
          ...prev,
          accountOwners: res?.data?.account_owners || "",
        }));
      });

      accountContactInfoApi(currentAccount?.account_id).then((res) => {
        setAccountInfoData((prev) => ({
          ...prev,
          contactInfo: res?.data?.contact_information || "",
        }));
      });
    }
  };

  useEffect(() => {
    fetchProfileInformation();
  }, [activeNav, isApiSuccess]);

  return (
    <div className="w-full px-5 py-7">
      <h2 className="font24px-500 tesxt-[var(--textBlack)] mb-1">
        Account Settings
      </h2>

      <div className="flex">
        <div className="w-100 h-full flex-row mt-10 gap-4">
          <div className="w-[20%] bg-white b-radius p-10">
            {accountSettingsSidebar?.map((item) => {
              return (
                <div
                  className={`w-100 b-radius-6 px-[1vw] py-[0.4vw]  flex-row align-center cursor-pointer gap-2.5 mt-10 ${
                    activeNav === item?.name
                      ? "sidebar-sub-header-active ptb-10"
                      : "hover:bg-slate-100 sidebar-sub-header hover:text-[var(--contentBlack)] hover:weight-medium mr-10 ptb-10"
                  }`}
                  onMouseEnter={() => setHoveredNav(item?.name)}
                  onMouseLeave={() => setHoveredNav(null)}
                  onClick={() => handleNavigate(item)}
                >
                  <item.icon
                    color={
                      activeNav === item?.name
                        ? "var(--new-primary)"
                        : hoveredNav === item?.name
                        ? "var(--textBlack)"
                        : "#616874"
                    }
                    fill={
                      activeNav === item?.name ? "var(--new-primary)" : "none"
                    }
                    fillColor={
                      activeNav === item?.name
                        ? "var(--new-primary)"
                        : hoveredNav === item?.name
                        ? "var(--textBlack)"
                        : "#616874"
                    }
                    stroke={
                      activeNav === item?.name
                        ? "white"
                        : hoveredNav === item?.name
                        ? "var(--textBlack)"
                        : "var(--font-600)"
                    }
                    lineColor={
                      activeNav === item?.name
                        ? "var(--new-primary)"
                        : hoveredNav === item?.name
                        ? "var(--textBlack)"
                        : "#616874"
                    }
                  />
                  <span>{item?.name}</span>
                </div>
              );
            })}
          </div>
          <div className="w-100 h-[90vh] overflow-scroll pr-20">
            {activeNav === "Account Info" && (
              <AccountInfo
                accountInfoData={accountInfoData}
                setAccountInfoData={setAccountInfoData}
                countryListss={countryListss}
                editContact={editContact}
                setEditContact={setEditContact}
                stateList={stateList}
                cityList={cityList}
                fetchProfileInformation={fetchProfileInformation}
              />
            )}
            {activeNav === "Profile Info" && (
              <ProfileInfo
                userData={userData}
                setIsApiSuccess={setIsApiSuccess}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
