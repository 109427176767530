import { cn } from 'helper/cn';

import React from 'react';

import { ICONS } from '../../../assets/icons/index';

const EditDelete = ({
  onEditClick,
  onDeleteClick,
  showEdit = true,
  rootClassName = '',
}) => {
  return (
    <div className={cn('w-full flex-column p-[15px]', rootClassName)}>
      {showEdit && (
        <div className='w-full py-1'>
          <div
            className='w-full h-11 px-2.5 py-1.5 flex-row align-center justify-center tags-border pointer rounded-md hover:bg-[var(--BG-50)]'
            onClick={onEditClick}>
            <img
              src={ICONS?.crmTagEdit}
              alt=''
              className=''
            />
            <span className='pl-10 text-[0.8vw] text-[var(--contentText)] font-500'>
              Edit
            </span>
          </div>
        </div>
      )}
      <div className='w-full py-1'>
        <div
          className='w-full h-11 px-2.5 py-1.5 flex-row align-center justify-center pointer rounded-md hover:bg-[var(--BG-50)]'
          onClick={onDeleteClick}>
          <img
            src={ICONS?.RedDeleteIcon}
            alt=''
            className=''
          />
          <span className='text-[0.8vw] text-[--fontRed] font-500'>Delete</span>
        </div>
      </div>
    </div>
  );
};

export default EditDelete;
