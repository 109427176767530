const SequencePreview = ({
  width = 21,
  height = 21,
  color = '#616874',
  bg = 'transparent',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        background: bg,
      }}
      className={className}
    >
      <path
        d='M13.8644 2.92328C14.4167 2.92328 14.8644 3.37099 14.8644 3.92328V16.0774C14.8644 16.6297 14.4167 17.0774 13.8644 17.0774H6.51563C5.96335 17.0774 5.51563 16.6297 5.51563 16.0774V3.92327C5.51563 3.37099 5.96335 2.92328 6.51563 2.92328H13.8644ZM16.0234 2.66699C16.0234 2.11471 15.5756 1.66699 15.0234 1.66699H5.35669C4.80441 1.66699 4.35669 2.11471 4.35669 2.66699V17.3337C4.35669 17.8859 4.8044 18.3337 5.35669 18.3337H15.0234C15.5756 18.3337 16.0234 17.8859 16.0234 17.3337V2.66699Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.8644 5.72559H5.51562V4.47559H14.8644V5.72559Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.8644 15.6924H5.51562V14.4424H14.8644V15.6924Z'
        fill={color}
      />
    </svg>
  );
};

export default SequencePreview;
