import React from 'react';
import './label.css';

function label({ title, required, className }) {
  return (
    <div className={`label ${className}`}>
      {title}
      {required && <span className="required">*</span>}
    </div>
  );
}

export default label;
