import React from 'react';

const NodeBg = ({
  width = 164,
  height = 164,
  color = '#6940F2',
  stroke = '#6940F2',
  fill = '#6940F2',
  strokeWidth = 5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 164 164'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M82 161C140.326 161 161 140.326 161 82C161 23.6743 140.326 3 82 3C23.6743 3 3 23.6743 3 82C3 140.326 23.6743 161 82 161Z'
        fill={color ?? fill}
        stroke={color ?? stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default NodeBg;
