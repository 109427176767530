import React from "react";
import { convertName } from "../../../helpers";

const Input = ({
  name,
  onChange,
  value,
  label,
  required = false,
  className,
  placeholder,
  type,
  iconPrefix,
  max,
  min,
  showCountryDropDown = false,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="text-[.7vw] font-[500] capitalize">
          {convertName(label)}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}

      <div className="flex gap-[.6vw] mt-[.2vw]">
        {/* {showCountryDropDown && (
          <CountryCode
            value={"+91"}
            options={countryList}
            onChange={(value) => {
            }}
            className="!min-w-[5vw] !min-h-[4.7vh]"
            modalClassName="!max-w-[11vw]"
          />
        )} */}
        <div
          className={`w-full bg-gray-50- border border-solid border-gray-200 text-gray-900 text-[.8vw] rounded-lg px-[.8vw] py-[.5vw] flex-row space-between ${className}`}
        >
          <input
            autoComplete="off"
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            // data-contact="contact"
            {...rest}
            placeholder={placeholder}
            required={required}
            min={min}
            max={max}
            onWheel={(e) => e.target.blur()}
            className={` border-gray-200 text-gray-900 text-[.8vw]  w-full  placeholder:font-[400] flex-row space-between ${className}`}
          />
          {iconPrefix && (
            <img
              // src={ICONS?.modelDropdownIcon}
              alt="modelDropdownIcon"
              className="pointer"
              src={iconPrefix}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Input;
