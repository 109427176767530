import EdgeElements from './EdgeElements';

const EdgesNode = ({
  nodes,
  edges,
  scale,
  handleOnDeleteEdge,
  getSelectectedNodeId,
  selectedIds,
  allowToEditFlow,
}) => {
  return (
    <>
      {edges?.map((edge) => {
        return (
          <EdgeElements
            id={edge?.uniq_id}
            edge={edge}
            isNew={false}
            position={{
              x0: edge?.coordinates?.start?.x || 0,
              y0: edge?.coordinates?.start?.y || 0,
              x1: edge?.coordinates?.end?.x || 0,
              y1: edge?.coordinates?.end?.y || 0,
            }}
            onClickDelete={() => handleOnDeleteEdge(edge)}
            scale={scale}
            selectedIds={selectedIds}
            allowToEditFlow={allowToEditFlow}
          />
        );
      })}
    </>
  );
};

export default EdgesNode;
