import React from 'react';
import { TRIGGERS_ICONS } from '../../constants/sequence';
import { cn } from '../../../../helper/cn';

const Icons = ({
  keyProp,
  bgProp,
  colorProp,
  strokeWidth,
  className,
  rect,
}) => {
  const value = TRIGGERS_ICONS[keyProp];

  if (!value) return null;

  const { icon: Icon, color } = value;

  return (
    <div
      className={cn(
        'w-full h-full flex items-center justify-center rounded-md',
        className
      )}
      style={{
        background: bgProp ? bgProp : value?.bg ? value?.bg : 'transparent',
      }}>
      <Icon
        color={colorProp ? colorProp : color}
        rect={false}
        strokeWidth={strokeWidth}
        className={cn(
          'stroke-2',
          ['CAMP_WAPP_BCAST', 'BROADCAST']?.includes(keyProp)
            ? 'size-[2.5rem]'
            : 'size-[2rem]',
          className
        )}
      />
    </div>
  );
};

export default Icons;
