import React, { useCallback, useEffect, useReducer, useState } from "react";

// assets
import { ICONS } from "../../../assets/icons";
import Eye from "../../../assets/customSVG/Eye";
import FullArrow from "../../../assets/customSVG/FullArrow";
import CircleTick from "../../../assets/customSVG/CircleTick";

// utils
import { Link, useNavigate } from "react-router-dom";

// API
import { ChangePassword } from "../api/SignUp";

// constants
import {
  checkingValidation,
  passwordValidations,
} from "../../../constant/auth/authValidation";
import { invitation } from "../components/AuthForm";

// helper
import { cn } from "../../../helper/cn";
import { removeSpace } from "../../../helper/validation";

// hooks
import useToggle from "../../../hooks/useToggle";
import {
  INITIAL_STATE,
  signupReducer,
} from "../../../hooks/auth/signupReducer";

// redux
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  updateCurrentUser,
  updateToggleToast,
} from "../../../reduxToolkit/appSlice";
import { useSearchParams } from "react-router-dom";

// component
import Loader from "../../../components/commonComponents/Loader/Index";
import { Modal } from "antd";
import { invitationAccept } from "../api/Api";

export default function VerifyPage() {
  const { toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();
  const [data, dispatchReducer] = useReducer(signupReducer, INITIAL_STATE);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useToggle(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [toastError, setToastError] = useState("");

  const [showPassword, setShowPassword] = useToggle(false);
  const [showConfirmPassword, setShowConfirmPassword] = useToggle(false);
  const [errorValues, setErrorValues] = useState(passwordValidations);
  const [error, setError] = useState({
    password: false,
    confirm_password: false,
    focused: true,
  });
  const [queryParams] = useSearchParams();

  const token = queryParams.get("token");
  const validationToken2 = queryParams?.get("role");

  const role = localStorage?.getItem("role");
  const invite_token = localStorage?.getItem("invite_token");
  const name = localStorage?.getItem("name");
  const account = localStorage?.getItem("account");

  const validateValues = (...values) => {
    return values.every((value) => value); // Checks if all values are truthy
  };

  useEffect(() => {
    if (!token) localStorage.clear();
  }, []);

  const onChange = (type, key, value, token) => {
    let datas = { type: type };
    setToastError("");

    if (key) datas["key"] = key;
    if (value) datas["value"] = value ? value : "";
    if (token) datas["token"] = token;
    dispatchReducer(datas);
  };

  const card = "flex flex-col gap-2";
  const label = "font16px-600 leading-6 text-[var(--textBlack)]";
  const inputContainer =
    "w-full flex items-center gap-2 py-2 px-3 border-[1px] border-[#D1D3D8] b-radius-6";
  const input = "w-full text-sm weight-medium";

  const checkToShowSubmit = useCallback(() => {
    const res = errorValues.filter((e) => e.value);
    return res?.length === errorValues?.length && !toastError;
  }, [errorValues, toastError]);

  const onErrorChange = useCallback(
    ({ type, key, value }) => {
      if (type === "both") {
        setError({
          password: value,
          confirm_password: value,
        });
      } else {
        setError({ ...error, [key]: value });
      }
    },
    [data]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      checkToShowSubmit() &&
      !error?.password &&
      !error?.confirm_password &&
      data?.password === data?.confirm_password
    ) {
      const payload = {
        token: token,
        email: data?.email,
        password: data?.password,
        password_confirmation: data?.confirm_password,
      };

      //   if (token) payload["invite_token"] = token;
      if (token) setIsLoading(true);
      const res = await ChangePassword(payload);
      if (res?.status === 200) {
        localStorage?.setItem("authToken", res?.data?.auth_token);
        dispatch(updateCurrentUser(res?.data?.current_user));
        setIsLoading(false);
        const activeAccountsLength =
          res?.data?.current_user?.active_accounts?.length;
        // if (validationToken2 || (role && invite_token && name)) {
        if (
          validateValues(
            token,
            validationToken2,
            role,
            invite_token,
            name,
            account
          )
        ) {
          setPopoverOpen(true);
        } else navigate("/on-boaring");
      } else {
        setIsLoading(false);
        setToastError(res?.response?.data?.error);
        // dispatch(
        //   updateToggleToast([
        //     ...toggleToast,
        //     {
        //       id: toggleToast?.length + 1,
        //       content: res?.response?.data?.error,
        //       status: "Error",
        //       duration: "",
        //     },
        //   ])
        // );
      }
    }
  };

  const handleClick = (item) => {
    const payLoad = {
      invite_token: invite_token,
      status: item?.status,
      role_code: validationToken2 || role,
    };

    if (role) {
      invitationAccept(payLoad).then((res) => {
        if (res?.status === 200) {
          // localStorage?.clear("role");
          // localStorage?.clear("invite_token");
          if (item?.status === "rejected") navigate("/on-boarding");
          else if (item?.status === "accepted") navigate("/Accounts");
        }
      });
    }
  };

  return (
    <div className="w-70 text-[var(--textBlack)] ">
      <h2 className="font24px-600 text-center mt-8 pb-6">
        Setup Password to Your Profile
      </h2>
      <form className="" onSubmit={handleSubmit}>
        <div className={cn(card, "mt-3")}>
          <label htmlFor="password" className={cn(label)}>
            New Password
            <sup className="text-red-500">*</sup>
          </label>
          <div
            className={cn(
              inputContainer,
              error?.password ? "border-red-400" : "border-[#D1D3D8]"
            )}
          >
            <img src={ICONS?.lock} alt="lock icon" />
            <input
              name="password"
              id="password"
              maxLength={30}
              type={showPassword ? "text" : "password"}
              placeholder="********"
              className={cn(input)}
              value={data?.password}
              onChange={(e) => {
                const { name, value } = e.target;

                const removedSpace = removeSpace(value);
                onChange("update", name, removedSpace);

                let newErrorValues = checkingValidation(
                  name,
                  data,
                  errorValues,
                  removedSpace
                );
                const filteredValues = newErrorValues.filter((e) => e.value);
                setErrorValues(newErrorValues);
                if (error?.password && removedSpace) {
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: false,
                  });
                }
                if (!removedSpace) {
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                } else if (
                  removedSpace &&
                  filteredValues?.length !== passwordValidations?.length
                )
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                else if (
                  removedSpace &&
                  filteredValues?.length !== passwordValidations?.length
                )
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: false,
                  });
                else if (removedSpace && data?.confirm_password) {
                  if (removedSpace !== data?.confirm_password) {
                    onErrorChange({
                      type: "single",
                      key: "confirm_password",
                      value: true,
                    });
                  } else {
                    onErrorChange({
                      type: "both",
                      value: false,
                    });
                  }
                }
              }}
              onBlur={() => {
                let newErrorValues = checkingValidation(
                  "password",
                  data,
                  errorValues,
                  data?.password
                );
                const filteredValues = newErrorValues.filter((e) => e.value);
                if (!data?.password)
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                else if (
                  data?.password &&
                  filteredValues?.length !== passwordValidations?.length
                )
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
              }}
            />
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <Eye color={showPassword ? "#000000" : "#898E99"} />
            </div>
          </div>
        </div>

        <div className="mt-4 ml-[15px]">
          <h3 className="font14px-400 text-[var(--textBlack)] pb-2">
            Your Password must contain
          </h3>
          <div className="grid grid-cols-2 gap-x-6">
            {errorValues?.map((e) => {
              return (
                <div className="flex items-center gap-2.5 py-1">
                  <CircleTick color={e.value ? "var(--primary)" : "#D1D3D8"} />
                  <p
                    className={cn(
                      "text-xs weight-medium",
                      e.value
                        ? "text-[var(--contentText)]"
                        : "text-[var(--font-600)]"
                    )}
                  >
                    {e.msg}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={cn(card, "mt-8")}>
          <label htmlFor="confirmPassword" className={cn(label)}>
            Confirm Password
            <sup className="text-red-500">*</sup>
          </label>
          <div
            className={cn(
              inputContainer,
              error?.confirm_password ? "border-red-400" : "border-[#D1D3D8]"
            )}
          >
            <img src={ICONS?.lock} alt="lock icon" />
            <input
              name="confirm_password"
              maxLength={30}
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="********"
              className={cn(input)}
              value={data?.confirm_password}
              onChange={(e) => {
                const { name, value } = e.target;

                const removedSpace = removeSpace(value);
                onChange("update", name, removedSpace);

                if (data?.password && checkToShowSubmit()) {
                  if (data?.password === removedSpace) {
                    onErrorChange({
                      type: "single",
                      key: "confirm_password",
                      value: false,
                    });
                  } else {
                    onErrorChange({
                      type: "single",
                      key: "confirm_password",
                      value: true,
                    });
                  }
                } else if (data?.password && !checkToShowSubmit()) {
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                } else {
                  onErrorChange({
                    type: "both",
                    value: false,
                  });
                }
              }}
              onFocus={() => {
                if (!data?.password)
                  onErrorChange({ type: "both", value: true });
                else if (data?.password && !checkToShowSubmit())
                  onErrorChange({ type: "both", value: true });
              }}
              onPaste={(e) => {
                e.preventDefault();
              }}
            />
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              <Eye color={showConfirmPassword ? "#000000" : "#898E99"} />
            </div>
          </div>
        </div>

        {(data?.password && data?.confirm_password && data?.password) !==
          data?.confirm_password && (
          <div className="mt-1 flex items-center gap-2">
            <img src={ICONS?.alertIconError} alt="alert icon" />
            <p className="font13px-500 text-[#FF543E]">
              Your passwords do not match.
            </p>
          </div>
        )}
        {toastError && (
          <div className="flex-row align-center">
            <img src={ICONS?.alertIconError} alt="" />
            <span className="font14px-500 text-red-500 pl-5">{toastError}</span>
          </div>
        )}

        <div className="flex flex-col items-center mt-11">
          <button
            type="submit"
            onClick={handleClick}
            className={cn(
              "font16px-600 px-4 py-2 w-fit rounded-[8px] flex items-center justify-center",
              checkToShowSubmit() &&
                !error?.password &&
                !error?.confirm_password &&
                data?.password === data?.confirm_password
                ? isLoading
                  ? "cursor-not-allowed bg-[var(--new-primary)] text-[var(--white)]"
                  : "bg-[var(--new-primary)] text-[var(--white)] cursor-pointer"
                : "bg-[var(--disable-bg)] text-[var(--disable-color)] cursor-not-allowed"
            )}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Loader Width={120} Height={20} loaderBg="white" />
              </div>
            ) : (
              "Complete Registration"
            )}
          </button>
        </div>
      </form>
      <div className="flex items-center justify-center gap-1  border-t border-[var(--border-100)] mt-8 pt-6">
        <p className="font16px-400 text-[var(--textBlack)]">
          Already have an account?
        </p>
        <Link to={"/login"} className="font16px-600 text-[var(--a)]">
          Login
        </Link>
      </div>
      {/* <div
        className="flex items-center justify-center gap-2 mt-4 cursor-pointer"
        onClick={() => {
          onChange("back");
        }}
      >
        <div className="rotate-180">
          <FullArrow color="#898E99" />
        </div>
        <p className="text-base weight-semibold text-[#898E99]">Back</p>
      </div> */}
      <Modal
        open={popoverOpen}
        closable={false}
        centered={true}
        footer={false}
        wrapClassName={"backdrop-blur-xl"}
        width={"49vw"}
      >
        <div className="w-100 h-[38vh] asp-modal-linear b-radius flex-column align-center pt-16">
          <span className="w-100 text-center font24px-600 text-[var(--textBlack)] mb-20">
            {name} has invited you to Join "{account}" account on GainWix.ai
          </span>
          <div className="justify-center flex-column mt-20 mb-20">
            {invitation?.map((item) => {
              return (
                <div
                  className="flex align-center justify-center "
                  key={item?.id}
                  onClick={() => handleClick(item)}
                >
                  <span
                    className={`font16px-500 text-[#4C515B] pointer ${
                      item?.id === 1
                        ? "button-primary b-radius-6 px-7 py-1.5"
                        : "font16px-500 text-[#4C515B] mt-10"
                    }`}
                  >
                    {item?.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
}
