import React, { useState } from "react";
// import '../styles/inbox.css';

// assets
import NewConversation from "assets/customSVG/NewConversation";
import Filters from "../../../../assets/customSVG/inbox/Filters";
import { Popover, Tooltip } from "antd";
import { CONVERSATION_LIST } from "../../constants";
import { cn } from "../../../../helper/cn";
import { ICONS } from "../../../../assets/icons";
import Resolved from "../../../../assets/customSVG/inbox/Resolved";
import NewRadioButton from "../../../../components/form/radio/NewRadio";
import ClearFilters from "../../../../assets/customSVG/ClearFilters";
import CheckBoxSqure from "../../../../components/commonComponents/checkbox/CheckBoxSqure";

const ConversationsType = ({
  loading,
  conversations,
  selectedConversationType,
  onConversationTypeChange,
  onFilterChange,
  selectedFilterType,
  activeTabName,
  phoneNumberCount,
  clearType,
}) => {
  const conversationType = selectedConversationType
    ?.filter((item) => item?.isSelected === true)
    ?.map((item) => item.msg);

  const selectedFiltersType = selectedFilterType
    ?.filter((item) => item?.isSelected === true)
    ?.map((item) => item.msg);

  return (
    <div className="w-full max-h-[5.5vw] bg-[var(--white)] pt-20">
      <div className="w-100 flex-row align-center space-between border-[var(--border-50)]">
        <Popover
          trigger={["click"]}
          placement="bottom"
          arrow={false}
          content={
            <div className=" px-3.5 py-2.5">
              <div className="text-[var(--font-400)]">Change</div>
              {conversations?.map((s) => {
                return (
                  <div
                    className={cn(
                      "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer new-body-text p-10 mt-10"
                    )}
                    key={s?.id}
                    onClick={() => {
                      if (!loading) onConversationTypeChange(s);
                    }}
                  >
                    <div className="w-5 h-9 flex items-center justify-center mt-1">
                      <NewRadioButton
                        value={s?.isSelected}
                        selected={s?.isSelected}
                        onChange={(e) => {}}
                        rootClassName={s?.isSelected && "size-3 new-radio"}
                      />
                    </div>
                    {s?.icon1 && (
                      <img src={s?.icon1} alt={s.msg} className="w-5" />
                    )}
                    {s?.icon && (
                      <Resolved
                        color="var(--textBlack)"
                        lineColor={"var(--font-600)"}
                      />
                    )}
                    <p>{s?.msg}</p>
                  </div>
                );
              })}
            </div>
          }
        >
          <div className="w-auto group/sort flex items-center gap-3 hover:bg-[var(--BG-25)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
            <Tooltip title={`${conversationType} : ${phoneNumberCount}`}>
              <div className="campaign-filters flex-row align-center gap-2">
                <img src={ICONS?.inboxOpen} alt="inboxOpen" />
                {conversationType}
                {!loading && (
                  <span className="bg-[#FF543E] inbox10px-600 px-2 py-0.9 b-radius">
                    {phoneNumberCount > 99 ? "99+" : phoneNumberCount}
                  </span>
                )}
              </div>
            </Tooltip>

            <div
              className={cn(
                "transition-transform duration-200 ease-linear",
                // group-hover/sort:-rotate-180,
                false ? "-rotate-180" : "rotate-0"
                // isLoading ? "cursor-not-allowed" : "cursor-pointer"
              )}
            >
              <img src={ICONS?.sidebarChevronDown} alt="chevronDown" />
            </div>
          </div>
        </Popover>

        <Popover
          trigger="click"
          content={
            ![
              "All Conversations",
              "Your Inbox",
              "Unread",
              "Resolved",
              "Favorited",
            ].includes(activeTabName) && (
              <>
                <div className=" px-3.5 py-2.5 w-[13vw]">
                  <div className="text-[var(--font-400)] flex-row align-center space-between w-100">
                    <span>Filter</span>
                    <div
                      className={cn(
                        "text-[#F96056]",
                        selectedFiltersType?.length > 0
                          ? "cursor-pointer opacity-100"
                          : "cursor-not-allowed opacity-50"
                      )}
                      onClick={() => {
                        if (selectedFiltersType?.length > 0) clearType();
                      }}
                    >
                      Clear
                    </div>
                  </div>
                  {selectedFilterType?.map((s) => {
                    return (
                      <div
                        className={cn(
                          "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer new-body-text p-10 mt-10 hover:bg-[var(--channelButton)] b-radius-6"
                        )}
                        key={s?.id}
                        onClick={() => {
                          if (!loading) onFilterChange(s);
                        }}
                      >
                        <div className="w-5 h-9 flex items-center justify-center mt-1">
                          <CheckBoxSqure
                            onChange={() => {}}
                            value={s?.isSelected}
                          />
                        </div>
                        {s?.icon1 && (
                          <img src={s?.icon1} alt={s.msg} className="w-5" />
                        )}
                        {s?.icon && (
                          <s.icon
                            color="var(--textBlack)"
                            lineColor={"var(--textBlack)"}
                          />
                        )}
                        <p>{s?.msg}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )
          }
        >
          {![
            "All Conversations",
            "Your Inbox",
            "Unread",
            "Resolved",
            "Favorited",
          ].includes(activeTabName) ? (
            <div
              className={cn(
                `group/status flex justify-end gap-2.5  px-2.5 py-2.5 rounded-[0.4vw] pointer ${
                  selectedFiltersType?.length === 0 ?? "hover:bg-[var(--BG-25)]"
                }`
              )}
            >
              {selectedFiltersType?.length === 0 ? (
                <Filters color="var(--textBlack)" />
              ) : (
                <img src={ICONS?.inboxFiltersActive} alt="inboxFiltersActive" />
              )}
            </div>
          ) : (
            <div className="cursor-not-allowed pr-10">
              {/* <Filters color="#D1D3D8" /> */}
            </div>
          )}
        </Popover>
        {/* {conversations?.map((item) => {
          return (
            <div
              key={item?.id}
              className={`w-1/2 h-[2vw] pr-[0.8vw] border-r-[0.08vw] border-r-[var(--border-50)] last:border-r-0 last:pr-0 flex-row align-center justify-center transition-colors ease-linear duration-200 ${
                loading ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={() => {
                if (!loading) onConversationTypeChange(item);
              }}
            >
              <div
                key={item?.id}
                className={`w-full h-full border-b-[0.15vw] flex-row align-center justify-center transition-colors ease-linear duration-200 ${
                  selectedConversationType?.id === item?.id
                    ? 'bg-[var(--primaryBackground)] border-b-[var(--primary)]'
                    : 'bg-transparent border-b-transparent'
                }`}
              >
                <item.icon
                  stroke={
                    selectedConversationType?.id === item?.id
                      ? '#25C277'
                      : '#898E99'
                  }
                  color={
                    selectedConversationType?.id === item?.id
                      ? '#25C277'
                      : '#898E99'
                  }
                  width={'1.15vw'}
                  height={'1.15vw'}
                />
              </div>
            </div>
          );
        })} */}
      </div>

      {/* <div className={`flex-row align-center px-[0.4vw] py-[0.5vw]`}>
        {
          <div
            className={`w-full flex-row align-center bg-[var(--primaryBackground)] rounded-[0.5vw] px-[0.8vw] py-[0.5vw]`}
          >
            <NewConversation
              stroke={"#25C277"}
              color={"#25C277"}
              width={"1.1vw"}
              height={"1.1vw"}
            />
            <span className="font-md weight-semibold text-[var(--primary)] pl-[0.4vw]">
              {conversationList?.length} {selectedConversationType?.msg}
            </span>
          </div>
        }
      </div> */}
    </div>
  );
};

export default ConversationsType;
