import React, { useEffect, useState } from "react";
import "../style/SignUp.css";

import { useNavigate } from "react-router-dom";

import { validatePassword } from "../../../constant/auth/authValidation";
import { ICONS } from "../../../assets/icons/index";
import useToggle from "../../../hooks/useToggle";

import { Form } from "antd";

import { Button } from "../../../components/form/Button/Button";
import Label from "../../../components/form/label/label";
import { Input } from "../../../components/form/Input/Input";

import { resetPassword, passwordLinkValidate } from "../api/Api";

import { useAspSelector, useAspDispatch } from "../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import { updateResetPasswordEmail } from "../../../reduxToolkit/authSlice";

const button = {
  margin: "25px 0 15px 0",
};

const input = {
  width: "60%",
};

const validatePasswordRules = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("Password is required"));
  }

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!passwordRegex.test(value)) {
    return Promise.reject(
      new Error(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
    );
  }

  return Promise.resolve();
};

const passwordRules = [{ validator: validatePasswordRules }];

// const passwordRules = [{ validator: validatePassword }];
const confirmPasswordRules = [
  {
    required: true,
    message: "Password is required",
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("These passwords didn’t match. Try again")
      );
    },
  }),
];

function ResetPassword() {
  const [value, toggleValue] = useToggle(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const dispatch = useAspDispatch();
  const { toggleToast } = useAspSelector((state) => state.app);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const TOKEN = window.location.search?.split("token=")[1];

  useEffect(() => {
    toggleValue(true);
    passwordLinkValidate(TOKEN).then((res) => {
      passwordLinkValidateResponse(res);
    });
  }, []);

  const passwordLinkValidateResponse = (res) => {
    toggleValue(false);
    if (res?.status === 200) {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: res?.data?.message,
            status: "Success",
            duration: "",
          },
        ])
      );
      dispatch(updateResetPasswordEmail(res?.data?.email));
    } else {
      navigate("/404");
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: res?.response?.data?.error,
            status: "Error",
            duration: "",
          },
        ])
      );
    }
  };

  const onFinish = (values) => {
    const payLoad = {
      token: TOKEN,
      password: values?.password,
      password_confirmation: values?.reEnterPassword,
    };
    toggleValue(true);
    resetPassword(payLoad).then((res) => {
      resetPasswordResponse(res);
    });
  };

  const resetPasswordResponse = (res) => {
    toggleValue(false);
    form.setFieldsValue({
      password: "",
      reEnterPassword: "",
    });
    if (res?.status === 200) {
      navigate("/login");
    } else {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: res?.response?.data?.error,
            status: "Error",
            duration: "",
          },
        ])
      );
    }
  };

  // const onFieldsChange = (_, allFields) => {
  //   const password = allFields.find(
  //     (field) => field.name[0] === "password"
  //   )?.value;
  //   const reEnterPassword = allFields.find(
  //     (field) => field.name[0] === "reEnterPassword"
  //   )?.value;

  //   // Enable button only if passwords match and are not empty
  //   if (password && reEnterPassword && password === reEnterPassword) {
  //     setIsButtonDisabled(false);
  //   } else {
  //     setIsButtonDisabled(true);
  //   }
  // };

  const onFieldsChange = (_, allFields) => {
    const password = allFields.find(
      (field) => field.name[0] === "password"
    )?.value;
    const reEnterPassword = allFields.find(
      (field) => field.name[0] === "reEnterPassword"
    )?.value;

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (
      password &&
      reEnterPassword &&
      password === reEnterPassword &&
      passwordRegex.test(password)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  return (
    <>
      <span style={{ marginTop: "25px" }} />
      <Form
        onFinish={onFinish}
        form={form}
        className="step2Wrapper"
        onFieldsChange={onFieldsChange}
      >
        <div className="w-60">
          <Label title="New Password" required />
        </div>
        <Form.Item name="password" style={input} rules={passwordRules}>
          <Input
            type="password"
            iconPrefix={ICONS.passwordLock}
            iconSuffixPassword={ICONS.passwordEyeOpen}
            placeholder="password"
          />
        </Form.Item>
        <span style={{ marginBottom: "20px" }} />
        <div className="w-60">
          <Label title="Re-enter Password" required />
        </div>
        <Form.Item
          name="reEnterPassword"
          style={input}
          rules={confirmPasswordRules}
        >
          <Input
            type="password"
            iconPrefix={ICONS.passwordLock}
            iconSuffixPassword={ICONS.passwordEyeOpen}
            placeholder="password"
          />
        </Form.Item>
        <div className="flex flex-col items-center mt-11">
          <button
            className={`font16px-600 px-4 py-2 w-fit rounded-[8px] flex items-center justify-center ${
              isButtonDisabled
                ? "bg-[var(--disable-bg)] text-[var(--disable-color)] cursor-not-allowed"
                : "bg-[var(--new-primary)] text-[var(--white)] cursor-pointer"
            }`}
          >
            Reset Password
          </button>
        </div>
        {/* <Button
          size="large"
          type="submit"
          loading={value}
          disabled={isButtonDisabled || value}
          label={`Reset Password`}
          style={button}
        /> */}
      </Form>
    </>
  );
}

export default ResetPassword;
