import { useEffect, useRef, useState } from "react";
import { cn } from "../../../helper/cn";

const OtpInput = ({
  data,
  length = 4,
  onChange,
  disabled,
  onOtpSubmit = () => {},
  className,
  clearOtp,
  toastError,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (!disabled && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (!disabled) setOtp(new Array(length).fill(""));
  }, [disabled]);

  useEffect(() => {
    if (clearOtp) {
      setOtp(new Array(length).fill(""));
    }
  }, [clearOtp]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length && onOtpSubmit) onOtpSubmit(combinedOtp);
    if (onChange) onChange(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="w-full flex items-center gap-4 justify-between">
      {otp.map((value, index) => {
        return (
          <>
            {!disabled ? (
              <input
                key={index}
                type="text"
                ref={(input) => (inputRefs.current[index] = input)}
                value={value}
                onChange={(e) => {
                  if (!disabled) handleChange(index, e);
                }}
                onClick={() => {
                  if (!disabled) handleClick(index);
                }}
                onKeyDown={(e) => {
                  if (!disabled) handleKeyDown(index, e);
                }}
                className={cn(
                  "w-[72px] h-12 !border-[1px] border-solid rounded-lg flex items-center justify-center text-center",
                  value
                    ? "!border-[var(--new-primary)]"
                    : "!border-[var(--BG-100)]",
                  // value && !toastError
                  //   ? "!border-[var(--new-primary)]"
                  //   : toastError
                  //   ? "border-red-500"
                  //   : "!border-[var(--BG-100)]",
                  className
                )}
                // onPaste={(e) => {
                //   const pastedData = e.clipboardData.getData('text/plain');
                //   if (isNaN(pastedData)) {
                //     return;
                //   } else {
                //     const splitedData = pastedData?.split('');

                //     setOtp(splitedData);
                //     if (pastedData.length === length && onOtpSubmit)
                //       onOtpSubmit(pastedData);
                //     if (onChange) onChange(pastedData);
                //   }
                // }}
              />
            ) : (
              <div
                className={cn(
                  "w-[72px] h-12 !border-[1px] border-solid rounded-lg flex items-center justify-center text-center !border-[var(--BG-50)] cursor-not-allowed ",
                  className
                )}
              >
                <span className="text-[var(--font-400)] opacity-20 font14px-400">
                  0
                </span>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
export default OtpInput;
