export const INITIAL_STATE = {
  firstname: "",
  lastname: "",
  mail: "",
  terms: true,
  services: false,
  otp: "",
  password: "",
  confirm_password: "",
  step1: false,
  step2: false,
  token: null,
  captchaValue: null,
};

export function signupReducer(state, action) {
  const { type, key, value, token } = action;

  const checkedValue = key === "terms" ? value ?? false : value ?? "";

  switch (type) {
    case "update":
      return { ...state, [key]: checkedValue };

    case "change_email":
      return {
        ...state,
        step1: false,
        otp: "",
      };

    case "update_token":
      return {
        ...state,
        step2: true,
        token: token,
      };

    case "back":
      return {
        ...state,
        otp: "",
        step1: false,
        step2: false,
        password: "",
        confirm_password: "",
      };

    default:
      break;
  }
}
