import * as XLSX from 'xlsx';

export default function useJsonToExcel() {
  const renameKeys = (data, keys) => {
    return data.map((item) => {
      let newItem = {};
      keys.forEach(({ from, to }) => {
        if (item[from]) {
          newItem[to] = item[from];
          //   delete newItem[from];
        }
      });
      return newItem;
    });
  };

  const handleDownloadExcel = ({ fileName, data, keys }) => {
    if (data?.length > 0) {
      let modifiedData = [];
      if (keys?.length > 0) modifiedData = renameKeys(data, keys);
      else modifiedData = data;
      const ws = XLSX.utils.json_to_sheet(modifiedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } else {
    }
  };

  return { handleDownloadExcel };
}
