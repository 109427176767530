import React from 'react';

// assets
import { ICONS } from '../../../assets/icons';

// helpers
import { cn } from '../../../helper/cn';
import { getLanguageName } from '../../../helper/setLanguageCode';

const TemplatePreviewCard = ({
  category,
  name,
  language,
  categoryType,
  onChange,
}) => {
  const tabStyle =
    'px-2 py-1 rounded-md text-center text-xs weight-semibold bg-white rounded-lg';
  return (
    <div className='w-full py-2 px-4 bg-[#F4F6F8] flex flex-col gap-5 rounded-lg'>
      <div className='flex items-center justify-between gap-5'>
        <div className='flex items-center gap-2.5'>
          <img
            src={
              category === 'MARKETING'
                ? ICONS?.marketingInbox
                : ICONS?.utilityInbox
            }
            alt='sync'
            className='w-7'
          />
          <p className='max-w-28 truncate text-sm weight-semibold'>{name}</p>
        </div>
        <div
          className='flex items-center gap-2.5 py-1 px-2.5 bg-white rounded-md'
          onClick={() => {
            onChange();
          }}
        >
          <img src={ICONS?.syncChange} alt='sync' className='w-4' />
          <p className='text-sm weight-semibold text-[#616874]'>Change</p>
        </div>
      </div>
      <div className='w-full h-6 flex items-center gap-4'>
        <div className={cn('text-[#ED4F9D]', tabStyle)}>{category}</div>
        <span className='w-[1px] h-full bg-gray-200 contents-[""]' />
        <div className={cn('text-[#4AACEA]', tabStyle)}>
          {getLanguageName(language)}
        </div>
      </div>
    </div>
  );
};

export default TemplatePreviewCard;
