import React, { useCallback, useRef, useState } from "react";

// assets
import AddImageSvg from "../../../../assets/customSVG/AddImageSvg";

// hooks
import { useFileUploader } from "hooks/useFileUploader";
import useToggle from "../../../../hooks/useToggle";

// helper
import { cn } from "../../../../helper/cn";
import { numberOnly, removeSpace } from "../../../../helper/validation";

// constants
import { countryList } from "constant/app/countryList";

// redux
import { useAspSelector } from "../../../../test/jest-redux-hooks";

// components
import Loader from "components/commonComponents/Loader/Index";
import BtnInput from "../../../../components/commonComponents/templates/Buttons/BtnInput";
import TemplateBody from "./TemplateBody";
import CountryCode from "../../../../components/commonComponents/countryCode";
import { isValidHttpsUrl } from "../../../../helper/checkUrls";

export default function CarouselCard({
  template_id,
  format,
  data,
  dispatch,
  allow,
  language,
  headerVariable,
  button1,
  button2,
  setData,
  onChange,
}) {
  const { uploading, selectedFile, uploadFiles, error, clearFiles } =
    useFileUploader();
  const { currentBrand } = useAspSelector((state) => state.app);

  const [showEmojiPicker, setShowEmojiPicker] = useToggle(false);

  const inputRef = useRef();

  // get the file name from uploaded file
  const fileName = useCallback(() => {
    const names = data ? data?.image?.split("/") : "";
    return names?.[names?.length - 1];
  }, [data?.image]);

  // commen styles
  const uploadContainer =
    "size-[10vw] px-[0.5vw] py-[0.1.5vw] font-default text-[var(--primary)] flex flex-col items-center justify-center border border-dashed border-[var(--primary)] rounded-[0.4vw] cursor-pointer";
  const errorStyle = "text-[var(--fontRed)] font-md weight-400 my-[0.5vw]";
  const header =
    "pb-[0.4vw] font-default weight-semibold text-[var(--contentText)] flex items-center gap-[0.2vw]";
  const subHeader = "font-md weight-medium text-[var(--font-400)] pb-[0.5vw]";

  return (
    <div className="w-full min-h-[40vh] bg-white flex gap-[2vw] p-[1.5vw]">
      {["IMAGE", "VIDEO"].includes(format) && (
        <div className="w-3/12 min-w-[7vw] max-w-[10vw] h-full max-h-[30vh] flex flex-col">
          {/* file upload component based on the selected media type */}
          {["IMAGE", "VIDEO"]?.includes(format) && !data?.image && (
            <div className="w-full h-full flex-1">
              <div className="w-full h-full">
                {uploading ? (
                  <div className="min-h-[20vh] flex-row align-center justify-center ">
                    <Loader Width={25} Height={25} />
                  </div>
                ) : (
                  <div
                    className={`${uploadContainer}`}
                    onClick={() => {
                      inputRef.current.value = null;
                      inputRef?.current?.click();
                    }}
                  >
                    <AddImageSvg
                      width={30}
                      height={30}
                      color="var(--primary)"
                    />
                    <span className="w-4/5 text-center mt-[0.8vw] font-medium">
                      Upload card {format.toLowerCase()}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* file upload component based on the selected media type */}

          {/* uploaded file preview section starts here */}
          <div className="w-full h-[15vh] flex flex-col items-center gap-[0.6vw]">
            {format === "VIDEO" && data?.image && (
              <video
                className="w-full h-full rounded-[0.6vw] object-cover"
                src={data?.image}
                autoPlay
                controls
              />
            )}
            {format === "IMAGE" && data?.image && (
              <img
                src={data?.image}
                className="w-full h-full rounded-[0.6vw] object-cover"
                alt="preview"
              />
            )}

            {(selectedFile || data?.image || fileName()) && (
              <div
                className={`w-full font-md weight-medium text-[#616874] truncate`}
              >
                {fileName()}
              </div>
            )}
            {data?.image && (
              <div
                className={`w-fit group p-[0.5vw] !text-[0.8vw] font-[600] rounded-[0.3vw] ${
                  allow
                    ? "cursor-pointer bg-[var(--primary)] text-white "
                    : "cursor-not-allowed bg-[var(--BG-50)] text-[var(--font-600)]"
                }`}
                onClick={() => {
                  if (allow) {
                    // dispatch({
                    //   type: "update_carousel",
                    //   updateType: "update_card",
                    //   id: data?.id,
                    //   key: "image",
                    //   value: "",
                    // });
                    // clearFiles();
                  }
                  inputRef?.current?.click();
                }}
              >
                Change Media
              </div>
            )}
          </div>
          {/* uploaded file preview section ends here */}

          {/* Error message for the the uploaded data format incorrect based on the selected media type */}
          {error && (
            <div className={`${errorStyle}`}>
              {format === "IMAGE" && data?.image && (
                <>Image size should be less than 5MB</>
              )}
              {format === "VIDEO" && <>Video size should be less than 16MB</>}
            </div>
          )}
          {/* Error messages ends here */}
        </div>
      )}

      <div className="w-9/12">
        <TemplateBody
          type="carousel_card"
          title={"Body Message"}
          subTitle={
            "Enter the text for your message in the language that you've selected."
          }
          count={160}
          language={language}
          body={data?.bodyText}
          dispatch={dispatch}
          headerVariable={headerVariable}
          variables={data?.bodyVariables}
          allow={allow}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          textAreaClassName={"h-[10vh]"}
          showVariables={false}
          onChange={(value) => {
            setData({ ...data, bodyText: value?.text });
            onChange({
              type: "carousel",
              updateKey: "update_card",
              id: data?.id,
              key: "bodyText",
              value: value?.text,
            });
          }}
          onVariableChange={(value) => {
            onChange({
              type: "update_bodyVariables",
              updateKey: "carousel_card",
              id: data?.id,
              key: value?.key,
              value: value?.value,
              text: value?.text,
            });
          }}
        />
        <div className="mt-4">
          <h3 className={cn(header)}>
            Buttons<sup className="text-[var(--fontRed)]">*</sup>
          </h3>
          <p className={cn(subHeader)}>
            Create buttons that let customers respond to your message or take
            action
          </p>
          <div className="">
            <div className="flex flex-col gap-[1vw] py-[1vw]">
              {(!["NONE"]?.includes(button1) || !["NONE"]?.includes(button2)) &&
                data?.buttons?.map((b, i) => {
                  return (
                    // <div className="flex items-center gap-[1vw]">
                    //   <div className="w-[12vw] h-full px-[1vw] py-[0.45vw] bg-[var(--BG-50)] rounded-[0.4vw] flex items-center justify-center font-md weight-medium">
                    //     {b?.label}
                    //   </div>
                    //   <BtnInput
                    //     value={b?.text}
                    //     placeholder={
                    //       b?.type === "QUICK_REPLY"
                    //         ? "Enter sample text"
                    //         : b?.type === "PHONE_NUMBER"
                    //         ? "000 000 0000"
                    //         : "https://www.example.com"
                    //     }
                    //     count={b?.type === "PHONE_NUMBER" ? 20 : 2000}
                    //     onChange={(value) => {
                    //       dispatch({
                    //         type: "update_carousel",
                    //         updateType: "update_card_button",
                    //         id: data?.id,
                    //         btnObj: { id: b?.id, text: value },
                    //       });
                    //     }}
                    //   />
                    // </div>
                    <div>
                      <div className="w-full h-auto flex items-center justify-between gap-[0.6vw]">
                        <div className="w-[10vw] bg-[var(--channelButton)] text-[0.78vw] font-[500] text-[var(--textBlack)] p-[9px] b-radius-6 capitalize">
                          {`${
                            b?.type === "URL"
                              ? "Website"
                              : `${b?.type
                                  ?.split("_")?.[0]
                                  ?.toLowerCase()} ${b?.type
                                  ?.split("_")?.[1]
                                  ?.toLowerCase()}`
                          }`}
                        </div>
                        {b?.type !== "QUICK_REPLY" ? (
                          <div className="w-[11.5vw]">
                            <BtnInput
                              value={b?.text ?? ""}
                              count={25}
                              onChange={(value) => {
                                onChange({
                                  type: "carousel",
                                  updateKey: "update_card_button",
                                  id: data?.id,
                                  data: { id: b?.id, text: value },
                                });
                              }}
                            />
                          </div>
                        ) : (
                          <div className="w-[75%]">
                            <BtnInput
                              value={b?.text ?? ""}
                              count={25}
                              onChange={(value) => {
                                onChange({
                                  type: "carousel",
                                  updateKey: "update_card_button",
                                  id: data?.id,
                                  data: { id: b?.id, text: value },
                                });
                              }}
                            />
                          </div>
                        )}
                        {b?.type === "PHONE_NUMBER" && (
                          <div className="w-[7.5vw] h-[2vw]">
                            {b?.type === "PHONE_NUMBER" && (
                              <CountryCode
                                value={b?.code || "+91"}
                                options={countryList}
                                onChange={(val) => {
                                  onChange({
                                    type: "carousel",
                                    updateKey: "update_card_button",
                                    id: data?.id,
                                    data: { id: b?.id, code: val?.value },
                                  });
                                }}
                              />
                            )}
                          </div>
                        )}

                        {b?.type !== "QUICK_REPLY" && (
                          <div
                            className={`${
                              b?.type === "URL" ? "w-[20.5vw]" : "w-[12vw]"
                            }`}
                          >
                            <BtnInput
                              type="text"
                              value={
                                b?.type === "PHONE_NUMBER"
                                  ? b?.phone_number
                                  : b?.example?.[0] ??
                                    isValidHttpsUrl(b?.example?.[0])
                              }
                              count={b?.type === "PHONE_NUMBER" ? 20 : 2000}
                              placeholder={
                                b?.type === "PHONE_NUMBER"
                                  ? "000 000 0000"
                                  : "https://www.example.com"
                              }
                              onChange={(value) => {
                                // Apply the numberOnly validation to the input value
                                const validatedValue =
                                  numberOnly(value) && removeSpace(value);

                                let obj = {};
                                if (b?.type === "PHONE_NUMBER") {
                                  obj = { phone_number: validatedValue };
                                } else {
                                  obj = { example: [value] };
                                }

                                onChange({
                                  type: "carousel",
                                  updateKey: "update_card_button",
                                  id: data?.id,
                                  data: { id: b?.id, ...obj },
                                });
                              }}
                              error={
                                b?.type === "URL" &&
                                !isValidHttpsUrl(b?.example?.[0])
                              }
                            />
                          </div>
                        )}
                      </div>
                      {b?.type === "URL" &&
                      !isValidHttpsUrl(b?.example?.[0]) ? (
                        <p className="font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw] px-[0.5vw]">
                          Enter a valid URL.
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <input
        type="file"
        accept={
          format === "IMAGE"
            ? ".jpg, .png, .jpeg"
            : format === "VIDEO"
            ? ".mp4"
            : ".jpg, .png, .jpeg"
        }
        ref={inputRef}
        onChange={async (e) => {
          const res = await uploadFiles(e, format, null, {
            brand_id: currentBrand?.brand_id,
            template_id: template_id,
          });
          if (res)
            await onChange({
              type: "carousel",
              updateKey: "update_card",
              id: data?.id,
              key: "image",
              value: res,
            });
        }}
        hidden
      />
    </div>
  );
}
