import { useState } from 'react';

import { preSendUrl } from 'api/Api';

import { useSelect } from './useSelect';
import useToggle from './useToggle';

const uploadFile = async (file, data) => {
  let type = file?.name?.split('.').pop();

  const apiResponse = await preSendUrl(type, data).then((res) => {
    if (res?.status === 200) {
      let fileType = '';
      switch (type) {
        case 'mp4':
          fileType = 'video/mp4';
          break;
        case 'mp3':
          fileType = 'audio/mp3';
          break;
        case 'png':
          fileType = 'image/png';
          break;
        case 'jpg':
          fileType = 'image/jpeg';
          break;
        case 'jpeg':
          fileType = 'image/jpeg';
          break;
        case 'pdf':
          fileType = 'application/pdf';
          break;
        case 'xlsx':
          fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        default:
          fileType = null;
      }
      return res?.data?.response && fileType && file
        ? getUrl(res?.data?.response, fileType, file, data)
        : undefined;
    }
  });
  return apiResponse;
};

const getUrl = async (url, fileType, file, data) => {
  let responseData = '';

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        contentType: fileType,
      },
      body: file,
    });
    responseData =
      response?.ok && response?.status === 200
        ? response?.url?.split('?').shift()
        : undefined;
  } catch (error) {
    console.error('Error during file upload:', error?.message);
    responseData = undefined;
  }

  return responseData;
};

const defaultFileSize = {
  IMAGE: 5,
  VIDEO: 16,
  DOCUMENT: 100,
};

export function useFileUploader() {
  const [uploading, setUploading] = useToggle(false);
  const [error, setError] = useToggle(false);
  const [dimensionError, setDimensionError] = useToggle(false);
  const [selectedFileFormat, setSelectedFileFormat] = useSelect(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [responseFile, setResponseFile] = useState(null);

  const validateImageDimensions = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(img.width >= 320 && img.height >= 320);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  function bytesToMB(bytes) {
    const MB = 1048576; // 1 MB = 1024 * 1024 bytes
    return bytes / MB; // Round to 2 decimal places
  }

  const uploadFiles = async (e, format, size = null, data) => {
    const maxFileSize = size ?? defaultFileSize[format];
    if (bytesToMB(e.target.files[0]?.size) > maxFileSize) {
      let url = '';
      setError(true);
      // const fileReader = new FileReader();
      // if (e.target.files[0].type.startsWith('image/')) {
      //   fileReader.onloadend = () => (url = fileReader.result);
      //   fileReader.readAsDataURL(e.target.files[0]);
      // } else {
      url = URL.createObjectURL(e.target.files[0]);
      // }
      setResponseFile(url);
      return url;
    }

    if (e.target.files[0]) {
      setError(false);
      setDimensionError(false);
      setSelectedFile(null);
      let convertedFile = '';
      if (e.target.files[0]?.name.includes('.jpeg')) {
        const name = `${e.target.files[0]?.name.split('.')[0]}.jpg`;
        convertedFile = new File(e.target.files, name, {
          type: 'image/jpeg',
        });
      } else {
        convertedFile = e.target.files[0];
      }

      setUploading(true);
      const responseFile = await uploadFile(convertedFile, data);
      if (responseFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setResponseFile(responseFile);
          setUploading(false);
          setSelectedFile(convertedFile);
          setSelectedFileFormat(format);
        };
        if (format === 'IMAGE') {
          const isValidDimension = await validateImageDimensions(convertedFile);
          setDimensionError(!isValidDimension);
        }
        reader.readAsDataURL(convertedFile);
        if (format === 'IMAGE')
          convertedFile.size >= maxFileSize * 1024 * 1024
            ? setError(true)
            : setError(false);

        if (format === 'VIDEO')
          convertedFile.size >= maxFileSize * 1024 * 1024
            ? setError(true)
            : setError(false);

        if (format === 'DOCUMENT')
          convertedFile.size >= maxFileSize * 1024 * 1024
            ? setError(true)
            : setError(false);

        return responseFile;
      }

      return null;
    } else {
      setUploading(false);
      return null;
    }
  };

  const handleDropFiles = async (
    e = null,
    acceptedFormats,
    format,
    size = null,
    data
  ) => {
    const maxFileSize = size ?? defaultFileSize[format];
    setError(false);
    setDimensionError(false);

    if (e?.length > 0) {
      setError(false);
      setDimensionError(false);

      setSelectedFile(null);
      let convertedFile = '';
      if (e?.[0]?.name?.includes('.jpeg')) {
        const name = `${e?.[0]?.name?.split('.')[0]}.jpg`;
        convertedFile = new File(e, name, {
          type: 'image/jpeg',
        });
      } else {
        convertedFile = e;
      }

      const extension = `.${e?.[0]?.name?.split('.')?.[1]}`;

      if (!acceptedFormats?.includes(extension)) {
        setError(true);
        return;
      } else {
        setError(false);
      }

      if (acceptedFormats?.includes(extension)) {
        setUploading(true);
        const responseFile = await uploadFile(convertedFile?.[0], data);
        if (responseFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setResponseFile(responseFile);
            setUploading(false);
            setSelectedFile(convertedFile?.[0]);
            setSelectedFileFormat(format);
          };

          if (format === 'IMAGE') {
            const isValidDimension = await validateImageDimensions(
              convertedFile[0]
            );
            setDimensionError(!isValidDimension);
          }
          reader.readAsDataURL(convertedFile?.[0]);
          if (format === 'IMAGE')
            convertedFile?.[0].size >= maxFileSize * 1024 * 1024
              ? setError(true)
              : setError(false);

          if (format === 'VIDEO')
            convertedFile?.[0].size >= maxFileSize * 1024 * 1024
              ? setError(true)
              : setError(false);

          if (format === 'DOCUMENT')
            convertedFile?.[0].size >= maxFileSize * 1024 * 1024
              ? setError(true)
              : setError(false);

          return responseFile;
        }
      }
      // else {
      //   setError(true);
      // }

      return null;
    } else {
      setUploading(false);
      return null;
    }
  };

  const clearFiles = () => {
    setSelectedFile(null);
    setResponseFile(null);
    setError(false);
    setUploading(false);
    setSelectedFileFormat(null);
    setDimensionError(false);
  };

  return {
    uploading,
    setUploading,
    selectedFile,
    selectedFileFormat,
    setSelectedFile,
    responseFile,
    uploadFiles,
    handleDropFiles,
    error,
    setError,
    clearFiles,
    setSelectedFileFormat,
    setResponseFile,
    dimensionError,
  };
}
