import { ICONS } from "assets/icons";

export const BroadcastHeaderList = [
  {
    id: 1,
    name: "Status",
    selected: true,
  },
  {
    id: 2,
    name: "Contacts",
    selected: true,
  },
  {
    id: 3,
    name: "Sent",
    selected: true,
  },
  {
    id: 4,
    name: "Delivered",
    selected: true,
  },
  {
    id: 5,
    name: "Opened",
    selected: true,
  },
  {
    id: 6,
    name: "Leads",
    selected: true,
  },
  {
    id: 7,
    name: "Bounced",
    selected: true,
  },
  {
    id: 8,
    name: "Approximate",
    selected: false,
  },
];

export const BroadcastList = [
  {
    id: 1,
    deal: "Release new dish promotion",
    RunType: "Broadcast",
    Contacts: "English",
    Sent: "15000",
    Delivered: "15000",
    Opened: "15000",
    Lead: "15000",
    Bounced: "15000",
  },
  {
    id: 2,
    deal: "Release new dish promotion",
    RunType: "Broadcast",
    Contacts: "15000",
    Sent: "15000",
    Delivered: "15000",
    Opened: "15000",
    Lead: "15000",
    Bounced: "15000",
    status: "Active",
  },
  {
    id: 3,
    deal: "Release new dish promotion",
    RunType: "Broadcast",
    Contacts: "15000",
    Sent: "15000",
    Delivered: "15000",
    Opened: "15000",
    Lead: "15000",
    Bounced: "15000",
  },
  {
    id: 4,
    deal: "Release new dish promotion",
    RunType: "Broadcast",
    Contacts: "15000",
    Sent: "15000",
    Delivered: "15000",
    Opened: "15000",
    Lead: "15000",
    Bounced: "15000",
  },
  {
    id: 5,
    deal: "Release new dish promotion",
    RunType: "Broadcast",
    Contacts: "15000",
    Sent: "15000",
    Delivered: "15000",
    Opened: "15000",
    Lead: "15000",
    Bounced: "15000",
    status: "Draft",
  },
];

export const Broadcaststatus = [
  {
    id: 1,
    status: "Running",
    icon: ICONS?.CampaignRunningIcon,
  },
  {
    id: 2,
    status: "Active",
    icon: ICONS?.CampaignActiveIcon,
  },
  {
    id: 3,
    status: "Paused",
    icon: ICONS?.CampaignPausedIcon,
  },
  {
    id: 4,
    status: "Completed",
    icon: ICONS?.CampaignCompleteIcon,
  },
  {
    id: 5,
    status: "Draft",
    icon: ICONS?.CampaignDraftIcon,
  },
];

export const BroadSettingList = [
  {
    id: 1,
    name: "Broadcast Settings",
    icon: ICONS?.BroadCastSettingIcon,
    icon1: ICONS?.CampaignDraftIcon,
  },

  {
    id: 2,
    name: "Define Audience",
    icon: ICONS?.BroadcastSegmentsIcon,
    icon1: ICONS?.CampaignDraftIcon,
  },
  {
    id: 3,
    name: "Broadcast Segments",
    icon: ICONS?.BroadcastMessageIcon,
    icon1: ICONS?.CampaignDraftIcon,
  },
  {
    id: 4,
    name: "Broadcast Message",
    icon: ICONS?.BroadcastReviewIcon,
    icon1: ICONS?.CampaignDraftIcon,
  },
  {
    id: 5,
    name: "Review and Activate",
    icon: ICONS?.BroadcastDefineIcon,
    icon1: ICONS?.CampaignDraftIcon,
  },
];

export const SendSettingList = [
  {
    id: 1,
    is_selected: false,
    name: "Send Now",
    content: "Messages will be delivered right after the broadcast starts.",
  },
  {
    id: 2,
    is_selected: false,
    name: "Send Later",
    content: "Messages will start to sent at the scheduled time period.",
  },
];

export const AudienceList = [
  {
    id: 1,
    is_selected: false,
    name: "Use as Individual File",
    content: "Use each files as a different audience segments.",
  },
  {
    id: 2,
    is_selected: false,
    name: "Merge Files",
    content: "Merge all files into single audience",
  },
];

export const DefineList = [
  {
    id: 1,
    name: "yes",
  },
  {
    id: 2,
    name: "no",
  },
];

export const TimerList = [
  {
    id: 1,
    name: "Minute(s)",
  },
  {
    id: 2,
    name: "Hour(s)",
  },
  {
    id: 3,
    name: "Day(s)",
  },
];

export const SegmentList = [
  {
    id: 1,
    name: "File",
    content: "CSV_sample.csv",
    profile_detail: "5000 Unique contacts",
    copy_detail: "589 Duplicates",
    invalid_detail: "189 Invalid",
  },

  {
    id: 2,
    name: "File",
    content: "CSV_sample.csv",
    profile_detail: "6000 Unique contacts",
    copy_detail: "189 Duplicates",
    invalid_detail: "89 Invalid",
  },
];

export const selectTemplateList = [
  {
    id: 1,
    Template_name: "exclusive_dinner_deal",
    category: "Marketing",
  },
  {
    id: 2,
    Template_name: "New customer",
    category: "Marketing",
  },
  {
    id: 3,
    Template_name: "Welcome to new customer",
    category: "Utility",
  },
  {
    id: 4,
    Template_name: "New customer",
    category: "Marketing",
  },
];
export const StatusList = [
  {
    id: 1,
    value: "{{first name}}",
  },
  {
    id: 2,
    value: "{{email}}",
  },
  {
    id: 3,
    value: "{{product}}",
  },
  {
    id: 4,
    value: "{{orders}}",
  },
  {
    id: 5,
    value: "{{Custome variable240}}",
  },
];

export const StatusInputList = [
  {
    id: 1,
    name: "Select match fields",
  },
  {
    id: 2,
    name: "Select match fields",
  },
  {
    id: 3,
    name: "Select match fields",
  },
  {
    id: 4,
    name: "Select match fields",
  },
  {
    id: 5,
    name: "Select match fields",
  },
];

export const posterlist = [
  {
    id: 1,
    icon: ICONS?.PublishedIcon,
    title: "Published",
    content: "13",
  },
  {
    id: 2,
    icon: ICONS?.ChannelScheduleIcon,
    title: "Scheduled",
    content: "13",
  },
  {
    id: 3,
    icon: ICONS?.RejectIcon,
    title: "Failed",
    content: "13",
  },
];


export const ReviewSettingList = [
  {
    id: 1,
    name: "Broadcast Name",
  },
  {
    id: 2,
    name: "Connected Channel",
  },
  {
    id: 3,
    name: "Send From",
  },
  {
    id: 4,
    name: "Send Time",
  },
  {
    id: 5,
    name: "Diwali offer sale",
  },
  {
    id: 6,
    name: "WhatsApp",
  },
  {
    id: 7,
    name: "VGN Homes - (91) 987 654 3210",
  },
  {
    id: 8,
    name: "Scheduled on Jan 23, 2024 at 10:30 AM",
  },
];

export const SegmentReviewList = [
  {
    id: 1,
    content: "Total File",
  },
  {
    id: 2,
    content: "Contacts",
  },
  {
    id: 3,
    content: "Segment Type",
  },
  {
    id: 4,
    content: "Total Segments",
  },

  {
    id: 5,
    content: "2000 Contacts",
  },
  {
    id: 6,
    content: "Merge File",
  },
  {
    id: 7,
    content: "2 Segments ",
  },
];

export const MessageList = [
  {
    id: 1,
    name: "Message Template",
  },
  {
    id: 2,
    name: "exclusive_dinner_deal",
  },
];

export const UploadList = [
  {
    id: 1,

    content: "CSV_sample.csv",
    profile_detail: "5000 Unique contacts",
    copy_detail: "589 Duplicates",
    invalid_detail: "189 Invalid",
  },

  {
    id: 2,

    content: "CSV_sample.csv",
    profile_detail: "6000 Unique contacts",
    copy_detail: "189 Duplicates",
    invalid_detail: "89 Invalid",
  },
];

export const BroadcastSegmentList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Broadcast Settings",
  },
  {
    id: 3,
    name: "Define Audience",
  },
  {
    id: 4,
    name: "Broadcast Segments",
  },
  {
    id: 5,
    name: "Broadcast Message",
  },
  {
    id: 6,
    name: "Contact Details",
  },
];

export const SegmentHeaderList = [
  {
    id: 1,
    name: "Audience",
    selected: true,
  },
  {
    id: 2,
    name: "Sent",
    selected: true,
  },
  {
    id: 3,
    name: "Delivered",
    selected: true,
  },
  {
    id: 4,
    name: "Opened",
    selected: true,
  },
  {
    id: 5,
    name: "Clicks",
    selected: true,
  },
  {
    id: 6,
    name: "Leads",
    selected: true,
  },
  {
    id: 7,
    name: "Bounced",
    selected: true,
  },
  // {
  //   id: 8,
  //   name: "Approximate Charges",
  //   selected: true,
  // },
];

export const SegmentDetailList = [
  {
    id: 1,
    deal: "Segment - 1",
    create: "Completed @",
    date: "Feb 23, 10:30am",
    Contacts: "10,000",
    Sent: "9,500",
    Delivered: "8,000",
    Opened: "7,200",
    Clicks: "7,200",
    Leads: "5,800",
    Bounced: "0",
    ApproximateCharges: "$378.34",
  },
  {
    id: 2,
    deal: "Segment - 2",
    create: "Running",
    Contacts: "10,000",
    Sent: "5,000",
    Delivered: "2,500",
    Opened: "1,175",
    Clicks: "1,175",
    Leads: "300",
    Bounced: "30",
    ApproximateCharges: "$78.34",
  },
  {
    id: 3,
    deal: "Segment - 3",
    create: "Starts @",
    date: "Mar 06, 10:30am",
    Contacts: "10,000",
    Sent: "-",
    Delivered: "-",
    Opened: "-",
    Clicks: "-",
    Leads: "-",
    Bounced: "-",
    ApproximateCharges: "-",
  },
  // {
  //   id: 4,
  //   deal: "Segment - 4",
  //   Contacts: "10,000",
  //   Sent: "-",
  //   Delivered: "-",
  //   Opened: "-",
  //   Clicks: "-",
  //   Leads: "-",
  //   Bounced: "-",
  //   ApproximateCharges: "-",
  // },
  // {
  //   id: 5,
  //   deal: "Segment - 5",
  //   Contacts: "10,000",
  //   Sent: "-",
  //   Delivered: "-",
  //   Opened: "-",
  //   Clicks: "-",
  //   Leads: "-",
  //   Bounced: "-",
  //   ApproximateCharges: "-",
  // },
];
export const inboxButtonList = [
  {
    id: 1,
    button: "Button1",
  },
  {
    id: 2,
    button: "Button2",
  },
  {
    id: 3,
    button: "Button3",
  },
  {
    id: 4,
    button: "Button4",
  },
  {
    id: 5,
    button: "Button5",
  },
  {
    id: 6,
    button: "Button6",
  },
];

export const timer = [
  {
    id: 1,
    time: "05  27  AM GMT",
  },
  {
    id: 2,
    time: "06: 28  PM IST",
  },
  {
    id: 3,
    time: "07  29     UTC",
  },
];

export const PhoneNumberList = [
  {
    value: "IN +91",
    label: "India",
  },
  {
    value: "BR  +55",
    label: "India",
  },
  {
    value: "GB +44",
    label: "India",
  },
  {
    value: "CA +1",
    label: "India",
  },
  {
    value: "US +1",
    label: "India",
  },
];
export const AudienceHeaderList = [
  {
    id: 1,
    // name: "Audience list",
    Contacts: "10,000",
    Sent: "9,500",
    Delivered: "8,000",
    Opened: "7,200",
    Clicks: "7,200",
    Leads: "5,800",
    Bounced: "0",
    ApproximateCharges: "$378.34",
    segment: [
      {
        id: 1,
        deal: "Segment - 1",
        create: "Completed @",
        date: "Feb 23, 10:30am",
        Contacts: "10,000",
        Sent: "9,500",
        Delivered: "8,000",
        Opened: "7,200",
        Clicks: "7,200",
        Leads: "5,800",
        Bounced: "0",
        ApproximateCharges: "$378.34",
      },
      {
        id: 2,
        deal: "Segment - 2",
        create: "Running",
        Contacts: "10,000",
        Sent: "5,000",
        Delivered: "2,500",
        Opened: "1,175",
        Clicks: "1,175",
        Leads: "300",
        Bounced: "30",
        ApproximateCharges: "$78.34",
      },
      {
        id: 3,
        deal: "Segment - 3",
        create: "Starts @",
        date: "Mar 06, 10:30am",
        Contacts: "10,000",
        Sent: "-",
        Delivered: "-",
        Opened: "-",
        Clicks: "-",
        Leads: "-",
        Bounced: "-",
        ApproximateCharges: "-",
      },
    ],
  },

  {
    id: 2,

    Contacts: "10,000",
    Sent: "-",
    Delivered: "-",
    Opened: "-",
    Clicks: "-",
    Leads: "-",
    Bounced: "-",
    ApproximateCharges: "-",
    segment: [
      {
        id: 1,
        deal: "Segment - 1",
        create: "Starts @",
        date: "Mar 06, 10:30am",
        Contacts: "10,000",
        Sent: "9,500",
        Delivered: "8,000",
        Opened: "7,200",
        Clicks: "7,200",
        Leads: "5,800",
        Bounced: "0",
        ApproximateCharges: "$378.34",
      },
      {
        id: 2,
        deal: "Segment - 2",
        create: "Starts @",
        date: "Mar 06, 10:30am",
        Contacts: "10,000",
        Sent: "-",
        Delivered: "-",
        Opened: "-",
        Clicks: "-",
        Leads: "-",
        Bounced: "-",
        ApproximateCharges: "-",
      },
      {
        id: 3,
        deal: "Segment - 3",
        create: "Starts @",
        date: "Mar 06, 10:30am",
        Contacts: "10,000",
        Sent: "-",
        Delivered: "-",
        Opened: "-",
        Clicks: "-",
        Leads: "-",
        Bounced: "-",
        ApproximateCharges: "-",
      },
    ],
  },
];
