import React from 'react';
import './HorizontalSteps.css';

import { useAspSelector } from '../../../test/jest-redux-hooks';
import { ICONS } from 'assets/icons';

const defaultColor = {
  color: '#898e99',
};
const activeColor = {
  color: 'var(--new-primary)',
  fontWeight: '600',
};

function HorizontalSteps({ data }) {
  return (
    <div className='w-100 flex  items-center justify-center my-6'>
      <span
        className={
          data?.step > 0
            ? 'dot-completed flex-row align-center justify-center'
            : 'dot-active'
        }
      >
        {data?.step > 0 ? (
          <img src={ICONS?.TickGreenIcon} className='w-2.5' />
        ) : null}
        <span className='stepName' style={activeColor}>
          Step-1
        </span>
      </span>
      <span className={data?.step > 0 ? 'hr-line-active' : 'hr-line'} />
      <span
        className={
          data?.step > 1
            ? 'dot-completed flex-row align-center justify-center'
            : data?.step > 0
            ? 'dot-active'
            : 'dot'
        }
      >
        {data?.step > 1 ? (
          <img src={ICONS?.TickGreenIcon} className='w-2.5' />
        ) : null}
        <span>
          <span
            className='stepName'
            style={data?.step > 0 ? activeColor : defaultColor}
          >
            Step-2
          </span>
        </span>
      </span>
      <span className={data?.step > 1 ? 'hr-line-active' : 'hr-line'} />
      <span className={data?.step > 1 ? 'dot-active' : 'dot'}>
        <span
          className='stepName'
          style={data?.step > 2 ? activeColor : defaultColor}
        >
          Step-3
        </span>
      </span>
    </div>
  );
}

export default HorizontalSteps;
