import React, { useEffect, useReducer, useState } from "react";

// hooks
import useToggle from "../../../hooks/useToggle";
import {
  INITIAL_STATE,
  signupReducer,
} from "../../../hooks/auth/signupReducer";

// components
import Step1 from "../components/signUp/Step1";
import Step2 from "../components/signUp/Step2";
import Step3 from "../components/signUp/Step3";
import { useSearchParams } from "react-router-dom";
import { textsOnly, textsOnlyNoSpaces, validateEmail } from "helper/validation";

export default function SignUp() {
  const [data, dispatch] = useReducer(signupReducer, INITIAL_STATE);

  const [showOnUnloadPopup, setshowOnUnloadPopup] = useToggle(false);
  const [queryParams] = useSearchParams();
  const [errorMail, setErrorMail] = useState(false);
  const [toastError, setToastError] = useState("");
  const [resendError, setResendError] = useState("");

  const token = queryParams.get("token");

  useEffect(() => {
    if (!token) localStorage.clear();
  }, []);

  useEffect(() => {
    if ((data?.step1 && !showOnUnloadPopup) || !data?.step1) return;
    else {
      const handleonBeforeUnload = (e) => {
        e.preventDefault();
        return (e.returnValue = "");
      };

      window.addEventListener("beforeunload", handleonBeforeUnload, {
        capture: true,
      });

      return () => {
        window.removeEventListener("beforeunload", handleonBeforeUnload, {
          capture: true,
        });
      };
    }
  }, [data, showOnUnloadPopup]);

  useEffect(() => {
    checkValueHasChanged();
  }, [data]);

  const checkValueHasChanged = () => {
    const datas = JSON.stringify(INITIAL_STATE) !== JSON.stringify(data);
    setshowOnUnloadPopup(datas);
  };

  const onChange = (type, key, value, token) => {
    setToastError("");
    setResendError("");

    if (key === "mail") {
      if (value === null || value === "") {
        setErrorMail(false);
      } else if (!validateEmail(value)) {
        setErrorMail(true);
      } else {
        setErrorMail(false);
      }
    }

    if (key === "firstname") {
      value = textsOnly(value.trimStart());
    }

    if (key === "lastname") {
      value = textsOnlyNoSpaces(value.trimStart());
    }

    let datas = { type: type };

    if (key) datas["key"] = key;
    if (value) datas["value"] = value ? value : "";
    if (token) datas["token"] = token;
    dispatch(datas);
  };

  return (
    <div className="w-full">
      {!data?.step1 ? (
        <Step1
          data={data}
          onChange={onChange}
          token={token}
          errorMail={errorMail}
          setToastError={setToastError}
          toastError={toastError}
        />
      ) : !data?.step2 ? (
        <Step2
          data={data}
          onChange={onChange}
          token={token}
          setResendError={setResendError}
          resendError={resendError}
        />
      ) : (
        <Step3 data={data} onChange={onChange} token={token} />
      )}
    </div>
  );
}
