import AspApi from "api/ApiConfig";

export const accountList = async (params) => {
  let qpm = [];
  if (params?.brand_id) qpm.push(`brand_id=${params?.brand_id}`);
  if (params?.search) qpm.push(`search=${params?.search}`);

  let qpmString = "";
  if (qpm.length > 0) qpmString = `?${qpm.join("&")}`;

  const res = await AspApi.get(`api/v1/account/accounts_list${qpmString}`);
  return res;
};

export const singleAccount = async (params) => {
  let qpm = [];
  if (params.invited_members)
    qpm.push(`invited_members=${params.invited_members}`);

  let qpmString = "";
  if (qpm.length > 0) qpmString = `?${qpm.join("&")}`;

  const res = await AspApi.get(`api/v1/account/${params.acc_id}${qpmString}`);
  return res;
};

export const singleAccountupdate = async (params, payload) => {
  const res = await AspApi.patch(`api/v1/account/${params.acc_id}`, payload);
  return res;
};

export const brandList = async (params) => {
  let qpm = [];
  if (params.search) qpm.push(`search=${params.search}`);

  let qpmString = "";
  if (qpm.length > 0) qpmString = `?${qpm.join("&")}`;
  const res = await AspApi.get(
    `api/v1/account/${params.acc_id}/brands${qpmString}`
  );
  return res;
};

export const brandListOverview = async (params) => {
  let qpm = [];
  if (params?.account_id) qpm.push(`account_id=${params?.account_id}`);
  if (params?.search) qpm.push(`search=${params?.search}`);
  if (params?.status) qpm.push(`status=${params?.status}`);
  if (params?.page) qpm.push(`page=${params?.page}`);
  if (params?.limit) qpm.push(`limit=${params?.limit}`);

  let qpmString = "";
  if (qpm.length > 0) qpmString = `?${qpm.join("&")}`;

  const res = await AspApi.get(`api/v1/brands${qpmString}`);
  return res;
};

export const singleBrand = async (params) => {
  const res = await AspApi.get(`api/v1/account/${params.acc_id}`);
  return res;
};

export const inviteMembersToBrand = async (params) => {
  const res = await AspApi.get(`api/v1/account/${params.acc_id}`);
  return res;
};

export const accountUsersListApi = async (accountId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.search) qpm.push(`search=${query?.search}`);
  if (query?.role_ids) qpm.push(`role_ids=${query?.role_ids}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/account_center/account_users_list?account_id=${accountId}${qpmString}`
  );
  return res;
};

export const reAssignRole = async (payload) => {
  const res = await AspApi.post(
    `/api/v1/account_center/reassign_user_role`,
    payload
  );
  return res;
};

export const reAssignBrandRole = async (brand_id, payload) => {
  const res = await AspApi.post(
    `/api/v1/brands/${brand_id}/reassign_user_role`,
    payload
  );
  return res;
};

export const removeUser = async (id, accountUserId, payload) => {
  const res = await AspApi.delete(
    `/api/v1/account_center/remove_user?account_id=${id}&accounts_user_id=${accountUserId}`,
    payload
  );
  return res;
};

export const activeSuspendUser = async (payload) => {
  const res = await AspApi.post(
    `/api/v1/account_center/update_user_status`,
    payload
  );
  return res;
};

export const cancelInvitation = async (id, accountUserId, payload) => {
  const res = await AspApi.delete(
    `/api/v1/account_center/cancel_invitation?account_id=${id}&accounts_user_id=${accountUserId}`,
    payload
  );
  return res;
};

// Brand Info

export const brandsInfoApi = async (brand_id) => {
  const res = await AspApi.get(`api/v1/brands/info?brand_id=${brand_id}`);
  return res;
};

export const brandUserEditApi = async (brand_id, payload) => {
  const res = await AspApi.put(`/api/v1/brands/${brand_id}`, payload);
  return res;
};

export const brandUsersListApi = async (brand_id, account_id, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.search) qpm.push(`search=${query?.search}`);
  if (query?.role_ids) qpm.push(`role_ids=${query?.role_ids}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/brands/${brand_id}/assigned_brand_users_list?account_id=${account_id}${qpmString}`
  );
  return res;
};

export const brandAssignRoleListApi = async (account_id, brand_id, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.search) qpm.push(`search=${query?.search}`);
  if (query?.role_ids) qpm.push(`role_ids=${query?.role_ids}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/account_center/account_users_list?account_id=${account_id}&brand_id=${brand_id}${qpmString}`
  );
  return res;
};

export const removeBrandUser = async (
  brand_id,
  account_id,
  brand_user_id,
  payload
) => {
  const res = await AspApi.delete(
    `/api/v1/brands/${brand_id}/remove_user?account_id=${account_id}&brand_user_id=${brand_user_id}`,
    payload
  );
  return res;
};

// CREDITS

export const creditsSendApi = async (payload) => {
  const res = await AspApi.post(`/api/v1/transactions/create_items`, payload);
  return res;
};

export const creditsCancelApi = async (payload) => {
  const res = await AspApi.delete(`/api/v1/transactions/cancel_items`, {
    data: payload,
  });
  return res;
};

export const transactionsApi = async (payload) => {
  const res = await AspApi.post(`/api/v1/transactions/create_order`, payload);
  return res;
};

export const transactionsResponseApi = async (payload) => {
  const res = await AspApi.post(
    `/api/v1/transactions/success_transaction`,
    payload
  );
  return res;
};

export const brandCreditsReportApi = async (brand_id, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.start_date) qpm.push(`start_date=${query?.start_date}`);
  if (query?.end_date) qpm.push(`end_date=${query?.end_date}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/transactions/credit_usage_report?brand_id=${brand_id}${qpmString}`
  );
  return res;
};

export const brandPaymentApi = async (brand_id, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.year) qpm.push(`year=${query?.year}`);
  if (query?.type) qpm.push(`type=${query?.type}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/transactions/payment_history?brand_id=${brand_id}${qpmString}`
  );
  return res;
};

export const paymentPreviewApi = async (transaction_slug, brand_id) => {
  const res = await AspApi.get(
    `api/v1/transactions/${transaction_slug}?brand_id=${brand_id}`
  );
  return res;
};

// invoices

export const invoicesListApi = async (brand_id, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.year) qpm.push(`year=${query?.year}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/brand_invoices?brand_id=${brand_id}${qpmString}`
  );
  return res;
};

export const invoicePreviewApi = async (brand_invoice_slug, brand_id) => {
  const res = await AspApi.get(
    `api/v1/brand_invoices/${brand_invoice_slug}?brand_id=${brand_id}`
  );
  return res;
};

// setup status

export const setupApi = async (brand_id) => {
  const res = await AspApi.get(`api/v1/brands/${brand_id}/brand_setup_status`);
  return res;
};
