import React from "react";

const Other = ({ color }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 22.25C6.33908 22.25 1.75 17.661 1.75 12C1.75 6.33908 6.33908 1.75 12 1.75C17.661 1.75 22.25 6.33908 22.25 12C22.25 17.661 17.661 22.25 12 22.25ZM11.9963 10.8081C11.338 10.8081 10.8044 11.3417 10.8044 12C10.8044 12.6583 11.338 13.1919 11.9963 13.1919H12.0049C12.6631 13.1919 13.1967 12.6583 13.1967 12C13.1967 11.3417 12.6631 10.8081 12.0049 10.8081H11.9963ZM14.6221 12C14.6221 11.3417 15.1557 10.8081 15.814 10.8081H15.8225C16.4807 10.8081 17.0144 11.3417 17.0144 12C17.0144 12.6583 16.4807 13.1919 15.8225 13.1919H15.814C15.1557 13.1919 14.6221 12.6583 14.6221 12ZM8.18605 10.8081C7.5278 10.8081 6.99419 11.3417 6.99419 12C6.99419 12.6583 7.5278 13.1919 8.18605 13.1919H8.19461C8.85286 13.1919 9.38647 12.6583 9.38647 12C9.38647 11.3417 8.85286 10.8081 8.19461 10.8081H8.18605Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Other;
