import React from "react";

const Teams = ({ color, fillColors }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_22338_33242)">
          <path
            d="M17.3117 15C17.9361 15 18.4327 14.6071 18.8787 14.0576C19.7916 12.9328 18.2927 12.034 17.7211 11.5938C17.14 11.1463 16.4912 10.8928 15.8333 10.8333M15 9.16667C16.1506 9.16667 17.0833 8.23392 17.0833 7.08333C17.0833 5.93274 16.1506 5 15 5"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
          />
          <path
            d="M2.68847 15C2.06404 15 1.56739 14.6071 1.12145 14.0576C0.208572 12.9328 1.70739 12.034 2.27903 11.5938C2.86014 11.1463 3.50898 10.8928 4.16683 10.8333M4.5835 9.16667C3.43291 9.16667 2.50017 8.23392 2.50017 7.08333C2.50017 5.93274 3.43291 5 4.5835 5"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
          />
          <path
            d="M6.73642 12.5926C5.88494 13.1191 3.6524 14.1942 5.01217 15.5395C5.6764 16.1966 6.41619 16.6666 7.34627 16.6666H12.6536C13.5837 16.6666 14.3234 16.1966 14.9877 15.5395C16.3474 14.1942 14.1149 13.1191 13.2634 12.5926C11.2667 11.358 8.7331 11.358 6.73642 12.5926Z"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill={fillColors}
          />
          <path
            d="M12.9168 6.25004C12.9168 7.86087 11.611 9.16671 10.0002 9.16671C8.38935 9.16671 7.0835 7.86087 7.0835 6.25004C7.0835 4.63921 8.38935 3.33337 10.0002 3.33337C11.611 3.33337 12.9168 4.63921 12.9168 6.25004Z"
            stroke={color}
            stroke-width="1.67"
            fill={fillColors}
          />
        </g>
        <defs>
          <clipPath id="clip0_22338_33242">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Teams;
