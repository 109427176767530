import React from "react";

const Status = ({ status, style = {} }) => {
  if ([2, 3, 4, 5].includes(status)) {
    return (
      <span
        style={{
          ...style,
        }}
        className="py-1  rounded-[5px] align-center justify-center flex-row align-center text-center text-[#566F8F] bg-[#EDEFF1]"
      >
        Onboarding
      </span>
    );
  }

  switch (status) {
    case 10:
    case "ACTIVE":
      return (
        <span
          style={{
            ...style,
          }}
          className="w-20 px-7 py-0.5 rounded-[5px] flex-row align-center justify-center text-center text-[var(--primary)] bg-[#25C27729]"
        >
          Active
        </span>
      );
    case "INACTIVE":
    case 9:
      return (
        <span
          style={{
            ...style,
          }}
          className="w-40 py-0.5 rounded-[5px] flex-row align-center justify-center align-center text-center text-[#FF543E] bg-[#FF543E29]"
        >
          In-active
        </span>
      );
    default:
      return "--";
  }
};

// const Status = ({ status, style = {} }) => {
//   switch (status) {
//     case 10:
//     case "ACTIVE":
//       return (
//         <span
//           style={{
//             ...style,
//           }}
//           className="w-20 px-7 py-0.5 rounded-[5px] flex-row align-center justify-center text-center text-[var(--primary)] bg-[#25C27729]"
//         >
//           Active
//         </span>
//       );
//     case "INACTIVE":
//     case 9:
//       return (
//         <span
//           style={{
//             ...style,
//           }}
//           className="w-40 py-0.5  rounded-[5px] flex-row align-center justify-center align-center text-center text-[#FF543E] bg-[#FF543E29]"
//         >
//           In-active
//         </span>
//       );
//     case [2, 4, 5]:
//       return (
//         <span
//           style={{
//             ...style,
//           }}
//           className="py-1  rounded-[5px] align-center justify-center flex-row align-center text-center text-[#566F8F] bg-[#EDEFF1]"
//         >
//           Onboarding
//         </span>
//       );

//     default:
//       return "--";
//   }
// };

export default Status;
