import React, { useCallback, useState } from "react";

// assets
import Business from "../../../../assets/customSVG/Business";
import { ICONS } from "../../../../assets/icons/index";

// API
import { CreateAccount, WhatsappOTPSent } from "../../api/OnBoarding";

// redux
import {
  UpdateOnboardingAccountId,
  updateToggleToast,
} from "../../../../reduxToolkit/appSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";

// helper
import { cn } from "../../../../helper/cn";
import { numberOnly } from "../../../../helper/validation";

// hooks
import useTimerCount from "hooks/useTimerCount";
import { useFileUploader } from "../../../../hooks/useFileUploader";

// components
import OtpInput from "components/commonComponents/otp";
import { useLocation } from "react-router-dom";
import { ICONSS } from "../../../../assets/icons/index1";
import Loader from "../../../../components/commonComponents/Loader/Index";
import Select from "../../../../components/commonComponents/select/index";
import useToggle from "../../../../hooks/useToggle";

export const accountType = [
  {
    id: 1,
    icon1: Business,
    label: "Business Entity",
    // label: (
    //   <div className="flex items-center gap-3">
    //     <Business width={20} height={20} />
    //     <p>Business Entity</p>
    //   </div>
    // ),
    value: "Business Entity",
    key: "Business",
    account_type_id: 2,
  },
  // {
  //   id: 2,
  //   icon1: Campaigns,
  //   label: "Marketing Agency",
  //   // label: (
  //   //   <div className="flex items-center gap-3">
  //   //     <Campaigns width={20} height={20} />
  //   //     <p>Marketing Agency</p>
  //   //   </div>
  //   // ),
  //   value: "Marketing Agency",
  //   key: "Agency",
  //   account_type_id: 1,
  // },
  // {
  //   id: 3,
  //   // label: (
  //   //   <div className="flex items-center gap-3">
  //   //     <Influencer width={20} height={20} />
  //   //     <p>Content Creator</p>
  //   //   </div>
  //   // ),
  //   icon1: Influencer,
  //   label: "Content Creator",
  //   value: "Content Creator",
  //   key: "Creator",
  //   account_type_id: 3,
  // },
];

export const accountTypevalues = {
  "Business Entity": "Business",
  "Marketing Agency": "Agency",
  "Content Creator": "Creator",
};

const countryList = [
  {
    label: "India",
    value: "+91",
    shortName: "IN",
  },
  // {
  //   label: 'United States',
  //   value: '+1',
  //   shortName: 'US',
  // },
  // {
  //   label: 'Japan',
  //   value: '+81',
  //   shortName: 'JP',
  // },
  // {
  //   label: 'Brazil',
  //   value: '+55',
  //   shortName: 'BR',
  // },
  // {
  //   label: 'Russia',
  //   value: '+7',
  //   shortName: 'RU',
  // },
  // {
  //   label: 'Canada',
  //   value: '+1',
  //   shortName: 'CA',
  // },
  // {
  //   label: 'Israel',
  //   value: '+972',
  //   shortName: 'IL',
  // },
];

const membersCount = [
  {
    id: 1,
    label: "Only Me",
    value: "Only Me",
    key: "my_self",
  },
  {
    id: 2,
    label: "2-10",
    value: "2-10",
    key: "2-10",
  },
  {
    id: 4,
    label: "11-25",
    value: "11-25",
    key: "11-25",
  },
  {
    id: 5,
    label: "26-50",
    value: "26-50",
    key: "26-50",
  },
  {
    id: 6,
    label: "50+",
    value: "50+",
    key: "50+",
  },
];

export const aboutUs = [
  {
    id: 1,
    icon: ICONS?.whatsApp,
    label: "Whatsapp",
    value: "Whatsapp",
    key: "Whatsapp",
  },
  {
    id: 2,
    icon: ICONS?.crmFaceBook,
    label: "Facebook",
    value: "Facebook",
    key: "facebook",
  },
  {
    id: 3,
    icon: ICONS?.crmInstagram,
    label: "Instagram",
    value: "Instagram",
    key: "instagram",
  },
  {
    id: 4,
    icon: ICONS?.crmLinkedIn,
    label: "Linked In",
    value: "Linked In",
    key: "linked_In",
  },
  {
    id: 5,
    icon: ICONSS?.website,
    label: "Website",
    value: "Website",
    key: "website",
  },
  {
    id: 6,
    icon: ICONSS?.friend_user,
    label: "Friend referal",
    value: "Facebook",
    key: "Facebook",
  },
];

const otpLength = 6;
const minutes = 2;
const secounds = 0;

export default function AccountCreation({
  data,
  onChange,
  isLoading,
  setIsLoading,
  className,
  otpError,
  setOtpError,
  setLoading,
  loading,
}) {
  const { toggleToast, acc_id } = useAspSelector((state) => state.app);
  const [countryCode, setCountryCode] = useState("IN (+91)");
  const [clearOtp, setClearOtp] = useState(false);
  const [toastError, setToastError] = useToggle(false);
  const location = useLocation();

  const dispatch = useAspDispatch();

  const {
    uploading,
    selectedFile,
    responseFile,
    uploadFiles,
    error,
    dimensionError,
    clearFiles,
  } = useFileUploader();

  const [
    remainingMinutes,
    remainingSeconds,
    setRemainingMinutes,
    setRemainingSeconds,
  ] = useTimerCount(0, 0);

  // styles
  const card = "w-full flex flex-col gap-1 mt-4";
  const label = "font14px-500 leading-6 text-[var(--font-600)]";
  const inputContainer = `w-full py-2 flex items-center border-[var(--border-input)] gap-2 border  b-radius-6`;
  const inputContainer1 = `w-full py-2 flex items-center gap-2 border  b-radius-6 ${
    data?.name?.endsWith(".") ||
    data?.name?.endsWith("-") ||
    data?.name?.endsWith("'")
      ? "border-red-500"
      : "border-[var(--border-input)]"
  }`;
  const input = "w-full h-full text-[20vw] font14px-400 px-3 rounded-lg";

  const validation = useCallback(() => {
    return (
      data?.name &&
      !data?.name?.endsWith(".") &&
      !data?.name?.endsWith("'") &&
      !data?.name?.endsWith("-") &&
      // data?.type &&
      data?.code &&
      data?.phone_number &&
      data?.otp?.length === otpLength &&
      data?.isOTP &&
      !error &&
      !dimensionError &&
      !otpError
    );
  }, [data, error, dimensionError, otpError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      setIsLoading(true);
      const datas = {
        acc_name: data?.name,
        // acc_type: accountTypevalues[data?.type],
        acc_type: "Business",
        country_alpha_code: data?.code,
        phone_number: `${data?.phone_number}`,
        phone_country_code: countryCode,
        otp: data?.otp,
        total_members: data?.totalMembers,
        referral_source: data?.knowAbout,
      };
      if (data?.image) datas["acc_img_url"] = data?.image;
      const res = await CreateAccount(datas);
      if (res?.status === 200) {
        if (data?.totalMembers === "Only Me") {
          onChange("update_brand", null, null, res?.data?.account);
        } else {
          onChange("update_account", null, null, res?.data?.account);
        }
        dispatch(
          UpdateOnboardingAccountId({
            acc_id: res?.data?.account?.id,
          })
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setOtpError(res?.response?.data?.error);
      }
    }
  };

  const sendOTP = async () => {
    setLoading(true);
    if (data?.phone_number && data?.phone_number?.length === 10) {
      const res = await WhatsappOTPSent({
        phone_number: [`${data?.phone_number}`],
        phone_country_code: countryCode,
      });

      if (res?.status === 200) {
        setLoading(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Check your Whatsapp for OTP",
              status: "Success",
              duration: "",
            },
          ])
        );
        onChange("update", "account", "isOTP", true);
        setRemainingMinutes(minutes);
        setRemainingSeconds(secounds);
      }
    } else {
      // setOtpError(res?.response?.data?.error);
    }
  };

  return (
    <div
      className={cn(
        `w-full  flex flex-col items-center overflow-y-scroll ${
          location.pathname.startsWith("/profile")
            ? "w-100 pl-20 pr-20 max-h-[52vh]"
            : " max-w-[480px] min-h-[400px]"
        }`,
        className
      )}
    >
      <div
        className={cn(
          "w-full flex items-center justify-between px-4 py-3 mt-6 bg-slate-50 rounded-[10px] border",
          error || dimensionError
            ? "border-red-400 animate-shake"
            : "border-none"
        )}
      >
        <div className="flex-row align-center">
          <div className="rounded-[10px] size-12 bg-slate-100">
            {!responseFile && (
              <div className="p-[14px]">
                <img
                  src={ICONS.uploadStep}
                  alt="UploadStep"
                  className="upload-image-before"
                />
              </div>
            )}

            {selectedFile && (
              <div className="relative">
                <img
                  src={responseFile}
                  alt="SelectedImage"
                  className="uploadStep-image"
                />
                <img
                  className="absolute pointer -top-2 -right-2 size-4"
                  src={ICONS.Exit}
                  alt="popup"
                  onClick={() => {
                    clearFiles();
                    onChange("update", "account", "image", null);
                  }}
                />
              </div>
            )}
          </div>
          <div className="upload-content">
            <p className="account-image">Account Image</p>
            <p className="recommended-file">
              {selectedFile ? (
                <p className="truncate max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {selectedFile?.name}
                </p>
              ) : (
                <p>Recommended file 320*320 px, less than 1MB.</p>
              )}
            </p>
          </div>
        </div>

        <div>
          <button className="">
            <input
              type="file"
              // onChange={async (e) => {
              //   const res = await uploadFiles(e, "IMAGE", 1);
              //   if (res) {
              //     onChange("update", "account", "image", res);
              //   }
              // }}
              onChange={async (e) => {
                const fileInput = e.target;
                if (fileInput.files.length > 0) {
                  const res = await uploadFiles(e, "IMAGE", 1);
                  if (res) {
                    onChange("update", "account", "image", res);
                  }
                  fileInput.value = "";
                }
              }}
              accept=".jpg, .jpeg, .png"
              id="uploadImg"
              hidden
            />
            <label
              for="uploadImg"
              className="w-full flex-center cursor-pointer"
            >
              <div
                className={cn(
                  "min-w-[100px] text-sm weight-semibold text-[var(--background)] bg-[var(--new-primary)] flex items-center justify-center rounded-md px-4 py-1.5 tracking-wide"
                )}
              >
                {uploading ? (
                  <Loader Width={20} Height={20} loaderBg="white" />
                ) : (
                  <p>Upload</p>
                )}
              </div>
            </label>
          </button>
        </div>
      </div>
      {error && (
        <div className="w-full text-left text-xs mt-1 px-1 text-red-400">
          File Should be less than 1MB.
        </div>
      )}
      {dimensionError && (
        <div className="w-full text-left text-xs mt-1 px-1 text-red-400">
          Recommended file should be greater than 320*320 px
        </div>
      )}
      <form className="w-full" onSubmit={handleSubmit}>
        <div className={cn(card)}>
          <label htmlFor="name" className={cn(label)}>
            Account Name<sup className="text-red-500">*</sup>
          </label>
          <div className={cn(inputContainer1)}>
            <input
              name="name"
              id="name"
              type="text"
              placeholder="ex:- Company org"
              className={cn(input)}
              value={data?.name}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange("update", "account", name, value);
              }}
              maxLength={64}
              autoComplete="off"
            />
            <span className="font14px-400 text-[var(--font-400)] pr-10">
              {data?.name?.length || 0}/64
            </span>
          </div>
        </div>
        <div className={cn(card)}>
          <label htmlFor="type" className={cn(label)}>
            Account Type<sup className="text-red-500">*</sup>
          </label>
          {/* <Select
            value={data?.type}
            // options={accountType}
            onChange={({ value }) => {
              if (value) onChange("update", "account", "type", value);
            }}
            placeholder="-- select option --"
            rootClassName="w-full h-10 px-3 py-2.5 "
            position="bottom"
          /> */}
          <div className={cn(inputContainer, "cursor-not-allowed")}>
            <span className={cn(input)}>Business Entity</span>
            <img
              src={ICONS?.modelDropdownIcon}
              alt="dropDownIcon"
              className="w-2.5 mr-10"
            />
          </div>
        </div>
        <div className={cn(card)}>
          <label htmlFor="type" className={cn(label)}>
            How many members are there in your team?
          </label>
          <Select
            value={data?.totalMembers}
            options={membersCount}
            onChange={({ value }) => {
              if (value) onChange("update", "account", "totalMembers", value);
            }}
            placeholder="-- select option --"
            rootClassName="w-full h-10 px-3 py-2.5"
            position="bottom"
          />
        </div>
        <div className={cn(card)}>
          <label htmlFor="type" className={cn(label)}>
            How did you find out about us?
          </label>
          <Select
            value={data?.knowAbout}
            options={aboutUs}
            onChange={({ value }) => {
              if (value) onChange("update", "account", "knowAbout", value);
            }}
            placeholder="-- select option --"
            rootClassName="w-full h-10 px-3 py-2.5"
            position="bottom"
            optionClick="option-click"
          />
        </div>
        <div className={cn(card)}>
          <label htmlFor="type" className={cn(label, "")}>
            Phone Number<sup className="text-red-500">*</sup>
          </label>
          <div className="flex items-center gap-5 justify-between">
            <div className={cn(inputContainer)}>
              <div className="w-[7vw] border-r-[1px] pl-10">
                {/* <CountryCode
                  // list={countryList}
                  value={countryCode}
                  className="!min-h-[0vh] !min-w-[7vw] !mr-5 border-none py-0"
                  // onChange={(val) => {
                  //   if (val) {
                  //     onChange("update", "account", "code", val?.shortName);
                  //     setCountryCode(`${val?.shortName} (${val?.value})`);
                  //   }
                  // }}
                /> */}
                <span className="font14px-400 text-[var(--textBlack)]">
                  {countryCode}
                </span>
              </div>
              <input
                name="phone_number"
                id="phone_number"
                type="text"
                placeholder="EX:- 0000 000 000"
                className={cn(
                  input,
                  data?.isOTP ? "cursor-not-allowed" : "cursor-text"
                )}
                value={data?.phone_number}
                onChange={(e) => {
                  const { name, value } = e.target;
                  const updatedValue = numberOnly(value);
                  onChange("update", "account", name, updatedValue);
                }}
                // disabled={data?.isOTP}
                maxLength={10}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-2 flex items-center justify-between">
          {data?.isOTP && (
            <div
              className="text-[#6940F2] font14px-600 cursor-pointer"
              onClick={() => {
                onChange("update_number", null, null, null);
              }}
            >
              Change Number
            </div>
          )}
          <div className="w-full flex flex-1 items-center justify-end text-sm weight-semibold gap-4">
            {data?.isOTP && (
              <p className="text-[var(--new-primary)]">
                {remainingMinutes?.toString().padStart(2, "0")}:
                {remainingSeconds?.toString().padStart(2, "0")}
              </p>
            )}
            {data?.isOTP ? (
              <p
                className={`${
                  remainingMinutes === 0 && remainingSeconds === 0
                    ? "text-[#2D3036] cursor-pointer"
                    : "text-[var(--font-600)] cursor-not-allowed"
                }`}
                onClick={async () => {
                  if (remainingMinutes === 0 && remainingSeconds === 0) {
                    // if (data?.phone_number) {
                    //   const res = await WhatsappOTPSent({
                    //     phone_number: [`${data?.phone_number}`],
                    //     phone_country_code: countryCode,
                    //   });
                    //   if (res?.status === 200) {
                    //     setRemainingMinutes(minutes);
                    //     setRemainingSeconds(secounds);
                    //   }
                    // }
                    sendOTP();
                    setClearOtp(true);
                    setOtpError("");
                  }
                }}
              >
                Resend code?
              </p>
            ) : (
              <p
                className={cn(
                  "font16px-600",
                  data?.phone_number?.length >= 10
                    ? "text-[var(--new-primary)] cursor-pointer"
                    : "text-[var(--font-400)] cursor-not-allowed"
                )}
                onClick={() => {
                  sendOTP();
                }}
              >
                {loading ? (
                  <Loader Width={20} Height={20} loaderBg="white" />
                ) : (
                  <>Get OTP</>
                )}
              </p>
            )}
          </div>
        </div>
        <div className="mt-6">
          <p
            className={cn(
              "font14px-500",
              data?.isOTP
                ? "text-[var(--contentTexts)]"
                : "text-[var(--font-600)]"
            )}
          >
            Check your WhatsApp with the code
            <sup className="text-red-500">*</sup>
          </p>
        </div>
        <div className="w-full mt-4 pb-9">
          <OtpInput
            length={otpLength}
            data={data?.otp}
            onChange={(data) => {
              onChange("update", "account", "otp", data);
            }}
            className={cn(
              "w-[13%] h-11",
              data?.isOTP
                ? "text-[var(--new-primary)]"
                : "text-[var(--font-600)]"
            )}
            disabled={!data?.isOTP}
            clearOtp={clearOtp}
            toastError={toastError}
          />
          {otpError && (
            <div className="flex-row align-center pt-5">
              <img src={ICONS?.alertIconError} alt="alertIconError" />
              <span className="error-message pl-5">{otpError}</span>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className={cn(
              "px-3.5 rounded-[6px]  py-2 font16px-600 flex items-center justify-center",
              validation()
                ? isLoading
                  ? "cursor-not-allowed bg-[var(--new-primary)] text-[var(--white)]"
                  : "bg-[var(--new-primary)] text-[var(--white)] cursor-pointer"
                : "bg-[var(--BG-50)] text-[#566F8F] cursor-not-allowed"
            )}
          >
            {isLoading ? (
              <div className=" flex items-center justify-center">
                <Loader Width={100} Height={20} loaderBg="white" />
              </div>
            ) : (
              "Create Account"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
