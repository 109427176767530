import React from 'react';
import { Link } from 'react-router-dom';

// assets
import Arrow from 'assets/customSVG/Arrow';

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div>
      <ul className='flex items-center gap-3'>
        {breadcrumbs?.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs.length - 1;

          return (
            <li
              key={index}
              className='text-sm weight-medium flex items-center gap-3'
            >
              {!isLast ? (
                <Link to={breadcrumb.path} className='text-[var(--font-400)]'>
                  {breadcrumb.label}
                </Link>
              ) : (
                <span className='text-[#6940F2]'>{breadcrumb.label}</span>
              )}
              {!isLast && (
                <Arrow
                  width={10}
                  height={10}
                  color='var(--font-400)'
                  className={'rotate-180'}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
