// import React, { useEffect, useState } from 'react';
// import Dropdown from '../../commonComponents/dropdown';
// import Arrow from '../../../../../assets/customSVG/Arrow';
// import Loader from '../../../../../components/commonComponents/Loader/Index';
// import Button from '../../commonComponents/button';

// import AudienceFile from '../../../../../assets/icons/AudienceFile.svg';
// import {
//   contactFilters,
//   crmFontColor,
//   csvInfo,
//   primaryColor,
// } from '../../../constants';
// import { ICONS } from '../../../../../assets/icons';
// import redDelete from '../../../../../assets/icons/redelete.svg';
// import {
//   handleContactModelSelection,
//   setFiles,
// } from '../../../../../reduxToolkit/crmSlice';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import UploadLoading from './UploadLoading';
// import CsvInfo from './CsvInfo';

// import SelectMergeOption from './SelectMergeOption';
import MapContacts from './MapContacts';
import ImportContacts from './ImportContacts';
const UploadFromCsv = ({
  selectOpen,
  setSelectOpen,
  importStep,
  setImportStep,
}) => {
  // const dispatch = useDispatch();
  const {
    // modelContactSources,
    // selectedContactSource,
    files,
  } = useSelector((store) => store.crm);

  // const handleModelContactSelection = (id) => {
  //   dispatch(handleContactModelSelection({ id }));
  //   setSelectOpen(false);
  // };

  // const [mergeOption, setMergeOption] = useState('REM');
  // const [files, setFiles] = useState([]);

  // ==> step 1
  //1.1 get google url from prefix - 70%
  //1.2 put the uploaded file to this link to get the google url - 100%
  // useEffect(() => {
  //   if (files.length === 0) return;

  //   // const fetch = () => {
  //   const time = setTimeout(() => {
  //     // axios.get('prefetch google url') => www.g.upload.com/
  //     // axios.put( www.g.upload.com/ body=> file)
  //     setFiles((prev) => {
  //       return prev.map((item, i) => {
  //         if (i + 1 === files.length) {
  //           return { ...item, progress: 100 };
  //         }
  //         return item;
  //       });
  //     });
  //   }, 1000);
  //   // };
  //   // const start = fetch();
  //   return clearInterval(time);
  // }, [files]);

  // ==> step 2
  //2.1 post the google url from 1.2 to our endpoint for sanity checks
  // useEffect(() => {}, []);
  if (importStep === 2) {
    return <MapContacts />;
  }
  return (
    <ImportContacts
      selectOpen={selectOpen}
      setSelectOpen={setSelectOpen}
      importStep={importStep}
      setImportStep={setImportStep}
    />
  );
};

export default UploadFromCsv;
