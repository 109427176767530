import React from 'react';

const EditIcon = ({ width = 14, height = 14, color = '#616874' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.08638 3.21333L10.7864 5.91333M1.33643 12.6633L4.61091 12.0035C4.78475 11.9685 4.94436 11.8829 5.06971 11.7575L12.4 4.42322C12.7514 4.07158 12.7512 3.50158 12.3994 3.15024L10.8466 1.59918C10.495 1.24798 9.92532 1.24822 9.57402 1.59972L2.243 8.93475C2.11789 9.05993 2.03246 9.21922 1.9974 9.3927L1.33643 12.6633Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EditIcon;
