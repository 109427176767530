export const updateCommonReducerData = ({
  state,
  primaryKey,
  secondaryKey,
  key,
  data,
}) => {
  if (primaryKey && secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [secondaryKey]: {
        ...state[primaryKey][secondaryKey],
        [key]: { ...state[primaryKey][secondaryKey][key], ...data },
      },
    };
  else if (primaryKey && !secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [key]: { ...state[primaryKey][key], ...data },
    };
  else if (!primaryKey && !secondaryKey && key)
    state[key] = { ...state[key], ...data };

  return state;
};

export const updateCommonReducerValue = ({
  state,
  primaryKey,
  secondaryKey,
  key,
  value,
}) => {
  if (primaryKey && !secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [key]: value,
    };
  else if (primaryKey && secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [secondaryKey]: {
        ...state[primaryKey][secondaryKey],
        [key]: value,
      },
    };
  else if (!primaryKey && !secondaryKey && key) state[key] = value;

  return state;
};

export const onChangeObject = ({
  obj,
  primaryKey,
  secondaryKey,
  key,
  value,
  data,
}) => {
  if (primaryKey && secondaryKey && key)
    obj = {
      ...obj,
      [primaryKey]: {
        ...obj[primaryKey],
        [secondaryKey]: {
          ...obj[primaryKey][secondaryKey],
          [key]: data,
        },
      },
    };
  else if (primaryKey && !secondaryKey && key)
    obj = {
      ...obj,
      [primaryKey]: {
        ...obj[primaryKey],
        [key]: data,
      },
    };
  else if (!primaryKey && !secondaryKey && key)
    obj = {
      ...obj,
      [key]: data,
    };
  else if (!primaryKey && !secondaryKey && !key) obj = { ...obj, ...data };
  else obj;

  return obj;
};

export const generateRandomId = () => {
  const id = "id-" + Date.now() + "-" + Math.random().toString(36).substr(2, 9);
  return id;
};

export const findMaxId = (arr) => {
  if (arr?.length === 0) return null;

  return arr?.reduce((max, current) => {
    return current.id > max ? current.id : max;
  }, arr?.[0]?.id);
};

export const isObjectEmpty = (obj) => {
  if (typeof obj !== "object" || obj === null) return false;

  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const calculatePositionUsingRef = (ref, event) => {
  if (!ref.current) return;

  const rect = ref.current.getBoundingClientRect();
  const centerX = rect.left + Math.abs(rect.right - rect.left) / 2;
  const centerY = rect.top + Math.abs(rect.bottom - rect.top) / 2;
  return { x: centerX, y: centerY };
};

export const calculatePositionUsingId = (id, event) => {
  const element = document.getElementById(id);
  if (!element) return null;

  const rect = element.getBoundingClientRect();

  const centerX = rect.left + rect.width / 2;
  const centerY = rect.top + rect.height / 2;

  return { x: centerX, y: centerY };
};

export const calculatePositionUsingEvents = (event) => {
  event.stopPropagation();
};

export const useElementPosition = (id) => {
  const getPosition = () => {
    const element = document.getElementById(id);
    if (element) {
      const rect = element.getBoundingClientRect();
      return {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
      };
    }
    return null;
  };

  return getPosition;
};

export const formatNumber = (num) => num.toLocaleString();

export const convertSecondsToHours = (seconds) => Math.floor(seconds / 3600);

export const convertToK = (value) =>
  value >= 10000000
    ? `${value / 10000000}C`
    : value >= 100000
    ? `${value / 100000}L`
    : value >= 1000
    ? `${value / 1000}K`
    : value.toString();
