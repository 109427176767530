import React, { useEffect, useState } from "react";
import "../styles/overview.css";

import { useOutletContext } from "react-router-dom";
import { ICONS } from "../../../assets/icons";

import OverviewChart from "../components/overviewChart/BroadcastPerformance";
import Performance from "../components/overviewChart/Performance";
import {
  overviewData,
  performanceData,
  performanceLeads,
} from "../constants/overview";
import GaugeCharts from "../components/overviewChart/GaugeCharts";
import AreaChart from "../components/overviewChart/AreaChart";
import GlobalLoader from "../../../components/commonComponents/Loader/globalLoader";
import { Tooltip } from "antd";

import { formatCount } from "../helper/ThousandsCount";

const Overview = () => {
  const {
    performanceList,
    OverviewList,
    goalInfo,
    goalInfoDate,
    campaignOverviewData,
    handlePerformance,
    filter,
    captureRef,
    loader,
    startDate,
    endDate,
  } = useOutletContext();

  const performanceDataMapping =
    campaignOverviewData?.campaign_goal_type_id === 2
      ? performanceLeads
      : performanceData;

  const [updatedoverviewData, setUpdatedoverviewData] = useState(overviewData);
  const [updatedPerformanceData, setUpdatedPerformanceData] = useState(
    performanceDataMapping
  );

  useEffect(() => {
    if (OverviewList) {
      setUpdatedoverviewData(
        overviewData?.map((item) => {
          switch (item.label) {
            case "Delivered":
              return {
                ...item,
                count: OverviewList?.DELIVERED,
                data: OverviewList?.delivered,
              };
            case "Bounced":
              return {
                ...item,
                count: OverviewList?.FAILED,
                data: OverviewList?.bounced,
              };
            case "Read":
              return {
                ...item,
                count: OverviewList?.READ,
                data: OverviewList?.read,
              };
            case "Sent":
              return {
                ...item,
                count: OverviewList?.SENT,
                data: OverviewList?.sent,
              };
            default:
              return item;
          }
        })
      );
    }

    if (performanceDataMapping) {
      setUpdatedPerformanceData(
        performanceDataMapping?.map((item) => {
          switch (item.label) {
            case "Clicks":
              return { ...item, count: performanceList?.URL_CLICKED };
            case "Reactions":
              return { ...item, count: performanceList?.REACTED };
            case "Leads":
              return { ...item, count: performanceList?.LEAD };
            case "Opt-out":
              return { ...item, count: performanceList?.OPT_OUT };
            default:
              return item;
          }
        })
      );
    }
  }, [OverviewList, performanceList]);

  // const goalInfoRoundof =
  //   goalInfo?.metric_type === "NUMBER"
  //     ? goalInfo?.goal_target < goalInfo?.goal_achieved
  //     : goalInfo?.goal_target - goalInfo?.goal_achieved;

  // const goalRound =
  //   goalInfo?.metric_type === "NUMBER" ? 0 : `${Math.round(goalInfoRoundof)} %`;

  const goalTarget = goalInfo?.goal_target || 0;
  const goalAchieved = goalInfo?.goal_achieved || 0;

  const goalDifference = goalTarget - goalAchieved;
  const goalRound2 = goalDifference < 0 ? 0 : goalDifference;

  const goalRound =
    goalInfo?.metric_type === "NUMBER"
      ? goalRound2
      : `${Math.round(goalRound2)} %`;

  return (
    <div className={`h-full max-h-[72vh] overflow-y-scroll`}>
      <div className="flex flex-col gap-7 overflow-hidden" ref={captureRef}>
        <div className="flex flex-row align-center gap-7 items-stretch">
          <div className="grid grid-cols-2 w-[30%] !min-h-[44vh] gap-7">
            {updatedoverviewData?.map((item) => (
              <div
                className="w-100 bg-white rounded-2xl flex flex-col prl-10 justify-between"
                key={item?.id}
              >
                <>
                  <div className="pl-[10px] ">
                    <Tooltip
                      title={`Total ${item?.label}: ${item?.count || 0}`}
                    >
                      <div
                        className="flex-row align-center gap-2 no-search-body"
                        style={{ transform: "translate(0px, 15px)" }}
                      >
                        <img src={item?.icon} alt="" className="w-20" />
                        {item?.label}
                        <img
                          src={ICONS?.conversationAction}
                          alt="performanceIcon"
                          className="pointer"
                        />
                      </div>
                    </Tooltip>
                    {loader ? (
                      <div
                        className="loader"
                        style={{ transform: "translate(0px, 4vw)" }}
                      ></div>
                    ) : (
                      <div className="flex-column space-between hello">
                        <div
                          className={`${
                            item?.count === undefined
                              ? "main-header-light"
                              : "main-header"
                          }`}
                          style={{ transform: "translate(0px, 20px)" }}
                        >
                          {formatCount(item?.count || 0)}
                        </div>
                        <AreaChart
                          data={item?.data}
                          color={item.color}
                          name={item?.label}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    )}
                  </div>
                </>

                {/* <AreaChart OverviewList={OverviewList} /> */}
              </div>
            ))}
          </div>
          <div className="w-50 !min-h-[44vh] bg-white rounded-2xl flex flex-col">
            <>
              <div className="bg-white rounded-[1vw]  w-100">
                <div className="flex-row justify-between items-center pt-20 prl-10 ml-10 mr-10 border-b-[1px] pb-2">
                  <p className="flex-row items-center chart-headers">
                    Performance
                  </p>
                </div>
                {loader ? (
                  <div
                    className="flex justify-center items-center"
                    style={{ height: "34vh" }}
                  >
                    <div class="loader flex-row align-center"></div>
                  </div>
                ) : (
                  <>
                    <Performance
                      performanceList={performanceList}
                      startDate={startDate}
                      endDate={endDate}
                      updatedPerformanceData={updatedPerformanceData}
                    />

                    <div className="flex-row align-center text-[var(--textBlack)] mt-10 pt-5">
                      {updatedPerformanceData?.map((item) => (
                        <div
                          key={item?.id}
                          className={`w-full flex-col items-center gap-[1.3vw] border-r-[1px] last:border-r-0 ${
                            item?.label !== "Conversions"
                              ? "pointer"
                              : "cursor-not-allowed"
                          }`}
                          onClick={() => {
                            item?.label !== "Conversions" &&
                              handlePerformance(item?.value);
                          }}
                        >
                          <div
                            className={`rounded-lg w-100 ${
                              filter?.includes(item?.value) &&
                              item?.label !== "Conversions"
                                ? "bg-[#E8E8EA] w-95 m-5 p-5"
                                : ""
                            }`}
                          >
                            <div className="flex-row justify-center items-center">
                              <img
                                src={item?.icon}
                                alt="summary"
                                className="w-5"
                              />
                              <span className="no-search-body px-2">
                                {item?.label}
                              </span>
                              <Tooltip
                                title={`Overall ${
                                  item?.label === "Clicks"
                                    ? "Website Clicks"
                                    : item?.label
                                } Count`}
                              >
                                <img
                                  src={ICONS?.conversationAction}
                                  alt="performanceIcon"
                                  className=""
                                />
                              </Tooltip>
                            </div>
                            <div className="flex-row justify-center pt-5">
                              <span
                                className={` ${
                                  item?.count === undefined ||
                                  item?.label === "Conversions"
                                    ? "count-style-light"
                                    : "count-style"
                                }`}
                              >
                                {item?.count || 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </>
          </div>
          <div className="w-30 !min-h-[44vh] bg-white rounded-2xl flex flex-col px-6 pt-20  overflow-hidden">
            <>
              <div className="flex-row align-center border-b pb-2">
                <span className="chart-headers">Goal Info</span>
                <Tooltip
                  title={`${
                    goalInfo?.goal_evaluation_type?.charAt(0).toUpperCase() +
                    goalInfo?.goal_evaluation_type?.slice(1).toLowerCase()
                  } Counts`}
                >
                  <img
                    src={ICONS?.conversationAction}
                    alt="performanceIcon"
                    className="pointer pl-10"
                  />
                </Tooltip>
              </div>
              {/* <span className="w-100 border-b mt-5 mb-20"></span> */}

              {loader ? (
                <div class="loader"></div>
              ) : (
                <div className="flex-column space-between min-h-[36vh] pt-[3vw]">
                  <GaugeCharts
                    id="gauge-chart1"
                    goalInfo={goalInfo}
                    campaignOverviewData={campaignOverviewData}
                  />
                  <div className="ml-10 w-90 h-100 flex-row space-between">
                    <div className="flex-column align-center">
                      <span
                        className={`ml-20 pt-20 flex-row align-center gap-2 ${
                          goalRound === 0 ? "main-header-light" : "main-header"
                        }`}
                      >
                        <img
                          src={ICONS?.goalTargetInfo}
                          alt="goalTargetInfo"
                          className=""
                        />
                        {goalRound}
                      </span>
                      <span className="ml-20 new-sidebar">
                        Targets remaining
                      </span>
                    </div>

                    <div className="flex-column align-center">
                      <span
                        className={`ml-20 pt-20 flex-row align-center gap-2 ${
                          goalInfo?.progress_status === "No data found"
                            ? "main-header-light"
                            : "main-header"
                        }`}
                      >
                        <img
                          src={ICONS?.goalRemainingDays}
                          alt="goalTargetInfo"
                          className="w-60"
                        />
                        {goalInfo?.progress_status === "No data found"
                          ? 0
                          : goalInfoDate}
                      </span>
                      <span className="ml-20 new-sidebar">Days remaining</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
        <div className="w-100 h-100 bg-white rounded-2xl flex flex-col ptb-10">
          <OverviewChart />
        </div>
      </div>
    </div>
  );
};

export default Overview;
